import React from 'react';

const Miles = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/miles"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M21,4 C22.6568542,4 24,5.34314575 24,7 L24,18 C24,19.6568542 22.6568542,21 21,21 L3,21 C1.34314575,21 2.02906125e-16,19.6568542 0,18 L0,7 C-2.02906125e-16,5.34314575 1.34314575,4 3,4 L21,4 Z M14.0875386,7.5 L13.4745309,7.5 L14.1915399,9.7651549 L14.4000425,10.4341437 L14.758047,11.5794804 L12.3185165,11.7471029 L11.4045051,10.8204259 C11.3700046,10.7818977 11.3120039,10.7558787 11.2545032,10.7558787 L11,10.7558787 L11.5205065,12.4546198 L11,14.1278422 L11.242503,14.1278422 C11.3005038,14.1278422 11.3470043,14.1018232 11.3930049,14.0632951 L12.3065163,13.1371184 L14.7465468,13.3042405 L14.3765422,14.4625868 L14.1685396,15.1315755 L13.4630308,17.3837209 L14.0760385,17.3837209 C14.1915399,17.3837209 14.3070413,17.3321833 14.3765422,17.2296083 L17.2330779,13.471863 L20.3671171,13.1501279 C20.4596182,13.1371184 20.5521194,13.0985903 20.6216203,13.0210336 L20.8526232,12.7893643 C21.0491256,12.5967236 21.0491256,12.261979 20.8526232,12.0688379 L20.6216203,11.8371687 C20.5521194,11.759612 20.4596182,11.7210839 20.3671171,11.7085747 L17.2330779,11.3868397 L14.3880424,7.65461296 C14.3070413,7.55153765 14.20304,7.5 14.0875386,7.5 Z M8,11.5 C7.44771525,11.5 7,11.9425093 7,12.4883721 C7,13.0342349 7.44771525,13.4767442 8,13.4767442 C8.55228475,13.4767442 9,13.0342349 9,12.4883721 C9,11.9425093 8.55228475,11.5 8,11.5 Z M4,11.5 C3.44771525,11.5 3,11.9425093 3,12.4883721 C3,13.0342349 3.44771525,13.4767442 4,13.4767442 C4.55228475,13.4767442 5,13.0342349 5,12.4883721 C5,11.9425093 4.55228475,11.5 4,11.5 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Miles;
