export const noNegativeValues = number => {
    return number < 0 ? 0 : number;
};

export const onlyNums = str => {
    const num = str.match(/(\d+)/g);
    return num ? num.join('') : '';
};

export const removeDuplicateWhitespaces = string => {
    return string.replaceAll(/\s+/g, ' ');
};
export const removeDuplicatedDashes = string => {
    return string.replaceAll(/-+/g, '-');
};
export const removeDuplicatedDots = string => {
    return string.replaceAll(/\.+/g, '.');
};

export const phoneWithDash = str => {
    const strWithSingleWS = removeDuplicateWhitespaces(str);
    const strWithSingleDash = removeDuplicatedDashes(strWithSingleWS);
    const phoneRegex = /^\+?([0-9]+[-|\s]?)*/g;
    const validPhone = strWithSingleDash.match(phoneRegex)[0];
    return validPhone;
};

export const onlyNumsWithDotDash = str => {
    const strWithSingleWS = removeDuplicateWhitespaces(str);
    const strWithSingleDash = removeDuplicatedDashes(strWithSingleWS);
    const strWithSingleDots = removeDuplicatedDots(strWithSingleDash);
    const numRegex = /([0-9]+[-|\s|\.]?)*/g;
    const validResult = strWithSingleDots.match(numRegex)[0];
    return validResult;
};

export const onlyAlphanumericWithDotDash = str => {
    const strWithSingleWS = removeDuplicateWhitespaces(str);
    const strWithSingleDash = removeDuplicatedDashes(strWithSingleWS);
    const strWithSingleDots = removeDuplicatedDots(strWithSingleDash);
    const alphaNumRegex = /([a-zA-Z0-9\s\.\-]*)/g;
    return strWithSingleDots.match(alphaNumRegex)[0];
};

export const capitalizeFirstLetter = str => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const cutText = (str, length) => {
    if (str !== null && str.length > length) {
        return `${str.substring(0, length)}...`;
    }
    return str;
};

export const fixDecimals = num => {
    if (!num || num === null || num === 0) return 0;
    let returnNum = num;
    if (typeof num !== 'number') returnNum = parseFloat(returnNum);

    if (returnNum <= 0.01) return 0.01;
    return returnNum % 1 !== 0 ? returnNum.toFixed(2) : returnNum;
};

export const countDecimals = num => {
    const stringedNum = String(num);
    let decimals = 0;
    if (stringedNum.includes('.') || stringedNum.includes(',')) {
        decimals = String(num).split('.')[1].length;
    }
    return decimals;
};

export const isEmptyObject = obj => {
    for (let key in obj) {
        if (
            obj[key] !== null &&
            obj[key] !== '' &&
            obj[key] !== undefined &&
            obj[key] !== {} &&
            typeof obj[key] !== 'boolean'
        )
            return false;
    }
    return true;
};

export const buildErrors = errors => {
    const newErrors = {};
    [...errors].forEach(err => {
        newErrors[err.path] = err.message;
    });
    return newErrors;
};

export const weightRestriction = (tareWeight, actualWeight, maxGrossWeight) => {
    if (maxGrossWeight !== 0) {
        return Number(tareWeight) + Number(actualWeight) > maxGrossWeight;
    }
    return false;
};

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = Math.random() * 16 || 0;
        const v = c === 'x' ? r : (r && 0x3) || 0x8;
        return v.toString(16);
    });
};

export const isEmptyValues = value => {
    return (
        value === undefined ||
        value === null ||
        value === NaN ||
        (typeof value === 'array' && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const updateStops = (value, option, stops) => {
    let newValues = [...stops];
    if (!value) {
        const indexOfValue = stops.indexOf(option);
        newValues.splice(indexOfValue, 1);
    } else {
        newValues.push(option);
    }
    return newValues;
};

export const validatePhoneWithDashes = phone => {
    const phoneRegex = /^\+?([0-9]+-?)*$/g;
    return phoneRegex.test(phone);
};

export const phoneValidation = v => {
    return validatePhoneWithDashes(v) || !v || v === '+';
};

export const isOverwrittenRoute = route => {
    if (!route) return false;
    if (!isEmptyValues(route?.embargoes_applieds)) {
        return true;
    }
    return false;
};
