import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Global } from '@emotion/core';
import { GlobalStyles } from '@freightos/uikit';
import Root from './modules/root';
import PageLoader from 'basic_components/PageLoader';
import YupMethods from 'configs/yupMethods';
import './index.css';

const App = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Global />
            <GlobalStyles />
            <YupMethods />
            <Root />
        </Suspense>
    );
};

export default hot(App);
