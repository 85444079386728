import React from 'react';

const UnionPay = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/unionpay"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group" transform="translate(0.000000, 5.000000)">
                    <path
                        d="M4.6618831,0 L10.5670822,0 C11.4015125,0 11.9150081,0.652173913 11.7224473,1.5 L8.96240854,13.4347826 C8.7698477,14.2826087 7.93541739,14.9347826 7.10098708,14.9347826 L1.19578796,14.9347826 C0.361357646,14.9347826 -0.15213793,14.2826087 0.0404229109,13.4347826 L2.80046163,1.5 C3.05720942,0.717391304 3.89163973,0 4.6618831,0 Z"
                        id="Shape"
                        fill="#CF0A2C"
                        fillRule="nonzero"></path>
                    <path
                        d="M10.4387083,0 L17.2425247,0 C18.076955,0 17.6918333,0.652173913 17.4992725,1.5 L14.7392338,13.4347826 C14.5466729,14.2826087 14.6108599,14.9347826 13.7764296,14.9347826 L6.97261319,14.9347826 C6.13818288,14.9347826 5.6246873,14.2826087 5.81724814,13.4347826 L8.57728686,1.5 C8.7698477,0.717391304 9.60427801,0 10.4387083,0 Z"
                        id="Shape"
                        fill="#002856"
                        fillRule="nonzero"></path>
                    <path
                        d="M16.857403,0 L22.7626022,0 C23.5970325,0 24.110528,0.652173913 23.9179672,1.5 L21.1579285,13.4347826 C20.9653676,14.2826087 20.1309373,14.9347826 19.296507,14.9347826 L13.3913079,14.9347826 C12.5568776,14.9347826 12.043382,14.2826087 12.2359428,13.4347826 L14.9959816,1.5 C15.1885424,0.717391304 16.0229727,0 16.857403,0 Z"
                        id="Shape"
                        fill="#007078"
                        fillRule="nonzero"></path>
                    <path
                        d="M17.882915,8.80220408 L17.1604381,11.0687347 L17.3554229,11.0687347 L17.2043419,11.537102 L17.011294,11.537102 L16.9654532,11.6815918 L16.2778411,11.6815918 L16.3249732,11.537102 L14.9290883,11.537102 L15.0685477,11.1036327 L15.2105896,11.1036327 L15.9427513,8.80220408 L16.0893127,8.33812245 L16.7898377,8.33812245 L16.7162342,8.572 C16.7162342,8.572 16.9028256,8.44465306 17.080378,8.40179592 C17.2566392,8.35710204 18.2728847,8.34118367 18.2728847,8.34118367 L18.1224494,8.80220408 L17.882915,8.80220408 L17.882915,8.80220408 Z M16.6477958,8.80220408 L16.46185,9.38138776 C16.46185,9.38138776 16.6703934,9.29261224 16.7827356,9.26322449 C16.8983061,9.23383673 17.0700477,9.22404082 17.0700477,9.22404082 L17.2043419,8.80220408 L16.6477958,8.80220408 L16.6477958,8.80220408 Z M16.3688771,9.66853061 L16.1771205,10.2709796 C16.1771205,10.2709796 16.3901834,10.1681224 16.5031713,10.1362857 C16.6187418,10.1117959 16.7937116,10.0903673 16.7937116,10.0903673 L16.9286514,9.66853061 L16.3688771,9.66853061 L16.3688771,9.66853061 Z M15.9233819,11.0742449 L16.4812193,11.0742449 L16.641985,10.5654694 L16.0847932,10.5654694 L15.9233819,11.0742449 Z"
                        id="Fill-10"
                        fill="#FFFFFF"></path>
                    <path
                        d="M18.1919208,8.3412449 L18.9415148,8.3412449 L18.9486169,8.60573469 C18.9434518,8.65042857 18.9847731,8.6712449 19.0712895,8.6712449 L19.2243074,8.6712449 L19.0848481,9.10777551 L18.6800285,9.10777551 C18.3307345,9.13104082 18.1970859,8.989 18.206125,8.82859184 L18.1919208,8.3412449 Z"
                        id="Fill-12"
                        fill="#FFFFFF"></path>
                    <path
                        d="M18.2915438,10.4123469 L17.5781059,10.4123469 L17.7001329,10.0235714 L18.5162283,10.0235714 L18.6311532,9.66846939 L17.8266793,9.66846939 L17.9635561,9.23010204 L20.2039448,9.23010204 L20.0644855,9.66846939 L19.3129545,9.66846939 L19.1954471,10.0235714 L19.9495606,10.0235714 L19.8243055,10.4123469 L19.0101469,10.4123469 L18.8655224,10.5758163 L19.1954471,10.5758163 L19.2761527,11.065 C19.2851917,11.1139796 19.2851917,11.1458163 19.3019785,11.1666327 C19.3194109,11.1843878 19.4188403,11.1929592 19.4763027,11.1929592 L19.5763777,11.1929592 L19.4240055,11.6692857 L19.1689756,11.6692857 C19.1302369,11.6692857 19.0714832,11.6668367 18.9901319,11.6631633 C18.9152371,11.6570408 18.8616485,11.6147959 18.8093513,11.5903061 C18.7622192,11.5694898 18.6931351,11.5156122 18.6763484,11.4237755 L18.596934,10.9352041 L18.225688,11.4170408 C18.1088262,11.5694898 17.9499975,11.6852041 17.6807635,11.6852041 L17.1623104,11.6852041 L17.2985416,11.2603061 L17.4967546,11.2603061 C17.5535714,11.2603061 17.6039317,11.2388776 17.6413791,11.2205102 C17.6794722,11.2052041 17.7130458,11.1868367 17.7511388,11.1341837 L18.2915438,10.4123469 Z"
                        id="Fill-14"
                        fill="#FFFFFF"></path>
                    <path
                        d="M10.4788499,9.34808163 L12.3692989,9.34808163 L12.2291939,9.77481633 L11.4737891,9.77481633 L11.355636,10.1397143 L12.1284733,10.1397143 L11.9877226,10.5805306 L11.215531,10.5805306 L11.0276482,11.1731837 C11.004405,11.2386939 11.2116571,11.2472653 11.2859063,11.2472653 L11.6720021,11.1970612 L11.5164016,11.6850204 L10.646072,11.6850204 C10.575051,11.6850204 10.5233994,11.6758367 10.4478589,11.6593061 C10.374901,11.6421633 10.3419731,11.6109388 10.3096908,11.5637959 C10.2780542,11.5154286 10.2283395,11.4762449 10.2625587,11.3727755 L10.513069,10.5878776 L10.083715,10.5878776 L10.2257569,10.1397143 L10.6564023,10.1397143 L10.7713271,9.77481633 L10.3419731,9.77481633 L10.4788499,9.34808163 Z"
                        id="Fill-16"
                        fill="#FFFFFF"></path>
                    <path
                        d="M11.7499315,8.57983673 L12.5247057,8.57983673 L12.3846007,9.02616327 L11.3257426,9.02616327 L11.2114634,9.12106122 C11.1617487,9.16636735 11.144962,9.14738776 11.0803975,9.17983673 C11.0203525,9.208 10.8957429,9.26493878 10.7323947,9.26493878 L10.3927853,9.26493878 L10.5296621,8.83636735 L10.6323197,8.83636735 C10.7175449,8.83636735 10.7769442,8.82840816 10.8066439,8.81065306 C10.8408631,8.79044898 10.8783105,8.74514286 10.9202775,8.67106122 L11.1159079,8.33616327 L11.885517,8.33616327 L11.7499315,8.57983673 Z"
                        id="Fill-18"
                        fill="#FFFFFF"></path>
                    <path
                        d="M12.8577295,8.3412449 L13.5175789,8.3412449 L13.4213777,8.65655102 C13.4213777,8.65655102 13.6299211,8.49797959 13.7764826,8.44104082 C13.9211071,8.39022449 14.2503861,8.34430612 14.2503861,8.34430612 L15.3189289,8.33818367 L14.954785,9.48797959 C14.8934487,9.6845102 14.8217821,9.81185714 14.7772326,9.87002041 C14.7372026,9.92879592 14.6900705,9.97961224 14.5970976,10.0273673 C14.5079986,10.0732857 14.425356,10.099 14.3498155,10.1069592 C14.2787945,10.1118571 14.1709718,10.1130816 14.0211821,10.1149184 L12.991378,10.1149184 L12.702129,11.0234898 C12.6743663,11.1134898 12.6608077,11.1575714 12.6795314,11.1820612 C12.6937356,11.2028776 12.7286005,11.2273673 12.7770238,11.2273673 L13.231558,11.1863469 L13.0759576,11.6853265 L12.5684805,11.6853265 C12.4070692,11.6853265 12.2889161,11.6816531 12.2069192,11.6755306 C12.1281504,11.6694082 12.0467992,11.6755306 11.9912737,11.6363469 C11.9447872,11.596551 11.872475,11.543898 11.8744119,11.4912449 C11.8789314,11.4416531 11.901529,11.3602245 11.9338112,11.2469592 L12.8577295,8.3412449 L12.8577295,8.3412449 Z M14.2594252,9.50206122 L13.1779695,9.50206122 L13.111468,9.70777551 L14.0476535,9.70777551 C14.1580589,9.69553061 14.1813021,9.71022449 14.1903411,9.70593878 L14.2594252,9.50206122 L14.2594252,9.50206122 Z M13.2367232,9.31471429 C13.2367232,9.31471429 13.4478492,9.13104082 13.8107018,9.07165306 C13.8926987,9.05757143 14.4066323,9.06246939 14.4066323,9.06246939 L14.485401,8.81573469 L13.3942606,8.81573469 L13.2367232,9.31471429 Z"
                        id="Fill-20"
                        fill="#FFFFFF"></path>
                    <path
                        d="M14.7827206,10.1953061 L14.7213843,10.4714286 C14.6955585,10.5583673 14.6729609,10.6220408 14.6045225,10.6777551 C14.5315646,10.7365306 14.4469851,10.7971429 14.2494177,10.7971429 L13.8820456,10.8118367 L13.8801086,11.1240816 C13.8762347,11.2110204 13.9007693,11.2030612 13.9149735,11.2165306 C13.9324059,11.232449 13.9472557,11.2385714 13.9627512,11.2453061 L14.079613,11.2385714 L14.428907,11.2208163 L14.2842825,11.6757143 L13.8820456,11.6757143 C13.6018356,11.6757143 13.3932922,11.6689796 13.3248538,11.6181633 C13.2577067,11.5777551 13.2486676,11.5269388 13.2493133,11.4387755 L13.2757847,10.2228571 L13.9162648,10.2228571 L13.9078714,10.4714286 L14.0615349,10.4714286 C14.1144778,10.4714286 14.1499883,10.4665306 14.1719402,10.4530612 C14.1919552,10.4389796 14.2055138,10.4206122 14.2145528,10.39 L14.2791173,10.1953061 L14.7827206,10.1953061 Z"
                        id="Fill-22"
                        fill="#FFFFFF"></path>
                    <path
                        d="M5.63651105,4.09679592 C5.61520476,4.19597959 5.20328314,5.9947551 5.20199185,5.99659184 C5.11353846,6.36026531 5.04961959,6.61985714 4.83074588,6.78761224 C4.70678201,6.88434694 4.56151184,6.9314898 4.39299845,6.9314898 C4.12311877,6.9314898 3.96558135,6.80414286 3.93910989,6.56291837 L3.93394473,6.48026531 C3.93394473,6.48026531 4.01658732,5.99291837 4.01658732,5.98985714 C4.01658732,5.98985714 4.44787829,4.35087755 4.52535571,4.13414286 C4.52922958,4.12189796 4.53052087,4.11516327 4.53181216,4.10965306 C3.69118215,4.11638776 3.54268376,4.10965306 3.53235344,4.09679592 C3.52654263,4.11393878 3.50588198,4.21557143 3.50588198,4.21557143 L3.06555198,6.06332653 L3.02745891,6.22006122 L2.95450101,6.7325102 C2.95450101,6.88434694 2.98613762,7.00863265 3.04811956,7.11332653 C3.24956085,7.44638776 3.82160247,7.49597959 4.14571635,7.49597959 C4.56344878,7.49597959 4.95470975,7.41210204 5.21942427,7.25842857 C5.67847799,7.00128571 5.79856799,6.59904082 5.90574509,6.2414898 L5.95545977,6.05781633 C5.95545977,6.05781633 6.40030929,4.35577551 6.47584978,4.13414286 C6.47843236,4.12189796 6.47972365,4.11516327 6.48359752,4.10965306 C5.87410848,4.11516327 5.69461912,4.10965306 5.63651105,4.09679592"
                        id="Fill-24"
                        fill="#FFFFFF"></path>
                    <path
                        d="M8.09674199,7.47846939 C7.79909956,7.47357143 7.69321375,7.47357143 7.345211,7.49132653 L7.33165246,7.46561224 C7.36199778,7.3394898 7.39428004,7.21581633 7.42462536,7.08846939 L7.46723794,6.91642857 C7.53180246,6.6494898 7.59443004,6.33908163 7.60153214,6.24357143 C7.60863423,6.1872449 7.62929488,6.04459184 7.45755326,6.04459184 C7.38459536,6.04459184 7.30905487,6.07765306 7.23286874,6.11193878 C7.19090181,6.25397959 7.10632229,6.65316327 7.06564664,6.835 C6.97977584,7.21887755 6.97461067,7.26295918 6.93522632,7.4527551 L6.9106918,7.47846939 C6.6033647,7.47357143 6.4961876,7.47357143 6.14366534,7.49132653 L6.12752421,7.46255102 C6.18627792,7.23540816 6.24503163,7.00520408 6.30120276,6.77683673 C6.44970115,6.15846939 6.48521163,5.92214286 6.52524163,5.60744898 L6.55365002,5.58908163 C6.89907019,5.54316327 6.98171277,5.53336735 7.35554133,5.46173469 L7.3865323,5.49540816 L7.32971552,5.69193878 C7.39298875,5.65581633 7.45367939,5.62030612 7.51759827,5.58908163 C7.69321375,5.50704082 7.88884424,5.48255102 7.99602134,5.48255102 C8.16066086,5.48255102 8.33885893,5.52663265 8.41310812,5.70540816 C8.48412909,5.86520408 8.43699699,6.06173469 8.34402409,6.44867347 L8.29624634,6.64704082 C8.20069086,7.07744898 8.18519537,7.15704082 8.13160682,7.4527551 L8.09674199,7.47846939 Z"
                        id="Fill-26"
                        fill="#FFFFFF"></path>
                    <path
                        d="M9.30732668,7.47846939 C9.12719168,7.4772449 9.01097555,7.47357143 8.89863329,7.4772449 C8.78629103,7.47846939 8.67653135,7.48397959 8.5099549,7.49132653 L8.50091586,7.4772449 L8.4899399,7.46255102 C8.53642635,7.29908163 8.56096086,7.24214286 8.58355845,7.18459184 C8.60615603,7.12581633 8.62746232,7.06765306 8.66813796,6.90234694 C8.72043522,6.68561224 8.75271748,6.53438776 8.77337813,6.40153061 C8.79855829,6.27357143 8.81082555,6.16459184 8.8301949,6.03785714 L8.84246216,6.02867347 L8.857312,6.01581633 C9.03551007,5.99255102 9.14914362,5.97663265 9.26600539,5.95887755 C9.38286717,5.94418367 9.50102024,5.92214286 9.68567476,5.88785714 L9.69277685,5.90316327 L9.69729637,5.91908163 C9.66372282,6.05316327 9.62821234,6.1872449 9.59399314,6.32255102 C9.56171088,6.45908163 9.52684604,6.59255102 9.49520943,6.72602041 C9.42806233,7.01071429 9.40159088,7.1172449 9.38544975,7.19438776 C9.36995427,7.26540816 9.3660804,7.30581633 9.34025459,7.4527551 L9.32282217,7.46561224 L9.30732668,7.47846939 Z"
                        id="Fill-28"
                        fill="#FFFFFF"></path>
                    <path
                        d="M11.9838487,6.5045102 C12.0884433,6.07042857 12.007092,5.86716327 11.90508,5.74410204 C11.7501252,5.55553061 11.475726,5.49553061 11.1909965,5.49553061 C11.0205462,5.49553061 10.613144,5.51144898 10.294841,5.78940816 C10.0662826,5.98961224 9.96104242,6.26206122 9.89776919,6.52287755 C9.83320468,6.78920408 9.75895548,7.26797959 10.2231744,7.44614286 C10.3665076,7.50430612 10.573114,7.52022449 10.706117,7.52022449 C11.046372,7.52022449 11.3950204,7.43206122 11.6571523,7.16818367 C11.8598849,6.95389796 11.9515665,6.63553061 11.9838487,6.5045102 M11.2032637,6.47328571 C11.1890595,6.5467551 11.1206212,6.81920408 11.0289395,6.93491837 C10.9656663,7.0212449 10.8901258,7.07267347 10.8068376,7.07267347 C10.7823031,7.07267347 10.6338047,7.07267347 10.6318678,6.8645102 C10.6305765,6.76226531 10.6525284,6.65695918 10.6796455,6.54308163 C10.7584142,6.21369388 10.8507415,5.93818367 11.0870476,5.93818367 C11.2717021,5.93818367 11.2859063,6.14328571 11.2032637,6.47328571"
                        id="Fill-30"
                        fill="#FFFFFF"></path>
                    <path
                        d="M19.269438,7.50718367 C18.9098136,7.50412245 18.8065104,7.50412245 18.4746488,7.51820408 L18.4533425,7.4924898 C18.5430872,7.168 18.6334775,6.84473469 18.7141832,6.51718367 C18.8187777,6.09106122 18.8420209,5.91106122 18.8762401,5.66187755 L18.9033572,5.64106122 C19.260399,5.59269388 19.360474,5.57922449 19.73172,5.51371429 L19.7420503,5.54310204 C19.6742575,5.81065306 19.6071104,6.07636735 19.5412546,6.3457551 C19.4043778,6.90595918 19.3553088,7.19004082 19.3023659,7.48391837 L19.269438,7.50718367 Z"
                        id="Fill-32"
                        fill="#FFFFFF"></path>
                    <path
                        d="M19.4409214,6.54338776 C19.5448702,6.1117551 19.1239096,6.50481633 19.0567625,6.36216327 C18.9541049,6.13991837 19.0180238,5.68930612 18.6041652,5.53808163 C18.4453365,5.47930612 18.0721536,5.55583673 17.7512679,5.83257143 C17.5252921,6.03032653 17.4168237,6.30155102 17.3554875,6.5617551 C17.2909229,6.82379592 17.2160281,7.30257143 17.67831,7.474 C17.8255171,7.53277551 17.9572288,7.55053061 18.091523,7.54440816 C18.5563875,7.52053061 18.910201,6.85502041 19.172333,6.59236735 C19.3737743,6.38359184 19.4092848,6.67073469 19.4409214,6.54338776 M18.7294204,6.51093878 C18.7113423,6.58134694 18.6435496,6.8562449 18.551868,6.97073469 C18.4911773,7.05277551 18.3465528,7.10359184 18.2626189,7.10359184 C18.2393757,7.10359184 18.0928142,7.10359184 18.0876491,6.89910204 C18.0863578,6.79746939 18.1089554,6.69277551 18.1360725,6.57828571 C18.2161325,6.25440816 18.3071684,5.98134694 18.5441202,5.98134694 C18.7294204,5.98134694 18.8088347,6.18032653 18.7294204,6.51093878"
                        id="Fill-34"
                        fill="#FFFFFF"></path>
                    <path
                        d="M14.0197617,7.47846939 C13.7201823,7.47357143 13.618816,7.47357143 13.269522,7.49132653 L13.2559634,7.46561224 C13.2863088,7.3394898 13.3198823,7.21581633 13.3508733,7.08846939 L13.3915489,6.91642857 C13.4574047,6.6494898 13.5200323,6.33908163 13.5258431,6.24357143 C13.5329452,6.1872449 13.5536059,6.04459184 13.3831555,6.04459184 C13.307615,6.04459184 13.2333659,6.07765306 13.1571797,6.11193878 C13.1165041,6.25397959 13.0299876,6.65316327 12.9880207,6.835 C12.9047325,7.21887755 12.8989217,7.26295918 12.8608286,7.4527551 L12.8343571,7.47846939 C12.5276757,7.47357143 12.4224355,7.47357143 12.0692676,7.49132653 L12.0531265,7.46255102 C12.1118802,7.23540816 12.1706339,7.00520408 12.226805,6.77683673 C12.3746578,6.15846939 12.408877,5.92214286 12.4501983,5.60744898 L12.4766697,5.58908163 C12.8214442,5.54316327 12.9060237,5.53336735 13.2798523,5.46173469 L13.307615,5.49540816 L13.2540265,5.69193878 C13.3160084,5.65581633 13.378636,5.62030612 13.4412636,5.58908163 C13.6162334,5.50704082 13.8131552,5.48255102 13.9203323,5.48255102 C14.0823893,5.48255102 14.2618786,5.52663265 14.3387104,5.70540816 C14.4084401,5.86520408 14.3606623,6.06173469 14.2676894,6.44867347 L14.2205573,6.64704082 C14.1224193,7.07744898 14.1082151,7.15704082 14.0559178,7.4527551 L14.0197617,7.47846939 Z"
                        id="Fill-36"
                        fill="#FFFFFF"></path>
                    <path
                        d="M16.9239382,5.15028571 C16.871641,5.37497959 16.7173318,5.56477551 16.5191187,5.656 C16.3564161,5.7337551 16.1569118,5.7404898 15.9509509,5.7404898 L15.8173024,5.7404898 L15.8276327,5.68967347 C15.8276327,5.68967347 16.0736235,4.67763265 16.0723322,4.68069388 L16.0794343,4.62865306 L16.0833082,4.58946939 L16.1814463,4.59926531 C16.1814463,4.59926531 16.6876321,4.64028571 16.6992537,4.6415102 C16.8994037,4.71436735 16.9814006,4.90355102 16.9239382,5.15028571 M16.6017613,4.09416327 C16.5998243,4.09416327 16.3531879,4.09661224 16.3531879,4.09661224 C15.7094797,4.10395918 15.4512216,4.10212245 15.3453358,4.08987755 C15.3356511,4.13395918 15.3188643,4.21355102 15.3188643,4.21355102 C15.3188643,4.21355102 15.0877234,5.22742857 15.0877234,5.22926531 C15.0877234,5.22926531 14.5356967,7.38559184 14.5098709,7.48722449 C15.0722279,7.48110204 15.3027232,7.48110204 15.4002156,7.49089796 C15.4221676,7.38926531 15.5525879,6.78742857 15.5538792,6.78742857 C15.5538792,6.78742857 15.6649301,6.34538776 15.6720322,6.32946939 C15.6720322,6.32946939 15.7062514,6.28355102 15.7417619,6.26457143 L15.7927679,6.26457143 C16.2776474,6.26457143 16.8258002,6.26457143 17.2551542,5.96518367 C17.5476315,5.76008163 17.7464902,5.45640816 17.8362349,5.08783673 C17.8588324,4.99783673 17.8762649,4.89008163 17.8762649,4.78232653 C17.8762649,4.6415102 17.8465652,4.50130612 17.7600487,4.39293878 C17.541175,4.10212245 17.1047189,4.09661224 16.6017613,4.09416327"
                        id="Fill-38"
                        fill="#FFFFFF"></path>
                    <path
                        d="M22.2133217,5.51138776 L22.1849134,5.48016327 C21.8168956,5.55057143 21.7503941,5.56220408 21.4127217,5.60506122 L21.3875416,5.62832653 C21.3868959,5.63261224 21.3856046,5.63873469 21.3836677,5.64363265 L21.3823764,5.63873469 C21.1305748,6.18853061 21.1383225,6.07036735 20.933653,6.50322449 C20.9323617,6.48363265 20.9323617,6.47138776 20.9310704,6.45057143 L20.8800645,5.51138776 L20.8477822,5.48016327 C20.462332,5.55057143 20.453293,5.56220408 20.0975425,5.60506122 L20.0697798,5.62832653 C20.0659059,5.63995918 20.0659059,5.65220408 20.0633233,5.66567347 L20.0659059,5.67057143 C20.1104554,5.88608163 20.1001251,5.83771429 20.144029,6.17812245 C20.1646896,6.34526531 20.1924523,6.51302041 20.213113,6.67771429 C20.2486235,6.95383673 20.2679928,7.0897551 20.3112511,7.51097959 C20.0710711,7.88689796 20.0142543,8.02893878 19.7824677,8.35893878 L19.7973175,8.39016327 C20.144029,8.37853061 20.2247346,8.37853061 20.4817014,8.37853061 L20.5378725,8.31791837 C20.7328574,7.92057143 22.2133217,5.51138776 22.2133217,5.51138776"
                        id="Fill-40"
                        fill="#FFFFFF"></path>
                    <path
                        d="M9.63331293,5.74397959 C9.83088035,5.61357143 9.85541487,5.43479592 9.68948406,5.34173469 C9.52161632,5.24683673 9.22655647,5.27744898 9.02834341,5.4072449 C8.83013034,5.53459184 8.80753276,5.71520408 8.97475486,5.81071429 C9.14004002,5.90255102 9.43639115,5.875 9.63331293,5.74397959"
                        id="Fill-42"
                        fill="#FFFFFF"></path>
                    <path
                        d="M20.5121758,8.35079592 L20.2229268,8.821 C20.1312452,8.98202041 19.9607949,9.10202041 19.6915608,9.1032449 L19.2312158,9.09528571 L19.36551,8.67222449 L19.4552547,8.67222449 C19.5023868,8.67222449 19.5359603,8.66977551 19.5617861,8.65814286 C19.5850294,8.65018367 19.6024618,8.63304082 19.6211855,8.60732653 L19.7922815,8.35079592 L20.5121758,8.35079592 Z"
                        id="Fill-44"
                        fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    );
};

export default UnionPay;
