import React from 'react';

const Lock = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/lock"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12.0329289,0 C15.5600677,0 18.4181503,2.8635 18.4181503,6.39825 L18.4181503,6.39825 L18.4181503,7.311 L19.1725225,7.311 C20.4567512,7.311 21.5,8.35425 21.5,9.64275 L21.5,9.64275 L21.5,21.6675 C21.5,22.95525 20.4567512,24 19.1725225,24 L19.1725225,24 L4.82747755,24 C3.54100362,24 2.5,22.95525 2.5,21.6675 L2.5,21.6675 L2.5,9.64275 C2.5,8.35425 3.54100362,7.311 4.82747755,7.311 L4.82747755,7.311 L5.64845596,7.311 L5.64845596,6.39825 C5.64845596,2.8635 8.50653852,0 12.0329289,0 Z M12.0329289,2.3025 C9.77579959,2.3025 7.94749488,4.13625 7.94749488,6.39825 L7.94749488,6.39825 L7.94749488,7.311 L16.118363,7.311 L16.118363,6.39825 C16.118363,4.13625 14.2900583,2.3025 12.0329289,2.3025 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Lock;
