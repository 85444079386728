import React from 'react';

//actions
import { openModal } from 'basic_components/Modal';
import { saveDraft, updateDraft } from 'modules/bookingSteps/actions';
//constants
import { REVIEW_ROUTE } from 'modules/bookingSteps/constants';

import { isDomesticIataRestriction } from 'basic_components/Validations';
import { AlertAtmosNotification } from 'basic_components/AlertAtmosNotification/AlertAtmosNotification';
import { isEmptyValues } from 'basic_components/jsTools';
import removeNotifications from './removeNotifications';
import handleNotifications from './handleNotifications';
import partialSaveCheck from './partialSaveCheck';

const handleDraft = async (
    dispatch,
    values,
    setFieldValue,
    validateForm,
    isFirstStep,
    agent,
    notificationsPreferencesStructure,
    pathname,
    translate
) => {
    if (
        isFirstStep &&
        isDomesticIataRestriction(
            values.origin,
            values.destination,
            values.account_number
        )
    ) {
        return dispatch(openModal('isDomesticIATA', '', '', 800, true));
    }
    removeNotifications();
    if (partialSaveCheck(values)) {
        const valuesWithAgentPhone = {
            ...values,
            agent: { ...values.agent, phone: agent.phone },
        };
        if (pathname === REVIEW_ROUTE) {
            values.id
                ? dispatch(updateDraft(valuesWithAgentPhone))
                : dispatch(saveDraft(valuesWithAgentPhone));
        } else {
            if (!isEmptyValues(notificationsPreferencesStructure)) {
                const validation = await handleNotifications(
                    dispatch,
                    setFieldValue,
                    validateForm,
                    notificationsPreferencesStructure,
                    false
                );
                if (validation) {
                    values.id
                        ? dispatch(updateDraft(valuesWithAgentPhone))
                        : dispatch(saveDraft(valuesWithAgentPhone));
                }
            } else {
                values.id
                    ? dispatch(updateDraft(valuesWithAgentPhone))
                    : dispatch(saveDraft(valuesWithAgentPhone));
            }
        }
    } else {
        AlertAtmosNotification(<span>{translate('Incomplete details')}</span>);
    }
};

export default handleDraft;
