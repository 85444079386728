import React from 'react';

const Passport = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-travel-20"
                    points="0 0 18 0 18 24 0 24"></polygon>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/passport"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-3" transform="translate(3.000000, 0.000000)">
                    <mask id="mask-passport" fill="white">
                        <use xlinkHref="#path-travel-20"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path
                        d="M16.575,0 L1.425,0 C0.6,0 0,0.6 0,1.425 L0,22.575 C0,23.325 0.6,24 1.425,24 L16.575,24 C17.325,24 18,23.4 18,22.575 L18,1.425 C18,0.6 17.4,0 16.575,0 Z M13.5,5.475 L11.925,5.475 C11.7,4.65 11.4,3.975 11.025,3.375 C12,3.825 12.9,4.575 13.5,5.475 Z M13.5,11.025 C12.9,11.925 12.075,12.675 11.025,13.125 C11.4,12.6 11.7,11.85 11.925,11.025 L13.5,11.025 Z M12.15,10.125 C12.225,9.675 12.3,9.15 12.3,8.7 L14.25,8.7 C14.25,9.225 14.1,9.675 13.95,10.125 L12.15,10.125 Z M11.4,8.7 C11.4,9.225 11.325,9.675 11.25,10.125 L6.75,10.125 C6.675,9.675 6.6,9.15 6.6,8.7 L11.4,8.7 Z M6.6,7.8 C6.6,7.275 6.675,6.825 6.75,6.375 L11.25,6.375 C11.325,6.825 11.4,7.35 11.4,7.8 L6.6,7.8 Z M6.975,13.125 C5.925,12.675 5.1,12 4.5,11.025 L6.075,11.025 C6.3,11.85 6.6,12.525 6.975,13.125 Z M6.975,11.025 L11.025,11.025 C10.575,12.525 9.75,13.5 9,13.5 C8.25,13.5 7.425,12.525 6.975,11.025 Z M9,3 C9.75,3 10.575,3.975 11.025,5.475 L6.975,5.475 C7.425,3.975 8.25,3 9,3 Z M6.975,3.375 L6.975,3.375 C6.6,3.9 6.3,4.65 6.075,5.475 L4.5,5.475 C5.1,4.575 6,3.825 6.975,3.375 Z M4.05,6.375 L5.85,6.375 C5.775,6.825 5.7,7.35 5.7,7.8 L3.75,7.8 C3.75,7.275 3.9,6.825 4.05,6.375 Z M5.7,8.7 C5.7,9.225 5.775,9.675 5.85,10.125 L4.05,10.125 C3.9,9.675 3.75,9.225 3.75,8.7 L5.7,8.7 Z M12,21 L6,21 C5.7,21 5.475,20.775 5.475,20.475 C5.475,20.175 5.7,19.95 6,19.95 L12,19.95 C12.3,19.95 12.525,20.175 12.525,20.475 C12.525,20.775 12.3,21 12,21 Z M13.725,18.975 L4.275,18.975 C3.975,18.975 3.75,18.75 3.75,18.525 C3.75,18.225 3.975,18 4.275,18 L13.8,18 C14.1,18 14.325,18.225 14.325,18.525 C14.25,18.75 14.025,18.975 13.725,18.975 Z M12.3,7.8 C12.3,7.275 12.225,6.825 12.15,6.375 L13.95,6.375 C14.1,6.825 14.25,7.35 14.25,7.8 L12.3,7.8 Z"
                        id="Shape"
                        fill={fill || '#002244'}
                        mask="url(#mask-passport)"></path>
                </g>
            </g>
        </svg>
    );
};

export default Passport;
