import React from 'react';

const ArrowUp = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/arrow-up"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12.6853676,0.271800007 L21.1853676,8.27180001 C21.5875413,8.65031648 21.6067193,9.28319095 21.2282029,9.6853647 C20.8496864,10.0875384 20.2168119,10.1067165 19.8146382,9.72819999 L13,3.314 L13.0000057,23.0000029 C13.0000057,23.5522876 12.5522905,24.0000029 12.0000057,24.0000029 C11.447721,24.0000029 11.0000057,23.5522876 11.0000057,23.0000029 L11,3.314 L4.18536757,9.72819999 C3.78319382,10.1067165 3.15031935,10.0875384 2.77180288,9.6853647 C2.39328641,9.28319095 2.41246442,8.65031648 2.81463817,8.27180001 L11.3146382,0.271800007 C11.6996882,-0.0906000024 12.3003176,-0.0906000024 12.6853676,0.271800007 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default ArrowUp;
