import React from 'react';

const LegroomExtra = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/legroom-extra"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M5.64735,6.7638 C6.71535,6.3288 7.9371,6.84405 8.3721,7.91205 L8.3721,7.91205 L10.78185,13.8948 L16.4181,11.2323 C17.14035,10.8903 17.9871,11.12205 18.44385,11.74455 L18.44385,11.74455 L23.6826,17.21955 C24.1191,17.68005 24.1056,18.41055 23.64435,18.85155 C23.1861,19.29105 22.45485,19.2753 22.01385,18.8148 L22.01385,18.8148 L19.8621,16.56555 L22.94985,21.4443 C23.32785,22.04505 23.15085,22.8408 22.5486,23.21955 C21.94935,23.60055 21.15435,23.42055 20.77335,22.82055 L20.77335,22.82055 L17.14635,17.0883 C17.13435,17.0898 17.1261,17.09505 17.1141,17.09805 C12.8926,17.8818858 10.56785,18.3111358 10.13985,18.3858 C9.49785,18.4977963 8.11601667,18.4533 7.5981,17.18205 L7.5981,17.18205 L4.4991,9.4863 C4.0641,8.41905 4.57635,7.1988 5.64735,6.7638 Z M1.81503017,5.35663076 L1.862325,5.4582 L6.747075,17.8587 C7.549155,19.34766 8.9339166,19.4224248 9.45415289,19.3908554 L9.514575,19.38645 L16.009575,18.22845 C16.533825,18.13245 17.037825,18.47745 17.134575,19.0032 C17.234325,19.5237 16.888575,20.0307 16.365825,20.13045 C11.798325,20.93695 9.484825,21.3387 9.425325,21.3357 C6.609825,21.62445 5.355825,19.44645 5.108325,18.94845 C5.100825,18.93495 5.094825,18.9177 5.083575,18.9012 C5.075575,18.8782 5.06824167,18.8622 5.06424167,18.8540889 L5.061075,18.84795 L0.070575,6.1752 C-0.130425,5.67645 0.111825,5.1162 0.609825,4.9197 C1.06668214,4.73584286 1.58691429,4.93352908 1.81503017,5.35663076 Z M5.4111,1.125375 C6.70335,1.096125 7.76985,2.122125 7.7991,3.406125 C7.83135,4.696875 6.8091,5.766375 5.51835,5.794125 C4.2276,5.823375 3.15885,4.801875 3.13185,3.514875 C3.0996,2.224875 4.1241,1.155375 5.4111,1.125375 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default LegroomExtra;
