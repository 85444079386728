import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Icon } from '@freightos/uikit';
import { useDispatch } from 'react-redux';
import { logOut } from 'modules/login/actions';
import {
    StyledMenu,
    CustomMenuRow,
    CustomDropMenu,
    ButtonDropDown,
} from './common/styles';

function UserMenu(props) {
    const { t } = useTranslation();
    const userRef = useRef(null);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    const {
        profile: profileLink,
        account: accountLink,
        users: usersLink,
    } = JSON.parse(process.env.REACT_APP_HEADER_LINKS);

    const handleClickOutside = event => {
        if (userRef.current && !userRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };
    const handleExpanded = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userRef]);

    const handleLogout = () => {
        dispatch(logOut());
        handleExpanded();
    };

    return (
        <StyledMenu ref={userRef}>
            <Flex alignItems="center" onClick={handleExpanded}>
                <ButtonDropDown
                    role="menuitem"
                    aria-label="My Profile Drop Down">
                    <span
                        style={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '550',
                            fontFamily: '"Open Sans",sans-serif',
                        }}>{`${t('HI')}, ${props.name.toUpperCase()}`}</span>
                    <Icon
                        icon={'caretUp'}
                        color="white"
                        hoverColor="white"
                        style={{
                            cursor: 'pointer',
                            transform: `rotate(${expanded ? 0 : 180}deg)`,
                            transition: 'none',
                            width: '26px',
                            height: '26px',
                        }}
                    />
                </ButtonDropDown>
            </Flex>
            {expanded && (
                <CustomDropMenu
                    flexDirection="column"
                    style={{ right: '35px', top: '45px' }}>
                    <CustomMenuRow
                        role="menuitem"
                        direction="top"
                        paddingTop
                        onClick={handleExpanded}>
                        <a href={profileLink}>{t('My Profile')}</a>
                    </CustomMenuRow>

                    <CustomMenuRow onClick={handleExpanded} role="menuitem">
                        <a href={accountLink}>{t('My Account')}</a>
                    </CustomMenuRow>

                    <CustomMenuRow
                        direction="bottom"
                        paddingBottom
                        onClick={handleExpanded}
                        role="menuitem">
                        <a href={usersLink}>{t('Manage Users')}</a>
                    </CustomMenuRow>

                    <CustomMenuRow
                        direction="bottom"
                        role="menuitem"
                        paddingBottom>
                        <a href="#" onClick={handleLogout}>
                            {t('Log Out')}
                        </a>
                    </CustomMenuRow>
                </CustomDropMenu>
            )}
        </StyledMenu>
    );
}

export default UserMenu;
