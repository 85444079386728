import React from 'react';

const Menu = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/menu"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M1.25,10.75 L22.75,10.75 C23.4403559,10.75 24,11.3096441 24,12 C24,12.6903559 23.4403559,13.25 22.75,13.25 L1.25,13.25 C0.559644063,13.25 8.45442189e-17,12.6903559 0,12 C-8.45442189e-17,11.3096441 0.559644063,10.75 1.25,10.75 Z M1.25,19.5 L22.75,19.5 C23.4403559,19.5 24,20.0596441 24,20.75 C24,21.4403559 23.4403559,22 22.75,22 L1.25,22 C0.559644063,22 8.45442189e-17,21.4403559 0,20.75 C-8.45442189e-17,20.0596441 0.559644063,19.5 1.25,19.5 Z M1.25,2 L22.75,2 C23.4403559,2 24,2.55964406 24,3.25 C24,3.94035594 23.4403559,4.5 22.75,4.5 L1.25,4.5 C0.559644063,4.5 8.45442189e-17,3.94035594 0,3.25 C-8.45442189e-17,2.55964406 0.559644063,2 1.25,2 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Menu;
