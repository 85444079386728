import { all, call, put, takeLatest, delay, select } from 'redux-saga/effects';
import * as types from './actionTypes';
import { doRequest } from '../../api/requestSaga';
import { push } from 'connected-react-router';
import { formatReceivedBooking } from '../../basic_components/sagaFormatters';
import {
    getNotificationOptions,
    getSHC,
    oneReceived as saveTemplateForm,
    setIsDraft,
} from '../bookingSteps/actions';
import { DETAILS_ROUTE, FIELDS } from '../bookingSteps/constants';
import { MODULE_ROUTE, TEMPLATE_SHOW } from './constants';
import * as format from 'basic_components/sagaFormatters';
import { callNotificationFormat } from '../bookingSteps/sagas';
import { bookingStatusProductChange } from '../bookings/sagas';
import { resetForm } from '../bookingSteps/actions';

function* saveTemplate(payload) {
    const templateToSend = yield callNotificationFormat(payload.template);
    const sendData = format.formatSendBooking(templateToSend, true);

    delete sendData.id;
    sendData.awb_number = null;

    try {
        yield call(doRequest, 'POST', `cargo/booking_templates/save`, sendData);
        yield put(push(`${MODULE_ROUTE}`));
    } catch (e) {}
}

function* updateTemplate(payload) {
    const templateToSend = yield callNotificationFormat(payload.template);

    const sendData = {
        ...format.formatSendBooking(templateToSend, true),
        booking_id: payload.template.template_booking_id,
    };
    sendData.awb_number = null;

    yield call(doRequest, 'PUT', `cargo/booking_templates/update`, sendData);
    yield put(push(`${MODULE_ROUTE}`));
}

function* viewTemplate(payload) {
    try {
        yield put(getNotificationOptions());
        const res = yield call(
            doRequest,
            'GET',
            `cargo/booking_templates/show/${payload.id}`
        );

        if (res.response.data?.booking_general?.id) {
            res.response.data.template_booking_id =
                res.response.data.booking_general.id;
            delete res.response.data.booking_general.id;
        }
        yield put(setIsDraft(false));
        yield put(
            saveTemplateForm(
                {
                    ...formatReceivedBooking(res.response.data),
                    template_booking_id: res.response.data?.template_booking_id,
                },
                'show'
            )
        );
        yield bookingStatusProductChange(
            res.response.data?.booking_general?.product?.code
        );
        yield put(push(`${MODULE_ROUTE}${TEMPLATE_SHOW}/${payload.id}`));
    } catch (e) {}
}

function* utilizeTemplate(payload) {
    try {
        const res = yield call(
            doRequest,
            'GET',
            `cargo/booking_templates/show/${payload.id}`
        );
        if (res.response.data?.booking_general?.id) {
            res.response.data.template_booking_id =
                res.response.data.booking_general.id;
            delete res.response.data.booking_general.id;
        }
        if (payload.reset) {
            yield put(
                resetForm(res.response.data?.booking_general?.product?.code)
            );
        }

        yield bookingStatusProductChange(
            res.response.data?.booking_general?.product?.code
        );
        const products = yield select(state => state.bookingSteps?.products);
        const productSelected = products.find(
            prod =>
                prod.code === res.response.data?.booking_general?.product?.code
        );

        const formatData = { ...formatReceivedBooking(res.response.data) };
        yield put(
            saveTemplateForm(
                {
                    ...formatData,
                    product_id: productSelected,
                    template_booking_id: res.response.data?.template_booking_id,
                },
                'use_template'
            )
        );
        yield put(getSHC(res.response.data.booking_general.product.id));
        yield put(push(DETAILS_ROUTE));
    } catch (e) {}
}

function* deleteTemplate(payload) {
    const data = {
        booking_id: payload.id,
        id: payload.id,
    };
    try {
        yield call(doRequest, 'DELETE', `cargo/booking_templates/delete`, data);
        yield delay(2000);
        yield put(push(MODULE_ROUTE));
    } catch (e) {}
}

export default function*() {
    yield all([
        takeLatest(types.VIEW_TEMPLATE, viewTemplate),
        takeLatest(types.USE_TEMPLATE, utilizeTemplate),
        takeLatest(types.DELETE, deleteTemplate),
        takeLatest(types.SAVE_TEMPLATE, saveTemplate),
        takeLatest(types.UPDATE_TEMPLATE, updateTemplate),
    ]);
}
