import React from 'react';

const LegroomStandard = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M8.17806497,4.42581019 C6.96645125,4.24055942 6.13603619,3.10580475 6.32140337,1.89229975 C6.50378075,0.67579473 7.63765986,-0.158958711 8.8470312,0.0255420497 C10.0616347,0.210792813 10.8905548,1.34929751 10.7066826,2.56205251 C10.5228103,3.77780752 9.38967866,4.61331096 8.17806497,4.42581019 Z M15.4700958,17.8470405 C15.9753709,17.8447905 16.3909521,18.2512921 16.3939419,18.7605442 C16.4029113,19.2667963 15.9940571,19.683798 15.488782,19.6905481 L9.51965992,19.6905481 C9.44267275,19.6905481 9.35746364,19.680048 9.27300198,19.6597979 C6.59265236,19.4662972 5.77793371,17.2147879 5.6291915,16.7062858 C5.62470681,16.6912857 5.62171702,16.6747856 5.61424254,16.6582856 C5.6075155,16.6237854 5.60153592,16.6050354 5.60153592,16.6050354 L3.02059695,3.88273295 C2.91371184,3.38248089 3.23361971,2.89647888 3.72992538,2.79447846 C4.22473615,2.68947803 4.71207244,3.01197936 4.81297796,3.5077314 L7.34159555,15.9547827 C7.86854663,17.5477892 9.23787191,17.8050403 9.67886641,17.8447905 L15.4700958,17.8470405 Z M19.6291971,15.2598298 L20.9798362,22.5506099 C21.1009228,23.2143626 20.664413,23.8556152 19.999184,23.9793657 C19.3369448,24.1023663 18.6978765,23.6628644 18.575295,22.9968617 L17.4683241,17.0328371 L10.2390042,17.0328371 L10.2277924,17.0313371 C9.29796676,17.0493372 8.45559253,16.4073345 8.2478019,15.4593306 L6.61612223,7.72304875 C6.38516071,6.64904435 7.06683355,5.58853998 8.14016935,5.35678902 C9.21126275,5.12503807 10.2689021,5.8112909 10.4991162,6.88529532 L11.9798112,13.9008242 L18.0902011,13.9008242 C18.633596,13.9008242 19.1097205,14.1790754 19.3900136,14.6020771 C19.4131845,14.6343272 19.4273861,14.6718274 19.4468197,14.7063275 C19.5417457,14.8750782 19.6045313,15.060329 19.6291971,15.2598298 Z"
                    id="path-travel-14"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/legroom-standard"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-14"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-14"></use>
            </g>
        </svg>
    );
};

export default LegroomStandard;
