import { useTranslation } from 'react-i18next';

export const MODULE_ROUTE = '/contacts';
export const MODULE_NAME = 'CARGO_CONTACTS';

export const FIELDS = {
    ACCOUNT_NUMBER: 'account_number',
    ADDRESS_1: 'address',
    ADDRESS_2: 'address2',
    ADDRESS_3: 'address3',
    CITY_CODE: 'city',
    CITY_TEXT: 'city_text',
    COMPANY_NAME: 'company_name',
    CONFIRM_EMAIL: 'confirm_email',
    CONTACT_NAME: 'contact_name',
    COUNTRY: 'country',
    COUNTRY_CALLING_CODE: 'calling_code',
    EMAIL: 'email',
    PO_BOX: 'po_box',
    PRIMARY_PHONE: 'phone',
    STATE: 'state',
    STATE_CODE: 'state_code',
    TYPE_OF_CONTACT: 'type_of_contact',
    ZIP_CODE: 'zip_code',
};

export const LABELS = {
    ACCOUNT_NUMBER: 'Select the account number',
    ADDRESS_1: 'Address',
    ADDRESS_2: 'Address line 2',
    ADDRESS_3: 'Address line 3',
    CITY_CODE: 'City code',
    CITY_TEXT: 'City / Town',
    COMPANY_NAME: 'Company name',
    CONFIRM_EMAIL: 'Confirm email',
    CONTACT_NAME: 'Contact name',
    COUNTRY: 'Country',
    COUNTRY_CALLING_CODE: 'Country calling code',
    EMAIL: 'Email',
    PO_BOX: 'PO Box',
    PRIMARY_PHONE: 'Phone number',
    STATE: 'State / Province',
    TYPE_OF_CONTACT: 'Type of contact',
    ZIP_CODE: 'ZIP code',
};

export const ERROR_LABELS = {
    ACCOUNT_NUMBER: 'Account number',
};

export const TypeOfContactOptions = () => {
    const { t } = useTranslation();

    return [
        { value: 'SHI', label: t('Shipper') },
        { value: 'CON', label: t('Consignee') },
    ];
};

export const ZIP_CODE_MAX_LENGTH = 9;
