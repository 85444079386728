import {
    FIELDS,
    optionsDocumentationAwb,
    PIECES_EMPTY_LOAD,
} from 'modules/bookingSteps/constants';
import {
    generateUUID,
    isEmptyObject,
    isEmptyValues,
} from 'basic_components/jsTools';
import { formatDate, weightToKg } from 'basic_components/conversions';
import { isCompleteUser } from 'basic_components/userTools';
import moment from 'moment';
import {
    getTotalSummaryOfTotalPiecesInObject,
    getTotalSummaryOfContainersInObject,
    getTotalSummaryOfPiecesInObject,
} from 'basic_components/summaries';
import { AIRCRAFT_FILTERS as AIRCRAFT_FILTERS_GCR } from '../modules/bookingSteps/components/common/initialValuesByProductType/GCR_EXP';
import { AIRCRAFT_FILTERS as AIRCRAFT_FILTERS_QPK } from '../modules/bookingSteps/components/common/initialValuesByProductType/QPK';
import {
    showDangerousGoodsProducts,
    showRDSSubstance,
    showDryIce,
    hideExtraDetailsPieces,
    showMedevac,
    shouldHaveExtraDetails,
    GCR,
    EXP,
    QPK,
    QPI,
    LFG,
    LXL,
} from '../modules/bookingSteps/components/common/initialValuesByProductType/productCodes';

//UTILITIES
export const isDraft = values => {
    return !('booking_number' in values[FIELDS.BOOKING_DATA]) ||
        values[FIELDS.BOOKING_DATA]?.booking_number
        ? false
        : true;
};
export const isTemplate = values => {
    return !isEmptyValues(values?.[FIELDS.TEMPLATE_ID]);
};

export const AWBFormat = data => {
    return data.awb_later
        ? 'laterAWB'
        : data.awb_number
        ? 'haveAWBNumber'
        : 'assignAWB';
};

export const checkAgentNotificationsSelection = data => {
    if (data) {
        if (data?.used === false) {
            return null;
        }
        return data?.value;
    }
    return null;
};

export const formatNotificationOrderByContacts = (data, agent) => {
    let returnedSubscribers = [];
    if (Array.isArray(data)) {
        data.map(notification => {
            const { subscribers, code } = notification;
            if (Array.isArray(subscribers)) {
                subscribers.map(singleSubscriber => {
                    const existingSubscriberIndex = returnedSubscribers.findIndex(
                        sub =>
                            singleSubscriber?.email === sub?.email?.value &&
                            singleSubscriber?.phone === sub?.phone?.value
                    );

                    const newSub = {
                        email: {
                            value: singleSubscriber?.email,
                            used: !!singleSubscriber?.email,
                        },
                        phone: {
                            value: singleSubscriber?.phone,
                            used: !!singleSubscriber?.phone,
                        },
                    };

                    if (existingSubscriberIndex !== -1) {
                        returnedSubscribers[existingSubscriberIndex] = {
                            ...newSub,
                            notifications: [
                                ...returnedSubscribers[existingSubscriberIndex]
                                    ?.notifications,
                                code,
                            ],
                        };
                    } else {
                        returnedSubscribers = [
                            ...returnedSubscribers,
                            {
                                ...newSub,
                                notifications: [code],
                            },
                        ];
                    }
                });
            }
        });
    }

    if (agent) {
        const agentIsSubscribed = returnedSubscribers.find(subscriber => {
            if (
                agent.email === subscriber.email.value ||
                agent.phone === subscriber.phone.value
            ) {
                return true;
            }
            return false;
        });

        const emptyAgentSubscriber = {
            email: { value: agent.email, used: false },
            phone: { value: agent.phone, used: false },
            notifications: [],
        };

        if (agentIsSubscribed) {
            returnedSubscribers.sort((firstSubscriber, secondSubscriber) => {
                const validateFirst =
                    agent.email === firstSubscriber.email.value ||
                    agent.phone === firstSubscriber.phone.value;
                const validateSecond =
                    agent.email === secondSubscriber.email.value ||
                    agent.phone === secondSubscriber.phone.value;
                return validateFirst ? -1 : validateSecond ? 1 : 0;
            });
        } else {
            returnedSubscribers = [
                emptyAgentSubscriber,
                ...returnedSubscribers,
            ];
        }
    }

    return returnedSubscribers;
};

export const formatNotificationOrderByOptions = data => {
    let returnedCodes = [];

    if (Array.isArray(data)) {
        data.map(users => {
            users.notifications.map(code => {
                const existingCodeIndex = returnedCodes.findIndex(
                    originalCode => code === originalCode.code
                );

                if (existingCodeIndex !== -1) {
                    returnedCodes[existingCodeIndex] = {
                        code: code,
                        subscribers: [
                            ...returnedCodes[existingCodeIndex]?.subscribers,
                            {
                                email: checkAgentNotificationsSelection(
                                    users.email
                                ),
                                phone: checkAgentNotificationsSelection(
                                    users.phone
                                ),
                            },
                        ],
                    };
                } else {
                    returnedCodes = [
                        ...returnedCodes,
                        {
                            code: code,
                            subscribers: [
                                {
                                    email: checkAgentNotificationsSelection(
                                        users.email
                                    ),
                                    phone: checkAgentNotificationsSelection(
                                        users.phone
                                    ),
                                },
                            ],
                        },
                    ];
                }
            });
        });
    }
    return returnedCodes;
};

//FILTERS

export const formatAircraftOptions = array => {
    if (!array || array === []) return [];
    return array.map(aircraft => {
        const value = aircraft.toLowerCase();
        const label = value.charAt(0).toUpperCase() + value.slice(1);

        return { value: value, label: label };
    });
};

export const formatAircraft = product => {
    switch (product) {
        case GCR:
        case EXP:
            return AIRCRAFT_FILTERS_GCR;
            break;
        case QPK:
        case QPI:
        case LFG:
        case LXL:
            return AIRCRAFT_FILTERS_QPK;
        default:
            return AIRCRAFT_FILTERS_GCR;
            break;
    }
};

//Transform Array of objects in array of object.value
export const formatValuesOfArray = arr => {
    if (!arr) return [];
    return arr.map(item => {
        return item?.value;
    });
};

//SORTERS
export const formatSorters = sorter => {
    if (sorter && sorter.field === 'Departure_Latest') {
        return {
            ...sorter,
            field: 'Departure',
        };
    }
    return sorter;
};

//PACKAGE
const extraDetails = (pack, toBack) => {
    return toBack
        ? {
              tiltable: false,
              stackable: pack.stackable,
              crated: false,
              forkliftable: false,
          }
        : { stackable: pack.stackable };
};
export const formatPackageJson = (json, toBack = true) => {
    if (!json) return null;
    return toBack
        ? json.map(pack => ({
              amount: parseFloat(pack.pieces),
              dimension: {
                  length: parseFloat(pack.length),
                  width: parseFloat(pack.width),
                  height: parseFloat(pack.height),
                  unit: pack.dimsUnit.value || pack.dimsUnit || 'cm',
              },
              weight: {
                  amount: parseFloat(pack.weight),
                  unit: pack.weightUnit.value || pack.weightUnit || 'kg',
              },
              ...extraDetails(pack, true),
          }))
        : json.map(pack => ({
              pieces: pack.amount,
              length: pack.dimension?.length,
              width: pack.dimension?.width,
              height: pack.dimension?.height,
              dimsUnit: pack.dimension?.unit || 'cm',
              weight: pack?.weight?.amount,
              weightUnit: pack.weight?.unit || 'kg',
              ...extraDetails(pack, false),
              id: generateUUID(),
          }));
};

export const formatPackageDryIce = (
    json,
    toBack = true,
    hasExtraDetails = false
) => {
    if (!json) return null;
    return toBack
        ? json.map(pack => {
              const basicResponse = {
                  amount: parseFloat(pack.pieces),
                  dimension: {
                      length: parseFloat(pack.length),
                      width: parseFloat(pack.width),
                      height: parseFloat(pack.height),
                      unit: pack.dimsUnit.value || pack.dimsUnit || 'cm',
                  },
                  weight: {
                      amount: parseFloat(pack.weight),
                      unit: pack.weightUnit.value || pack.weightUnit || 'kg',
                  },
                  weight_dry_ice: {
                      amount: pack.dry_ice_weight || 0,
                      unit: pack.dry_ice_weightUnit,
                  },
              };
              const extraDetailsObject = hasExtraDetails
                  ? extraDetails(pack, toBack)
                  : {};

              return {
                  ...basicResponse,
                  ...extraDetailsObject,
              };
          })
        : json.map(pack => {
              const basicResponse = {
                  pieces: pack.amount,
                  length: pack.dimension?.length,
                  width: pack.dimension?.width,
                  height: pack.dimension?.height,
                  dimsUnit: pack.dimension?.unit || 'cm',
                  weight: pack?.weight?.amount,
                  weightUnit: pack.weight?.unit || 'kg',
                  dry_ice_weight: pack.weight_dry_ice?.amount,
                  dry_ice_weightUnit: pack.weight_dry_ice?.unit || 'kg',
                  id: generateUUID(),
              };
              const extraDetailsObject = hasExtraDetails
                  ? extraDetails(pack, toBack)
                  : {};
              return {
                  ...basicResponse,
                  ...extraDetailsObject,
              };
          });
};

export const formatContainer = (containers, toBack = true) => {
    if (!containers) return null;

    return toBack
        ? containers.map(container => ({
              id: container.containerType?.id,
              weight: {
                  amount: container.weight,
                  unit: container.weightUnit.value || container.weightUnit,
              },
              unit_number: container.unitNumber || null,
          }))
        : containers.map(container => ({
              containerType: {
                  id: container?.id,
                  code: container?.code,
                  max_gross_weight: container?.max_gross_weight,
                  max_volume: container?.max_volume,
                  tare_weight: container?.tare_weight,
                  type: container?.uld_type
                      ? container?.uld_type
                      : container?.type,
              },
              weight: container?.weight?.amount,
              weightUnit: container?.weight?.unit || 'kg',
          }));
};

export const formatTotalShipment = (data, toBack = true) => {
    if (!data) return null;
    return toBack
        ? {
              pieces: parseFloat(data.package_total_shipment_pieces),
              weight: {
                  amount: parseFloat(data.package_total_shipment_weight),
                  unit: data.package_total_shipment_weight_unit,
              },

            dry_ice_weight:{
                amount: parseFloat(data.package_total_shipment_dry_ice_weight),
                unit:
                    data.package_total_shipment_weight_unit?.value ||
                    data.package_total_shipment_weight_unit,
            },


              volume: {
                  amount: parseFloat(data.package_total_shipment_volume),

                  unit: data.package_total_shipment_volume_unit || 'cbm',
              },
              overweight: data[FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT],
          }
        : {
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: data.pieces,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: data.weight?.amount,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT]: data.dry_ice_weight?.amount && 0,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT]: data.weight?.unit,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: data.volume?.amount,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT]: data.volume?.unit,
              [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: data.overweight,
          };
};

export const formatPackageInOneObject = data => {
    let returnedObject = null;

    if (data.product_type === 'container') {
        returnedObject = getTotalSummaryOfContainersInObject(
            data.container_list
        );
    } else {
        if (data.measure_by === 'total') {
            returnedObject = getTotalSummaryOfTotalPiecesInObject(data);
        } else {
            returnedObject = data?.isUploadCSV
                ? getTotalSummaryOfPiecesInObject(data?.package_list_csv)
                : getTotalSummaryOfPiecesInObject(data?.package_list);
        }
    }

    return {
        returnedObject,
    };
};

export const formatPackagesForBooking = data => {
    const productCode = data[FIELDS.PRODUCT]?.code;
    const packages = {
        type: 'packages',
        data: data?.isUploadCSV
            ? formatPackageJson(data?.package_list_csv)
            : formatPackageJson(data?.package_list),
    };

    if (data.product_type === 'container') {
        packages.type = 'ulds';
        packages.data = formatContainer(data.container_list);
    }

    if (data.measure_by === 'total' && data.product_type === 'bulk') {
        packages.type = 'totals';
        packages.data = formatTotalShipment(data);
    }

    if (
        showDryIce.includes(productCode) &&
        data.is_dry_ice &&
        packages.type !== 'totals' &&
        packages.type !== 'ulds'
    ) {
        const hasExtraDetails = shouldHaveExtraDetails.includes(productCode);
        packages.type = hasExtraDetails
            ? 'packages_dry_ice_extra'
            : 'packages_dry_ice';
        packages.data = data?.isUploadCSV
            ? formatPackageDryIce(data?.package_list_csv, true, hasExtraDetails)
            : formatPackageDryIce(data?.package_list, true, hasExtraDetails);
    }

    return packages;
};

export const formatPackagesOfBooking = packages => {
    let ret = {
        [FIELDS.PACKAGE_LIST]: [],
        [FIELDS.CONTAINER_LIST]: [],
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: 0,
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: null,
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT]: null,
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT]: 'kg',
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: null,
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT]: 'cbm',
        [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: '',
        [FIELDS.IS_UPLOAD_CSV]: false,
        [FIELDS.MEASURE_BY]: 'pieces',
        [FIELDS.PRODUCT_TYPE]: 'bulk',
    };
    switch (packages.type) {
        case 'packages':
            ret[FIELDS.PACKAGE_LIST] = formatPackageJson(packages.data, false);
            break;
        case 'packages_dry_ice':
            ret[FIELDS.PACKAGE_LIST] = formatPackageDryIce(
                packages.data,
                false
            );
            break;
        case 'packages_dry_ice_extra':
            ret[FIELDS.PACKAGE_LIST] = formatPackageDryIce(
                packages.data,
                false,
                true
            );
            break;
        case 'totals':
            ret = { ...ret, ...formatTotalShipment(packages.data, false) };
            ret[FIELDS.MEASURE_BY] = 'total';
            break;
        case 'ulds':
            ret[FIELDS.CONTAINER_LIST] = formatContainer(packages.data, false);
            ret[FIELDS.PRODUCT_TYPE] = 'container';
            ret[FIELDS.PACKAGE_LIST] = [];
            break;
        default:
            break;
    }
    return ret;
};

export const isEmptyPackages = data => {
    const packages = formatPackagesForBooking(data);
    switch (packages.type) {
        case 'packages_dry_ice_extra':
        case 'packages_dry_ice':
        case 'packages':
            return !(
                packages.data[0]?.weight?.amount > 0 &&
                packages.data[0]?.amount > 0
            );
            break;
        case 'totals':
            return !(
                packages.data?.weight?.amount > 0 && packages.data?.pieces > 0
            );
            break;
        case 'ulds':
            return !(packages.data[0]?.weight?.amount > 0);
            break;
        default:
            break;
    }

    return false;
};

//CONTACT
export const formatContact = (data, toBack = true) => {
    return toBack // FORMAT TO BACK
        ? {
              email: data?.email || null,
              company_name: data?.company_name || null,
              phone: data?.phone || null,
              calling_code: data?.calling_code?.value || null,
              address1: data?.address || null,
              address2: data?.address2 || null,
              address3: data?.address3 || null,
              country: data?.country?.value || null,
              city_text: data?.city_text || null,
              state: data?.state || null,
              state_code: data?.state_code || null,
              zip_code: data?.zip_code || null,
              city_id: data?.city?.value || null,
              type_of_contact: data?.type_of_contact?.value || null,
              account_number_id: data?.account_number?.account_number_id || data?.account_number?.id,
              contact_name: data?.contact_name || null,
          }
        : {
              [FIELDS.EMAIL]: data.email || '',
              [FIELDS.COMPANY_NAME]: data.company_name || '',
              [FIELDS.PRIMARY_PHONE]: data.phone || '',
              [FIELDS.COUNTRY_CALLING_CODE]: data.calling_code
                  ? {
                        value: data.calling_code?.id,
                        label: `${data?.calling_code?.name} - ${data?.calling_code?.code} (${data?.calling_code?.calling_code})`,
                    }
                  : null,
              [FIELDS.ADDRESS_1]: data.address || '',
              [FIELDS.ADDRESS_2]: data.address2 || '',
              [FIELDS.ADDRESS_3]: data.address3 || '',
              [FIELDS.COUNTRY]: data.country
                  ? {
                        value: data.country?.id,
                        label: `${data?.country?.name}${
                            data?.country?.code
                                ? ` (${data?.country?.code})`
                                : ''
                        }`,
                    }
                  : null,
              [FIELDS.CITY_TEXT]: data.city_text || '',
              [FIELDS.STATE]: data.state || '',
              [FIELDS.STATE_CODE]: data.state_code || '',
              [FIELDS.ZIP_CODE]: data.zip_code || '',
              [FIELDS.CITY_CODE]: data.city
                  ? {
                        value: data.city?.id,
                        label: `${data?.city?.name} (${data?.city?.code})`,
                    }
                  : null,
              [FIELDS.TYPE_OF_CONTACT]: {
                  value: data?.type_of_contact,
                  label:
                      data?.type_of_contact === 'SHI' ? 'Shipper' : 'Consignee',
              },
              [FIELDS.ACCOUNT_NUMBER]: data.client_account
                  ? {
                        id: data.client_account?.id,
                        account_number: data.client_account?.account_number,
                    }
                  : null,
              [FIELDS.CONTACT_NAME]: data?.contact_name || '',
              id: data?.id,
          };
};

export const formatBookingContact = (data, toBack = true) => {
    return toBack // FORMAT TO BACK
        ? {
              email: data?.email || null,
              company_name: data?.company_name || null,
              phone: data?.phone || null,
              calling_code: data?.calling_code?.value || null,
              calling_label: data?.calling_code?.label || null,
              address1: data?.address || null,
              address2: data?.address2 || null,
              address3: data?.address3 || null,
              country_id: data?.country?.value || null,
              country_label: data?.country?.label || null,
              city_text: data?.city_text || null,
              state: data?.state || null,
              state_code: data?.state_code || null,
              zip_code: data?.zip_code || null,
              city_code: data?.city?.code || null,
              city_id: data?.city?.value || null,
              city_label: data?.city?.label || null,
              contact_name: data?.contact_name || null,
              account_number:
                  data?.account_number?.account_number ||
                  data?.account_number ||
                  null,
          }
        : {
              [FIELDS.EMAIL]: data?.email || '',
              [FIELDS.COMPANY_NAME]: data?.company_name || '',
              [FIELDS.PRIMARY_PHONE]: data?.phone || '',
              [FIELDS.COUNTRY_CALLING_CODE]: data?.calling_code
                  ? { value: data.calling_code, label: data?.calling_label }
                  : null,
              [FIELDS.ADDRESS_1]: data?.address1 || '',
              [FIELDS.ADDRESS_2]: data?.address2 || '',
              [FIELDS.ADDRESS_3]: data?.address3 || '',
              [FIELDS.COUNTRY]: data?.country_id
                  ? { value: data.country_id, label: data?.country_label }
                  : null,
              [FIELDS.CITY_TEXT]: data?.city_text || '',
              [FIELDS.STATE]: data?.state || '',
              [FIELDS.STATE_CODE]: data?.state_code || '',
              [FIELDS.ZIP_CODE]: data?.zip_code || '',
              [FIELDS.CITY_CODE]: data?.city_id
                  ? {
                        value: data?.city_id,
                        label: data?.city_label,
                        code: data?.city_code,
                    }
                  : null,
              [FIELDS.ACCOUNT_NUMBER]: data?.account_number || null,
              [FIELDS.ACCOUNT_STATUS]: data?.account_status || null,
              [FIELDS.CONTACT_NAME]: data?.contact_name || '',
              allowed_bup: data?.allowed_bup || false,
          };
};

export const formatContacts = (data, toBack = true) => {
    let contactData = {};
    if (toBack) {
        contactData = {
            dropping_info:
                !data.shipper || isEmptyObject(data?.shipper)
                    ? null
                    : JSON.stringify(formatBookingContact(data?.shipper)),
            picking_info:
                !data.consignee || isEmptyObject(data?.consignee)
                    ? null
                    : JSON.stringify(formatBookingContact(data?.consignee)),
            agent_info: JSON.stringify(formatBookingContact(data?.agent)),
        };
    } else {
        const agentInfo = formatBookingContact(
            {
                ...data.agent_info,
                allowed_bup: data.booking_general?.account_number?.allowed_bup,
                account_status:
                    data.booking_general?.account_number?.account_status,
            },
            toBack
        );
        contactData = {
            shipper: formatBookingContact(data.booking_drop_off, toBack),
            consignee: formatBookingContact(data.booking_pick_up, toBack),
            agent: {
                ...agentInfo,
                isCompleteUser: isCompleteUser(agentInfo),
            },
        };
    }
    return contactData;
};

//OTHERS

export const formatAllotmentData = data => {
    const productsObject = { GCR: [], EXP: [], QPK: [], QPI: [], LFG: [] };
    const uldType = type =>
        type === 'BULK'
            ? { value: 'bulk', label: 'Bulk' }
            : { value: 'container', label: 'Container' };
    data.map(allotment => {
        const newAllotment = {
            ...allotment,
            product: {
                label: `${allotment.product?.code}`,
                value: allotment.product?.id,
                code: allotment.product?.code,
            },
            uld_type: uldType(allotment.uld_type),
            uniqueKey: generateUUID(),
        };
        if (productsObject[allotment.product?.code]) {
            productsObject[allotment.product?.code].push(newAllotment);
        }
        return newAllotment;
    });
    return productsObject;
};

export const formatCustomerCommunications = data => {
    const returnedData = {
        default: data[FIELDS.REMEMBER_SETTINGS] || false,
        notification_preferences: data[FIELDS.NOTIFICATION_PREFERENCES] || null,
    };
    return returnedData;
};
//SCHEDULES
const tenderIsWithinRange = (departure, tender) => {
    const dayBeforeDeparture = moment(departure)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    return dayBeforeDeparture === tender || departure === tender
        ? tender
        : departure;
};

export const formatShipment = data => {
    const packages = formatPackagesForBooking(data);
    const shcs = formatValuesOfArray(data.shc_code);
    const uld_overflow_weight =
        data?.uld_overflow_weight > 0 ? data?.uld_overflow_weight : null;

    const departure_date = formatDate(data.departure_date);
    const tender_date = data.tender_date
        ? formatDate(tenderIsWithinRange(departure_date, data.tender_date))
        : null;
    const isDryIce = formatIsDryIceToBack(data);

    return {
        ...isDryIce,
        account_number_id: data.account_number?.id,
        allotment_id: data.isAllotmentBooking ? data.allotment_id : null,
        arrival_date: formatDate(data.deliver_date),
        charge_code: data.payment_info,
        commodity_id:
            data.commodity?.value ||
            data[FIELDS.PRODUCT]?.default_commodity?.value,
        manifest: data.commodity_description,
        departure_date,
        destination: data.destination?.value,
        flight_number: null,
        freighter: data?.freighter ? 1 : 0,
        narrowbody: data?.narrowbody ? 1 : 0,
        origin: data.origin?.value,
        packages,
        passenger: data?.passenger ? 1 : 0,
        product_code: data[FIELDS.PRODUCT]?.code,
        screened: 0,
        shcs: JSON.stringify(shcs),
        sorted: data.sort ? JSON.stringify([formatSorters(data.sort)]) : null,
        stops: data.stops?.join(',') || ['0', '1', '2'],
        tender_date,
        truck: data?.truck ? 1 : 0,
        uax: data?.uax ? 1 : 0,
        uld_overflow_weight: uld_overflow_weight,
        overweight: data.overweight ? 1 : 0,
        via: data.via ? data.via?.value : null,
        widebody: data?.widebody ? 1 : 0,
    };
};

//BOOKING RECEIVED
export const formatReceivedBooking = data => {
    const contacts = formatContacts(data, false);
    const productCode = data.booking_general?.product?.code;
    const AWBFormatValue = AWBFormat(data.booking_general);
    const hasULDOverflowWeight = !!data?.uld_overflow_weight;
    const filters = formatAircraft(productCode);
    const packages = formatPackagesOfBooking(data.booking_packages);
    const isDangerousGoodProduct = showDangerousGoodsProducts.includes(
        productCode
    );
    const isRDSSubstance = showRDSSubstance.includes(productCode);
    const isMedevacProduct = showMedevac.includes(productCode);
    const isDryIce = formatIsDryIceFromBack(data, productCode);
    const emptyPieceLoad = PIECES_EMPTY_LOAD(
        hideExtraDetailsPieces.includes(productCode) || undefined,
        showDryIce.includes(productCode) || undefined
    );
    const isLifeguardXL =
        productCode === 'LXL' ? true : productCode === 'LFG' ? false : null;

    if (data.payment_method == 'CC') {
        data.payment_method = null;
    }
    const removeForTemplate = data.template_id
        ? {
              [FIELDS.DELIVER_DATE]: null,
              [FIELDS.TENDER_DATE]: null,
              [FIELDS.DEPARTURE_DATE]: null,
          }
        : {
              [FIELDS.DELIVER_DATE]:
                  data.booking_general?.delivery_date || null,
              [FIELDS.DEPARTURE_DATE]:
                  data.booking_general?.departure_date || null,
              [FIELDS.TENDER_DATE]: data.booking_general?.tender_date || null,
          };

    return {
        ...contacts,
        ...packages,
        ...filters,
        ...isDryIce,
        ...removeForTemplate,
        [FIELDS.ACCOUNT_NUMBER]: {
            id: data.booking_general?.account_number?.id,
            account_number:
                data.booking_general?.account_number?.account_number,
            account_type: data.booking_general?.account_number?.type,
            label: `${data.booking_general?.account_number?.account_number} - ${
                data.booking_general?.account_number?.type
            } - ${data.booking_general?.account_number?.city || ''}`,
        },
        [FIELDS.AIRCRAFT_MODE]: null,
        [FIELDS.AIRCRAFT_TYPE]: null,
        [FIELDS.ALLOTMENT_ID]: data.allotment_id || null,
        [FIELDS.AWB_CAPTURE]: formatAWBCaptureFromBack(data?.awb_capture),
        [FIELDS.AWB_NUMBER]: data.booking_general?.awb_number || null,
        [FIELDS.AWB_TYPE]: AWBFormatValue,
        [FIELDS.BOOKING_DATA]: {
            booking_movement_status:
                data.booking_general?.booking_movement_status,
            booking_number: data.booking_general?.booking_number,
            booking_status: data.booking_general?.booking_status,
            external_user: data.booking_general?.external_user,
            is_editable: data.is_editable,
            awb_prefix: data.awb_prefix,
            is_awb_editable: data.is_awb_editable,
        },
        [FIELDS.BY_PIECE_PRODUCT_EDIT]: emptyPieceLoad,
        [FIELDS.CALLER_NAME]: data?.caller_name || '',
        [FIELDS.COMMODITY_CODE]: {
            value: data.commodity?.id,
            code: data.commodity?.code,
            label: data.commodity?.code
                ? `${data.commodity?.code} - ${data.commodity?.name}`
                : null,
            products: data.commodity?.products,
        },
        [FIELDS.COMMODITY_DESCRIPTION]:
            data.booking_general?.awb_description || '',
        [FIELDS.DESTINATION]: data.destination || null,
        [FIELDS.FLIGHT]: data.booking_routing || null,
        [FIELDS.IS_ALLOTMENT_BOOKING]: data.allotment_id ? true : false,
        [FIELDS.IS_DANGEROUS_GOODS]: isDangerousGoodProduct ? false : null,
        [FIELDS.IS_RDS]: isRDSSubstance ? false : null,
        [FIELDS.IS_MEDEVAC]: isMedevacProduct ? false : null,
        [FIELDS.LIFEGUARD_XL]: isLifeguardXL,
        [FIELDS.NOTIFICATION_PREFERENCES]:
            data?.notification_preferences || null,
        [FIELDS.ORIGIN]: data.origin || null,
        [FIELDS.PAYMENT_INFO]: data.payment_method || null,
        [FIELDS.PRODUCT]: {
            label: `${data.booking_general?.product?.code} - ${data.booking_general?.product?.name}`,
            value: data.booking_general?.product?.id,
            code: data.booking_general?.product?.code,
        },
        [FIELDS.REMEMBER_SETTINGS]:
            data?.remember_notification_settings || null,
        [FIELDS.AWB_CAPTURE]: formatAWBCaptureFromBack(data?.awb_capture),
        [FIELDS.OVERFLOW_WEIGHT]: hasULDOverflowWeight
            ? data.uld_overflow_weight
            : null,
        [FIELDS.HAS_OVERFLOW_WEIGHT]: hasULDOverflowWeight,
        [FIELDS.SHC]: data.booking_shcs,
        [FIELDS.SORT]: {
            value: 'Journey',
            field: 'Journey',
            label: 'Fastest',
            order: 'Asc',
        },
        [FIELDS.STOPS]: formatStops(data.stops,productCode),
        [FIELDS.TEMPLATE_DESCRIPTION]: data.template_description,
        [FIELDS.TEMPLATE_ID]: data.template_id,
        [FIELDS.TEMPLATE_NAME]: data.template_name,
        [FIELDS.VIA]: data.via || null,
        id: data.booking_general?.id,
    };
};

//BOOKING SAVE
export const formatSendBooking = (data, isTemplate = false) => {
    const shcs = formatValuesOfArray(data[FIELDS.SHC]);

    const contacts = formatContacts(data);

    const packages = formatPackagesForBooking(data);
    const isDryIce = formatIsDryIceToBack(data);
    const removeForTemplate = isTemplate
        ? {
              departure_date: null,
              delivery_date: null,
              tender_date: null,
          }
        : {
              delivery_date: formatDate(data[FIELDS.DELIVER_DATE]),
              departure_date: formatDate(data[FIELDS.DEPARTURE_DATE]),
              tender_date: formatDate(data[FIELDS.TENDER_DATE]),
          };

    return {
        ...contacts,
        ...isDryIce,
        ...removeForTemplate,
        account_number_id: data[FIELDS.ACCOUNT_NUMBER]?.id,
        allotment_id: data[FIELDS.IS_ALLOTMENT_BOOKING]
            ? data[FIELDS.ALLOTMENT_ID]
            : null,
        awb_description: data[FIELDS.COMMODITY_DESCRIPTION],
        awb_later: data[FIELDS.AWB_TYPE] === 'laterAWB' ? 1 : 0,
        awb_number:
            data[FIELDS.AWB_TYPE] === 'laterAWB'
                ? null
                : data[FIELDS.AWB_NUMBER] || null,
        booking_id: data.id || null,
        caller_name: data.caller_name || null,
        commodity_code:
            data[FIELDS.COMMODITY_CODE]?.code ||
            data[FIELDS.PRODUCT]?.default_commodity?.code ||
            null,
        customer_communications: JSON.stringify(
            formatCustomerCommunications(data)
        ),
        destination_code: data[FIELDS.DESTINATION]?.value,
        id: data.id || null,
        origin_code: data[FIELDS.ORIGIN]?.value,
        packages: JSON.stringify(packages),
        payment_method: data[FIELDS.PAYMENT_INFO] || null,
        product_code: data[FIELDS.PRODUCT]?.code,
        remember_notification_settings: data[FIELDS.REMEMBER_SETTINGS] ? 1 : 0,
        routing: data[FIELDS.FLIGHT],
        shc_ids: JSON.stringify(shcs), //Ej: [1,2,3] ID's
        stops: data.stops?.join(',') || null,
        template_description: data[FIELDS.TEMPLATE_DESCRIPTION] || null,
        template_name: data[FIELDS.TEMPLATE_NAME] || null,
        via_code: data[FIELDS.VIA]?.value || null,
        uld_overflow_weight:
            data?.uld_overflow_weight > 0 ? data.uld_overflow_weight : null,
    };
};
// AWB CAPTURE

export const formatAWBCaptureToBack = data => {
    return {
        provide_paper_documentation:
            data[FIELDS.DOCUMENTATION]?.value === 'eap',
        customs_information: {
            country: data[FIELDS.COUNTRY],
            customs_information_identifier: {
                code: data[FIELDS.CUSTOM_IDENTIFIER]?.value,
                description: data[FIELDS.CUSTOM_IDENTIFIER]?.label,
            },
            information_identifier: {
                code: data[FIELDS.INFO_IDENTIFIER]?.value,
                description: data[FIELDS.INFO_IDENTIFIER]?.label,
            },
            supplemental_customs_information: data[FIELDS.SUPPLEMENTAL_CUSTOM],
        },
        value_declaration: {
            carriage: data[FIELDS.VALUE_CARRIAGE],
            customs: data[FIELDS.VALUE_CUSTOM],
        },
        accounting_information:
            data[FIELDS.ACCOUNTING_CODE] && data[FIELDS.ACCOUNTING_DESCRIPTION]
                ? {
                      code: data[FIELDS.ACCOUNTING_CODE]?.value,
                      description: data[FIELDS.ACCOUNTING_DESCRIPTION],
                  }
                : null,
        handling_information: data[FIELDS.SCI]
            ? {
                  types: formatHandlingInfo(data[FIELDS.HANDLING_INFO], true),
                  sci: data[FIELDS.SCI]?.value || null,
              }
            : null,
    };
};

export const formatAWBCaptureFromBack = data => {
    return data
        ? {
              [FIELDS.DOCUMENTATION]: data?.provide_paper_documentation
                  ? optionsDocumentationAwb[0]
                  : optionsDocumentationAwb[1],
              [FIELDS.COUNTRY]: data?.customs_information?.country,
              [FIELDS.CUSTOM_IDENTIFIER]: {
                  label:
                      data?.customs_information?.customs_information_identifier
                          ?.description,
                  value:
                      data?.customs_information?.customs_information_identifier
                          ?.code,
              },
              [FIELDS.INFO_IDENTIFIER]: {
                  label:
                      data?.customs_information?.information_identifier
                          ?.description,
                  value:
                      data?.customs_information?.information_identifier?.code,
              },
              [FIELDS.SUPPLEMENTAL_CUSTOM]:
                  data?.customs_information?.supplemental_customs_information,
              [FIELDS.VALUE_CARRIAGE]: data?.value_declaration?.carriage,
              [FIELDS.VALUE_CUSTOM]: data?.value_declaration?.customs,
              [FIELDS.NO_CARRIAGE]:
                  data?.value_declaration?.carriage?.amount === 0
                      ? true
                      : false,
              [FIELDS.NO_CUSTOM]:
                  data?.value_declaration?.customs?.amount === 0 ? true : false,
              [FIELDS.ACCOUNTING_CODE]: {
                  value: data?.accounting_information?.code,
                  label: data?.accounting_information?.code,
              },
              [FIELDS.ACCOUNTING_DESCRIPTION]:
                  data?.accounting_information?.description,
              [FIELDS.HANDLING_INFO]: data?.handling_information?.types
                  ? formatHandlingInfo(data?.handling_information?.types, false)
                  : [],
              [FIELDS.SCI]: {
                  label: data?.handling_information?.sci,
                  value: data?.handling_information?.sci,
              },
          }
        : null;
};

export const formatHandlingInfo = (data, toBack) => {
    let dataToReturn = [];
    if (toBack && !isEmptyValues(data)) {
        data.forEach(type => {
            if (
                type[FIELDS.HANDLING_TYPE]?.value &&
                type[FIELDS.HANDLING_REMARKS]
            ) {
                const handlingObject = {
                    type: type[FIELDS.HANDLING_TYPE]?.value,
                    remarks: type[FIELDS.HANDLING_REMARKS],
                };
                dataToReturn = [...dataToReturn, handlingObject];
            }
        });
    } else if (!isEmptyValues(data)) {
        data.forEach(type => {
            const handlingObject = {
                [FIELDS.HANDLING_TYPE]: { value: type.type, label: type.type },
                [FIELDS.HANDLING_REMARKS]: type.remarks,
            };
            dataToReturn = [...dataToReturn, handlingObject];
        });
    }
    return dataToReturn;
};

export const formatULDList = uld_list => {
    return uld_list.map(uld => {
        return {
            ...uld,
            label: `${uld.type} - ${uld?.rate_class ||
                'N/A'} - ${uld?.load_code || 'N/A'}`,
        };
    });
};

export const formatExtraEmbargoesCheck = data => {
    const packages = JSON.stringify(formatPackagesForBooking(data));
    const shcs = formatValuesOfArray(data[FIELDS.SHC]);
    return {
        packages,
        product_code: data[FIELDS.PRODUCT]?.code,
        account_number_id: data[FIELDS.ACCOUNT_NUMBER]?.id,
        commodity_id:
            data[FIELDS.COMMODITY_CODE]?.value ||
            data[FIELDS.PRODUCT]?.default_commodity?.value,
        shcs: JSON.stringify(shcs),
        charge_code: data[FIELDS.PAYMENT_INFO],
        manifest: data[FIELDS.COMMODITY_DESCRIPTION],
        route_jwt:
            (typeof data[FIELDS.FLIGHT] === 'string' && data[FIELDS.FLIGHT]) ||
            null,
        routing:
            (typeof data[FIELDS.FLIGHT] === 'object' &&
                JSON.stringify(data[FIELDS.FLIGHT])) ||
            null,
        dropping_info:
            !data.shipper || isEmptyObject(data?.shipper)
                ? null
                : JSON.stringify(formatBookingContact(data?.shipper)),
        picking_info:
            !data.consignee || isEmptyObject(data?.consignee)
                ? null
                : JSON.stringify(formatBookingContact(data?.consignee)),
    };
};

const formatIsDryIceToBack = data => {
    if (
        showDryIce.includes(data?.[FIELDS.PRODUCT]?.code) &&
        !!data.is_dry_ice
    ) {
        if (data.dry_ice_more_than) {
            return { is_dry_ice: 1 };
        }
        return { is_dry_ice: 0 };
    }
    return { is_dry_ice: null };
};
const formatIsDryIceFromBack = (data, productCode) => {
    if (!showDryIce.includes(productCode)) {
        return { is_dry_ice: null, dry_ice_more_than: null };
    }
    if (data.is_dry_ice === true) {
        return { is_dry_ice: true, dry_ice_more_than: true };
    }
    if (data.is_dry_ice === false) {
        return { is_dry_ice: true, dry_ice_more_than: false };
    }
    return { is_dry_ice: false, dry_ice_more_than: null };
};
const formatStops = (stops, productCode) => {
    const products_array = ['QPI','QPK','LXL','LFG'];
    if(products_array.includes(productCode)){
        if((JSON.stringify(stops) === JSON.stringify(['0']) || JSON.stringify(stops) === JSON.stringify(['1']))){
            return [0, 1];
        }
    }
    return stops?.map(elem => parseInt(elem)) || [0, 1, 2];
};
