import styled from 'styled-components';

export const DisabledDiv = styled.div`
    z-index: 99999999999999999;
    left: 0%;
    top: 121px;
    height: 100vh;
    width: 100%;
    position: fixed;
    cursor: wait;
`;
