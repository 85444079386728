import React from 'react';

const Cart = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M9.10848,19.1193416 C9.56448,19.1193416 9.94848,19.483833 9.94848,19.9423868 C9.94848,20.3891828 9.56448,20.7654321 9.10848,20.7654321 C8.64048,20.7654321 8.26848,20.3891828 8.26848,19.9423868 C8.26848,19.483833 8.64048,19.1193416 9.10848,19.1193416 M19.54848,19.1193416 C20.00448,19.1193416 20.38848,19.483833 20.38848,19.9423868 C20.38848,20.3891828 20.00448,20.7654321 19.54848,20.7654321 C19.08048,20.7654321 18.70848,20.3891828 18.70848,19.9423868 C18.70848,19.483833 19.08048,19.1193416 19.54848,19.1193416 M23.36448,6.2680776 L6.03648,6.2680776 L4.95648,2.45855379 C4.87248,2.18812463 4.63248,2 4.34448,2 L0.62448,2 C0.27648,2 0.00048,2.28218695 0.00048,2.62316285 C0.00048,2.96413874 0.27648,3.2345679 0.62448,3.2345679 L3.86448,3.2345679 L8.08848,18.143445 C7.44048,18.4961787 7.00848,19.1663727 7.00848,19.9423868 C7.00848,21.0711346 7.94448,22 9.10848,22 C10.26048,22 11.20848,21.0711346 11.20848,19.9423868 C11.20848,19.6484421 11.14848,19.3662551 11.02848,19.1193416 L17.61648,19.1193416 C17.50848,19.3662551 17.43648,19.6484421 17.43648,19.9423868 C17.43648,21.0711346 18.38448,22 19.54848,22 C20.70048,22 21.64848,21.0711346 21.64848,19.9423868 C21.64848,18.8018812 20.70048,17.8730159 19.54848,17.8730159 L9.32448,17.8730159 L8.77248,15.9329806 L21.26448,15.9329806 C21.55248,15.9329806 21.80448,15.7330982 21.87648,15.462669 L23.97648,7.03233392 C24.02448,6.84420929 23.98848,6.65608466 23.85648,6.50323339 C23.74848,6.35038213 23.55648,6.2680776 23.36448,6.2680776 Z"
                    id="path-digital-4"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/cart"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-4"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-4"></use>
            </g>
        </svg>
    );
};

export default Cart;
