import React from 'react';

const Visa = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/visa"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group" transform="translate(0.000000, 4.000000)">
                    <polygon
                        id="Fill-1"
                        fill="#FFFFFF"
                        points="0 15.2388889 24 15.2388889 24 8.08242362e-14 0 8.08242362e-14"></polygon>
                    <polygon
                        id="Fill-3"
                        fill="#F1AD2B"
                        points="0.399666667 14.8483333 23.5998889 14.8483333 23.5998889 12.7683333 0.399666667 12.7683333"></polygon>
                    <polygon
                        id="Fill-4"
                        fill="#182E66"
                        points="0.399666667 2.47988889 23.5998889 2.47988889 23.5998889 0.399888889 0.399666667 0.399888889"></polygon>
                    <path
                        d="M11.7996667,4.77144444 L10.5767778,10.4886667 L9.09766667,10.4886667 L10.3206667,4.77144444 L11.7996667,4.77144444 Z M18.0223333,8.46322222 L18.801,6.31611111 L19.2488889,8.46322222 L18.0223333,8.46322222 Z M19.6734444,10.4886667 L21.041,10.4886667 L19.8461111,4.77144444 L18.5845556,4.77144444 C18.3001111,4.77144444 18.0605556,4.93633333 17.9544444,5.19055556 L15.735,10.4886667 L17.2884444,10.4886667 L17.5967778,9.63488889 L19.4941111,9.63488889 L19.6734444,10.4886667 Z M15.8121111,8.62222222 C15.8185556,7.11344444 13.7263333,7.02977778 13.7402222,6.35555556 C13.7447778,6.15066667 13.9401111,5.93255556 14.3674444,5.87677778 C14.5792222,5.84944444 15.1636667,5.82733333 15.8262222,6.13255556 L16.0853333,4.91966667 C15.7293333,4.791 15.2714444,4.667 14.7017778,4.667 C13.2395556,4.667 12.2108889,5.44366667 12.2026667,6.55677778 C12.1933333,7.37988889 12.9375556,7.83888889 13.497,8.113 C14.0737778,8.39311111 14.2671111,8.57288889 14.2643333,8.82322222 C14.2603333,9.20677778 13.8042222,9.37666667 13.3797778,9.383 C12.6356667,9.39466667 12.2044444,9.182 11.8606667,9.02166667 L11.5922222,10.2751111 C11.9383333,10.4334444 12.5762222,10.5714444 13.2365556,10.5784444 C14.791,10.5784444 15.8075556,9.81088889 15.8121111,8.62222222 L15.8121111,8.62222222 Z M9.68622222,4.77144444 L7.28966667,10.4886667 L5.72633333,10.4886667 L4.54688889,5.92588889 C4.47544444,5.64544444 4.41311111,5.54222222 4.19566667,5.42377778 C3.84,5.23044444 3.25277778,5.04977778 2.73655556,4.93744444 L2.77144444,4.77144444 L5.28833333,4.77144444 C5.609,4.77144444 5.89733333,4.98477778 5.97066667,5.35411111 L6.59366667,8.66266667 L8.13233333,4.77144444 L9.68622222,4.77144444 L9.68622222,4.77144444 Z"
                        id="Fill-5"
                        fill="#182E66"></path>
                </g>
            </g>
        </svg>
    );
};

export default Visa;
