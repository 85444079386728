import * as c from './actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case c.SEARCH:
            return Object.assign({}, state, { filters: action.filters });
        case c.STORE_BOOKINGS_STATUS_OPTIONS:
            return Object.assign({}, state, {
                bookingStatusOptions: [
                    ...action.data,
                    { id: 'draft', name: 'Drafts', description: 'Drafts' },
                ],
            });
        case c.STORE_BOOKINGS_MOVEMENT_STATUS_OPTIONS:
            return Object.assign({}, state, {
                bookingMovementStatusOptions: [...action.data],
            });

        default:
            return state;
    }
};
