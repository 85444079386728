import React from 'react';

const Download = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/download"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,0 C12.5522847,0 13,0.442709286 13,0.988818866 L13,16.685 L19.8146353,10.2718029 C20.2168091,9.89328641 20.8496835,9.91246442 21.2282,10.3146382 C21.6067165,10.7168119 21.5875384,11.3496864 21.1853647,11.7282029 L12.6853647,19.7282029 C12.3003147,20.0906029 11.6996853,20.0906029 11.3146353,19.7282029 L2.8146353,11.7282029 C2.41246155,11.3496864 2.39328354,10.7168119 2.77180001,10.3146382 C3.15031648,9.91246442 3.78319095,9.89328641 4.1853647,10.2718029 L11,16.685 L11,0.988818866 C11,0.442709286 11.4477153,0 12,0 Z M1,24 C0.44771525,24 0,23.5522847 0,23 C0,22.4477153 0.44771525,22 1,22 L23,22 C23.5522847,22 24,22.4477153 24,23 C24,23.5522847 23.5522847,24 23,24 L1,24 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Download;
