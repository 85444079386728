import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Formik, Form } from 'formik';
import { generateSchema } from './schema';
import { cloneDeep } from 'lodash';

function FormWrapper({ children }) {
    const initialValues = useSelector(state => state.bookingSteps.form);
    const bookingProduct = useSelector(
        state => state.bookingSteps.bookingProduct
    );
    const { pathname } = useLocation();

    return (
        <Formik
            enableReinitialize={false}
            initialValues={cloneDeep(initialValues)}
            validationSchema={generateSchema(pathname, bookingProduct)}
            validateOnChange={false}
            validateOnBlur={false}>
            <Form>{children}</Form>
        </Formik>
    );
}

export default FormWrapper;
