import React from 'react';

const AddTraveler = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M18.203588,16.8428989 L18.203588,18.8156496 L20.1823809,18.8156496 L20.1823809,19.7156271 L18.203588,19.7156271 L18.203588,21.6883778 L17.300854,21.6883778 L17.300854,19.7156271 L15.3220611,19.7156271 L15.3220611,18.8156496 L17.300854,18.8156496 L17.300854,16.8428989 L18.203588,16.8428989 Z M10.6115951,9.47868303 C13.1633232,9.47868303 15.3539577,11.1226419 16.1483636,13.4025849 C13.4802246,14.2175293 11.3430176,15.9031982 11.7670946,20.39841 L2.00553104,20.39841 C1.72869261,20.39841 1.5,20.1824154 1.5,19.8944226 L1.5,15.3465363 C1.5,12.1066173 4.13598326,9.47868303 7.38582564,9.47868303 L10.6115951,9.47868303 Z M8.99293288,0 C11.3316158,0 13.2369863,1.89955251 13.2369863,4.23109422 C13.2369863,6.57223569 11.3316158,8.47178821 8.99293288,8.47178821 C6.65425001,8.47178821 4.75008311,6.57223569 4.75008311,4.23109422 C4.75008311,1.89955251 6.65425001,0 8.99293288,0 Z M17.7516192,23.1000225 C15.6307961,23.1000225 13.9059724,21.3804655 13.9059724,19.2661183 C13.9059724,17.1517712 15.6307961,15.4310142 17.7516192,15.4310142 C19.8724423,15.4310142 21.597266,17.1517712 21.597266,19.2661183 C21.597266,21.3804655 19.8724423,23.1000225 17.7516192,23.1000225 M13.0032384,19.2661183 C13.0032384,21.880853 15.128876,24 17.7516192,24 C20.3743624,24 22.5,21.880853 22.5,19.2661183 C22.5,16.6513837 20.3743624,14.5310367 17.7516192,14.5310367 C15.128876,14.5310367 13.0032384,16.6513837 13.0032384,19.2661183 Z"
                    id="path-travel-2"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/add-traveler"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-2"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-2"></use>
            </g>
        </svg>
    );
};

export default AddTraveler;
