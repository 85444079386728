import * as Yup from 'yup';
import { LABELS, FIELDS } from '../../constants';
import { ZIP_CODE_MAX_LENGTH } from 'modules/contacts/constants';
import {
    GCR,
    EXP,
    QPK,
    QPI,
    LFG,
    LXL,
} from '../common/initialValuesByProductType/productCodes';

export const generateContactSchemas = product => {
    return generateContactSchema(product)
        .concat(generateContactSchema(product))
        .concat(generateAgentSchema(product));
};

const generateContactSchema = product => {
    let schema;

    const defaultSchema = {
        [FIELDS.EMAIL]: Yup.string()
            .email('Please enter a valid email address')
            .nullable()
            .label(LABELS.EMAIL),
        [FIELDS.PRIMARY_PHONE]: Yup.string()
            .nullable()
            .required()
            .max(18)
            .label(LABELS.PRIMARY_PHONE),
        [FIELDS.STATE]: Yup.string().when('country', {
            is: val => {
                return val?.value === '227';
            },
            then: Yup.string()
                .label(LABELS.STATE)
                .matches(
                    /^[aA-zZ\s]+$/,
                    'Only alphabets are allowed for this field '
                )
                .min(2, 'State must be exactly 2 digits')
                .max(2, 'State must be exactly 2 digits')
                .required()
                .nullable(),
            otherwise: Yup.string()
                .label(LABELS.STATE)
                .matches(
                    /^[aA-zZ\s]+$/,
                    'Only alphabets are allowed for this field '
                )
                .max(9)
                .notRequired()
                .nullable(),
        }),
    };

    const schemaToModify = {
        [FIELDS.COMPANY_NAME]: Yup.string()
            .nullable()
            .label(LABELS.COMPANY_NAME)
            .max(35),
        [FIELDS.PRIMARY_PHONE]: Yup.string()
            .nullable()
            .label(LABELS.PRIMARY_PHONE)
            .max(18),
        [FIELDS.ADDRESS_1]: Yup.string()
            .nullable()
            .label(LABELS.ADDRESS_1)
            .max(35, 'Address should not be longer than 35 characters.'),
        [FIELDS.CITY_TEXT]: Yup.string()
            .nullable()
            .label(LABELS.CITY_TEXT)
            .matches(
                /^[aA-zZ\s-]+$/,
                'Only alphabets are allowed for this field '
            )
            .max(17),
        [FIELDS.ZIP_CODE]: Yup.string()
            .nullable()
            .label(LABELS.ZIP_CODE)
            .max(ZIP_CODE_MAX_LENGTH),
        [FIELDS.CITY_CODE]: Yup.string()
            .nullable()
            .label(LABELS.CITY_CODE),
    };

    const requiredString = Yup.string().required();

    const notRequiredString = Yup.string().notRequired();

    const toggleRequire = (rules, required) => {
        for (const k in rules) {
            rules[k] = rules[k].concat(
                required ? requiredString : notRequiredString
            );
        }
        return rules;
    };

    const countryObject = Yup.object()
        .shape({
            value: Yup.string(),
            label: Yup.string(),
        })
        .default(null)
        .label(LABELS.COUNTRY)
        .nullable();
    const isRequiredCountry = required => {
        return {
            [FIELDS.COUNTRY]: required
                ? countryObject.required()
                : countryObject,
        };
    };

    const schemaAfterModification = {
        [FIELDS.ADDRESS_2]: Yup.string()
            .nullable()
            .label(LABELS.ADDRESS_1)
            .max(35, 'Address should not be longer than 35 characters.'),
        [FIELDS.ADDRESS_3]: Yup.string()
            .nullable()
            .label(LABELS.ADDRESS_1)
            .max(35, 'Address should not be longer than 35 characters.'),
    };

    const requiredSchema = {
        ...defaultSchema,
        ...toggleRequire({ ...schemaToModify }, true),
        ...isRequiredCountry(true),
        ...schemaAfterModification,
    };

    const notRequiredSchema = {
        ...defaultSchema,
        ...toggleRequire({ ...schemaToModify }, false),
        ...isRequiredCountry(false),
        ...schemaAfterModification,
    };

    switch (product) {
        case GCR:
        case EXP:
        case QPK:
        case QPI:
        case LFG:
        case LXL:
            schema = Yup.object().shape({
                contactValidation: Yup.boolean(),
                shipper: Yup.object().when('contactValidation', {
                    is: true,
                    then: Yup.object(requiredSchema),
                    otherwise: Yup.object(notRequiredSchema)
                        .nullable()
                        .notRequired(),
                }),
                consignee: Yup.object().when('contactValidation', {
                    is: true,
                    then: Yup.object(requiredSchema),
                    otherwise: Yup.object(notRequiredSchema)
                        .nullable()
                        .notRequired(),
                }),
            });
            break;
        default:
            schema = Yup.object().shape({
                contactValidation: Yup.boolean(),
                shipper: Yup.object().when('contactValidation', {
                    is: true,
                    then: Yup.object(requiredSchema),
                    otherwise: Yup.object(notRequiredSchema)
                        .nullable()
                        .notRequired(),
                }),
                consignee: Yup.object().when('contactValidation', {
                    is: true,
                    then: Yup.object(requiredSchema),
                    otherwise: Yup.object(notRequiredSchema)
                        .nullable()
                        .notRequired(),
                }),
            });
    }
    return schema;
};

const generateAgentSchema = product => {
    let schema;

    switch (product) {
        case GCR:
        case EXP:
        case QPK:
        case QPI:
        case LFG:
        case LXL:
            schema = Yup.object().shape({
                agent: Yup.object({
                    [FIELDS.EMAIL]: Yup.string()
                        .email('Please enter a valid email address')
                        .nullable()
                        .required()
                        .label(LABELS.EMAIL),
                    [FIELDS.PRIMARY_PHONE]: Yup.string()
                        .nullable()
                        .required()
                        .max(18)
                        .label(LABELS.PRIMARY_PHONE),
                }).required(),
            });
            break;
        default:
            schema = Yup.object().shape({
                agent: Yup.object({
                    [FIELDS.EMAIL]: Yup.string()
                        .email('Please enter a valid email address')
                        .nullable()
                        .required()
                        .label(LABELS.EMAIL),
                    [FIELDS.PRIMARY_PHONE]: Yup.string()
                        .nullable()
                        .required()
                        .max(18)
                        .label(LABELS.PRIMARY_PHONE),
                }).required(),
            });
            break;
    }
    return schema;
};

export const agentFormSchema = Yup.object().shape({
    [FIELDS.EMAIL]: Yup.string()
        .email('Please enter a valid email address')
        .nullable()
        .label(LABELS.EMAIL),
    [FIELDS.PRIMARY_PHONE]: Yup.string()
        .nullable()
        .required()
        .max(18)
        .label(LABELS.PRIMARY_PHONE),
});
