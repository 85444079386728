import React from 'react';

const Traveler = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.000123,9.96729967 C14.8095492,9.96729967 17.100123,7.73287733 17.100123,4.97764978 C17.100123,2.23562236 14.8095492,0 12.000123,0 C9.18946721,0 6.90012295,2.23562236 6.90012295,4.97764978 C6.90012295,7.73287733 9.18946721,9.96729967 12.000123,9.96729967 M10.0622951,11.1490715 C6.16106557,11.1490715 3,14.2475025 3,18.0539405 L3,23.4095941 C3,23.7407974 3.26680328,24 3.60491803,24 L20.3938525,24 C20.7331967,24 21,23.7407974 21,23.4095941 L21,18.0539405 C21,14.2475025 17.8389344,11.1490715 13.9377049,11.1490715 L10.0622951,11.1490715 Z"
                    id="path-travel-30"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/traveler"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-30"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-30"></use>
            </g>
        </svg>
    );
};

export default Traveler;
