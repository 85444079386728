import React from 'react';
import { useSelector } from 'react-redux';
import { DisabledDiv } from './DisabledDiv.styled';

const DisabledScreen = () => {
    const isApiResponseLoading = useSelector(
        state => state.bookingSteps?.isApiResponseLoading
    );

    if (isApiResponseLoading) {
        return (
            <DisabledDiv
                className="disabledScreen"
                onClick={() => false}></DisabledDiv>
        );
    }
    return <></>;
};

export default DisabledScreen;
