import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import './Page.css';
import Header from '../Header/Header';
import Footer from '../Footer';
import IdleMonitor from './userActivityTimeout';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryMessage from 'basic_components/ErrorBoundaryMessage';

const { Content } = Layout;

const Page = props => {
    const { children } = props;

    const { loggedIn } = useSelector(state => state.user);

    return (
        <Layout>
            {loggedIn && <Header />}
            <ErrorBoundary
                beforeCapture={scope => {
                    scope.setTag('eventType', 'system');
                }}
                fallback={<ErrorBoundaryMessage />}>
                <Layout>
                    <Content style={{ padding: '16px' }}>{children}</Content>
                    {loggedIn && <IdleMonitor />}
                </Layout>
            </ErrorBoundary>
            {loggedIn && <Footer />}
        </Layout>
    );
};
export default Page;
