import React from 'react';

const Mouse = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.6,9.024 C12.6,9.36 12.324,9.624 12,9.624 C11.664,9.624 11.4,9.36 11.4,9.024 L11.4,6.06 C11.4,5.736 11.664,5.46 12,5.46 C12.324,5.46 12.6,5.736 12.6,6.06 L12.6,9.024 Z M4.5,7.5 L4.5,16.5 C4.5,20.64 7.86,24 12,24 C16.14,24 19.5,20.64 19.5,16.5 L19.5,7.5 C19.5,3.36 16.14,0 12,0 C7.86,0 4.5,3.36 4.5,7.5 Z"
                    id="path-digital-16"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/mouse"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-16"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-16"></use>
            </g>
        </svg>
    );
};

export default Mouse;
