import React from 'react';

const File = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-digital-25"
                    points="0 0 21 0 21 24 0 24"></polygon>
            </defs>
            <g
                id="X-Global-Icons/Digital/file"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-3" transform="translate(1.500000, 0.000000)">
                    <mask id="mask-icon-file" fill="white">
                        <use xlinkHref="#path-digital-25"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <g
                        id="Group-8"
                        mask="url(#mask-icon-file)"
                        fill={fill || '#002244'}
                        fillRule="nonzero">
                        <g transform="translate(-0.000014, 0.000000)" id="Path">
                            <path d="M15,6.2625 L15,0 L0.6,0 C0.2625,0 0,0.2625 0,0.6 L0,23.4 C0,23.7375 0.2625,24 0.6,24 L20.4,24 C20.7375,24 21,23.7375 21,23.4 L21,6.75 L15.6,6.75 C15.3,6.75 15.075,6.525 15,6.2625 Z"></path>
                            <polygon points="21 5.25 16.5 0 16.5 5.25"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default File;
