import React from 'react';

const Medical = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-1"
                    points="0.0341833543 0.0593769231 20.9846452 0.0593769231 20.9846452 23.9250692 0.0341833543 23.9250692"></polygon>
            </defs>
            <g
                id="X-Global-Icons/CleanPlus/medical"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-64" transform="translate(1.500000, 0.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-63"></g>
                    <path
                        d="M15.1109,13.0632231 C15.1109,13.4566846 14.7988355,13.7758385 14.4136097,13.7758385 L12.256029,13.7758385 L12.256029,15.9808385 C12.256029,16.3743 11.9441903,16.6932231 11.5589645,16.6932231 L9.46009355,16.6932231 C9.07509355,16.6932231 8.76280323,16.3743 8.76280323,15.9808385 L8.76280323,13.7758385 L6.60522258,13.7758385 C6.22022258,13.7758385 5.90793226,13.4566846 5.90793226,13.0632231 L5.90793226,10.9182231 C5.90793226,10.5245308 6.22022258,10.2056077 6.60522258,10.2056077 L8.76280323,10.2056077 L8.76280323,8.00083846 C8.76280323,7.60691538 9.07509355,7.28822308 9.46009355,7.28822308 L11.5589645,7.28822308 C11.9441903,7.28822308 12.256029,7.60691538 12.256029,8.00083846 L12.256029,10.2056077 L14.4136097,10.2056077 C14.7988355,10.2056077 15.1109,10.5245308 15.1109,10.9182231 L15.1109,13.0632231 Z M19.9621258,5.15706923 C18.4523839,3.58760769 14.038771,0.375530769 10.6778677,0.0621461538 L10.3944806,0.0593769231 L10.3414161,0.0621461538 C6.73054516,0.398838462 2.29006129,3.80499231 1.04654516,5.16837692 C-0.278487097,6.62153077 0.0552548387,8.44506923 0.115545161,8.72014615 C2.16383548,22.2399923 9.55606129,23.7831462 10.3926742,23.9146846 L10.4425774,23.9250692 L10.5051258,23.9211462 C10.5051258,23.9211462 10.6379,23.9130692 10.664771,23.9084538 C11.2353839,23.8119923 13.2665129,23.3319923 15.4380935,21.2149154 C18.2202548,18.5029154 20.0592226,14.2987615 20.9041903,8.71783846 C20.9608677,8.45060769 21.3034161,6.55114615 19.9621258,5.15706923 L19.9621258,5.15706923 Z"
                        id="Fill-62"
                        fill={fill || '#002244'}
                        mask="url(#mask-2)"></path>
                </g>
            </g>
        </svg>
    );
};

export default Medical;
