import React from 'react';

const Seat = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M5.27582234,14.3413434 L18.7146379,14.3413434 L18.7146379,12.099721 L21.3906598,12.099721 L21.3906598,10.2949029 L18.7146379,10.2949029 L18.7146379,3.3504335 C18.7146379,1.49881499 17.1760476,0 15.299897,0 L8.69056324,0 C6.81318957,0 5.27582234,1.49881499 5.27582234,3.3504335 L5.27582234,10.2949029 L2.59980045,10.2949029 L2.59980045,12.0937209 L5.27582234,12.0937209 L5.27582234,14.3413434 Z M5.33624075,24 L7.17080785,24 L7.17080785,20.6891669 L5.33624075,20.6891669 L5.33624075,24 Z M16.8194078,24 L18.6539749,24 L18.6539749,20.6891669 L16.8194078,20.6891669 L16.8194078,24 Z M21.5,19.1812318 C21.4853235,17.3416134 19.8452205,15.9087991 17.9715159,15.9087991 L6.02848407,15.9087991 C4.15355649,15.9087991 2.51467654,17.3416134 2.5,19.1812318 L2.5,19.2196322 L21.5,19.2196322 L21.5,19.1812318 Z"
                    id="path-travel-25"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/seat"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-25"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-25"></use>
            </g>
        </svg>
    );
};

export default Seat;
