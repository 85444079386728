import React from 'react';

const CircleInfo = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12,10 C12.4142136,10 12.75,10.3357864 12.75,10.75 L12.75,17.25 C12.75,17.6642136 12.4142136,18 12,18 C11.5857864,18 11.25,17.6642136 11.25,17.25 L11.25,10.75 C11.25,10.3357864 11.5857864,10 12,10 Z M11.9994719,6 C12.6078253,6 13.1,6.49323092 13.1,7.10052808 C13.1,7.70782525 12.6078253,8.20105617 11.9994719,8.20105617 C11.3921747,8.20105617 10.9,7.70782525 10.9,7.10052808 C10.9,6.49323092 11.3921747,6 11.9994719,6 Z M0,12 C0,18.6204 5.37,24 12,24 C18.6204,24 24,18.6204 24,12 C24,5.37 18.6204,0 12,0 C5.37,0 0,5.37 0,12 Z"
                    id="path-alert-2"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/circle-info"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-2"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-alert-2"></use>
            </g>
        </svg>
    );
};

export default CircleInfo;
