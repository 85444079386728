import * as c from './actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case c.ONE_RECEIVED:
            return Object.assign({}, state, { one: action.data });

        case c.ADD_FORM:
            return Object.assign({}, state, { one: {}, form_action: 'add' });

        case c.EDIT_FORM:
            return Object.assign({}, state, { one: {}, form_action: 'edit' });

        case c.CONTACT_ID_TO_DELETE:
            return { ...state, contact_id_to_delete: action.id };

        case c.SEARCH:
            return Object.assign({}, state, { filters: action.filters });

        default:
            return state;
    }
};
