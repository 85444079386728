import React from 'react';
import { useSelector } from 'react-redux';

function EditableContent({ children }) {
    const editable = useSelector(
        state => state.bookingSteps?.form?.booking_data?.is_editable
    );
    return <>{editable && children}</>;
}

export default EditableContent;
