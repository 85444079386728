import React from 'react';

const CaretLeft = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/caret-left"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M21.7250876,18.1196441 C22.2464651,18.6277218 23.0906844,18.6266464 23.6107041,18.1172421 C24.1307239,17.6078378 24.1296232,16.7830056 23.6082457,16.2749279 L12.941579,5.88035589 C12.4211626,5.3732147 11.5788374,5.3732147 11.058421,5.88035589 L0.391754315,16.2749279 C-0.129623174,16.7830056 -0.130723851,17.6078378 0.389295885,18.1172421 C0.90931562,18.6266464 1.75353487,18.6277218 2.27491236,18.1196441 L12,8.64263249 L21.7250876,18.1196441 Z"
                    id="Path-2"
                    fill="#002244"
                    transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "></path>
            </g>
        </svg>
    );
};

export default CaretLeft;
