import {
    weightToKg,
    dimsToVolume,
    volumeToCuinValue,
    weightToLb,
    volumeToCbmValue,
} from 'basic_components/conversions';
import { fixDecimals } from 'basic_components/jsTools';

const validateVolumeUnitConsistency = products => {
    const firstDimsUnit = products[0].dimsUnit;
    const volumeUnitConsistency = products.every(pack => {
        return pack.dimsUnit === firstDimsUnit;
    });
    return volumeUnitConsistency;
};

const validateWeightUnitConsistency = products => {
    const firstWeightUnit = products[0].weightUnit;
    const weightUnitConsistency = products.every(pack => {
        return pack.weightUnit === firstWeightUnit;
    });
    return weightUnitConsistency;
};

export const getTotalSummaryOfPiecesInObject = (
    products,
    userDim = 'cm',
    userWeight = 'kg',
    isDryIce = false
) => {
    if (!products || !products.length) {
        return {
            pieces: 0,
            volume: 0,
            volumeUnit: userDim === 'cm' ? 'cbm' : 'cu in',
            totalWeight: 0,
            weightUnit: userWeight,
        };
    }
    let totalPieces = 0;
    let totalVolume = 0;
    let totalWeight = 0;
    let totalDryIceWeight = 0;
    let isCBMVolume = false;
    let isKGWeight = false;
    const unitConsistency = {
        volume: true,
        weight: true,
    };

    if (products.length > 1) {
        unitConsistency.volume = validateVolumeUnitConsistency(products);
        unitConsistency.weight = validateWeightUnitConsistency(products);
    }

    products.forEach(p => {
        if (p.dimsUnit === 'cm') isCBMVolume = true;
        if (p.weightUnit === 'kg') isKGWeight = true;

        const dimensions = {
            length: p.length,
            width: p.width,
            height: p.height,
            unit: p.dimsUnit,
        };
        const volume = dimsToVolume(dimensions);

        totalPieces += p.pieces;
        const totalPackageVolumeFixedDecimals = fixDecimals(
            volume?.value * p.pieces
        );
        totalWeight +=
            parseFloat(
                weightToKg({ value: p.weight, unit: p.weightUnit }) || 0
            ) * p.pieces;

        totalDryIceWeight +=
            parseFloat(
                weightToKg({
                    value: p.dry_ice_weight,
                    unit: p.dry_ice_weightUnit,
                }) || 0
            ) * p.pieces;

        const volumeToAdd = volumeToCbmValue({
            value: totalPackageVolumeFixedDecimals,
            unit: volume.unit,
        });

        const useMinimumVolume =
            volumeToAdd < 0.01 && unitConsistency.volume === false;

        totalVolume += useMinimumVolume ? 0.01 : volumeToAdd;
    });

    let returnedVolumeUnit = 'cbm';
    let returnedWeightUnit = 'kg';
    let returnedDryIceWeightUnit = 'kg';

    if (!isCBMVolume) {
        totalVolume = volumeToCuinValue({ value: totalVolume, unit: 'cbm' });
        returnedVolumeUnit = 'cu in';
    }

    if (!isKGWeight) {
        totalWeight = weightToLb({ value: totalWeight, unit: 'kg' });
        totalDryIceWeight = weightToLb({
            value: totalDryIceWeight,
            unit: 'kg',
        });
        returnedWeightUnit = 'lb';
        returnedDryIceWeightUnit = 'lb';
    }

    const basicResult = {
        pieces: totalPieces,
        volume: fixDecimals(totalVolume),
        volumeUnit: returnedVolumeUnit,
        totalWeight: fixDecimals(totalWeight),
        weight: totalWeight,
        weightUnit: returnedWeightUnit,
    };

    const dryIceResult = isDryIce
        ? {
              totalDryIceWeight: fixDecimals(totalDryIceWeight),
              dryIceWeightUnit: returnedDryIceWeightUnit,
          }
        : {};

    return {
        ...basicResult,
        ...dryIceResult,
    };
};
export const getTotalSummaryOfPieces = (products, isDryIce) => {
    const totalSummary = getTotalSummaryOfPiecesInObject(
        products,
        undefined,
        undefined,
        isDryIce
    );

    const showDryIce = totalSummary.totalDryIceWeight > 0 && isDryIce;

    return `${totalSummary.pieces} ${'pieces'} - ${totalSummary.volume} ${
        totalSummary.volumeUnit
    } - ${totalSummary.totalWeight} ${totalSummary.weightUnit}${
        showDryIce
            ? ` (Total dry ice weight: ${totalSummary.totalDryIceWeight} ${totalSummary.dryIceWeightUnit})`
            : ''
    }`;
};

export const getTotalSummaryOfContainers = (containers, overflowWeight = 0) => {
    const t = getTotalSummaryOfContainersInObject(containers, overflowWeight);
    return `${t.pieces} ${'pieces'} - ${t.totalWeight} ${t.weightUnit}`;
};

export const getTotalSummaryOfContainersInObject = (
    containers,
    overflowWeight = 0
) => {
    if (!containers || !containers.length) {
        return {
            pieces: 0,
            volume: 0,
            volumeUnit: 'cbm',
            totalWeight: 0,
            weightUnit: 'kg',
        };
    }

    let totalVolume = 0;
    let totalWeight = 0;
    let isKGWeight = false;

    containers.forEach(container => {
        if (container.weightUnit === 'kg') isKGWeight = true;

        totalWeight += parseFloat(
            weightToKg({
                value: container.weight,
                unit: container.weightUnit,
            }) || 0
        );
        totalVolume += container.containerType?.max_volume;
    });

    let returnedWeightUnit = 'kg';

    if (!isKGWeight) {
        totalWeight = weightToLb({ value: totalWeight, unit: 'kg' });
        returnedWeightUnit = 'lb';
    }

    return {
        pieces: containers.length,
        volume: fixDecimals(totalVolume),
        volumeUnit: 'cbm',
        totalWeight: fixDecimals(totalWeight + overflowWeight),
        weightUnit: returnedWeightUnit,
    };
};

export const getTotalSummaryOfTotalPiecesInObject = (
    values,
    userDim = 'cm',
    userWeight = 'kg'
) => {
    if (!values) {
        return {
            pieces: 0,
            volume: 0,
            volumeUnit: userDim === 'cm' ? 'cbm' : 'cu in',
            totalWeight: 0,
            weightUnit: userWeight,
        };
    }

    return {
        pieces: values.package_total_shipment_pieces,
        volume: fixDecimals(values.package_total_shipment_volume),
        volumeUnit: values.package_total_shipment_volume_unit,
        totalWeight: fixDecimals(values.package_total_shipment_weight),
        weightUnit: values.package_total_shipment_weight_unit,
    };
};
