import React from 'react';

const Uatp = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient
                    x1="-0.283196721%"
                    y1="50.5360262%"
                    x2="101.336066%"
                    y2="50.5360262%"
                    id="linearGradient-1">
                    <stop stop-color="#64B664" offset="0%"></stop>
                    <stop stop-color="#198A44" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g
                id="X-Global-Icons/Digital/Payment/uatp"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Group-11"
                    transform="translate(0.000000, 5.000000)"
                    fillRule="nonzero">
                    <rect
                        id="Rectangle-path"
                        stroke="#999999"
                        stroke-width="0.5"
                        fill="#FFFFFF"
                        x="0.25"
                        y="0.25"
                        width="23.5"
                        height="14.5"
                        rx="1.29464286"></rect>
                    <path
                        d="M19.6496989,5.0392562 C19.0282654,5.0392562 18.5198198,5.0392562 17.8983864,5.15082645 L17.8983864,10.0599174 L19.1412533,10.0599174 L19.1412533,8.55371901 C19.3107352,8.60950413 19.480217,8.60950413 19.5932049,8.60950413 C21.0055537,8.60950413 21.6269872,7.8285124 21.6269872,6.82438017 C21.6834812,5.87603306 21.0620477,5.0392562 19.6496989,5.0392562 Z M19.4237231,7.6053719 C19.3107352,7.6053719 19.2542412,7.6053719 19.1412533,7.6053719 L19.1412533,6.2107438 C19.1977473,6.2107438 19.3107352,6.2107438 19.4237231,6.2107438 C19.9886626,6.2107438 20.4406142,6.37809917 20.4406142,6.93595041 C20.4406142,7.43801653 19.9886626,7.6053719 19.4237231,7.6053719 Z"
                        id="Shape"
                        fill="#102C1E"></path>
                    <polygon
                        id="Shape"
                        fill="#102C1E"
                        points="16.8814952 5.09504132 12.5879548 5.09504132 12.5879548 6.2107438 14.1132915 6.2107438 14.1132915 10.1157025 15.3561585 10.1157025 15.3561585 6.2107438 16.8814952 6.2107438"></polygon>
                    <path
                        d="M5.30023492,5.09504132 L5.30023492,9.05578512 C3.94438005,9.33471074 3.60541634,8.72107438 3.60541634,8.21900826 L3.60541634,5.09504132 L2.36254938,5.09504132 L2.36254938,8.27479339 L2.36254938,8.27479339 C2.36254938,10.2830579 4.28334377,10.4504132 6.59959583,9.89256198 L6.59959583,9.39049587 L6.59959583,9 L6.59959583,5.0392562 L5.30023492,5.0392562 L5.30023492,5.09504132 Z"
                        id="Shape"
                        fill="#102C1E"></path>
                    <polygon
                        id="Shape"
                        fill="#102C1E"
                        points="10.5541725 5.09504132 11.5145697 7.6053719 10.4411846 8.05165289 9.36779951 5.09504132"></polygon>
                    <path
                        d="M10.5541725,8.38636364 L11.175606,10.0599174 L12.5314609,10.0599174 L11.6840516,7.94008264 C11.3450878,8.10743802 10.9496302,8.27479339 10.5541725,8.38636364 Z"
                        id="Shape"
                        fill="#102C1E"></path>
                    <path
                        d="M6.65608978,8.94421488 C13.7178339,8.49793388 14.734725,4.03512397 20.3276263,4.87190083 L20.3276263,4.87190083 L20.3276263,4.87190083 C20.3276263,4.87190083 20.3841203,4.87190083 20.3841203,4.8161157 C20.3841203,4.8161157 20.3841203,4.76033058 20.3276263,4.76033058 L20.3276263,4.76033058 C13.491858,3.19834711 12.5314609,7.8285124 6.59959583,8.83264463 L6.59959583,8.83264463 C6.65608978,8.88842975 6.65608978,8.88842975 6.65608978,8.94421488 C6.65608978,8.94421488 6.65608978,8.94421488 6.65608978,8.94421488 L6.65608978,8.94421488 Z"
                        id="Shape"
                        fill="url(#linearGradient-1)"></path>
                    <polygon
                        id="Shape"
                        fill="#102C1E"
                        points="9.02883579 5.09504132 7.1080414 10.1157025 8.40740231 10.1157025 10.2717027 5.09504132"></polygon>
                </g>
            </g>
        </svg>
    );
};

export default Uatp;
