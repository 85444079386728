import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from 'basic_components/PrivateRoute';
import { MODULE_ROUTE } from './constants';

const List = React.lazy(() => import('./components/List'));
const AddEditForm = React.lazy(() => import('./components/AddEditForm'));

export default () => (
    <Switch>
        <PrivateRoute
            exact
            path={`${MODULE_ROUTE}/modify/:id`}
            component={AddEditForm}
        />
        <PrivateRoute
            exact
            path={`${MODULE_ROUTE}/create`}
            component={AddEditForm}
        />
        <PrivateRoute exact path={`${MODULE_ROUTE}/`} component={List} />
    </Switch>
);
