import React from 'react';

const RentalCar = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M4.21968,9.82176627 L17.97048,10.2038924 L14.71368,7.35573304 C14.45448,7.12622785 14.11128,7 13.75848,7 L7.88568,7 C7.52328,7 7.17528,7.13540806 6.91128,7.37409345 L4.21968,9.82176627 Z M4.62132,14.1311852 C5.44572,14.1311852 6.12132,14.7737997 6.12132,15.5655926 C6.12132,16.3573855 5.44572,17 4.62132,17 C3.79332,17 3.12132,16.3573855 3.12132,15.5655926 C3.12132,14.7737997 3.79332,14.1311852 4.62132,14.1311852 Z M19.33656,14.1311852 C20.16456,14.1311852 20.83656,14.7737997 20.83656,15.5655926 C20.83656,16.3573855 20.16456,17 19.33656,17 C18.50856,17 17.83656,16.3573855 17.83656,15.5655926 C17.83656,14.7737997 18.50856,14.1311852 19.33656,14.1311852 Z M1.4076,10.8809327 L22.626,11.4787937 C23.3928,11.4971541 24,12.0950151 24,12.8294317 L24,12.8294317 L24,15.5651336 L21.8076,15.5651336 C21.8076,14.2649867 20.7012,13.2023777 19.3368,13.2023777 C17.976,13.2023777 16.866,14.2649867 16.866,15.5651336 L16.866,15.5651336 L7.092,15.5651336 C7.092,14.2649867 5.9808,13.2023777 4.6212,13.2023777 C3.2568,13.2023777 2.1504,14.2649867 2.1504,15.5651336 L2.1504,15.5651336 L0,15.5651336 L0,12.1902598 C0.024,11.470761 0.636,10.894703 1.3788,10.8809327 L1.3788,10.8809327 L1.4076,10.8809327 Z"
                    id="path-7"></path>
            </defs>
            <g
                id="X-Global-Icons/Destination/rental-car"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-7"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-7"></use>
            </g>
        </svg>
    );
};

export default RentalCar;
