import React from 'react';
import { Flex, Alert } from '@freightos/uikit';
import Modal from 'basic_components/Modal';
import { default as GlobalIcon } from 'icons/X Global Icons/GlobalIcons';
import theme from 'configs/theme';

function iataRestrictionNotification({ translate: t }) {
    return (
        <>
            <Modal id="isDomesticIATA">
                <Alert level="error" pt={10} pb={20} m={2} closable={false}>
                    <Flex className="warning">
                        <GlobalIcon
                            className="warning"
                            iconName="warning"
                            size="25px"
                            mr={2}
                            mt={1}
                            fill={theme.error}
                            pointer
                            style={{
                                hoverColor: theme.error,
                            }}
                        />
                        <p style={{ fontSize: '14px', fontWeight: 600 }}>
                            {t(
                                'IATA account not valid for US domestic shipments, please select a United account for the booking'
                            )}
                        </p>
                    </Flex>
                </Alert>
            </Modal>
        </>
    );
}

export default iataRestrictionNotification;
