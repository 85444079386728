import React from 'react';

const Phone = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M2.352,10.4734062 L2.352,12.4681587 C2.352,12.6749318 2.184,12.8452155 1.98,12.8452155 C1.788,12.8452155 1.62,12.6749318 1.62,12.4681587 L1.62,10.4734062 C1.62,10.2666331 1.788,10.1085124 1.98,10.1085124 C2.184,10.1085124 2.352,10.2666331 2.352,10.4734062 M2.352,13.5263505 C2.352,13.7331237 2.184,13.8912443 1.98,13.8912443 C1.788,13.8912443 1.62,13.7331237 1.62,13.5263505 C1.62,13.3195774 1.788,13.1492937 1.98,13.1492937 C2.184,13.1492937 2.352,13.3195774 2.352,13.5263505 M-1.77635684e-15,16.4698268 C-1.77635684e-15,17.8685861 1.128,18.9997567 2.508,18.9997567 L3.972,18.9997567 L3.972,5 L2.508,5 C1.128,5 -1.77635684e-15,6.13117061 -1.77635684e-15,7.52992998 L-1.77635684e-15,16.4698268 Z M21.024,12.0062032 C21.024,11.4466994 21.468,10.9966638 22.02,10.9966638 C22.572,10.9966638 23.016,11.4466994 23.016,12.0062032 C23.016,12.5535438 22.572,13.0035794 22.02,13.0035794 C21.468,13.0035794 21.024,12.5535438 21.024,12.0062032 L21.024,12.0062032 Z M20.04,19 L21.504,19 C22.884,19 24,17.8688294 24,16.47007 L24,7.53017324 C24,6.13141388 22.884,5.00024326 21.504,5.00024326 L20.04,5.00024326 L20.04,19 Z M5.17212,19 L18.84012,19 L18.84012,5.00024326 L5.17212,5.00024326 L5.17212,19 Z"
                    id="path-digital-18"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/phone"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-18"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                    xlinkHref="#path-digital-18"></use>
            </g>
        </svg>
    );
};

export default Phone;
