import React from 'react';

const Hotel = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M7.58396208,10.8903675 C7.4399628,9.51074988 6.27596862,8.41905243 4.8599757,8.41905243 L1.199994,8.41905243 L1.199994,5.59983376 C1.199994,5.27592353 0.93599532,5 0.599997,5 C0.27599862,5 0,5.27592353 0,5.59983376 L0,10.8903675 L7.58396208,10.8903675 Z M23.9520002,14.3692834 C23.9508002,14.3608857 23.9496003,14.3512884 23.9484003,14.3428907 C23.7420013,13.0136591 22.5012075,12.0899151 21.1560142,12.0899151 L0.0001199994,12.0899151 L0.0001199994,18.4001662 C0.0001199994,18.7360731 0.276118619,19 0.600116999,19 C0.936115319,19 1.200114,18.7360731 1.200114,18.4001662 L1.200114,15.5689509 L22.800006,15.5689509 L22.800006,18.4001662 C22.800006,18.7360731 23.0760046,19 23.400003,19 C23.7360013,19 24,18.7360731 24,18.4001662 C23.992,15.8408755 23.9760001,14.4972479 23.9520002,14.3692834 Z"
                    id="path-6"></path>
            </defs>
            <g
                id="X-Global-Icons/Destination/hotel"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-6"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-6"></use>
            </g>
        </svg>
    );
};

export default Hotel;
