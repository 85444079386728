import React from 'react';

const Swap = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/swap"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M17,6 C17.5128358,6 17.9355072,6.36459351 17.9932723,6.83430227 L18,6.94444444 L18,20.892 L22.3152717,17.4845664 C22.7232492,17.1624777 23.3087559,17.1923856 23.6797598,17.5356022 L23.7678151,17.6279113 C24.1003461,18.0230773 24.0694686,18.5901977 23.7151247,18.9495512 L23.6198229,19.0348413 L17.6154696,23.7751375 C17.2685739,24.0490034 16.7840652,24.0725735 16.4135675,23.8465848 L16.3060901,23.7713021 L10.3753453,19.031006 C9.93555646,18.6794938 9.87323257,18.0492131 10.236141,17.6232347 C10.5711334,17.2300239 11.1513571,17.1483401 11.5845459,17.414574 L11.6895529,17.4884018 L16,20.933 L16,6.94444444 C16,6.46009949 16.3860402,6.0609099 16.8833789,6.00635397 L17,6 Z M7.58643254,0.153415229 L7.69390989,0.228697878 L13.6246547,4.96899399 C14.0644435,5.32050621 14.1267674,5.95078689 13.763859,6.3767653 C13.4288666,6.76997614 12.8486429,6.8516599 12.4154541,6.58542607 L12.3104471,6.51159826 L8.032,3.092 L8.03242227,17.0555556 C8.03242227,17.5399005 7.63386648,17.9390901 7.12040384,17.993646 L7.0000018,18 C6.47053958,18 6.03416506,17.6354065 5.97452718,17.1656977 L5.96758133,17.0555556 L5.967,3.134 L1.68472835,6.51543358 C1.27675079,6.83752229 0.691244105,6.80761442 0.320240218,6.46439779 L0.232184925,6.37208876 C-0.100346052,5.97692267 -0.0694685528,5.4098023 0.284875319,5.05044882 L0.380177055,4.96515867 L6.38453044,0.224862563 C6.73142608,-0.049003409 7.21593485,-0.0725735088 7.58643254,0.153415229 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Swap;
