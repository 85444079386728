import * as Yup from 'yup';
import { LABELS, FIELDS, ERROR_LABELS } from '../../constants';
import { AWBFormat } from 'basic_components/Validations';
import {
    weightRestriction,
    countDecimals,
} from '../../../../basic_components/jsTools';
import { weightToKg } from 'basic_components/conversions';
import moment from 'moment';
import { getTotalSummaryOfPiecesInObject } from 'basic_components/summaries';
import { getCountryCode } from 'basic_components/Validations';
import {
    EXP,
    GCR,
    QPK,
    QPI,
    LFG,
    LXL,
} from '../common/initialValuesByProductType/productCodes';
import linearVolumeValidation from '../../utils/linearVolumeValidation';

export const packagesShape = product => {

    let schema = Yup.object().shape({
        pieces: Yup.lazy(value =>
            value === ''
                ? CheckEmptyStringRequired('Pieces')
                : Yup.number()
                      .moreThan(0)
                      .max(9999, 'Should be max 4 characters')
                      .required()
                      .nullable()
                      .label('Pieces')
        ),
        weight: Yup.lazy(value =>
            value === ''
                ? CheckEmptyStringRequired('Weight')
                : Yup.number().maxTwoDecimals('Weight')
        ),
        length: Yup.lazy(value =>
            value === ''
                ? CheckEmptyStringRequired('Length')
                : Yup.number().maxTwoDecimals('Length')
        ),
        width: Yup.lazy(value =>
            value === ''
                ? CheckEmptyStringRequired('Width')
                : Yup.number().maxTwoDecimals('Width')
        ),
        height: Yup.lazy(value =>
            value === ''
                ? CheckEmptyStringRequired('Height')
                : Yup.number().maxTwoDecimals('Height')
        ),
    });

    switch (product) {
        case QPK:
        case QPI:
        case LFG:
            schema = Yup.object().shape({
                pieces: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Pieces')
                        : Yup.number()
                              .moreThan(0)
                              .max(9999, 'Should be max 4 characters')
                              .required()
                              .nullable()
                              .label('Pieces')
                              .when(
                                  ['weightUnit', 'weight'],
                                  (weightUnit, weight, schema) => {
                                      return schema.test(
                                          `Number of packages exceeds max weight permitted 453.6 kg / 1000 lbs.`,
                                          `Number of packages exceeds max weight permitted 453.6 kg / 1000 lbs.`,
                                          value =>
                                              !(
                                                  weight * value >
                                                  (weightUnit === 'kg'
                                                      ? 453.6
                                                      : 1000)
                                              )
                                      );
                                  }
                              )
                ),
                weight: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Weight')
                        : Yup.number()
                              .maxTwoDecimals('Weight')
                              .when(['weightUnit'], (weightUnit, schema) =>
                                  schema.max(
                                      weightUnit === 'kg' ? 45.3 : 99.9,
                                      'Exceeds max weight of 45.3 kg / 99.9 lbs.'
                                  )
                              )
                ),
                dry_ice_weight: Yup.lazy(value =>
                    value === ''
                        ? Yup.string()
                              .label('Dry ice')
                              .when(['$isDryIce'], (isDryIce, schema) => {
                                  return isDryIce ? schema.required() : schema;
                              })
                        : Yup.number()
                              .nullable()
                              .label('Dry ice')
                              .test('maxDecimals', 'Max 2 decimals.', val => {
                                  return countDecimals(val) <= 2;
                              })
                              .when(
                                  [
                                      'dry_ice_weightUnit',
                                      'weightUnit',
                                      'weight',
                                      '$isDryIce',
                                  ],
                                  (
                                      dry_ice_weightUnit,
                                      weightUnit,
                                      weight,
                                      isDryIce,
                                      schema
                                  ) => {
                                      return isDryIce
                                          ? schema
                                                .test(
                                                    'Dry ice weight has to be less than total weight.',
                                                    'Dry ice weight has to be less than total weight.',
                                                    dryIceWeightValue => {
                                                        const packageWeightToKG = weightToKg(
                                                            {
                                                                unit: weightUnit,
                                                                value: weight,
                                                            }
                                                        );
                                                        const dryIceWeightToKG = weightToKg(
                                                            {
                                                                unit: dry_ice_weightUnit,
                                                                value: dryIceWeightValue,
                                                            }
                                                        );

                                                        return (
                                                            dryIceWeightValue ===
                                                                null ||
                                                            dryIceWeightValue ===
                                                                NaN ||
                                                            dryIceWeightValue ===
                                                                0 ||
                                                            packageWeightToKG >
                                                                dryIceWeightToKG
                                                        );
                                                    }
                                                )
                                                .max(
                                                    dry_ice_weightUnit === 'kg'
                                                        ? 2.5
                                                        : 5.511,
                                                    'Exceeds max dry ice weight of 2.5 kg / 5.51 lbs.'
                                                )
                                          : schema;
                                  }
                              )
                ),
                length: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Length')
                        : Yup.number().maxTwoDecimals('Length')
                ),
                width: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Width')
                        : Yup.number().maxTwoDecimals('Width')
                ),
                height: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Height')
                        : Yup.number().maxTwoDecimals('Height')
                ),
            });
            break;
        case LXL:
            schema = Yup.object().shape({
                dry_ice_weight: Yup.lazy(value =>
                    value === ''
                        ? Yup.string()
                            .label('Dry ice')
                            .when(['$isDryIce'], (isDryIce, schema) => {
                                return isDryIce ? schema.required() : schema;
                            })
                        : Yup.number()
                            .nullable()
                            .label('Dry ice')
                            .test('maxDecimals', 'Max 2 decimals.', val => {
                                return countDecimals(val) <= 2;
                            })
                            .when(
                                [
                                    'dry_ice_weightUnit',
                                    'weightUnit',
                                    'weight',
                                    '$isDryIce',
                                ],
                                (
                                    dry_ice_weightUnit,
                                    weightUnit,
                                    weight,
                                    isDryIce,
                                    schema
                                ) => {
                                    return isDryIce
                                        ? schema
                                            .test(
                                                'Dry ice weight has to be less than total weight.',
                                                'Dry ice weight has to be less than total weight.',
                                                dryIceWeightValue => {
                                                    const packageWeightToKG = weightToKg(
                                                        {
                                                            unit: weightUnit,
                                                            value: weight,
                                                        }
                                                    );
                                                    const dryIceWeightToKG = weightToKg(
                                                        {
                                                            unit: dry_ice_weightUnit,
                                                            value: dryIceWeightValue,
                                                        }
                                                    );

                                                    return (
                                                        dryIceWeightValue ===
                                                        null ||
                                                        dryIceWeightValue ===
                                                        NaN ||
                                                        dryIceWeightValue ===
                                                        0 ||
                                                        packageWeightToKG >
                                                        dryIceWeightToKG
                                                    );
                                                }
                                            )
                                            .max(
                                                dry_ice_weightUnit === 'kg'
                                                    ? 2.5
                                                    : 5.511,
                                                'Exceeds max dry ice weight of 2.5 kg / 5.51 lbs.'
                                            )
                                        : schema;
                                }
                            )
                ),
                pieces: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Pieces')
                        : Yup.number()
                              .moreThan(0)
                              .max(9999, 'Should be max 4 characters')
                              .required()
                              .nullable()
                              .label('Pieces')
                ),
                weight: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Weight')
                        : Yup.number().maxTwoDecimals('Weight')
                ),
                length: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Length')
                        : Yup.number().maxTwoDecimals('Length')
                ),
                width: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Width')
                        : Yup.number().maxTwoDecimals('Width')
                ),
                height: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Height')
                        : Yup.number().maxTwoDecimals('Height')
                ),
            });
            break;
        case GCR:
        case EXP:
        default:
            break;
    }
    return schema;
};

export const CSVListValidation = product => {
    let schema = packagesShape(product);
    switch (product) {
        case 'QPK':
        case 'QPI':
        case 'LFG':
            schema = Yup.object().shape({
                pieces: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Pieces')
                        : Yup.number()
                              .moreThan(0)
                              .max(9999, 'Should be max 4 characters')
                              .required()
                              .nullable()
                              .label('Pieces')
                              .when(
                                  ['weightUnit', 'weight'],
                                  (weightUnit, weight, schema, totalWeight) => {
                                      return schema.test(
                                          `Exceeds max weight of 453.6 kg / 1000 lbs.`,
                                          `Exceeds max weight of 453.6 kg / 1000 lbs.`,
                                          value =>
                                              !(
                                                  totalWeight.parent.totalProdWeight >
                                                  (weightUnit === 'kg'
                                                      ? 453.6
                                                      : 1000)
                                              )
                                      );
                                  }
                              )
                ),
                weight: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Weight')
                        : Yup.number()
                              .maxTwoDecimals('Weight')
                              .when(['weightUnit'], (weightUnit, schema) =>
                                  schema.max(
                                      weightUnit === 'kg' ? 45.3 : 99.9,
                                      'Exceeds max weight of 45.3 kg / 99.9 lbs.'
                                  )
                              )
                ),
                dry_ice_weight: Yup.lazy(value =>
                    value === ''
                        ? Yup.string()
                              .label('Dry ice')
                              .when(['$isDryIce'], (isDryIce, schema) => {
                                  return isDryIce ? schema.required() : schema;
                              })
                        : Yup.number()
                              .nullable()
                              .test('maxDecimals', 'Max 2 decimals.', val => {
                                  return countDecimals(val) <= 2;
                              })
                              .label('Dry ice')
                              .when(
                                  [
                                      'dry_ice_weightUnit',
                                      'weightUnit',
                                      'weight',
                                      '$isDryIce',
                                  ],
                                  (
                                      dry_ice_weightUnit,
                                      weightUnit,
                                      weight,
                                      isDryIce,
                                      schema
                                  ) => {
                                      return isDryIce
                                          ? schema
                                                .test(
                                                    'Dry ice weight has to be less than total weight.',
                                                    'Dry ice weight has to be less than total weight.',
                                                    dryIceWeightValue => {
                                                        const packageWeightToKG = weightToKg(
                                                            {
                                                                unit: weightUnit,
                                                                value: weight,
                                                            }
                                                        );
                                                        const dryIceWeightToKG = weightToKg(
                                                            {
                                                                unit: dry_ice_weightUnit,
                                                                value: dryIceWeightValue,
                                                            }
                                                        );
                                                        return (
                                                            dryIceWeightValue ===
                                                                null ||
                                                            dryIceWeightValue ===
                                                                NaN ||
                                                            dryIceWeightValue ===
                                                                0 ||
                                                            packageWeightToKG >
                                                                dryIceWeightToKG
                                                        );
                                                    }
                                                )
                                                .max(
                                                    dry_ice_weightUnit === 'kg'
                                                        ? 2.5
                                                        : 5.511,
                                                    'Exceeds max dry ice weight of 2.5 kg / 5.51 lbs.'
                                                )
                                                .required()
                                          : schema;
                                  }
                              )
                ),
                length: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Length')
                        : Yup.number().maxTwoDecimals('Length')
                ),
                width: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Width')
                        : Yup.number().maxTwoDecimals('Width')
                ),
                dimsUnit: Yup.string().nullable(),
                height: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Height')
                        : Yup.number()
                              .maxTwoDecimals('Height')
                              .when(
                                  ['length', 'width', 'height', 'dimsUnit'],
                                  (length, width, height, dimsUnit, schema) => {
                                      return schema.test(
                                          `Exceeds max linear volume of 228.6 cm / 90 inches.`,
                                          `Exceeds max linear volume of 228.6 cm / 90 inches.`,
                                          () => {
                                              const linearSum =
                                                  height + width + length;
                                              return linearVolumeValidation(
                                                  dimsUnit,
                                                  linearSum
                                              );
                                          }
                                      );
                                  }
                              )
                ),
            });
            break;
        case LXL:
            schema = Yup.object().shape({
                dry_ice_weight: Yup.lazy(value =>
                    value === ''
                        ? Yup.string()
                            .label('Dry ice')
                            .when(['$isDryIce'], (isDryIce, schema) => {
                                return isDryIce ? schema.required() : schema;
                            })
                        : Yup.number()
                            .nullable()
                            .label('Dry ice')
                            .test('maxDecimals', 'Max 2 decimals.', val => {
                                return countDecimals(val) <= 2;
                            })
                            .when(
                                [
                                    'dry_ice_weightUnit',
                                    'weightUnit',
                                    'weight',
                                    '$isDryIce',
                                ],
                                (
                                    dry_ice_weightUnit,
                                    weightUnit,
                                    weight,
                                    isDryIce,
                                    schema
                                ) => {
                                    return isDryIce
                                        ? schema
                                            .test(
                                                'Dry ice weight has to be less than total weight.',
                                                'Dry ice weight has to be less than total weight.',
                                                dryIceWeightValue => {
                                                    const packageWeightToKG = weightToKg(
                                                        {
                                                            unit: weightUnit,
                                                            value: weight,
                                                        }
                                                    );
                                                    const dryIceWeightToKG = weightToKg(
                                                        {
                                                            unit: dry_ice_weightUnit,
                                                            value: dryIceWeightValue,
                                                        }
                                                    );

                                                    return (
                                                        dryIceWeightValue ===
                                                        null ||
                                                        dryIceWeightValue ===
                                                        NaN ||
                                                        dryIceWeightValue ===
                                                        0 ||
                                                        packageWeightToKG >
                                                        dryIceWeightToKG
                                                    );
                                                }
                                            )
                                            .max(
                                                dry_ice_weightUnit === 'kg'
                                                    ? 2.5
                                                    : 5.511,
                                                'Exceeds max dry ice weight of 2.5 kg / 5.51 lbs.'
                                            )
                                        : schema;
                                }
                            )
                ),
                pieces: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Pieces')
                        : Yup.number()
                                .moreThan(0)
                                .max(9999, 'Should be max 4 characters')
                                .required()
                                .nullable()
                                .label('Pieces')
                ),
                weight: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Weight')
                        : Yup.number().maxTwoDecimals('Weight')
                        .when(
                            ['weightUnit', 'weight'],
                            (weightUnit, weight, schema, totalWeight) => {
                                return schema.test(
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    value =>
                                        (
                                            totalWeight.parent.totalProdWeight >=
                                            (weightUnit === 'kg' ? 45 : 100)
                                        )
                                );
                            }
                        )
                ),
                length: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Length')
                        : Yup.number().maxTwoDecimals('Length')
                ),
                width: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Width')
                        : Yup.number().maxTwoDecimals('Width')
                ),
                height: Yup.lazy(value =>
                    value === ''
                        ? CheckEmptyStringRequired('Height')
                        : Yup.number().maxTwoDecimals('Height')
                ),
            });
        default:
            break;
    }

    return Yup.array().of(schema);
};

export const generateShipmentSchema = (product, productObject) => {
    let schema;
    switch (product) {
        case GCR:
        case EXP:
            schema = Yup.object().shape({
                [FIELDS.ACCOUNT_NUMBER]: Yup.object()
                    .label(ERROR_LABELS.ACCOUNT_NUMBER)
                    .nullable()
                    .required(),
                [FIELDS.PAYMENT_INFO]: Yup.string()
                    .label(LABELS.PAYMENT_INFO)
                    .nullable()
                    .required(),
                [FIELDS.ORIGIN]: Yup.object()
                    .label(LABELS.ORIGIN)
                    .nullable()
                    .required()
                    .when(['agent'], (agent, schema) => {
                        return !!agent?.country
                            ? schema.test(
                                  `origin`,
                                  `Origin station must be in the same country (${agent.country?.label}) as the selected agent account`,
                                  value =>
                                      value?.country ===
                                      getCountryCode(agent.country?.label)
                              )
                            : schema;
                    }),
                [FIELDS.DESTINATION]: Yup.string()
                    .label(LABELS.DESTINATION)
                    .nullable()
                    .required(),
                [FIELDS.VIA]: Yup.string().nullable(),
                [FIELDS.DEPARTURE_DATE]: Yup.date()
                    .min(moment(new Date()).format('YYYY-MM-DD'))
                    .label(LABELS.DEPARTURE_DATE)
                    .nullable()
                    .required(),
                [FIELDS.TENDER_DATE]: null,
                [FIELDS.STOPS]: Yup.array(),
                [FIELDS.COMMODITY_CODE]: Yup.string()
                    .label(LABELS.COMMODITY_CODE)
                    .nullable()
                    .required(),
                [FIELDS.COMMODITY_DESCRIPTION]: Yup.string()
                    .label(LABELS.COMMODITY_DESCRIPTION)
                    .nullable()
                    .max(35, 'Maximum 35 characters allowed')
                    .required(),
                [FIELDS.AWB_NUMBER]: Yup.string().when([FIELDS.AWB_TYPE], {
                    is: val => val === 'haveAWBNumber',
                    then: Yup.string()
                        .required()
                        .label(ERROR_LABELS.AWB_NUMBER)
                        .test(
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            value => !AWBFormat(value)
                        ),
                    otherwise: Yup.string()
                        .notRequired()
                        .nullable(),
                }),
                [FIELDS.CONTAINER_LIST]: Yup.array().when(
                    [FIELDS.PRODUCT_TYPE],
                    {
                        is: val => val === 'container',
                        then: Yup.array()
                            .required()
                            .label(ERROR_LABELS.CONTAINER_LIST),
                        otherwise: Yup.array()
                            .min(0)
                            .nullable(),
                    }
                ),
                [FIELDS.ALLOTMENT]: Yup.object().when(
                    [FIELDS.IS_ALLOTMENT_BOOKING],
                    {
                        is: val => val === true,
                        then: Yup.object()
                            .required('You must select an allotment.')
                            .nullable(),
                        otherwise: Yup.object().nullable(),
                    }
                ),
                [FIELDS.PACKAGE_LIST]: Yup.array().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.array().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'pieces',
                        then: Yup.array().when([FIELDS.IS_UPLOAD_CSV], {
                            is: val => val === false,
                            then: Yup.array()
                                .of(Yup.object().shape(packagesShape(product)))
                                .required(
                                    'Please save at least one piece to continue.'
                                ),
                        }),
                    }),
                }),
                [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: Yup.mixed()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                            is: val => val === 'total',
                            then: Yup.number()
                                .typeError('Pieces is a required field')
                                .moreThan(0)
                                .max(9999, 'Should be max 4 characters')
                                .required()
                                .nullable()
                                .label('Pieces'),
                        }),
                    })
                    .nullable(),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: Yup.mixed()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                            is: val => val === 'total',
                            then: Yup.boolean()
                                .typeError('Over weight is a required field')
                                .required()
                                .nullable(),
                        }),
                    }),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: Yup.mixed()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed()
                            .nullable()
                            .when([FIELDS.MEASURE_BY], {
                                is: val => val === 'total',
                                then: Yup.number()
                                    .typeError('Weight is a required field')
                                    .maxTwoDecimals('Weight'),
                            }),
                    }),
                [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: Yup.mixed()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed()
                            .nullable()
                            .when([FIELDS.MEASURE_BY], {
                                is: val => val === 'total',
                                then: Yup.number()
                                    .typeError('Volume is a required field')
                                    .maxTwoDecimals('Volume'),
                            }),
                    }),

                [FIELDS.OVERFLOW_WEIGHT]: Yup.number()
                    .nullable()
                    .when([FIELDS.HAS_OVERFLOW_WEIGHT], {
                        is: val => val === true,
                        then: Yup.number()
                            .maxTwoDecimals('Volume')
                            .max(Number.MAX_VALUE),
                    }),
                [FIELDS.SHC]: Yup.array()
                    .max(
                        productObject?.shc_max - 1 || 8,
                        `Max ${productObject?.shc_max - 1 || 8} SHC`
                    )
                    .min(
                        productObject?.shc_min || 0,
                        `Min ${productObject?.shc_min} SHC`
                    )
                    .nullable()
                    .label(LABELS.SHC),
                [FIELDS.IS_DANGEROUS_GOODS]: Yup.boolean()
                    .nullable()
                    .required('This field is required'),
            });
            break;
        case QPK:
        case QPI:
        case LFG:
            schema = Yup.object().shape({
                [FIELDS.ACCOUNT_NUMBER]: Yup.object()
                    .label(ERROR_LABELS.ACCOUNT_NUMBER)
                    .nullable()
                    .required(),
                [FIELDS.PAYMENT_INFO]: Yup.string()
                    .label(LABELS.PAYMENT_INFO)
                    .nullable()
                    .required(),
                [FIELDS.ORIGIN]: Yup.string()
                    .label(LABELS.ORIGIN)
                    .nullable()
                    .required(),
                [FIELDS.DESTINATION]: Yup.string()
                    .label(LABELS.DESTINATION)
                    .nullable()
                    .required(),
                [FIELDS.VIA]: Yup.string().nullable(),
                [FIELDS.DEPARTURE_DATE]: Yup.date()
                    .min(moment(new Date()).format('YYYY-MM-DD'))
                    .label(LABELS.DEPARTURE_DATE)
                    .nullable()
                    .required(),
                [FIELDS.TENDER_DATE]: null,
                [FIELDS.STOPS]: Yup.array(),
                [FIELDS.COMMODITY_CODE]: Yup.string()
                    .label(LABELS.COMMODITY_CODE)
                    .nullable(),
                [FIELDS.COMMODITY_DESCRIPTION]: Yup.string()
                    .label(LABELS.COMMODITY_DESCRIPTION)
                    .max(35, 'Maximum 35 characters allowed')
                    .nullable()
                    .required(),
                [FIELDS.AWB_NUMBER]: Yup.string().when([FIELDS.AWB_TYPE], {
                    is: val => val === 'haveAWBNumber',
                    then: Yup.string()
                        .required()
                        .label(ERROR_LABELS.AWB_NUMBER)
                        .test(
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            value => !AWBFormat(value)
                        ),
                    otherwise: Yup.string()
                        .notRequired()
                        .nullable(),
                }),
                [FIELDS.ALLOTMENT]: Yup.object().when(
                    [FIELDS.IS_ALLOTMENT_BOOKING],
                    {
                        is: val => val === true,
                        then: Yup.object()
                            .required('You must select an allotment.')
                            .nullable(),
                        otherwise: Yup.object().nullable(),
                    }
                ),
                [FIELDS.PACKAGE_LIST]: Yup.array().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.array().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'pieces',
                        then: Yup.array().when([FIELDS.IS_UPLOAD_CSV], {
                            is: val => val === false,
                            then: Yup.array()
                                .required(
                                    'Please save at least one piece to continue.'
                                )
                                .test(
                                    `Number of packages exceeds max weight permitted 453.6 kg / 1000 lbs.`,
                                    `Number of packages exceeds max weight permitted 453.6 kg / 1000 lbs.`,
                                    value => {
                                        const summaryObj = getTotalSummaryOfPiecesInObject(
                                            value
                                        );
                                        return !(
                                            summaryObj.weight >
                                            (summaryObj.weightUnit === 'kg'
                                                ? 453.6
                                                : 1000)
                                        );
                                    }
                                ),
                        }),
                    }),
                }),
                [FIELDS.PACKAGE_LIST_CSV]: Yup.array().when(
                    [FIELDS.IS_UPLOAD_CSV],
                    {
                        is: val => val === true,
                        then: Yup.array()
                            .of(packagesShape(product))
                            .required(
                                'Please save at least one piece to continue.'
                            ),
                    }
                ),
                [FIELDS.SHC]: Yup.array()
                    .max(
                        productObject?.shc_max - 1 || 8,
                        `Max ${productObject?.shc_max - 1 || 8} SHC`
                    )
                    .min(
                        productObject?.shc_min || 0,
                        `Min ${productObject?.shc_min} SHC`
                    )
                    .nullable()
                    .label(LABELS.SHC),
                [FIELDS.IS_DANGEROUS_GOODS]: Yup.boolean()
                    .nullable()
                    .required('This field is required'),
                [FIELDS.IS_RDS]: Yup.boolean()
                    .nullable()
                    .required('This is a required field.'),
                [FIELDS.IS_DRY_ICE]: Yup.boolean()
                    .nullable()
                    .required('This field is required'),
                [FIELDS.DRY_ICE_MORE_THAN]: Yup.boolean().when(
                    [FIELDS.IS_DRY_ICE],
                    {
                        is: val => val === true,
                        then: Yup.boolean()
                            .nullable()
                            .required('This field is required'),
                        otherwise: Yup.boolean()
                            .notRequired()
                            .nullable(),
                    }
                ),
            });
            break;
        case LXL:
            schema = Yup.object().shape({
                [FIELDS.ACCOUNT_NUMBER]: Yup.object()
                    .label(ERROR_LABELS.ACCOUNT_NUMBER)
                    .nullable()
                    .required(),
                [FIELDS.PAYMENT_INFO]: Yup.string()
                    .label(LABELS.PAYMENT_INFO)
                    .nullable()
                    .required(),
                [FIELDS.ORIGIN]: Yup.string()
                    .label(LABELS.ORIGIN)
                    .nullable()
                    .required(),
                [FIELDS.DESTINATION]: Yup.string()
                    .label(LABELS.DESTINATION)
                    .nullable()
                    .required(),
                [FIELDS.VIA]: Yup.string().nullable(),
                [FIELDS.DEPARTURE_DATE]: Yup.date()
                    .min(moment(new Date()).format('YYYY-MM-DD'))
                    .label(LABELS.DEPARTURE_DATE)
                    .nullable()
                    .required(),
                [FIELDS.TENDER_DATE]: null,
                [FIELDS.STOPS]: Yup.array(),
                [FIELDS.COMMODITY_CODE]: Yup.string()
                    .label(LABELS.COMMODITY_CODE)
                    .nullable(),
                [FIELDS.COMMODITY_DESCRIPTION]: Yup.string()
                    .label(LABELS.COMMODITY_DESCRIPTION)
                    .max(35, 'Maximum 35 characters allowed')
                    .nullable()
                    .required(),
                [FIELDS.AWB_NUMBER]: Yup.string().when([FIELDS.AWB_TYPE], {
                    is: val => val === 'haveAWBNumber',
                    then: Yup.string()
                        .required()
                        .label(ERROR_LABELS.AWB_NUMBER)
                        .test(
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            value => !AWBFormat(value)
                        ),
                    otherwise: Yup.string()
                        .notRequired()
                        .nullable(),
                }),
                [FIELDS.ALLOTMENT]: Yup.object().when(
                    [FIELDS.IS_ALLOTMENT_BOOKING],
                    {
                        is: val => val === true,
                        then: Yup.object()
                            .required('You must select an allotment.')
                            .nullable(),
                        otherwise: Yup.object().nullable(),
                    }
                ),
                [FIELDS.PACKAGE_LIST]: Yup.array().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.array().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'pieces',
                        then: Yup.array().when([FIELDS.IS_UPLOAD_CSV], {
                            is: val => val === false,
                            then: Yup.array()
                                .required(
                                    'Please save at least one piece to continue.'
                                )
                                .test(
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    value => {
                                        const summaryObj = getTotalSummaryOfPiecesInObject(
                                            value
                                        );
                                        return !(
                                            summaryObj.weight <
                                            (summaryObj.weightUnit === 'kg'
                                                ? 45
                                                : 100)
                                        );
                                    }
                                ),
                        }),
                    }),
                }),
                [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: Yup.mixed()
                .when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'total',
                        then: Yup.number()
                            .typeError('Pieces is a required field')
                            .moreThan(0)
                            .max(9999, 'Should be max 4 characters')
                            .required()
                            .nullable()
                            .label('Pieces'),
                    }),
                })
                .nullable(),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: Yup.mixed().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'total',
                        then: Yup.mixed()
                            .when([FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT], {
                                is: val => val === 'kg',
                                then: Yup.mixed()
                                .required(
                                    'Please save at least one piece to continue.'
                                )
                                .test(
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    `Minimum weight must be 45kg or 100lbs.`,
                                    value => {
                                        return !(
                                            value < 45
                                            
                                        );
                                    }
                                ),
                                otherwise: Yup.mixed()
                                    .required(
                                        'Please save at least one piece to continue.'
                                    )
                                    .test(
                                        `Minimum weight must be 45kg or 100lbs.`,
                                        `Minimum weight must be 45kg or 100lbs.`,
                                        value => {
                                            return !(
                                                value < 100
                                                
                                            );
                                        }
                                    )  
                            }),
                    }).test('maxDecimals', 'Max 2 decimals.', val => {
                        return countDecimals(val) <= 2;
                    }),
                }),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: Yup.mixed()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                            is: val => val === 'total',
                            then: Yup.boolean()
                                .typeError('Over weight is a required field')
                                .required()
                                .nullable(),
                        }),
                }),




                [FIELDS.PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT]: Yup.mixed().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.mixed().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'total',
                        then: Yup.mixed().when([FIELDS.IS_UPLOAD_CSV], {
                            is: val => val === false,
                            then: Yup.mixed().when([FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT], {
                                is: val => {
                                    return val === 'kg';
                                },
                                then: Yup.mixed()
                                    .when(
                                        [   FIELDS.PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT,
                                            FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES,
                                            FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT
                                        ],(dryIceWeight, pieces, shipmentWeight, schema)=>{
                                            const dryIceWeightVal= !dryIceWeight ? 0 : dryIceWeight
                                            return schema.test(
                                                `Dry ice weight cannot exceed total weight.`,
                                                `Dry ice weight cannot exceed total weight.`,
                                                ()=>{
                                                    return shipmentWeight >= dryIceWeightVal

                                                }
                                            ).test(
                                                `Max weight per piece is 2.5kg.`,
                                                `Max weight per piece is 2.5kg.`,
                                                ()=>{
                                                    return !dryIceWeight ? true : dryIceWeight/pieces <= 2.5;

                                                }
                                            )
                                        }),
                                otherwise:Yup.mixed().when(
                                    [   FIELDS.PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT,
                                        FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES,
                                        FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT
                                    ],(dryIceWeight, pieces, shipmentWeight, schema)=>{

                                        const dryIceWeightVal= !dryIceWeight ? 0 : dryIceWeight

                                        return schema.test(
                                            `Dry ice weight cannot exceed total weight.`,
                                            `Dry ice weight cannot exceed total weight.`,
                                            ()=>{
                                                return shipmentWeight >= dryIceWeightVal

                                            }
                                        ).test(
                                            `Max weight per piece is 5.51lbs.`,
                                            `Max weight per piece is 5.51lbs.`,
                                            ()=>{
                                                return !dryIceWeight ? true : dryIceWeight/pieces <= 5.511;

                                            }
                                        )
                                    }),
                            })
                        }),
                    }),
                }).test('maxDecimals', 'Max 2 decimals.', val => {
                    return countDecimals(val) <= 2;
                }),


                [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: Yup.mixed()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.mixed()
                            .nullable()
                            .when([FIELDS.MEASURE_BY], {
                                is: val => val === 'total',
                                then: Yup.number()
                                    .typeError('Volume is a required field')
                                    .maxTwoDecimals('Volume'),
                            }),
                }),
                [FIELDS.PACKAGE_LIST_CSV]: Yup.mixed().when([FIELDS.MEASURE_BY], {
                    is: val => val === 'pieces',
                    then: Yup.mixed().when([FIELDS.IS_UPLOAD_CSV],
                    {
                        is: val => val === true,
                        then: Yup.array()
                            .of(packagesShape(product))
                            .required(
                                'Please save at least one piece to continue.'
                            ).test(
                                `Minimum weight must be 45kg or 100lbs.`,
                                `Minimum weight must be 45kg or 100lbs.`,
                                value => {
                                    const summaryObj = getTotalSummaryOfPiecesInObject(
                                        value
                                    );
                                    return !(
                                        summaryObj.weight <
                                        (summaryObj.weightUnit === 'kg'
                                            ? 45
                                            : 100)
                                    );
                                }
                            ),
                    }
                )}),
                [FIELDS.SHC]: Yup.array()
                    .max(
                        productObject?.shc_max - 1 || 8,
                        `Max ${productObject?.shc_max - 1 || 8} SHC`
                    )
                    .min(
                        productObject?.shc_min || 0,
                        `Min ${productObject?.shc_min} SHC`
                    )
                    .nullable()
                    .label(LABELS.SHC),
                [FIELDS.IS_DANGEROUS_GOODS]: Yup.boolean()
                    .nullable()
                    .required('This field is required'),
                [FIELDS.IS_RDS]: Yup.boolean()
                    .nullable()
                    .required('This is a required field.'),
                [FIELDS.IS_DRY_ICE]: Yup.boolean()
                    .nullable()
                    .required('This field is required'),
                [FIELDS.DRY_ICE_MORE_THAN]: Yup.boolean().when(
                    [FIELDS.IS_DRY_ICE],
                    {
                        is: val => val === true,
                        then: Yup.boolean()
                            .nullable()
                            .required('This field is required'),
                        otherwise: Yup.boolean()
                            .notRequired()
                            .nullable(),
                    }
                ),
            });
            break;

        default:
            schema = Yup.object().shape({
                [FIELDS.ACCOUNT_NUMBER]: Yup.object()
                    .label(ERROR_LABELS.ACCOUNT_NUMBER)
                    .nullable()
                    .required(),
                [FIELDS.PAYMENT_INFO]: Yup.string()
                    .label(LABELS.PAYMENT_INFO)
                    .nullable()
                    .required(),
                [FIELDS.ORIGIN]: Yup.string()
                    .label(LABELS.ORIGIN)
                    .nullable()
                    .required(),
                [FIELDS.DESTINATION]: Yup.string()
                    .label(LABELS.DESTINATION)
                    .nullable()
                    .required(),
                [FIELDS.VIA]: Yup.string().nullable(),
                [FIELDS.DEPARTURE_DATE]: Yup.date()
                    .min(moment(new Date()).format('YYYY-MM-DD'))
                    .label(LABELS.DEPARTURE_DATE)
                    .nullable()
                    .required(),
                [FIELDS.TENDER_DATE]: null,
                [FIELDS.STOPS]: Yup.array(),
                [FIELDS.COMMODITY_CODE]: Yup.string()
                    .label(LABELS.COMMODITY_CODE)
                    .nullable()
                    .required(),
                [FIELDS.COMMODITY_DESCRIPTION]: Yup.string()
                    .label(LABELS.COMMODITY_DESCRIPTION)
                    .max(35, 'Maximum 35 characters allowed')
                    .nullable()
                    .required(),
                [FIELDS.AWB_NUMBER]: Yup.string().when([FIELDS.AWB_TYPE], {
                    is: val => val === 'haveAWBNumber',
                    then: Yup.string()
                        .required()
                        .label(ERROR_LABELS.AWB_NUMBER)
                        .test(
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            `Please enter a valid ${ERROR_LABELS.AWB_NUMBER}`,
                            value => !AWBFormat(value)
                        ),
                    otherwise: Yup.string()
                        .notRequired()
                        .nullable(),
                }),
                [FIELDS.CONTAINER_LIST]: Yup.array().when(
                    [FIELDS.PRODUCT_TYPE],
                    {
                        is: val => val === 'container',
                        then: Yup.array()
                            .required()
                            .label(ERROR_LABELS.CONTAINER_LIST),
                        otherwise: Yup.array()
                            .min(0)
                            .nullable(),
                    }
                ),
                [FIELDS.ALLOTMENT]: Yup.object().when(
                    [FIELDS.IS_ALLOTMENT_BOOKING],
                    {
                        is: val => val === true,
                        then: Yup.object()
                            .required('You must select an allotment.')
                            .nullable(),
                        otherwise: Yup.object().nullable(),
                    }
                ),
                [FIELDS.PACKAGE_LIST]: Yup.array().when([FIELDS.PRODUCT_TYPE], {
                    is: val => val === 'bulk',
                    then: Yup.array().when([FIELDS.MEASURE_BY], {
                        is: val => val === 'pieces',
                        then: Yup.array().when([FIELDS.IS_UPLOAD_CSV], {
                            is: val => val === false,
                            then: Yup.array().required(
                                'Please save at least one piece to continue.'
                            ),
                        }),
                    }),
                }),
                [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: Yup.number().when(
                    [FIELDS.PRODUCT_TYPE],
                    {
                        is: val => val === 'bulk',
                        then: Yup.number().when([FIELDS.MEASURE_BY], {
                            is: val => val === 'total',
                            then: Yup.number()
                                .moreThan(0)
                                .required()
                                .nullable()
                                .label('Pieces'),
                        }),
                    }
                ),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: Yup.boolean()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.boolean().when([FIELDS.MEASURE_BY], {
                            is: val => val === 'total',
                            then: Yup.boolean()
                                .required('This field is required')
                                .nullable(),
                        }),
                    }),

                [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: Yup.number()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.number()
                            .nullable()
                            .when([FIELDS.MEASURE_BY], {
                                is: val => val === 'total',
                                then: Yup.number().maxTwoDecimals('Weight'),
                            }),
                    }),
                [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: Yup.number()
                    .nullable()
                    .when([FIELDS.PRODUCT_TYPE], {
                        is: val => val === 'bulk',
                        then: Yup.number()
                            .nullable()
                            .when([FIELDS.MEASURE_BY], {
                                is: val => val === 'total',
                                then: Yup.number().maxTwoDecimals('Weight'),
                            }),
                    }),

                [FIELDS.OVERFLOW_WEIGHT]: Yup.number()
                    .nullable()
                    .when([FIELDS.HAS_OVERFLOW_WEIGHT], {
                        is: val => val === true,
                        then: Yup.number()
                            .maxTwoDecimals('Weight')
                            .max(Number.MAX_VALUE),
                    }),
                [FIELDS.SHC]: Yup.array()
                    .max(
                        productObject?.shc_max - 1 || 8,
                        `Max ${productObject?.shc_max - 1 || 8} SHC`
                    )
                    .min(
                        productObject?.shc_min || 0,
                        `Min ${productObject?.shc_min} SHC`
                    )
                    .nullable()
                    .label(LABELS.SHC),
            });
            break;
    }
    return schema;
};

const CheckEmptyStringRequired = label =>
    Yup.string()
        .required()
        .label(label);

export const allotmentSchema = Yup.object().shape({
    [FIELDS.ACCOUNT_NUMBER]: Yup.object()
        .label(ERROR_LABELS.ACCOUNT_NUMBER)
        .nullable()
        .required(),
    [FIELDS.ORIGIN]: Yup.string()
        .label(LABELS.ORIGIN)
        .nullable()
        .required(),
    [FIELDS.DESTINATION]: Yup.string()
        .label(LABELS.DESTINATION)
        .nullable()
        .required(),
    [FIELDS.DEPARTURE_DATE]: Yup.date()
        .min(moment(new Date()).format('YYYY-MM-DD'))
        .label(LABELS.DEPARTURE_DATE)
        .nullable()
        .required(),
});

export const containerSchema = (product) => Yup.object({
    
    containerType: Yup.object()
        .required()
        .nullable()
        .label(ERROR_LABELS.CONTAINER_TYPE),
    weight: Yup.number()
        .required()
        .moreThan(0)
        .label(ERROR_LABELS.CONTAINER_WEIGHT)
        .test('maxDecimals', 'Max 2 decimals.', val => {
            return countDecimals(val) <= 2;
        }),
    weightUnit: Yup.string(),
    unitNumber: Yup.string()
        .notRequired()
        .nullable(),
}).test(`Exceeds max weight`, null, value => {
    
    if(product && product.code == 'LXL'){
        if (value.weight < (value.weightUnit === 'kg' ? 45 : 100)){
            return new Yup.ValidationError(
                'Minimum weight must be 45kg or 100lbs.',
                'Minimum weight must be 45kg or 100lbs.',
                'weight'
            );
        }
    }
    if (
        weightRestriction(
            weightToKg({
                unit: 'kg',
                value: value.containerType?.tare_weight,
            }),
            weightToKg({
                unit: value.weightUnit,
                value: value.weight,
            }),
            weightToKg({
                unit: 'kg',
                value: value.containerType?.max_gross_weight,
            })
        )
    ) {
        return new Yup.ValidationError(
            'Exceeds max weight',
            'Exceeds max weight',
            'weight'
        );
    }

    return true;
});
