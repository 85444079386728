export const FIELDS = {
    AWB_CAPTURE: 'awb_capture',
    EAP: 'eap',
    EAW: 'eaw',
    DOCUMENTATION: 'documentation',
    COUNTRY: 'country',
    CUSTOM_IDENTIFIER: 'custom_info',
    INFO_IDENTIFIER: 'info_identifier',
    SUPPLEMENTAL_CUSTOM: 'supplemental_custom',
    VALUE_CARRIAGE: 'value_carriage',
    NO_CARRIAGE: 'no_carriage',
    VALUE_CUSTOM: 'value_custom',
    NO_CUSTOM: 'no_custom',
    ACCOUNTING_CODE: 'accounting_code',
    ACCOUNTING_DESCRIPTION: 'accounting_description',
    HANDLING_INFO: 'handling_information',
    HANDLING_TYPE: 'handling_type',
    HANDLING_REMARKS: 'handling_remarks',
    SCI: 'sci',
};

export const LABELS = {
    DOCUMENTATION:
        'Will you provide paper documentation at the time of tender?',
    DOCUMENTATION_PROVIDE: 'Paper documentation will be provided',
    EAP: 'EAP - Consignment with accompanying paper documents',
    EAW: 'EAW - Consignment with NO accompanying paper documents',
    OTHER_CUSTOM: 'Other custom information',
    COUNTRY: 'Country',
    CUSTOM_IDENTIFIER: 'Customs information identifier',
    INFO_IDENTIFIER: 'Information identifier',
    SUPPLEMENTAL_CUSTOM: 'Supplemental customs information',
    VALUE_DECLARATION: 'Value declaration',
    VALUE_CARRIAGE: 'Value of carriage',
    VALUE_CUSTOM: 'Value of custom',
    NO_VALUE: 'No value declared',
    NO_CUSTOM: 'No custom declared',
    ACCOUNT_INFO: 'Accounting information',
    ACCOUNTING_CODE: 'Code',
    ACCOUNTING_DESCRIPTION: 'Description',
    HANDLING_INFO: 'Handling information',
    HANDLING_TYPE: 'Type',
    HANDLING_REMARKS: 'Remarks',
    SCI: 'SCI',
    ADD_HANDLING: 'Add extra handling information',
    DELETE_HANDLING: 'Delete extra',
};

export const ERROR_LABELS = {
    DOCUMENTATION: 'Select an option',
    VALUE_DECLARATION: 'Please enter a value or check the box if N/A',
    VALUE_DECLARATION_MAX: 'Is not permitted more than 7 digits',
    ACCOUNTING_CODE: 'Accounting code is required if description is added',
    ACCOUNTING_DESCRIPTION:
        'Accounting description is required if code is added',
    HANDLING_TYPE: 'Handling type is required if remarks is added',
    HANDLING_REMARKS: 'Remarks is required if handling type is added',
    SCI: 'Sci is required if handling information is added',
    COUNTRY: 'Country code is required',
    CUSTOM_IDENTIFIER: 'Custom identifier is required',
    INFO_IDENTIFIER: 'Information identifier is required',
    SUPPLEMENTAL_CUSTOM: 'Supplemental custom information is required',
};
