import React from 'react';

const Gift = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M22.03164,13.2037037 L22.03164,22.3915344 C22.03164,22.7322751 21.75564,23 21.43164,23 L21.43164,23 L12.61164,23 L12.61164,13.2037037 L22.03164,13.2037037 Z M11.41152,13.2037037 L11.41152,23 L2.59152,23 C2.26752,23 1.99152,22.7322751 1.99152,22.3915344 L1.99152,22.3915344 L1.99152,13.2037037 L11.41152,13.2037037 Z M11.412,6.27960847 L11.412,11.9870159 L0,11.9870159 L0,6.27960847 L11.412,6.27960847 Z M24,6.27960847 L24,11.9870159 L12.612,11.9870159 L12.612,6.27960847 L24,6.27960847 Z M7.16388,3.22486772 C6.39588,2.8962963 6.26388,2.34867725 6.25188,2.06878307 C6.22788,1.71587302 6.37188,1.3994709 6.58788,1.27777778 C6.94788,1.08306878 7.65588,1.3021164 8.41188,1.83756614 C10.09188,3.03015873 10.84788,4.02804233 11.18388,4.72169312 C10.73588,4.6973545 9.39588,4.1984127 7.16388,3.22486772 Z M16.63188,1.82539683 C16.71588,1.82539683 17.05188,2.12962963 17.03988,2.3973545 C17.02788,2.61640212 16.91988,3.04232804 16.30788,3.31005291 C14.45988,4.11322751 13.35588,4.55943563 12.99588,4.64867725 C13.37988,4.06455026 14.07588,3.28571429 15.33588,2.38518519 C15.89988,1.98359788 16.35588,1.82539683 16.63188,1.82539683 Z M15.31188,5.06243386 C15.49588,4.98130511 15.98388,4.77037037 16.77588,4.42962963 C18.03588,3.86984127 18.22788,2.87195767 18.23988,2.45820106 C18.27588,1.75238095 17.95188,1.10740741 17.41188,0.803174603 C16.71588,0.413756614 15.73188,0.620634921 14.65188,1.38730159 C13.36788,2.31216931 12.56388,3.13968254 12.08388,3.84550265 C11.59188,3.00582011 10.69188,1.97142857 9.09588,0.83968254 C8.31588,0.27989418 7.55988,0 6.91188,0 C6.57588,0 6.27588,0.073015873 6.01188,0.219047619 C5.38788,0.55978836 5.01588,1.31428571 5.05188,2.11746032 C5.07588,2.57989418 5.27988,3.72380952 6.69588,4.34444444 C7.56788,4.73386243 8.11188,4.97319224 8.32788,5.06243386 L15.31188,5.06243386 Z"
                    id="path-digital-10"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/gift"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-10"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-10"></use>
            </g>
        </svg>
    );
};

export default Gift;
