import React from 'react';
import { Notification } from '@freightos/uikit';
import { default as GlobalIcon } from 'icons/X Global Icons/GlobalIcons';
import theme from 'configs/theme';
import './styles.css';

export const AlertAtmosNotification = (message, autoClose = true) => {
    return Notification.show({
        message: (
            <>
                <GlobalIcon
                    className="warning"
                    iconName="warning"
                    size="18px"
                    mr="2"
                    fill={theme.chipWarningBg}
                    pointer
                    style={{
                        hoverColor: theme.chipWarningBg,
                    }}
                />
                <div>{message}</div>
            </>
        ),
        level: 'warning',
        type: 'toast',
        className: 'warning',
        autoClose: autoClose,
    });
};
