import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Icon } from '@freightos/uikit';
import {
    StyledMenu,
    CustomMenuRow,
    CustomDropMenu,
    ButtonDropDown,
} from './common/styles';
import { NavLink, BrowserRouter } from 'react-router-dom';
import { DETAILS_ROUTE } from 'modules/bookingSteps/constants';
import { useDispatch } from 'react-redux';
import { resetForm, saveBookingProduct } from 'modules/bookingSteps/actions';
import { default as GlobalIcon } from '../../icons/X Global Icons/GlobalIcons';

const MyCargoMenu = () => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };
    const handleExpanded = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <StyledMenu ref={wrapperRef} relative>
            <Flex alignItems="center" onClick={handleExpanded}>
                <ButtonDropDown
                    role="menuitem"
                    aria-label="My Cargo Menu Drop Down">
                    <span
                        style={{
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '550',
                            fontFamily: '"Open Sans",sans-serif',
                        }}>
                        {t('MY CARGO')}
                    </span>
                    <Icon
                        icon={'caretUp'}
                        color="white"
                        hoverColor="white"
                        style={{
                            cursor: 'pointer',
                            transform: `rotate(${expanded ? 0 : 180}deg)`,
                            transition: 'none',
                            width: '26px',
                            height: '26px',
                        }}
                    />
                </ButtonDropDown>
            </Flex>
            {expanded && (
                <CustomDropMenu
                    flexDirection="column"
                    style={{ top: '45px', right: '150px' }}>
                    <BrowserRouter
                        forceRefresh
                        basename={process.env.PUBLIC_URL}>
                        <CustomMenuRow
                            direction="top"
                            paddingTop
                            role="menuitem">
                            <NavLink to="/bookings">
                                {' '}
                                {t('My Bookings')}{' '}
                            </NavLink>
                        </CustomMenuRow>{' '}
                        <CustomMenuRow role="menuitem">
                            <NavLink to="/templates">
                                {t('My Templates')}{' '}
                            </NavLink>
                        </CustomMenuRow>
                        <CustomMenuRow
                            direction="bottom"
                            paddingBottom
                            role="menuitem">
                            <NavLink to="/contacts">{t('My Contacts')}</NavLink>
                        </CustomMenuRow>
                        <CustomMenuRow
                            onClick={() => {
                                handleExpanded();
                                dispatch(resetForm());
                                dispatch(saveBookingProduct(null));
                            }}
                            direction="bottom"
                            paddingBottom
                            alignItems="center"
                            role="menuitem">
                            <NavLink
                                to={DETAILS_ROUTE}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}>
                                <GlobalIcon
                                    iconName="circleAdd"
                                    fill="white"
                                    width="15px"
                                    height="15px"
                                    style={{
                                        cursor: 'pointer',
                                        marginRight: '6px',
                                    }}
                                />{' '}
                                {t('New booking')}
                            </NavLink>
                        </CustomMenuRow>
                    </BrowserRouter>
                </CustomDropMenu>
            )}
        </StyledMenu>
    );
};

export default MyCargoMenu;
