import React from 'react';

const Print = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M19.5,1.5 C19.92,1.5 20.256,1.84325744 20.256,2.27232925 L20.256,2.27232925 L20.256,6.80823117 L22.308,6.80823117 C23.244,6.80823117 24,7.59281961 24,8.54903678 L24,8.54903678 L24,17.1549912 C24,18.1112084 23.244,18.8957968 22.308,18.8957968 L22.308,18.8957968 L20.256,18.8957968 L20.256,21.7276708 C20.256,22.1567426 19.92,22.5 19.5,22.5 L19.5,22.5 L4.512,22.5 C4.092,22.5 3.756,22.1567426 3.756,21.7276708 L3.756,21.7276708 L3.756,18.8957968 L1.704,18.8957968 C0.768,18.8957968 3.60866892e-12,18.1112084 3.60866892e-12,17.1549912 L3.60866892e-12,17.1549912 L3.60866892e-12,8.54903678 C3.60866892e-12,7.59281961 0.768,6.80823117 1.704,6.80823117 L1.704,6.80823117 L3.756,6.80823117 L3.756,2.27232925 C3.756,1.84325744 4.092,1.5 4.512,1.5 L4.512,1.5 Z M18.744,15.2670753 L5.268,15.2670753 L5.268,20.9553415 L18.744,20.9553415 L18.744,15.2670753 Z M15.75588,18.5894396 C16.17588,18.5894396 16.51188,18.932697 16.51188,19.3617688 C16.51188,19.7908406 16.17588,20.1340981 15.75588,20.1340981 L15.75588,20.1340981 L8.25588,20.1340981 C7.83588,20.1340981 7.49988,19.7908406 7.49988,19.3617688 C7.49988,18.932697 7.83588,18.5894396 8.25588,18.5894396 L8.25588,18.5894396 Z M15.75588,16.1004553 C16.17588,16.1004553 16.51188,16.4437128 16.51188,16.8727846 C16.51188,17.3018564 16.17588,17.6451138 15.75588,17.6451138 L15.75588,17.6451138 L8.25588,17.6451138 C7.83588,17.6451138 7.49988,17.3018564 7.49988,16.8727846 C7.49988,16.4437128 7.83588,16.1004553 8.25588,16.1004553 L8.25588,16.1004553 Z M19.968,8.98669002 C18.9156,8.98669002 18.0552,9.85464098 18.0552,10.9395797 C18.0552,12.014711 18.9156,12.8924694 19.968,12.8924694 C21.0192,12.8924694 21.8796,12.014711 21.8796,10.9395797 C21.8796,9.85464098 21.0192,8.98669002 19.968,8.98669002 Z M18.744,3.04465849 L5.268,3.04465849 L5.268,6.80823117 L18.744,6.80823117 L18.744,3.04465849 Z"
                    id="path-digital-19"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/print"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-19"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-19"></use>
            </g>
        </svg>
    );
};

export default Print;
