import React from 'react';

const BooksMagazines = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-travel-"
                    points="0 0 24 0 24 19 0 19"></polygon>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/books-magazines"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-3" transform="translate(0.000000, 2.500000)">
                    <mask id="mask-books-magazines" fill="white">
                        <use xlinkHref="#path-travel-"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <g
                        id="Group-8"
                        mask="url(#mask-books-magazines)"
                        fill={fill || '#002244'}
                        fillRule="nonzero">
                        <path
                            d="M3.18,0.0895522388 C2.85814345,0.137928991 2.62134862,0.414937861 2.62495834,0.73880597 L2.62495834,14.6044776 C2.62495834,14.8229186 2.80296681,15 3.0225,15 L3.0225,15 C3.2925,15 3.675,14.9477612 4.1325,14.9477612 C6.2325,14.9477612 9.9,15.4850746 11.5875,19 L11.5875,3.17910448 C11.5875,3.17910448 8.43,0 4.3425,0 C3.95328718,0.000696689111 3.56468269,0.0306325185 3.18,0.0895522388 Z"
                            id="Path"></path>
                        <path
                            d="M4.17,15.6940299 C3.7275,15.6940299 3.36,15.6940299 3.1275,15.7462687 L3,15.7462687 C2.37332831,15.7380771 1.87082273,15.2280715 1.875,14.6044776 L1.875,3.17910448 L0.435,3.17910448 C0.194756134,3.17910448 0,3.37289168 0,3.6119403 L0,18.4552239 C0.000202482737,18.5786692 0.0605629328,18.6943493 0.161918167,18.7655382 C0.263273402,18.8367272 0.393032628,18.8545815 0.51,18.8134328 C1.9725,18.3059701 6.315,17.0597015 10.485,18.5298507 C8.82,16.0820896 5.88,15.6940299 4.17,15.6940299 Z"
                            id="Path"></path>
                        <path
                            d="M20.94,15 L20.94,15 C21.1595332,15 21.3375417,14.8229186 21.3375417,14.6044776 L21.3375417,0.73880597 C21.3411514,0.414937861 21.1043566,0.137928991 20.7825,0.0895522388 C20.3978173,0.0306325185 20.0092128,0.000696689111 19.62,0 C15.5325,0 12.3375,3.17910448 12.3375,3.17910448 L12.3375,19 C14.025,15.4850746 17.6925,14.9477612 19.7925,14.9477612 C20.2875,14.9477612 20.67,14.9776119 20.94,15 Z"
                            id="Path"></path>
                        <path
                            d="M23.565,3.17910448 L22.125,3.17910448 L22.125,14.6044776 C22.1291773,15.2280715 21.6266717,15.7380771 21,15.7462687 L20.895,15.7462687 C20.6625,15.7462687 20.295,15.6940299 19.8525,15.6940299 C18.1425,15.6940299 15.2025,16.0820896 13.5375,18.5223881 C17.7075,17.0298507 22.05,18.2985075 23.5125,18.8059701 C23.6253546,18.8381273 23.7467994,18.8169396 23.8419271,18.7484971 C23.9370548,18.6800547 23.9953291,18.5719382 24,18.4552239 L24,3.6119403 C24,3.37289168 23.8052439,3.17910448 23.565,3.17910448 Z"
                            id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BooksMagazines;
