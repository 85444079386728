import styled, { css } from 'styled-components';
import {
    Box,
    Flex,
    CollapsingPanel,
    Tooltip,
    AsyncAutocomplete,
    Icon,
    Chip,
} from '@freightos/uikit';

import theme from 'configs/theme';
import { background } from 'huge/mocks/accordionMock';

export const SectionWrapper = styled.div`
    padding: 25px 0px;
    border-bottom: 2px solid #ccc;
    width: 100%;
`;

export const SectionWrapperReviewStep = styled.div`
    padding: 13px 0px;
    border-bottom: 2px solid #ccc;
`;

export const SectionTitle = styled(Box)`
    font-size: 1.4em;
    width: 200px;
    flex-shrink: 0;
    font-weight: 600;
    line-height: 1.4em;
    color: black;
`;

export const AWBTitle = styled(Flex)`
    font-size: 1.4em;
    flex-shrink: 0;
    font-weight: 600;
    line-height: 1.4em;
    color: black;
`;

export const TitleH5 = styled.h5`
    white-space: nowrap;
    margin: 0px;
    font-weight: bold;
    font-size: 1.6em;
`;

export const AlertBox = styled(Flex)`
    padding: 100px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
`;

export const SummaryPanel = styled.div`
    background: #ededf6;
    padding: 10px;
    border-radius: 3px;
    display: flex;
`;

export const StyledAddButton = styled.div`
    color: ${theme.blue};
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    &:after,
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        background: ${theme.blue};
    }
    &:after {
        width: 20px;
        height: 2px;
        left: 1px;
        top: 7px;
    }
    &:before {
        height: 20px;
        width: 2px;
        top: -2px;
        left: 10px;
    }
`;

export const TileStrip = styled.div`
    ${({ isReview }) =>
        !isReview &&
        css`
            background: #f4f4f4;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        `};
    padding: 10px 20px;
    label {
        font-weight: 600;
        display: block;
    }
`;

export const LinkFormat = styled.span.attrs(() => ({
    tabIndex: 0,
    role: 'button',
}))`
    color: ${theme.blue};
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
    transition: color 0.15s ease-out;

    &:focus {
        box-shadow: 0px 0px 2px ${theme.blue};
    }

    &:hover {
        color: #0033a0;
    }
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid #ccc;
`;

export const SeparatorTop = styled.div`
    width: 100%;
    border-top: 1px solid #ccc;
`;

export const BoxField = styled(Box)`
    ${({ w, separation, mult = 1 }) =>
        css`
            width: calc(((100% / ${w}) * ${mult}) - (6px * ${separation}));
        `};
`;

export const CustomCollapsingPanel = styled(CollapsingPanel).attrs(() => ({
    tabIndex: 0,
    role: 'button',
}))`
    span:not([direction]) {
        order: 2;
        padding: 0;
        font-size: 1em;
        padding-right: 7px;
    }
    box-shadow: none;
    padding: 0;
    opacity: 1;
    width: 100%;
`;

export const DotLeader = styled.div`
    border-bottom: 2px dotted #444;
    flex-grow: 2;
    margin: 0 7px 5px;
`;

export const ItalicInfoLabel = styled.i`
    font-size: 14px;
    color: #767676;
    font-weight: 200;
`;

export const Caption = styled.span`
    font-weight: 600;
    padding-right: 5px;
`;

export const CaptionBox = styled.div`
    word-break: break-all;
    line-height: 1.2em;
    margin-bottom: 5px;
`;

export const CustomTooltip = styled(Tooltip)`
    display: inline-block !important;
    cursor: pointer;
    height: 16px;
    order: 3;
    margin: ${props => props.margin || '2px 5px 0 5px'};
`;

export const AdvancedOptionsLabel = styled(Box)`
    font-weight: bold;
`;

export const GreyChipAsyncAutocomplete = styled(AsyncAutocomplete)`
    div > div > div.css-oawit-multiValue {
        background-color: #eaeaf3;
    }
`;

export const CustomTab = styled(Box)`
    cursor: pointer;
    padding: 0 10px;
    p {
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
    }

    em {
        height: 2px;
        color: #0c2340;
        width: 100;
    }

    &.selected {
        border-bottom: solid 2px #0c2340;
    }
`;

export const ButtonLinkFormat = styled(LinkFormat)`
    margin: 0;
`;

export const LinkContainer = styled(Flex)`
    align-items: center;
    padding-bottom: 12px;

    div:first-child:nth-last-child(n + 1):not(:only-child) {
        border-right: 1px solid #ccc;
    }

    & > div {
        align-items: center;
        height: 22px;
        padding-left: 32px;
        padding-right: 32px;
    }
`;

export const CustomIcon = styled(Icon)`
    &:hover,
    &:focus {
        border: 3px solid ${theme.secondary};
        border-radius: 5px;
        transition: none !important;
    }
`;

export const PaddedChip = styled(Chip)`
    display: inline-block;
    padding-top: 2px;
    padding-right: 6px;
    padding-bottom: 2px;
    padding-left: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71429;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 20px;
`;

export const BoldSummaryLabel = styled.b`
    margin-right: 4px;
`;
