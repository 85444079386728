import React from 'react';

const MileagePlus = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M2.6077479,0 C2.26774079,0 2,0.2712 2,0.6156 L2,23.3844 C2,24 2.53179932,24.0456238 2.81388461,23.9628 L12,20.6028 L21.1861154,23.9628 C21.4328003,24.0628967 22,24 22,23.3844 L22,0.6156 C22,0.2712 21.7204123,0 21.3922521,0 L2.6077479,0 Z"
                    id="path-digital-15"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/mileageplus"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-15"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-15"></use>
            </g>
        </svg>
    );
};

export default MileagePlus;
