import React from 'react';

const Notifications = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M16.0374568,21.0335555 L7.95020733,21.0335555 C8.48355218,22.7567814 10.0956807,24 12.0065308,24 C13.8919834,24 15.504112,22.7567814 16.0374568,21.0335555 Z M19.573255,14.3138147 L19.573255,10.4497567 C19.573255,7.17490762 17.4640636,4.4064661 14.5240152,3.37205058 L14.5240152,2.49843748 C14.5240152,1.11961679 13.3956462,0 12.006047,0 C10.6043538,0 9.4759848,1.11961679 9.4759848,2.49843748 L9.4759848,3.37205058 C6.52384243,4.38126572 4.42674499,7.17490762 4.42674499,10.4497567 L4.42674499,14.3138147 L1.5,17.2178583 L1.5,19.802697 L22.5,19.802697 L22.5,17.2178583 L19.573255,14.3138147 Z"
                    id="path-digital-17"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/notifications"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-17"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-17"></use>
            </g>
        </svg>
    );
};

export default Notifications;
