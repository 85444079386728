import React from 'react';

const ArrowRight = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/arrow-right"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M15.7282029,2.81463817 L23.7282029,11.3146382 C24.0906029,11.6996882 24.0906029,12.3003176 23.7282029,12.6853676 L15.7282029,21.1853676 C15.3496864,21.5875413 14.7168119,21.6067193 14.3146382,21.2282029 C13.9124644,20.8496864 13.8932864,20.2168119 14.2718029,19.8146382 L20.685,13 L1,13.0000057 C0.44771525,13.0000057 -2.72845669e-12,12.5522905 -2.72842287e-12,12.0000057 C-2.72838906e-12,11.447721 0.44771525,11.0000057 1,11.0000057 L20.685,11 L14.2718029,4.18536757 C13.8932864,3.78319382 13.9124644,3.15031935 14.3146382,2.77180288 C14.7168119,2.39328641 15.3496864,2.41246442 15.7282029,2.81463817 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default ArrowRight;
