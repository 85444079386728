import React from 'react';

const Search = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/search"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M11,0 C16.5228475,0 21,4.4771525 21,10 C21,12.9100804 19.7569582,15.5298327 17.7728753,17.3572563 L22.7071068,22.2928932 C23.0976311,22.6834175 23.0976311,23.3165825 22.7071068,23.7071068 C22.3165825,24.0976311 21.6834175,24.0976311 21.2928932,23.7071068 L16.2928932,18.7071068 C16.2470892,18.6613027 16.2066574,18.6121607 16.1715979,18.5604638 C14.6641953,19.4736995 12.8935597,20 11,20 C5.4771525,20 1,15.5228475 1,10 C1,4.4771525 5.4771525,0 11,0 Z M11,2 C6.581722,2 3,5.581722 3,10 C3,14.418278 6.581722,18 11,18 C15.418278,18 19,14.418278 19,10 C19,5.581722 15.418278,2 11,2 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Search;
