import React from 'react';

const CircleRemove = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Alert/circle-remove"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M16.860527,7.13947296 C16.6745631,6.95350901 16.373056,6.95350901 16.187092,7.13947296 L16.187092,7.13947296 L12,11.326565 L7.81290799,7.13947296 C7.62694404,6.95350901 7.32543691,6.95350901 7.13947296,7.13947296 C6.95350901,7.32543691 6.95350901,7.62694404 7.13947296,7.81290799 L7.13947296,7.81290799 L11.326565,12 L7.13947296,16.187092 C6.95350901,16.373056 6.95350901,16.6745631 7.13947296,16.860527 C7.32543691,17.046491 7.62694404,17.046491 7.81290799,16.860527 L7.81290799,16.860527 L12,12.673435 L16.187092,16.860527 C16.373056,17.046491 16.6745631,17.046491 16.860527,16.860527 C17.046491,16.6745631 17.046491,16.373056 16.860527,16.187092 L16.860527,16.187092 L12.673435,12 L16.860527,7.81290799 C17.046491,7.62694404 17.046491,7.32543691 16.860527,7.13947296 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default CircleRemove;
