import React from 'react';

const Movies = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M23.2608,1.5 C23.6359,1.5 23.94445,1.78336375 23.9932701,2.15268116 L24,2.25538686 L24,21.7446131 C24,22.127927 23.7227083,22.4432336 23.3613049,22.4931228 L23.2608,22.5 L0.7308,22.5 C0.3546,22.5 0.054025,22.2166363 0.00654166666,21.8473188 L-3.57425201e-12,21.7446131 L-3.57425201e-12,2.25538686 C-3.57425201e-12,1.87207299 0.269225,1.55676642 0.630208333,1.50687723 L0.7308,1.5 L23.2608,1.5 Z M22.53,17.6978978 L19.2996,17.6978978 L19.2996,20.9978102 L22.53,20.9978102 L22.53,17.6978978 Z M4.6908,17.6978978 L1.4604,17.6978978 L1.4604,20.9978102 L4.6908,20.9978102 L4.6908,17.6978978 Z M22.53,12.7510949 L19.2996,12.7510949 L19.2996,16.1944818 L22.53,16.1944818 L22.53,12.7510949 Z M4.6908,12.7510949 L1.4604,12.7510949 L1.4604,16.1944818 L4.6908,16.1944818 L4.6908,12.7510949 Z M11.9904,8.16236496 C11.55,8.16236496 11.1396,8.23348905 10.7496,8.3769635 C9.57,8.80616058 8.6508,9.82887591 8.3496,11.0858102 C8.28,11.3813431 8.2404,11.6879124 8.2404,11.9944818 C8.2404,12.3010511 8.28,12.6186569 8.3496,12.9043796 C8.7396,14.5905109 10.23,15.837635 11.9904,15.837635 C13.38,15.837635 14.6004,15.0601752 15.2496,13.9160584 C15.57,13.3531971 15.75,12.6995912 15.75,11.9944818 C15.75,11.2991825 15.57,10.6455766 15.2496,10.0839416 C14.8104,9.29667153 14.0904,8.68353285 13.2396,8.3769635 C12.8496,8.23348905 12.4296,8.16236496 11.9904,8.16236496 Z M11.6,10.1009078 L13.6,11.6009078 C13.8666667,11.8009078 13.8666667,12.2009078 13.6,12.4009078 L11.6,13.9009078 C11.2703819,14.1481214 10.8,13.9129305 10.8,13.5009078 L10.8,10.5009078 C10.8,10.0888851 11.2703819,9.8536942 11.6,10.1009078 Z M22.53,7.80429197 L19.2996,7.80429197 L19.2996,11.2489051 L22.53,11.2489051 L22.53,7.80429197 Z M4.6908,7.80429197 L1.4604,7.80429197 L1.4604,11.2489051 L4.6908,11.2489051 L4.6908,7.80429197 Z M22.53,3.00218978 L19.2996,3.00218978 L19.2996,6.30332847 L22.53,6.30332847 L22.53,3.00218978 Z M4.6908,3.00218978 L1.4604,3.00218978 L1.4604,6.30332847 L4.6908,6.30332847 L4.6908,3.00218978 Z"
                    id="path-travel-17"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/movies"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-17"></use>
                </mask>
                <use
                    id="Combined-Shape"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-17"></use>
            </g>
        </svg>
    );
};

export default Movies;
