import React from 'react';

const External = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/external"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M18.7148539,16.215247 C18.7148539,15.6629623 19.1625692,15.215247 19.7148539,15.215247 C20.2671387,15.215247 20.7148539,15.6629623 20.7148539,16.215247 L20.7148539,23.0278691 C20.7148539,23.5801538 20.2671387,24.0278691 19.7148539,24.0278691 L1,24.0278691 C0.44771525,24.0278691 0,23.5801538 0,23.0278691 L0,4.4 C0,3.84771525 0.44771525,3.4 1,3.4 L7.86950684,3.4 C8.42179159,3.4 8.86950684,3.84771525 8.86950684,4.4 C8.86950684,4.95228475 8.42179159,5.4 7.86950684,5.4 L2,5.4 L2,22.0278691 L18.7148539,22.0278691 L18.7148539,16.215247 Z M14.5,2 C13.9477153,2 13.5,1.55228475 13.5,1 C13.5,0.44771525 13.9477153,2.46025422e-13 14.5,2.46025422e-13 L22.9998713,2.46025422e-13 L23.01361,2.46025422e-13 C23.5658948,2.46025422e-13 24.01361,0.44771525 24.01361,1 L24.01361,9.52797519 C24.01361,10.0802599 23.5658948,10.5279752 23.01361,10.5279752 C22.4613253,10.5279752 22.01361,10.0802599 22.01361,9.52797519 L22.01361,3.40657069 L10.8506828,14.6088167 C10.4608457,15.0000269 9.82768166,15.00114 9.43647141,14.6113029 C9.04526117,14.2214658 9.04414805,13.5883018 9.43398518,13.1970915 L20.591776,2 L14.5,2 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default External;
