import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDraft } from '../../../../basic_components/sagaFormatters';

import { Box, Flex, Alert } from '@freightos/uikit';
import BookingStepsBullets from './BookingStepsBullets';
import { default as GlobalIcon } from 'icons/X Global Icons/GlobalIcons';
import {useLocation} from "react-router";
import {
    REVIEW_ROUTE,
    updateStatus
} from 'modules/bookingSteps/constants';
import {useSelector} from "react-redux";

const BookgingSubHeading = ({
    form,
    isBookingRejected,
    isShowPage,
    showAlertOfBookingStatus,
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isLastStep = pathname === REVIEW_ROUTE;
    const status = useSelector(state => state.bookingSteps.status);

    const alertTitle = alertStatus => {
        let returnedText;
        switch (alertStatus) {
            case 'awbCompleted':
                returnedText = t('Your AWB was successfully completed');
                break;
            case 'awbUpdated':
                returnedText = t('Your AWB was successfully updated');
                break;
            case 'bookingSaved':
                returnedText = t(
                    'Your booking has been successfully completed'
                );
                break;
            case 'bookingUpdated':
                returnedText = t('Your booking has been successfully updated');
                break;
            default:
                returnedText = '';
                break;
        }
        return returnedText;
    };

    return isShowPage ? (
        <>
            {!isDraft(form) && (
                <h5
                    style={{
                        lineHeight: '1.3',
                        marginTop: '1em',
                        fontSize: '14px',
                    }}>
                    {t(
                        'Review your booking information for accuracy and edit as necessary. If you need to make corrections for fields that are not editable, please return to the booking flow.'
                    )}
                </h5>
            )}
            {showAlertOfBookingStatus && !isBookingRejected && (
                <Alert
                    title={
                        <Flex alignItems="center" pt="5px">
                            <GlobalIcon
                                fill="#028a3d"
                                mr="1"
                                iconName="circleCheck"
                            />{' '}
                            {alertTitle(showAlertOfBookingStatus)}
                        </Flex>
                    }
                    level="success"
                    my={2}
                    theme={{
                        alertTitleFontSize: '15px',
                    }}></Alert>
            )}

            {isBookingRejected && (
                <Alert
                    title={t('Your booking has been declined.')}
                    variation="danger"
                    my={2}
                    theme={{
                        alertTitleFontSize: '15px',
                    }}>
                    {t('Please contact the Help Desk for further information.')}
                </Alert>
            )}
        </>
    ) : (
        <>
            <Box mb={4} mt={2}>
                <p
                    style={{
                        lineHeight: '1.5em',
                        color: 'black',
                    }}>
                    {t(
                        'Welcome to the new United Cargo® online booking tool. United Cargo is committed to making your digital experience as easy and seamless as possible. Use this portal to create a booking with instant confirmation and notifications.'
                    )}
                </p>
            </Box>
            <Box mb={4} mt={2}>
                {isLastStep && status !== 'view_template' && updateStatus.includes(status) &&
                <Alert
                    style={{ display:'flex', padding:'10px', alignItems: 'center' }}
                    variation="info"
                    icon={ <GlobalIcon fill="#0063A0" size="25" iconName="CircleInfo" />}>
                    {t('Please review your booking details and click the "Update" button below to complete your booking.')}
                </Alert>}

            {isLastStep && status !== 'view_template' && !updateStatus.includes(status) &&
                <Alert
                    style={{ display:'flex', padding:'10px', alignItems: 'center' }}
                    variation="info"
                    icon={ <GlobalIcon fill="#0063A0" size="25" iconName="CircleInfo" />}>
                    {t('Please review your booking details and click the "Book now" button below to complete your booking.')}
                </Alert>}
            </Box>
            <BookingStepsBullets />
        </>
    );
};

export default BookgingSubHeading;
