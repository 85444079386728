import React from 'react';

const InSeatPower = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M9.68181818,1.5 C9.68181818,0.672 8.98945455,0 8.13636364,0 C7.28327273,0 6.59090909,0.672 6.59090909,1.5 L6.59090909,6 L9.68181818,6 L9.68181818,1.5 Z M17.4090909,1.5 C17.4090909,0.672 16.7167273,0 15.8636364,0 C15.0105455,0 14.3181818,0.672 14.3181818,1.5 L14.3181818,6 L17.4090909,6 L17.4090909,1.5 Z M3.5,7.2 L3.5,15 C3.5,18.312 7.29563636,21 10.708,21 L10.708,24 L13.2796364,24 L13.2796364,21 C16.692,21 20.5,18.312 20.5,15 L20.5,7.2 L3.5,7.2 Z"
                    id="path-travel-12"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/in-seat-power"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-12"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-12"></use>
            </g>
        </svg>
    );
};

export default InSeatPower;
