import { FIELDS } from '../constants';
import {
    showDangerousGoodsProducts,
    showDryIce,
    showMedevac, showRDSSubstance,
} from '../components/common/initialValuesByProductType/productCodes';

export const isRestrictedProductDG = values => {
    if (
        showDangerousGoodsProducts.includes(values?.product_id?.code) &&
        !!values[FIELDS.IS_DANGEROUS_GOODS]
    ) {
        return true;
    }
    if (
        showRDSSubstance.includes(values?.product_id?.code) &&
        !!values[FIELDS.IS_RDS]
    ) {
        return true;
    }


    if (
        showDryIce.includes(values?.product_id?.code) &&
        values[FIELDS.DRY_ICE_MORE_THAN] === false
    ) {
        return true;
    }

    if (
        showMedevac.includes(values?.product_id?.code) &&
        !!values[FIELDS.IS_MEDEVAC]
    ) {
        return true;
    }
    return false;
};
