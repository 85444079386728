import React from 'react';

const Edit = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M8.52247259,16.0440794 C8.19274428,16.0884258 7.91121119,15.8068927 7.95555757,15.4771644 C8.27321127,13.2969486 8.45809081,12.1807918 8.51019617,12.1286942 L16.6880265,3.95207392 C16.884904,3.75522562 17.2040668,3.75520095 17.4009747,3.95201882 L20.0486653,6.59849929 C20.2456471,6.79539108 20.2456836,7.1147 20.0487468,7.31163681 L11.8709164,15.4894672 C9.85843941,15.8296444 8.74229147,16.0145152 8.52247259,16.0440794 Z M23.8523213,2.79536931 C24.0492696,2.99231766 24.0492199,3.31164936 23.8522102,3.50853637 L21.9087957,5.45074083 C21.7119046,5.64750933 21.392807,5.64749611 21.1959322,5.45071129 L18.5482416,2.80423083 C18.3512598,2.60733903 18.3512233,2.28803011 18.5481601,2.0910933 L20.4915746,0.147678742 C20.6884796,-0.0492262475 21.0077257,-0.0492262475 21.2046307,0.147678742 L23.8523213,2.79536931 Z M18.7148539,16.115247 C18.7148539,15.5629623 19.1625692,15.115247 19.7148539,15.115247 C20.2671387,15.115247 20.7148539,15.5629623 20.7148539,16.115247 L20.7148539,22.9278691 C20.7148539,23.4801538 20.2671387,23.9278691 19.7148539,23.9278691 L1,23.9278691 C0.44771525,23.9278691 -2.04281037e-14,23.4801538 -2.04281037e-14,22.9278691 L-2.04281037e-14,4.3 C-2.04281037e-14,3.74771525 0.44771525,3.3 1,3.3 L7.86950684,3.3 C8.42179159,3.3 8.86950684,3.74771525 8.86950684,4.3 C8.86950684,4.85228475 8.42179159,5.3 7.86950684,5.3 L2,5.3 L2,21.9278691 L18.7148539,21.9278691 L18.7148539,16.115247 Z"
                    id="path-1"></path>
            </defs>
            <g
                id="X-Global-Icons/Interactive/edit"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use
                    id="Combined-Shape"
                    fill="#002244"
                    xlinkHref="#path-1"></use>
            </g>
        </svg>
    );
};

export default Edit;
