import * as c from './actionTypes';
import { generateInitialValues } from 'modules/bookingSteps/components/common/initialValuesByProductType';
export const oneReceived = (data, status) => ({
    type: c.ONE_RECEIVED,
    data,
    status,
});

export const search = (data, showCard = false, avoidRedirect = true) => ({
    type: c.SEARCH_SCHEDULES,
    data,
    showCard,
    avoidRedirect,
});

export const saveBooking = data => ({
    type: c.SAVE,
    data,
});

export const updateBooking = data => ({
    type: c.UPDATE,
    data,
});
export const saveDraft = data => ({ type: c.DRAFT, data });

export const updateDraft = data => ({ type: c.UPDATE_DRAFT, data });

export const cancelBooking = id => ({
    type: c.CANCEL,
    id,
});
export const sort = sortBy => ({ type: c.SORT_BY, sortBy });

export const fetchProducts = () => ({ type: c.FETCH_PRODUCTS });

export const productsReceived = products => ({
    type: c.PRODUCTS_RECEIVED,
    products,
});

export const saveBookingProduct = product => ({
    type: c.SAVE_BOOKING_PRODUCT,
    product,
});

export const saveBookingProductInit = data => ({
    type: c.SAVE_BOOKING_PRODUCT_INIT,
    data
});

export const checkIsDomestic = (origin, destination) => ({
    type: c.CHECK_IS_DOMESTIC,
    origin,
    destination,
});

export const setIsDomesticFlight = isDomesticFlight => ({
    type: c.SET_IS_DOMESTIC_FLIGHT,
    isDomesticFlight,
});

export const fetchAircraftModes = () => ({ type: c.FETCH_AIRCRAFT_MODES });

export const fetchUAX = () => ({ type: c.FETCH_UAX });

export const aircraftModesReceived = aircrafts => ({
    type: c.AIRCRAFT_MODES_RECEIVED,
    aircrafts,
});
export const aircraftUAX = data => ({
    type: c.UAX_RECEIVED,
    data,
});

export const fetchAircraftTypes = () => ({ type: c.FETCH_AIRCRAFT_TYPES });

export const aircraftTypesReceived = aircrafts => ({
    type: c.AIRCRAFT_TYPES_RECEIVED,
    aircrafts,
});

export const setForm = data => ({ type: c.SET_FORM, data });

export const resetForm = product => ({
    type: c.RESET_FORM,
    product,
});

export const changeInitialValues = (product, defaultCommodity) => ({
    type: c.CHANGE_INITIAL_VALUES,
    initialValues: generateInitialValues(product, defaultCommodity),
});

export const routingsReceived = routings => ({
    type: c.ROUTINGS_RECEIVED,
    routings,
});

export const changeBookingStatus = status => ({
    type: c.CHANGE_BOOKING_STATUS,
    status,
});

export const goBack = (pathname, hasAllotment) => ({
    type: c.GO_BACK,
    pathname,
    hasAllotment,
});

export const isApiResponseLoading = state => ({
    type: c.IS_API_RESPONSE_LOADING,
    state,
});

export const getNotificationOptions = () => {
    return {
        type: c.GET_NOTIFICATION_OPTIONS,
    };
};

export const notificationOptionsReceived = data => ({
    type: c.NOTIFICATION_OPTIONS_RECEIVED,
    data,
});

export const setNotificationsPreferencesStructure = data => ({
    type: c.SET_NOTIFICATIONS_PREFERENCES_STRUCTURE,
    data,
});

export const addNotificationsPreferencesStructure = () => ({
    type: c.ADD_NOTIFICATIONS_PREFERENCES_STRUCTURE,
});

export const deleteNotificationsPreferencesStructure = position => ({
    type: c.DELETE_NOTIFICATIONS_PREFERENCES_STRUCTURE,
    position,
});

export const deleteAllNotificationsPreferencesStructure = position => ({
    type: c.DELETE_ALL_NOTIFICATIONS_PREFERENCES_STRUCTURE,
});

export const copyNotificationsPreferencesStructure = (position, typeData) => ({
    type: c.COPY_NOTIFICATIONS_PREFERENCES_STRUCTURE,
    position,
    typeData,
});

export const saveNotificationsErrors = ({ arrayOfIndexWithTypeError }) => ({
    type: c.NOTIFICATIONS_ERRORS,
    arrayOfIndexWithTypeError,
});

export const setNotificationsPreferencesStructureField = (
    field,
    data,
    position
) => ({
    type: c.SET_NOTIFICATIONS_PREFERENCES_STRUCTURE_FIELD,
    field,
    data,
    position,
});

export const setNotificationsAgent = (field, data) => ({
    type: c.SET_NOTIFICATIONS_AGENT,
    field,
    data,
});

export const searchContacts = filters => ({ type: c.SEARCH_CONTACTS, filters });

export const getULDList = productId => ({
    type: c.GET_ULD_LIST,
    productId,
});

export const storeULDList = uld_list => ({
    type: c.STORE_ULD_LIST,
    uld_list,
});

export const getContact = (id, contact_type, without_client = false) => {
    return {
        type: c.GET_CONTACT,
        id,
        contact_type,
        without_client,
    };
};

export const contactReceived = (data, contact_type) => ({
    type: c.CONTACT_RECEIVED,
    data,
    contact_type,
});

export const getUserData = (user, user_type) => ({
    type: c.GET_USER_DATA,
    user,
    user_type,
});

export const userReceived = (data, user_type) => ({
    type: c.USER_RECEIVED,
    data,
    user_type,
});
export const updateAccountNumber = data => ({
    type: c.UPDATE_ACCOUNT_NUMBER,
    data,
});

export const getAllotments = data => ({
    type: c.GET_ALLOTMENTS,
    data,
});

export const storeAllotments = data => ({
    type: c.STORE_ALLOTMENTS,
    data,
});

export const createContact = (data, contact_type) => ({
    type: c.CREATE_CONTACT,
    data,
    contact_type,
});

export const modifyContact = (data, contact_type) => ({
    type: c.MODIFY_CONTACT,
    data,
    contact_type,
});

export const getSHC = (product, shc) => ({
    type: c.GET_SHC,
    product,
    shc,
});

export const getMultipleSHC = shcListToGet => ({
    type: c.GET_MULTIPLE_SHC,
    shcListToGet,
});

export const saveSpecialSHCs = (shc, shcName) => ({
    type: c.SAVE_SPECIAL_SHC,
    shc,
    shcName,
});

export const saveMultipleSHC = (newSHCList, setFieldValue) => ({
    type: c.SAVE_MULTIPLE_SHC,
    newSHCList,
    setFieldValue,
});

export const updatePartial = (data, isAWB = false) => ({
    type: c.UPDATE_PARTIAL,
    data,
    isAWB,
});

export const showAlertOfBookingStatus = show => ({
    type: c.SHOW_ALERT_OF_BOOKING_STATUS,
    show,
});

export const saveAWB = (data, booking_id) => ({
    type: c.SAVE_AWB,
    data,
    booking_id,
});

export const isEditingAWBChange = data => ({
    type: c.IS_EDITING_AWB,
    data,
});

export const checkExtraEmbargoes = data => ({
    type: c.CHECK_EXTRA_EMBARGOES,
    data,
});

export const saveAllotmentRoute = data => ({
    type: c.SAVE_ALLOTMENT_ROUTE,
    data,
});

export const setIsDraft = isDraft => ({
    type: c.SET_IS_DRAFT,
    isDraft,
});

export const setRestrictionQuestion = restrictionQuestion => ({
    type: c.SET_RESTRICTION_QUESTION,
    restrictionQuestion,
});

export const getProductMenus = products => ({ type: c.GET_PRODUCT_MENUS, products });