// React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Schema } from '../schema';
import { Formik } from 'formik';
import { isNull, cloneDeep } from 'lodash';
// Constants
import { MODULE_NAME, FIELDS, LABELS, FORM_INITIAL_VALUES } from '../constants';
// Actions
import { deleteTemplate, saveTemplate, updateTemplate } from '../actions';
import { push } from 'connected-react-router';
import Modal, { closeModal } from 'basic_components/Modal';
// Components
import { Flex, Row, Button, Box, Textarea, Checkbox } from '@freightos/uikit';
import InputField from 'basic_components/InputFreeText/InputFreeText';
import ErrorLabel from '../../../basic_components/ErrorLabel';
import { CONTACTS_ROUTE, NOTIFICATION_ROUTE } from '../../bookingSteps/constants';
import jwt_token from 'jwt-decode';

export function DeleteModal({ template }) {
    const { t } = useTranslation(MODULE_NAME);
    const dispatch = useDispatch();
    const templatesTable = useSelector(state => state.tables.TEMPLATES_TABLE);

    return (
        <Modal id="deleteModal">
            <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mt={3}>
                <Row>{t('Are you sure you want to delete this template?')}</Row>
            </Flex>
            <Flex justifyContent="flex-end" mr={4}>
                <Button
                    data-cc="btn_templates_cancel_delete"
                    onClick={() => {
                        dispatch(closeModal('deleteModal'));
                    }}
                    m={2}>
                    {t('No')}
                </Button>
                <Button
                    variation="primary"
                    data-cc="btn_templates_delete"
                    onClick={() => {
                        dispatch(deleteTemplate(template, templatesTable));
                        dispatch(closeModal('deleteModal'));
                        dispatch(push('/templates'));
                    }}
                    m={2}>
                    {t('Yes')}
                </Button>
            </Flex>
        </Modal>
    );
}

export function SaveTemplateModal() {
    const { t } = useTranslation(MODULE_NAME);
    const dispatch = useDispatch();
    const initialValues = useSelector(state =>
        isNull(state?.bookingSteps?.form?.template_id)
            ? FORM_INITIAL_VALUES
            : state.bookingSteps.form
    );
    const { templateSelected } = useSelector(state => state.templates);
    const { agent } = useSelector(state => state?.bookingSteps?.form);

    const onSubmit = (values, actions) => {
        const TemplateToUpdate = {
            ...templateSelected,
            template_name: values[FIELDS.TEMPLATE_NAME],
            template_description: values[FIELDS.TEMPLATE_DESCRIPTION],
            agent: {
                ...templateSelected.agent,
                phone: templateSelected?.phone || agent?.phone || '',
            },
        };

        if (templateSelected?.template_id && values[FIELDS.EDIT_CURRENT]) {
            dispatch(updateTemplate(TemplateToUpdate));
        } else {
            dispatch(saveTemplate(TemplateToUpdate));
        }
        actions.setSubmitting(false);
        dispatch(closeModal('saveTemplateModal'));
    };

    return (
        <Modal id="saveTemplateModal" closable={true}>
            <Formik
                initialValues={cloneDeep(initialValues)}
                onSubmit={onSubmit}
                enableReinitialize="true"
                validationSchema={Schema}
                validateOnChange={false}
                validateOnBlur={false}
                render={({
                    errors,
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleSubmit,
                }) => (
                    <>
                        <Box>
                            {values[FIELDS.EDIT_CURRENT] ? (
                                <h2>{t(LABELS.UPDATE_TITLE)}</h2>
                            ) : (
                                <h2>{t(LABELS.SAVE_TITLE)}</h2>
                            )}
                            <p>{t(LABELS.SAVE_INFO1)}</p>
                            <p>{t(LABELS.SAVE_INFO2)}</p>
                        </Box>
                        <InputField
                            data-cc="templates_name_save"
                            my={3}
                            label={LABELS.TEMPLATE_NAME}
                            name={FIELDS.TEMPLATE_NAME}
                            value={values[FIELDS.TEMPLATE_NAME]}
                            required
                            error={
                                errors[FIELDS.TEMPLATE_NAME] && (
                                    <ErrorLabel
                                        error={errors[FIELDS.TEMPLATE_NAME]}
                                    />
                                )
                            }
                            onChange={v => {
                                setFieldValue(FIELDS.TEMPLATE_NAME, v);
                            }}
                        />
                        {templateSelected?.template_id && (
                            <Checkbox
                                value={values[FIELDS.EDIT_CURRENT]}
                                label={t(LABELS.EDIT_CURRENT)}
                                data-cc="templates_toggle_edit_or_save"
                                onChange={v => {
                                    v
                                        ? setFieldValue(FIELDS.EDIT_CURRENT, v)
                                        : setFieldValue(
                                              FIELDS.EDIT_CURRENT,
                                              false
                                          );
                                }}
                            />
                        )}
                        <Textarea
                            my={3}
                            label={LABELS.TEMPLATE_DESCRIPTION}
                            name={FIELDS.TEMPLATE_DESCRIPTION}
                            value={values[FIELDS.TEMPLATE_DESCRIPTION]}
                            error={
                                errors[FIELDS.TEMPLATE_DESCRIPTION] && (
                                    <ErrorLabel
                                        error={
                                            errors[FIELDS.TEMPLATE_DESCRIPTION]
                                        }
                                    />
                                )
                            }
                            required
                            autoHeight={false}
                            minRows={10}
                            data-cc="templates_description_save"
                            onChange={v => {
                                setFieldValue(FIELDS.TEMPLATE_DESCRIPTION, v);
                            }}
                        />
                        <Flex justifyContent="flex-end">
                            <Button
                                disabled={isSubmitting}
                                data-cc="btn_templates_cancel_save"
                                onClick={() => {
                                    dispatch(closeModal('saveTemplateModal'));
                                }}
                                m={2}>
                                {t('No')}
                            </Button>
                            <Button
                                variation="primary"
                                data-cc="btn_templates_save"
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                m={2}>
                                {t('Yes')}
                            </Button>
                        </Flex>
                    </>
                )}
            />
        </Modal>
    );
}

export const ConfirmWithOverwritten = ({ flight }) => {
    const { t } = useTranslation(MODULE_NAME);
    const dispatch = useDispatch();
    const [route, setRoute] = useState(null);
    useEffect(() => {
        try {
            setRoute(jwt_token(flight)?.data?.data);
        } catch {
            setRoute(flight);
        }
    }, [flight]);

    return (
        <Modal id="confirmWithOverwritten">
            <Flex justifyContent="center">
                {flight && (
                    <Box width={'auto'} mb={2}>
                        <ul style={{ listStyleType: 'circle' }}>
                            {route?.embargoes_applieds &&
                                route?.embargoes_applieds.map(
                                    (message, key) => {
                                        return message?.type ===
                                            'business rule' ? (
                                            <li
                                                style={{
                                                    marginBottom: '5px',
                                                }}>
                                                {`Restriction ${key + 1} - ${
                                                    message?.description
                                                }`}
                                            </li>
                                        ) : (
                                            <li
                                                style={{
                                                    marginBottom: '5px',
                                                }}>
                                                {`${message?.description}`}
                                            </li>
                                        );
                                    }
                                )}
                        </ul>
                    </Box>
                )}
            </Flex>
            <Flex justifyContent="flex-end" mr={4}>
                <Button
                    data-cc="close_modal_confirm_with_override"
                    onClick={() => {
                        dispatch(closeModal('confirmWithOverwritten'));
                    }}
                    m={2}>
                    {t('Cancel')}
                </Button>
                <Button
                    data-cc={'confirm_with_overrride'}
                    variation="primary"
                    onClick={() => {
                        dispatch(closeModal('confirmWithOverwritten'));
                        dispatch(push(CONTACTS_ROUTE));
                    }}
                    m={2}>
                    {t('Accept')}
                </Button>
            </Flex>
        </Modal>
    );
};

export const CustomerEmbargoesData = ({embargoes}) =>{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return <Flex flexDirection="column">
                <Flex justifyContent="center" mb={2}>
                    <ul style={{ listStyleType: 'circle' }}>
                        {embargoes.map(
                            (embargo) =>
                                <li
                                    style={{
                                        marginBottom: '5px',
                                    }}>
                                    {t(`${embargo?.description}`)}
                                </li>
                        )}
                    </ul>
                </Flex>
                <Flex  justifyContent="flex-end" mr={4}>
                    <Button
                        data-cc="close_modal_confirm_with_override"
                        onClick={() => {
                            dispatch(closeModal('customerEmbargoesOverride'));
                        }}
                        m={2}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        data-cc={'confirm_with_overrride'}
                        variation="primary"
                        onClick={() => {
                            dispatch(closeModal('customerEmbargoesOverride'));
                            dispatch(push(NOTIFICATION_ROUTE));
                        }}
                        m={2}>
                        {t('Accept')}
                    </Button>
                </Flex>
            </Flex>
}

export const CustomerEmbargoesOverwrite = () => {
    return (
        <Modal id="customerEmbargoesOverride"></Modal>)
}