import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, CircularLoader } from '@freightos/uikit';

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 300px;
    font-size: 1.5em;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const PageLoader = () => {
    const { t } = useTranslation();

    return (
        <LoadingWrapper>
            <CircularLoader />
            <Box fontSize="1.4em" mt={3}>
                {t('Loading...')}
            </Box>
        </LoadingWrapper>
    );
};

export default PageLoader;
