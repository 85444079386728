import React from 'react';

const Wallet = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/wallet"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M0.7874421,1.09297404 L13.2815234,7.06082215 C13.7539887,7.26839948 14.0689655,7.73544846 14.0689655,8.09870878 L14.0689655,22.9404876 C14.0689655,23.3037479 13.7014925,23.4594309 13.2815234,23.2518535 L0.7874421,17.2840054 C0.31497684,17.0764281 0,16.6093791 0,16.2461188 L0,1.40434003 C0,1.04107971 0.36747298,0.885396716 0.7874421,1.09297404 Z M3.46949602,1.41615764 L23.0981432,2.92472906 C23.6286472,2.92472906 24,3.288867 24,3.65300493 L24,3.65300493 L24,18.3225616 C24,18.6866995 23.5755968,18.946798 23.0981432,18.8947783 L23.0981432,18.8947783 L15.0344828,18.2705419 L15.0344828,12.3402956 L21.2944297,12.8084729 L21.2944297,11.3519212 L15.0344828,10.8837438 L15.0344828,9.47921182 L21.2944297,9.94738916 L21.2944297,8.49083744 L14.9814324,8.0226601 C14.8753316,7.50246305 14.5570292,6.93024631 14.0795756,6.56610837 L14.0795756,6.56610837 L21.3474801,7.13832512 L21.3474801,5.6817734 L10.6843501,4.84945813 L3.46949602,1.41615764 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Wallet;
