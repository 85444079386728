import * as c from './actionTypes';
import {UPDATE_DESKTOP_SCREEN_STATUS} from "./actionTypes";

export const doLogin = (user, pass) => ({ type: c.DO_LOGIN, user, pass });
export const checkToken = () => ({ type: c.CHECK_TOKEN });

export const loginSuccess = token => ({ type: c.LOGIN_SUCCESS, token });
export const userDetails = details => ({ type: c.USER_DETAILS, details });
export const updateNotificationPreferences = notificationPreferences => ({
    type: c.UPDATE_NOTIFICATION_PREFERENCES,
    notificationPreferences,
});

export const logOut = () => ({
    type: c.LOG_OUT,
});

export const dummyCall = () => ({ type: c.CHECK });
export const loginDataReceived = data => ({ type: c.CHECK_RECEIVED, data });

export const salesForceSSO = token => ({ type: c.SALESFORCE_SSO, token });
export const updateLoginProcessStatus = status => ({
    type: c.UPDATE_LOGIN_PROCESS_STATUS,
    status,
});

export const updateMobileAlertStatus = status => ({
    type: c.UPDATE_MOBILE_ALERT_STATUS,
    status,
});

export const updateDesktopScreenStatus = status => ({
    type: c.UPDATE_DESKTOP_SCREEN_STATUS,
    status,
});
