import React from 'react';

const WeatherRain = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-rain"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,11.1158274 L15.624,17.5083592 C16.0275,18.1585744 16.2555,18.9259185 16.2555,19.7398138 C16.2555,22.0944038 14.349,24 12,24 C9.6525,24 7.743,22.0944038 7.743,19.7398138 C7.743,18.9259185 7.974,18.1585744 8.373,17.5083592 L8.373,17.5083592 L12,11.1158274 Z M8.5545,-5.5067062e-14 C10.842,-5.5067062e-14 12.987,0.890479527 14.604,2.50475523 C15.1545,3.05135649 15.621,3.66553209 15.999,4.32926219 L15.999,4.32926219 L16.1625,4.61757934 L16.491,4.55601161 C16.8795,4.48693563 17.2785,4.44789268 17.6685,4.44789268 C21.1575,4.44789268 24,7.28751627 24,10.7773551 C24,13.1709881 22.6605,15.2507759 20.697,16.3229553 L20.697,16.3229553 L19.959,15.1591751 L19.98,15.1051156 C21.5265,14.2762038 22.581,12.6484133 22.581,10.7773551 C22.581,8.06987686 20.376,5.86695365 17.6685,5.86695365 C16.854,5.86695365 16.077,6.06367004 15.366,6.43607969 C14.454,3.49434378 11.6865,1.42206427 8.5545,1.42206427 C4.62,1.42206427 1.4205,4.62058264 1.4205,8.5549104 C1.4205,10.9620583 2.625,13.0914005 4.458,14.3828211 L4.458,14.3828211 L3.87,15.7102813 C3.3855,15.3904295 2.9265,15.0210231 2.5065,14.6005606 C0.8925,12.9847833 0,10.8389228 0,8.5549104 C0,6.26939634 0.8925,4.12053259 2.5065,2.50475523 C4.122,0.890479527 6.2715,-5.5067062e-14 8.5545,-5.5067062e-14 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherRain;
