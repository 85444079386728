import React from 'react';

const Text = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/text"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M17.6923077,6.60769231 C18.7968772,6.60769231 19.6923077,7.50312281 19.6923077,8.60769231 L19.6923077,16.9153846 C19.6923077,18.0199541 18.7968772,18.9153846 17.6923077,18.9153846 L5.481,18.9146923 L2,21.7 L2,18.9153846 C0.8954305,18.9153846 1.3527075e-16,18.0199541 0,16.9153846 L0,8.60769231 C-1.3527075e-16,7.50312281 0.8954305,6.60769231 2,6.60769231 L17.6923077,6.60769231 Z M22,2.3 C23.1045695,2.3 24,3.1954305 24,4.3 L24,12.6076923 C24,13.7122618 23.1045695,14.6076923 22,14.6076923 L20.9226923,14.607 L20.9230769,7.37692308 C20.9230769,6.32256128 20.1071991,5.45875796 19.0723393,5.38240881 L18.9230769,5.37692308 L4.30769231,5.376 L4.30769231,4.3 C4.30769231,3.1954305 5.20312281,2.3 6.30769231,2.3 L22,2.3 Z M9.84615385,11.5307692 C9.16641877,11.5307692 8.61538462,12.0818034 8.61538462,12.7615385 C8.61538462,13.4412735 9.16641877,13.9923077 9.84615385,13.9923077 C10.5258889,13.9923077 11.0769231,13.4412735 11.0769231,12.7615385 C11.0769231,12.0818034 10.5258889,11.5307692 9.84615385,11.5307692 Z M14.1538462,11.5307692 C13.4741111,11.5307692 12.9230769,12.0818034 12.9230769,12.7615385 C12.9230769,13.4412735 13.4741111,13.9923077 14.1538462,13.9923077 C14.8335812,13.9923077 15.3846154,13.4412735 15.3846154,12.7615385 C15.3846154,12.0818034 14.8335812,11.5307692 14.1538462,11.5307692 Z M5.53846154,11.5307692 C4.85872646,11.5307692 4.30769231,12.0818034 4.30769231,12.7615385 C4.30769231,13.4412735 4.85872646,13.9923077 5.53846154,13.9923077 C6.21819662,13.9923077 6.76923077,13.4412735 6.76923077,12.7615385 C6.76923077,12.0818034 6.21819662,11.5307692 5.53846154,11.5307692 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Text;
