import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputField } from '@freightos/uikit';

const InputFreeText = ({ onChange, value, transformation, type, ...rest }) => {
    const [inputValue, setInputValue] = useState(value);

    useMemo(() => {
        setInputValue(value);
    }, [value]);

    const handleOnChange = v => {
        if (typeof transformation === 'function') {
            setInputValue(transformation(v));
        } else {
            setInputValue(v);
        }
    };

    const handleOnValueChange = v => {
        const newValue = type === 'number' && !!v ? parseFloat(v) : v;
        const valueTrimmed =
            typeof newValue === 'string' ? newValue.trim() : newValue;
        const valueToSave =
            typeof valueTrimmed === 'string' || !isNaN(valueTrimmed)
                ? valueTrimmed
                : '';
        setInputValue(valueToSave);
        onChange(valueToSave);
    };

    return (
        <InputField
            onChange={handleOnChange}
            onBlur={v => {
                handleOnValueChange(v);
            }}
            value={inputValue}
            type={type}
            {...rest}
        />
    );
};

InputFreeText.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: [PropTypes.string, PropTypes.number],
};

export default InputFreeText;
