import React from 'react';

const WellBeing = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/CleanPlus/well-being"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Group"
                    transform="translate(3.000000, 0.000000)"
                    fill={fill || '#002244'}>
                    <path
                        d="M13.9460625,5.00186916 C13.9460625,7.76456075 11.6987625,10.0050841 8.9285625,10.0050841 C6.1570125,10.0050841 3.9099375,7.76456075 3.9099375,5.00186916 C3.9099375,2.23895327 6.1570125,-2.47029811e-14 8.9285625,-2.47029811e-14 C11.6987625,-2.47029811e-14 13.9460625,2.23895327 13.9460625,5.00186916"
                        id="Fill-43"></path>
                    <path
                        d="M12.333375,17.8565607 L9.168975,21.5144299 C9.1134,21.5794766 9.029025,21.6182804 8.9442,21.6182804 C8.856,21.6182804 8.771175,21.5794766 8.715825,21.5144299 L5.55165,17.8565607 C5.215725,17.4696449 5.0337,16.9793271 5.0337,16.4726355 C5.0337,15.3098692 5.98185,14.3644486 7.148475,14.3644486 C7.813125,14.3644486 8.422875,14.6663551 8.8299,15.192785 L8.9442,15.3388037 L9.0549,15.192785 C9.462375,14.6663551 10.07505,14.3644486 10.736325,14.3644486 C11.902725,14.3644486 12.8547,15.3098692 12.8547,16.4726355 C12.8547,16.9793271 12.66885,17.4696449 12.333375,17.8565607 M11.030625,10.8575327 L6.917625,10.8388458 C3.1455,10.8216449 0.045225,13.9965981 0.02745,17.8937944 L-2.52597943e-13,23.918243 L17.828325,23.9994393 L17.8558542,17.9747664 C17.873775,14.0775701 14.8194,10.8748037 11.030625,10.8575327"
                        id="Fill-45"></path>
                </g>
            </g>
        </svg>
    );
};

export default WellBeing;
