import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as constants from './constants';
import * as bookingStepsActionTypes from 'modules/bookingSteps/actionTypes';
import { doRequest } from '../../api/requestSaga';
import { formatReceivedBooking } from 'basic_components/sagaFormatters';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import {
    oneReceived,
    getNotificationOptions,
    setIsDraft,
    saveBookingProduct,
    fetchProducts, saveBookingProductInit,
} from 'modules/bookingSteps/actions';
import {
    storeBookingStatusOptions,
    storeBookingMovementStatusOptions,
} from './actions';
import { MODULE_ROUTE, BOOKING_SHOW } from './constants';
import { isEmptyValues } from '../../basic_components/jsTools';
import {FORM_INITIAL_VALUES_GEN_EXP} from "../bookingSteps/components/common/initialValuesByProductType/GCR_EXP";
import {FORM_INITIAL_VALUES_QPK} from "../bookingSteps/components/common/initialValuesByProductType/QPK";
import {FORM_INITIAL_VALUES_LFG, FORM_INITIAL_VALUES_LXL} from "../bookingSteps/components/common/initialValuesByProductType/Lifeguard";

function* getOne(payload) {
    const data = {
        id: payload.id,
    };

    try {
        yield put(getNotificationOptions());
        const res = yield call(
            doRequest,
            'GET',
            'cargo' + constants.MODULE_ROUTE + '/show',
            data
        );
        const responseData = res?.response?.data;

        yield bookingStatusProductChange(
            responseData?.booking_general?.product?.code
        );
        const isDraft = !responseData?.booking_general?.booking_status;
        yield put(setIsDraft(isDraft));
        yield put(oneReceived(formatReceivedBooking(responseData), 'show'));
        yield put(push(`${MODULE_ROUTE}${BOOKING_SHOW}/${payload.id}`));
    } catch (e) {}
}

function* getBookingStatusOptions() {
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo' + constants.MODULE_ROUTE + '/booking_status'
        );
        yield put(storeBookingStatusOptions(res.response.data));
    } catch (e) {}
}

function* getBookingMovementStatusOptions() {
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo' + constants.MODULE_ROUTE + '/booking_movement_status'
        );
        yield put(storeBookingMovementStatusOptions(res.response.data));
    } catch (e) {}
}

export function* bookingStatusProductChange(code) {
    let {products, form} = yield select(state => state.bookingSteps);

    if (isEmptyValues(products)) {
        yield put(fetchProducts());
        yield take(bookingStepsActionTypes.PRODUCTS_RECEIVED);
        products = yield select(state => state.bookingSteps.products);
    }
    const bookingProduct = products.find(product => {
        return product.code === code;
    });

    if (!form.account_number){
        const formatedProduct={
            product:bookingProduct,
            //form:form
             ...((bookingProduct?.code === 'GCR' || bookingProduct?.code === 'EXP') && {form:cloneDeep(FORM_INITIAL_VALUES_GEN_EXP)}),
             ...((bookingProduct?.code === 'QPK' || bookingProduct?.code === 'QPI') && {form:cloneDeep(FORM_INITIAL_VALUES_QPK)}),
             ...(bookingProduct?.code === 'LFG'  && {form:cloneDeep(FORM_INITIAL_VALUES_LFG)}),
             ...(bookingProduct?.code === 'LXL' && {form:cloneDeep(FORM_INITIAL_VALUES_LXL)})
        }
        yield put(saveBookingProductInit(formatedProduct));
    }else{
        yield put(saveBookingProduct(bookingProduct));
    }
}

export default function*() {
    yield all([
        takeLatest(types.ONE, getOne),
        takeLatest(types.GET_BOOKINGS_STATUS_OPTIONS, getBookingStatusOptions),
        takeLatest(
            types.GET_BOOKINGS_MOVEMENT_STATUS_OPTIONS,
            getBookingMovementStatusOptions
        ),
    ]);
}
