import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { Notification } from '@freightos/uikit';
import { push } from 'connected-react-router';
import axios from 'axios';
import { doRequest } from '../../api/requestSaga';
import * as c from './actionTypes';
import { loginSuccess, userDetails, updateLoginProcessStatus } from './actions';

const token = process.env.REACT_APP_TOKEN_NAME;

function* doLogin(payload) {
    const data = {
        email: payload.user,
        //password: payload.pass,
        //is_internal: 0,
    };
    const formDataPost = new FormData();
    Object.keys(data).map(k => formDataPost.append(k, data[k]));
    try {
        const res = yield axios.post(
            'login/login/dev_salesforce_sso',
            formDataPost,
            {
                headers: {
                    Authorization: '',
                },
            }
        );
        sessionStorage.setItem(token, res.data.response.data.authToken);
        axios.defaults.headers.common.Authorization =
            res.data.response.data.authToken;

        yield put(loginSuccess(res.data.response.data.authToken));

        const userResponse = yield axios.get(
            'cargo/external_users/getFromCountry/'
        );
        const userData = userResponse.data.response.data;
        yield put(userDetails(userData));

        const redirectTo = yield select(state => state.router.location.state);
        yield put(updateLoginProcessStatus(true));
        yield put(push(redirectTo ? redirectTo.from : '/'));
    } catch (e) {
        Notification.show({
            message: 'Invalid MileagePlus number or password',
            level: 'danger',
            type: 'toast',
        });
        yield put(updateLoginProcessStatus(true));
    }
}

function* mileageplus_sso(payload) {
    const data = {
        token: payload.token,
    };
    try {
        const res = yield call(
            doRequest,
            'POST',
            'login/login/mileageplus_sso',
            data
        );
        sessionStorage.setItem(token, res.response.data.authToken);
        yield put(loginSuccess(res.response.data.authToken));

        const userResponse = yield axios.get(
            'cargo/external_users/getFromCountry/'
        );
        const userData = userResponse.data.response.data;
        yield put(userDetails(userData));
        yield put(updateLoginProcessStatus(true));
        yield put(push('/'));
    } catch (e) {
        Notification.show({
            message: 'Invalid user',
            level: 'danger',
            type: 'toast',
        });
        yield put(updateLoginProcessStatus(true));
    }
}

function* salesforce_sso(payload) {
    const data = {
        code: payload.token,
        portal_name: 'CARGO',
    };
    const formDataPost = new FormData();
    Object.keys(data).map(k => formDataPost.append(k, data[k]));
    try {
        const res = yield axios.post(
            'login/login/salesforce_sso',
            formDataPost,
            {
                headers: {
                    Authorization: '',
                },
            }
        );

        sessionStorage.setItem(token, res.data.response.data.authToken);

        axios.defaults.headers.common.Authorization =
            res.data.response.data.authToken;

        if (!res.data.response.data.userAllowedLogin) throw new Error('User not allowed to login');
        

        yield put(loginSuccess(res.data.response.data.authToken));

        const userResponse = yield axios.get(
            'cargo/external_users/getFromCountry/'
        );
        const userData = userResponse.data.response.data;
        yield put(userDetails(userData));
        const lastRoute = sessionStorage.getItem(
            process.env.REACT_APP_LOGIN_LAST_ROUTE
        );
        const redirectTo = yield select(state => state.router.location.state);
        yield put(updateLoginProcessStatus(true));
        yield put(
            push(redirectTo ? redirectTo.from : lastRoute ? lastRoute : '/')
        );
    } catch (e) {
        yield put(updateLoginProcessStatus(true));
        yield logOut();
    }
}

function* checkToken() {
    try {
        const res = yield axios.get('/login/login/check_token');

        if (res.data.response.data) {
            yield put(loginSuccess(sessionStorage.getItem(token)));
            const userResponse = yield axios.get(
                'cargo/external_users/getFromCountry/'
            );
            const userData = userResponse.data.response.data;
            yield put(userDetails(userData));
            yield put(updateLoginProcessStatus(true));
        } else {
            yield put(updateLoginProcessStatus(true));
        }
    } catch (e) {
        yield put(updateLoginProcessStatus(true));
    }
}
function* logOut() {
    try {
        yield call(
            doRequest,
            'DELETE',
            'login/login/logout',
            undefined,
            undefined,
            {
                isLogout: true,
            }
        );
    } catch (e) {}
    yield logOutPromise();
}

const logOutPromise = () => {
    return new Promise(resolve => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_SALESFORE_REDIRECT_LOGOUT;
        resolve();
    });
};


export default function*() {
    yield all([
        takeLatest(c.DO_LOGIN, doLogin),
        takeLatest(c.CHECK_TOKEN, checkToken),
        takeLatest(c.MILEAGEPLUS_SSO, mileageplus_sso),
        takeLatest(c.SALESFORCE_SSO, salesforce_sso),
        yield takeLatest(c.LOG_OUT, logOut),
    ]);
}
