export const MODULE_ROUTE = '/bookings';
export const MODULE_NAME = 'BOOKINGS';

export const BOOKING_SHOW = '/management';

export const FIELDS = {
    ACCOUNT_NAME: 'account_name',
    ACCOUNT_NUMBER: 'account_number',
    AWB_NUMBER: 'awb_number',
    AWB: 'awb',
    AWB_PREFIX: 'awb_prefix',
    BOOKING_NUMBER: 'booking_number',
    BOOKING_STATUS: 'booking_status',
    CA_ACCOUNT: 'ca_account',
    CA_CITY: 'ca_city',
    CA_CLIENT: 'ca_client',
    CA_CODE: 'ca_code',
    CA_PHONE: 'ca_phone',
    CA_USER_EMAIL: 'ca_user_email',
    CA_USER_NAME: 'ca_user_name',
    CITY: 'city',
    DESTINATION: 'destination',
    FLIGHT_DATE: 'flight_date',
    FLIGHT_NUMBER: 'flight_number',
    FROM_DATE: 'date_range_from',
    GUEST_EMAIL: 'guest_email',
    JOURNEY_NUMBER: 'journey_number',
    MP_LAST_NAME: 'mp_last_name',
    MP_NUMBER: 'mp_num',
    ORIGIN: 'origin',
    PET_BOOKING_TYPE: 'pet_booking_type',
    PIECES: 'pieces',
    PRODUCT: 'product',
    PRODUCT_ID: 'product_id',
    ROUTE: 'route',
    SHC_ID: 'shc_id',
    SHIPMENT_DATE: 'departure_date',
    SHIPMENT_STATUS: 'movement_status',
    SOURCE: 'source',
    STATUS: 'status',
    MOVEMENT_STATUS: 'movement_status',
    TO_DATE: 'date_range_to',
    TRACK: 'track',
    USER_TYPE: 'user_type',
    VOLUME_AND_WEIGHT: 'volume_and_weight',
};

export const LABELS = {
    FLIGHT_DATE: 'Flight Date',
    ACCOUNT_NAME: 'Account Name',
    ACCOUNT_NUMBER: 'Account Number',
    AWB_NUMBER: 'AWB',
    AWB_PREFIX: 'AWB Prefix',
    BOOKING_STATUS: 'Booking Status',
    CITY: 'City',
    COMPANY_NAME: 'Company Name',
    DESTINATION: 'Destination',
    FLIGHT_NUMBER: 'Flight Number',
    FROM_DATE: 'From...',
    JOURNEY_NUMBER: 'Journey Number',
    ORIGIN: 'Origin',
    PIECES: 'Pieces',
    PRODUCT: 'Product',
    ROUTE: 'Origin and destination',
    SHC: 'SHC',
    SHIPMENT_DATE: 'Flight Date',
    SHIPMENT_STATUS: 'Shipment Status',
    SOURCE: 'Source',
    STATUS: 'Booking Status',
    TO_DATE: 'To...',
    TRACK: 'Track',
    VOLUME_AND_WEIGHT: 'Volume / Weight',
    DRAFT_CONFIRMATION_MSG:
        'Your booking information has been saved in the “My Bookings” section with a status of “Draft”.  Your draft will be available for the next 48 hours, so please return soon to complete and confirm your booking.',
};

export const FILTERS = {
    ROUTE: 'origin',
    PIECES: 'packages_amount',
    VOLUME_AND_WEIGHT: 'total_volume',
};
