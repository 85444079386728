import React from 'react';

const UpgradeWaitlist = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Upgrade/upgrade-waitlist"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L20,0 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12,5.5 C15.5898509,5.5 18.5,8.41014913 18.5,12 C18.5,15.5898509 15.5898509,18.5 12,18.5 C8.41014913,18.5 5.5,15.5898509 5.5,12 C5.5,8.41014913 8.41014913,5.5 12,5.5 Z M11.5333333,7.65992 C11.1191198,7.65992 10.7833333,7.99570644 10.7833333,8.40992 L10.7833333,14.2852533 L14.453333,14.2852533 C14.8675466,14.2852533 15.203333,13.9494669 15.203333,13.5352533 C15.203333,13.1210398 14.8675466,12.7852533 14.453333,12.7852533 L12.2833333,12.7852533 L12.2833333,8.40992 C12.2833333,7.99570644 11.9475469,7.65992 11.5333333,7.65992 Z"
                    id="Combined-Shape"
                    fill={fill || '#69B3E7'}></path>
            </g>
        </svg>
    );
};

export default UpgradeWaitlist;
