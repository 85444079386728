import React from 'react';

const Camera = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/camera"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M14.8831684,2.75 C15.826244,2.75 16.6411715,3.40880026 16.8387732,4.33094182 L17.035,5.25 L22,5.25 C23.1045695,5.25 24,6.1454305 24,7.25 L24,19.25 C24,20.3545695 23.1045695,21.25 22,21.25 L2,21.25 C0.8954305,21.25 1.3527075e-16,20.3545695 0,19.25 L0,7.25 C-1.3527075e-16,6.1454305 0.8954305,5.25 2,5.25 L6.964,5.25 L7.16122675,4.33094182 C7.35882852,3.40880026 8.17375601,2.75 9.11683158,2.75 L14.8831684,2.75 Z M12,8.75 C9.23857625,8.75 7,10.9885763 7,13.75 C7,16.5114237 9.23857625,18.75 12,18.75 C14.7614237,18.75 17,16.5114237 17,13.75 C17,10.9885763 14.7614237,8.75 12,8.75 Z M12,10 C14.0710678,10 15.75,11.6789322 15.75,13.75 C15.75,15.8210678 14.0710678,17.5 12,17.5 C9.92893219,17.5 8.25,15.8210678 8.25,13.75 C8.25,11.6789322 9.92893219,10 12,10 Z M20,7.75 C19.4477153,7.75 19,8.19771525 19,8.75 C19,9.30228475 19.4477153,9.75 20,9.75 C20.5522847,9.75 21,9.30228475 21,8.75 C21,8.19771525 20.5522847,7.75 20,7.75 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Camera;
