export const GCR = 'GCR';
export const EXP = 'EXP';
export const QPK = 'QPK';
export const QPI = 'QPI';
export const LFG = 'LFG';
export const LXL = 'LXL';
export const TCO = 'TCO';
export const HUM = 'HUM';
export const AEV = 'AEV';

export const allowedProductsByCode = [GCR, EXP, QPK, LFG, TCO, HUM, AEV];
export const isGcrOrExp = [GCR, EXP];
export const showDangerousGoodsProducts = [GCR, EXP, QPK, QPI, LFG, LXL];
export const showRDSSubstance = [QPK, QPI, LFG, LXL];
export const showDryIce = [QPK, QPI, LFG, LXL];
export const showMedevac = [LFG];
export const hideExtraDetailsPieces = [QPK, QPI, LFG];
export const shouldHaveExtraDetails = [GCR, EXP, LXL];
export const shouldHideApplicableRate = [QPK, QPI, LFG];
export const totalWeightProducts = [QPK, QPI, LFG, LXL];

