import React from 'react';

const Farelock = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/farelock"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M14.3746258,16.3485 L12.6772885,15.831 L12.5642823,19.8945 L13.8245628,21.099 C13.8582401,21.207 13.8649756,21.342 13.8410273,21.5025 C13.2879707,21.39075 12.810501,21.23325 12.2664251,21.11475 C12.1212384,21.615 11.941626,21.61725 11.7912006,21.117 C11.2493698,21.2415 10.7726485,21.40275 10.2218371,21.52125 C10.1948952,21.3585 10.2008823,21.22425 10.2315661,21.117 L11.4783756,19.89975 L11.3234599,15.8355 L9.62687096,16.3485 L6.02788719,18.3645 C6.01366787,18.12825 5.98148732,17.8455 6.10721601,17.67975 C6.45147314,17.2275 7.78060501,15.96225 9.25118166,14.7435 C9.20253663,14.601 9.17484638,14.433 9.17484638,14.25225 C9.17484638,13.7385 9.39412321,13.659 9.66578699,13.659 C9.88955412,13.659 10.0781472,13.71525 10.1365212,14.02725 C10.5354104,13.71525 10.9320545,13.4175 11.3107374,13.15275 L11.3107374,13.13925 C11.3174728,11.01075 11.4252403,9.5235 11.970813,9.52123982 C12.5118954,9.51675 12.6353789,11.00475 12.6668111,13.13175 L12.6668111,13.13625 C13.0522294,13.40625 13.4571057,13.7085 13.8642272,14.02725 C13.9211045,13.71525 14.1111943,13.659 14.3357098,13.659 C14.6058768,13.659 14.825902,13.7385 14.825902,14.25225 C14.825902,14.433 14.7974634,14.601 14.7503151,14.7435 C16.219395,15.96225 17.5492752,17.2275 17.8942808,17.67975 C18.0192611,17.8455 17.9878289,18.12825 17.9728612,18.3645 L14.3746258,16.3485 Z M7.94749488,6.39825 C7.94749488,4.13625 9.77579959,2.3025 12.0329289,2.3025 C14.2900583,2.3025 16.118363,4.13625 16.118363,6.39825 L16.118363,7.311 L7.94749488,7.311 L7.94749488,6.39825 Z M18.4181503,7.311 L18.4181503,6.39825 C18.4181503,2.8635 15.5600677,0 12.0329289,0 C8.50653852,0 5.64845596,2.8635 5.64845596,6.39825 L5.64845596,7.311 L4.82747755,7.311 C3.54100362,7.311 2.5,8.35425 2.5,9.64275 L2.5,21.6675 C2.5,22.95525 3.54100362,24 4.82747755,24 L19.1725225,24 C20.4567512,24 21.5,22.95525 21.5,21.6675 L21.5,9.64275 C21.5,8.35425 20.4567512,7.311 19.1725225,7.311 L18.4181503,7.311 Z"
                    id="path-digital-1"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Farelock;
