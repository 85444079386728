import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router';
import SFLogin from './SFLogin';
import Login from 'modules/login/Login';
import {
    checkToken,
    updateLoginProcessStatus,
    updateMobileAlertStatus,
    updateDesktopScreenStatus,
} from 'modules/login/actions';
import { checkDev } from '../configs/checkDev';

import useWindowDimensions from './customHooks/useWindowDimensions';
import PageLoader from 'basic_components/PageLoader';
import MobileScreenModal from './MobileScreenModal';
const token = process.env.REACT_APP_TOKEN_NAME;

function PrivateRoute({ children, component, path, ...rest }) {
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const DesktopScreenStatus = width >= 1280 && height >= 800;
    const { loggedIn, finishedCheck, desktopScreen, mobileAlert } = useSelector(
        state => state.user
    );
    const localToken = sessionStorage.getItem(token);

    useEffect(() => {
        if (!mobileAlert) {
            dispatch(updateDesktopScreenStatus(DesktopScreenStatus));
        }
    }, [DesktopScreenStatus]);

    useEffect(() => {
        if (!localToken || (localToken && !loggedIn)) {
            // found local token, so let's validate
            if (!checkDev()) {
                dispatch(checkToken());
            } else {
                if (!localToken) {
                    dispatch(updateLoginProcessStatus(true));
                } else {
                    dispatch(checkToken());
                }
            }
        }
    }, [finishedCheck]);

    useEffect(() => {
        if (!mobileAlert) {
            if (loggedIn && desktopScreen) {
                dispatch(updateMobileAlertStatus(true));
            }
        }
    }, [loggedIn, desktopScreen]);

    const Component = component;

    // Loading
    if (!finishedCheck) return PageLoader;

    // Dev environment (No SalesForce)
    if (checkDev() && !loggedIn) return <Login />;

    // redirect to SalesForce
    if (!loggedIn) {
        return <SFLogin path={path} />;
    }
    

    // Render component
    return (!desktopScreen && !mobileAlert)
        ? (<Route {...rest}>{children || <MobileScreenModal />}</Route>)
        : (<Route {...rest}>{children || <Component />}</Route>);

    }

export default PrivateRoute;
