import React from 'react';

const SkiBoot = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M22.4328,20.0062547 L22.5696,19.5185011 C22.9524,18.164985 22.374,16.7407445 21.15,16.0652058 L20.6208,15.7652374 L18.8772,18.9514876 C18.5378418,19.4326782 17.9856,19.299012 17.8776,19.2404816 C17.526,19.0405026 17.3976,18.5832336 17.5932,18.2247348 L19.3272,15.049459 L18.1428,14.3922111 L16.2228,17.8967205 C15.9469604,18.3829956 15.3408,18.254 15.2232,18.1844951 C14.8704,17.9857355 14.7432,17.5272472 14.9388,17.1699677 L16.8588,13.6752133 L14.2236,12.2021975 L14.214,12.2021975 L8.8752,17.8259962 C8.532,18.1844951 8.09204102,18.0841064 7.8372,17.8369707 C7.58235898,17.5898349 7.5432,17.0797333 7.8168,16.7809842 L13.6068,10.6999165 L8.9148,10.301178 C8.5128,10.2706934 8.2092,9.9134139 8.2392,9.50492028 C8.2776,9.08667159 8.6304,8.78792253 9.0324,8.81840713 L14.7816,9.29518625 L14.9292,7.94167008 L9.2088,7.45391651 C8.8068,7.4246513 8.5032,7.06615242 8.532,6.64790373 C8.5716,6.23941012 8.9244,5.93090598 9.3264,5.97114565 L15.0864,6.44914415 L15.2232,5.14440335 C15.2532,4.89564903 15.1548,4.6468947 14.9688,4.48715541 L13.764,3.46287291 C13.1664,2.94463474 12.3336,2.7958699 11.598,3.07388943 L9.8064,3.73113737 L4.7412,2.55687065 C4.1544,2.41786088 3.5268,2.53736051 3.0276,2.87512985 C2.5176,3.21411859 2.1756,3.76162197 2.0772,4.35790071 L1.1268,10.0524237 C1.0776,10.3414177 1.1952,10.6304116 1.4208,10.7889316 L2.0472,11.2376648 L0.8916,17.2784928 C0.7152,18.2040052 0.8328,19.1600022 1.2156,20.0062547 L0.7356,20.0062547 C0.3336,20.0062547 0,20.3354883 0,20.7525177 C0,21.1610113 0.3336,21.5 0.7356,21.5 L23.2656,21.5 C23.6676,21.5 24,21.1610113 24,20.7525177 C24,20.3354883 23.6676,20.0062547 23.2656,20.0062547 L22.4328,20.0062547 Z"
                    id="path-travel-26"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/ski-boot"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-26"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-26"></use>
            </g>
        </svg>
    );
};

export default SkiBoot;
