import * as Yup from 'yup';
import { LABELS, FIELDS } from './constants';

export const Schema = Yup.object().shape({
    [FIELDS.TEMPLATE_NAME]: Yup.string()
        .label(LABELS.TEMPLATE_NAME)
        .required()
        .nullable(),
    [FIELDS.EDIT_CURRENT]: Yup.boolean()
        .label(LABELS.EDIT_CURRENT)
        .default(false),
    [FIELDS.TEMPLATE_DESCRIPTION]: Yup.string()
        .label(LABELS.TEMPLATE_DESCRIPTION)
        .required()
        .max(100)
        .nullable(),
});
