export const isCompleteUser = data => {
    let isComplete = true;
    let singleData = { ...data };

    delete singleData.po_box;

    const atributesToCheck = [
        'account_number',
        'address',
        'city',
        'city_text',
        'company_name',
        'country',
        'phone',
        'email',
        'zip_code',
    ];

    for (var i = 0; i < atributesToCheck.length; i++) {
        if (!singleData[atributesToCheck[i]]) {
            isComplete = false;
            break;
        }
    }

    return isComplete;
};
