const removeNotifications = () => {
    const notifications = document.querySelectorAll(
        'div[role="alert"][type="toast"] > div > div:nth-child(3)'
    );
    notifications.forEach(button => {
        button.click();
    });
};

export default removeNotifications;
