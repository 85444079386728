export const DO_LOGIN = 'DO_LOGIN';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_DETAILS = 'USER_DETAILS';
export const UPDATE_NOTIFICATION_PREFERENCES =
    'UPDATE_NOTIFICATION_PREFERENCES';

export const LOG_OUT = 'LOG_OUT';
export const CHECK = 'CHECK';
export const CHECK_RECEIVED = 'CHECK_RECEIVED';

export const MILEAGEPLUS_SSO = 'MILEAGEPLUS_SSO';
export const SALESFORCE_SSO = 'SALESFORCE_SSO';

export const UPDATE_LOGIN_PROCESS_STATUS = 'UPDATE_LOGIN_PROCESS_STATUS';
export const UPDATE_MOBILE_ALERT_STATUS = 'UPDATE_MOBILE_ALERT_STATUS';
export const UPDATE_DESKTOP_SCREEN_STATUS = 'UPDATE_DESKTOP_SCREEN_STATUS';