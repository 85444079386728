export const MODULE_ROUTE = "/templates";
export const MODULE_NAME = "BOOKING_TEMPLATES";

export const TEMPLATE_SHOW = "/show";

export const FIELDS = {
    TEMPLATE_NAME: 'template_name',
    TEMPLATE_DESCRIPTION: 'template_description',
    TEMPLATE_ID: 'template_id',
    EDIT_CURRENT: 'edit_current',
};

export const LABELS = {
    TEMPLATE_NAME: 'Template name',
    TEMPLATE_DESCRIPTION: 'Description',
    SAVE_INFO1: 'Your booking will be saved as a template in "My Template" section to be used whenever you want.',
    SAVE_INFO2: 'Please complete the following information:',
    SAVE_TITLE: 'Save as a template',
    UPDATE_TITLE:'Update template',
    EDIT_CURRENT: 'Edit current template',
};
export const FORM_INITIAL_VALUES = {
    [FIELDS.TEMPLATE_NAME]: null,
    [FIELDS.TEMPLATE_DESCRIPTION]: null
}
