import React from 'react';

const ArrowDown = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/arrow-down"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,-1.70530257e-13 C12.5522848,-1.70530257e-13 13,0.44771525 13,1 L13,20.685 L19.8146382,14.2718029 C20.2168119,13.8932864 20.8496864,13.9124644 21.2282029,14.3146382 C21.6067193,14.7168119 21.5875413,15.3496864 21.1853676,15.7282029 L12.6853676,23.7282029 C12.3003176,24.0906029 11.6996882,24.0906029 11.3146382,23.7282029 L2.81463817,15.7282029 C2.41246442,15.3496864 2.39328641,14.7168119 2.77180288,14.3146382 C3.15031935,13.9124644 3.78319382,13.8932864 4.18536757,14.2718029 L11,20.685 L11,1 C11,0.44771525 11.4477153,-1.70530257e-13 12,-1.70530257e-13 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default ArrowDown;
