import React from 'react';

const Favorite = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.3396,3.51236028 L12,3.95571797 L11.6496,3.51236028 C10.4004,1.91627257 8.5296,1 6.4896,1 C2.91,1 0,3.86704643 0,7.39380911 C0,8.93078246 0.5592,10.4180997 1.59,11.5909286 L11.2992,22.6843293 C11.8137817,23.2844849 12.1973267,23.2844849 12.69,22.6843293 L22.4004,11.5909286 C23.43,10.4180997 24,8.93078246 24,7.39380911 C24,3.86704643 21.0792,1 17.4996,1 C15.4704,1 13.59,1.91627257 12.3396,3.51236028 Z"
                    id="path-digital-9"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/favorite"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-9"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-9"></use>
            </g>
        </svg>
    );
};

export default Favorite;
