import React from 'react';

const CircleUprgade = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g
                id="X-Global-Icons/Alert/circle-add"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,6 C11.7238576,6 11.5,6.22385763 11.5,6.5 L11.5,6.5 L11.5,11.5 L6.5,11.5 C6.22385763,11.5 6,11.7238576 6,12 C6,12.2761424 6.22385763,12.5 6.5,12.5 L6.5,12.5 L11.5,12.5 L11.5,17.5 C11.5,17.7761424 11.7238576,18 12,18 C12.2761424,18 12.5,17.7761424 12.5,17.5 L12.5,17.5 L12.5,12.5 L17.5,12.5 C17.7761424,12.5 18,12.2761424 18,12 C18,11.7238576 17.7761424,11.5 17.5,11.5 L17.5,11.5 L12.5,11.5 L12.5,6.5 C12.5,6.22385763 12.2761424,6 12,6 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default CircleUprgade;
