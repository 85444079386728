import React from 'react';

const DownloadFolder = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M8.45261815,2.00038407 C8.58501479,2.0026885 8.60538794,2.0188195 8.748,2.13173653 L8.748,2.13173653 L11.172,4.07185629 L23.4,4.07185629 C23.736,4.07185629 24,4.33532934 24,4.67065868 L24,4.67065868 L24,21.4011976 C24,21.7365269 23.736,22 23.4,22 L23.4,22 L0.6,22 C0.276,22 0,21.7365269 0,21.4011976 L0,21.4011976 L0,2.5988024 C0,2.26347305 0.276,2 0.6,2 L0.6,2 Z M12,8 C11.7383915,8 11.5263159,8.22135461 11.5263159,8.49440936 L11.5263159,8.49440936 L11.526,16.343 L8.29833177,13.1359007 C8.1289955,12.9676712 7.87331513,12.9565555 7.69266168,13.097392 L7.62874869,13.1573183 C7.44945147,13.3584052 7.45853579,13.6748424 7.64903909,13.8641006 L7.64903909,13.8641006 L11.6753537,17.8641 C11.8577457,18.0453 12.1422543,18.0453 12.3246463,17.8641 L12.3246463,17.8641 L16.3509609,13.8641006 C16.5414642,13.6748424 16.5505485,13.3584052 16.3712513,13.1573183 C16.1919541,12.9562315 15.8921715,12.9466425 15.7016682,13.1359007 L15.7016682,13.1359007 L12.473,16.342 L12.4736841,8.49440936 C12.4736841,8.25169403 12.3061182,8.04982819 12.0851453,8.0079656 Z"
                    id="path-digital-7"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/download-folder"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-7"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-7"></use>
            </g>
        </svg>
    );
};

export default DownloadFolder;
