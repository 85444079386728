import React from 'react';

const PriorityDesk = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M21.528,9.38035528 C21.396,4.32131661 17.424,0.5 12,0.5 C6.576,0.5 2.616,4.32131661 2.472,9.38035528 C2.472,9.39637757 1.848,9.39637757 0.6,9.38035528 C0.276,9.38035528 0,9.65673981 0,9.98119122 L0,14.8239289 C0,15.1483804 0.276,15.4247649 0.6,15.4247649 L2.472,15.4247649 C2.472,15.7492163 2.736,16.145768 3.072,16.145768 L5.544,16.145768 C5.868,16.145768 6.144,15.8693835 6.144,15.5449321 L6.144,9.26018809 C6.144,8.93573668 5.868,8.65935214 5.544,8.65935214 L3.708,8.65935214 C4.104,4.58568443 7.464,1.70167189 12,1.70167189 C16.536,1.70167189 19.896,4.58568443 20.292,8.65935214 L18.456,8.65935214 C18.132,8.65935214 17.856,8.93573668 17.856,9.26018809 L17.856,15.5449321 C17.856,15.8693835 18.132,16.145768 18.456,16.145768 L20.268,16.145768 C20.1,17.3234065 19.596,18.4049112 18.744,19.3782654 C17.88,20.3876698 16.62,20.9644723 15.252,21.0726228 C14.832,20.3996865 13.776,19.9310345 12.456,19.9310345 C10.764,19.9310345 9.48,20.7001045 9.48,21.7215256 C9.48,22.73093 10.764,23.5 12.456,23.5 C13.824,23.5 14.892,23.0073145 15.276,22.2983281 C16.992,22.1781609 18.564,21.4211076 19.656,20.1713689 C20.844,18.7894462 21.48,17.1671891 21.528,15.4247649 L23.4,15.4247649 C23.736,15.4247649 24,15.1483804 24,14.8239289 L24,9.98119122 C24,9.65673981 23.736,9.38035528 23.4,9.38035528 C22.152,9.39637757 21.528,9.39637757 21.528,9.38035528 Z"
                    id="path-travel-24"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/priority-desk"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-24"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-24"></use>
            </g>
        </svg>
    );
};

export default PriorityDesk;
