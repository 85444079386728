import { FIELDS, PIECES_EMPTY_LOAD } from 'modules/bookingSteps/constants';
import { CLEAR_FORM } from '../../contactDetails/constants';

export const AIRCRAFT_FILTERS = {
    [FIELDS.FREIGHTER]: true,
    [FIELDS.PASSENGER]: true,
    [FIELDS.TRUCK]: true,
    [FIELDS.WIDEBODY]: true,
    [FIELDS.NARROWBODY]: true,
    [FIELDS.UAX]: false,
};

export const FORM_INITIAL_VALUES_GEN_EXP = {
    ...AIRCRAFT_FILTERS,
    [FIELDS.ACCEPT_TERMS]: false,
    [FIELDS.ACCOUNT_NUMBER]: null,
    [FIELDS.AIRCRAFT_MODE]: null,
    [FIELDS.AIRCRAFT_TYPE]: null,
    [FIELDS.ALLOTMENT]: null,
    [FIELDS.ALLOTMENT_ID]: null,
    [FIELDS.AWB_CAPTURE]: null,
    [FIELDS.AWB_TYPE]: 'assignAWB',
    [FIELDS.BOOKING_DATA]: { is_editable: true },
    [FIELDS.BY_PIECE_PRODUCT_EDIT]: PIECES_EMPTY_LOAD(false),
    [FIELDS.CALLER_NAME]: '',
    [FIELDS.COMMODITY_CODE]: undefined,
    [FIELDS.COMMODITY_DESCRIPTION]: '',
    [FIELDS.CONSIGNEE]: CLEAR_FORM,
    [FIELDS.CONTAINER_LIST]: [],
    [FIELDS.DELIVER_DATE]: null,
    [FIELDS.DEPARTURE_DATE]: null,
    [FIELDS.DESTINATION]: null,
    [FIELDS.FLIGHT]: null,
    [FIELDS.HAS_OVERFLOW_WEIGHT]: false,
    [FIELDS.IS_ALLOTMENT_BOOKING]: false,
    [FIELDS.IS_DANGEROUS_GOODS]: null,
    [FIELDS.IS_RDS]: null,
    [FIELDS.IS_UPLOAD_CSV]: false,
    [FIELDS.MEASURE_BY]: 'pieces',
    [FIELDS.NOTIFICATION_PREFERENCES]: [],
    [FIELDS.ORIGIN]: null,
    [FIELDS.OVERFLOW_WEIGHT]: 0,
    [FIELDS.PACKAGE_LIST]: [],
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT]: 'cbm',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT]: 'kg',
    [FIELDS.PAYMENT_INFO]: 'PP',
    [FIELDS.PRODUCT]: undefined,
    [FIELDS.PRODUCT_TYPE]: 'bulk',
    [FIELDS.REMEMBER_SETTINGS]: false,
    [FIELDS.SHIPPER]: CLEAR_FORM,
    [FIELDS.SHC]: [],
    [FIELDS.SORT]: {
        value: 'Journey',
        field: 'Journey',
        label: 'Fastest',
        order: 'Asc',
    },
    [FIELDS.STOPS]: [0, 1, 2],
    [FIELDS.TEMPLATE_DESCRIPTION]: null,
    [FIELDS.TEMPLATE_ID]: null,
    [FIELDS.TEMPLATE_NAME]: null,
    [FIELDS.TENDER_DATE]: null,
    [FIELDS.VIA]: null,
};
