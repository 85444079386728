import React from 'react';

const YellowWarning = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M13.9231774,2.60060667 C13.7323747,2.26676778 13.1418733,1.5 12.0031498,1.5 C10.8644263,1.5 10.3723263,2.08982137 10.072322,2.60060667 L0.290981076,19.2264914 C-0.588778575,20.8674316 0.662991444,22.5 2.22060887,22.5 L21.7736905,22.5 C22.712491,22.5 24.7509431,21.3843384 23.7045183,19.2264914 L13.9231774,2.60060667 Z M12.0005769,17 C12.6086383,17 13.1005769,17.4920245 13.1005769,18.1001922 C13.1005769,18.7073041 12.6086383,19.1993286 12.0005769,19.1993286 C11.3935711,19.1993286 10.9005769,18.7073041 10.9005769,18.1001922 C10.9005769,17.4920245 11.3935711,17 12.0005769,17 Z M12,7 C12.3865993,7 12.7,7.31340068 12.7,7.7 L12.7,14.3 C12.7,14.6865993 12.3865993,15 12,15 C11.6134007,15 11.3,14.6865993 11.3,14.3 L11.3,7.7 C11.3,7.31340068 11.6134007,7 12,7 Z"
                    id="path-alert-7"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/yellow_warning"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <rect
                    id="Rectangle"
                    fill="#000000"
                    x="9"
                    y="5"
                    width="6"
                    height="16"></rect>
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-7"></use>
                </mask>
                <use
                    id="Icon-color"
                    fill={fill || '#FFC658'}
                    xlinkHref="#path-alert-7"></use>
            </g>
        </svg>
    );
};

export default YellowWarning;
