import React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { setIsDraft } from '../../../actions';
import { MODULE_ROUTE as BOOKING_ROUTE } from 'modules/bookings/constants';
import { Flex } from '@freightos/uikit';
import { CustomIcon } from '../styled';

const GoBackButton = ({ isShowPage, isTemplate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const goBack = () => {
        dispatch(setIsDraft(false));
        if (isTemplate) {
            return dispatch(push('/templates/'));
        } else {
            return dispatch(push(BOOKING_ROUTE));
        }
    };

    const label = !isTemplate ? 'My Bookings' : 'My Templates';

    if (isShowPage) {
        return (
            <Flex alignItems="center">
                <CustomIcon
                    role="button"
                    tabIndex={0}
                    icon="caretLeft"
                    size={35}
                    color={'#006080'}
                    hoverColor={'#0033a0'}
                    onClick={goBack}
                    onKeyUp={v => {
                        if (v.keyCode === 13) {
                            goBack();
                        }
                    }}
                />
                <h5 style={{ marginTop: '12px' }}>{t(label)}</h5>
            </Flex>
        );
    }

    return <></>;
};

export default GoBackButton;
