import moment from 'moment';

const KG_LB_DIFFERENCE = 2.2046226218;
const IN_CM_DIFFERENCE = 2.54;
const CMB_CUIN_DIFFERENCE = 61023.744095;

//WEIGHT
export const weightToKg = weight => {
    if (!weight || weight.value === '') return null;
    if (weight.value === 0) return 0;
    if (!weight.unit) {
        weight.unit = 'kg';
    }
    try {
        let weightToKg =
            weight.unit === 'kg'
                ? weight.value
                : weight.value / KG_LB_DIFFERENCE;
        return isNaN(weightToKg) ? null : parseFloat(weightToKg);
    } catch (err) {
        console.error(err);
    }
};

export const weightToLb = weight => {
    if (!weight || weight.value === '') return null;
    if (weight.value === 0) return 0;
    if (!weight.unit) {
        weight.unit = 'kg';
    }
    try {
        let weightToKg =
            weight.unit === 'kg'
                ? weight.value * KG_LB_DIFFERENCE
                : weight.value;

        return isNaN(weightToKg) ? null : parseFloat(weightToKg);
    } catch (err) {
        console.error(err);
    }
};

//DIMENSIONS
export const dimToCm = dim => {
    if (!dim) return 0;
    let dimension = 0;
    try {
        switch (dim.unit) {
            case 'in':
                dimension = dim.value * IN_CM_DIFFERENCE;
                break;
            default:
                dimension = dim.value;
                break;
        }

        return isNaN(dimension) ? null : parseFloat(dimension);
    } catch (err) {
        console.error(err);
    }
};

export const dimsToCm = dims => {
    let returnObj = {};

    try {
        switch (dims.unit) {
            case 'in':
                returnObj = {
                    length: dimToCm({ value: dims.length, unit: 'in' }),
                    width: dimToCm({ value: dims.width, unit: 'in' }),
                    height: dimToCm({ value: dims.height, unit: 'in' }),
                    unit: 'cm',
                };
                break;
            default:
                returnObj = {
                    length: dims.length || 0,
                    width: dims.width || 0,
                    height: dims.height || 0,
                    unit: 'cm',
                };
                break;
        }

        return isNaN(returnObj.length) ||
            isNaN(returnObj.width) ||
            isNaN(returnObj.height)
            ? null
            : returnObj;
    } catch (err) {
        console.error(err);
    }
};

export const dimToIn = dim => {
    if (!dim) return 0;
    let dimension = 0;
    try {
        switch (dim.unit) {
            case 'cm':
                dimension = dim.value / IN_CM_DIFFERENCE;
                break;
            default:
                dimension = dim.value;
                break;
        }

        return isNaN(dimension) ? null : parseFloat(dimension);
    } catch (err) {
        console.error(err);
    }
};

export const dimsToIn = dims => {
    let returnObj = {};
    try {
        switch (dims.unit) {
            case 'cm':
                returnObj = {
                    length: dimToIn({ value: dims.length, unit: 'cm' }),
                    width: dimToIn({ value: dims.width, unit: 'cm' }),
                    height: dimToIn({ value: dims.height, unit: 'cm' }),
                    unit: 'in',
                };
                break;
            default:
                returnObj = {
                    length: dims.length || 0,
                    width: dims.width || 0,
                    height: dims.height || 0,
                    unit: 'in',
                };
                break;
        }

        return isNaN(returnObj.length) ||
            isNaN(returnObj.width) ||
            isNaN(returnObj.height)
            ? null
            : returnObj;
    } catch (err) {
        console.error(err);
    }
};

export const dimsToVolume = dims => {
    try {
        const volume =
            (dims.width || 0) * (dims.height || 0) * (dims.length || 0);
        if (dims.unit === 'cm') {
            return { value: volume / 1000000, unit: 'cbm' };
        }

        return { value: volume, unit: 'cu in' };
    } catch (err) {
        console.error(err);
    }
};

export const volumeToCbmValue = volume => {
    try {
        if (volume.unit === 'cu in') {
            return parseFloat(volume.value / CMB_CUIN_DIFFERENCE);
        } else {
            return parseFloat(volume.value);
        }
    } catch (err) {
        console.error(err);
    }
};

export const volumeToCuinValue = volume => {
    try {
        if (volume.unit === 'cbm') {
            return parseFloat(volume.value * CMB_CUIN_DIFFERENCE);
        }
        return parseFloat(volume.value);
    } catch (err) {
        console.error(err);
    }
};

//DATES
export const formatDate = date => {
    return date ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
};
