import React from 'react';

const Luggage = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M7.10041489,24 L16.9002051,24 L16.9002051,5.58350792 L7.10041489,5.58350792 L7.10041489,24 Z M0,8.19712099 L0,21.3863869 C0,22.8287941 1.21024867,24 2.69949935,24 L5.860282,24 L5.860282,5.58350792 L2.69949935,5.58350792 C1.65045183,5.58350792 0.740285305,6.16431082 0.290162079,7.00671503 C0.110360791,7.36431682 0,7.76151881 0,8.19712099 Z M21.2992606,5.58350792 L18.139718,5.58350792 L18.139718,24 L21.2992606,24 C22.7897513,24 24,22.8287941 24,21.3863869 L24,8.19712099 C24,7.76151881 23.8896392,7.36431682 23.7098379,7.00671503 C23.2597147,6.16431082 22.3507882,5.58350792 21.2992606,5.58350792 Z M6.57998316,4.13282066 L8.10023405,4.13282066 C8.48959684,2.60281301 10.0904483,1.45080725 12.000062,1.45080725 C13.9109157,1.45080725 15.5105272,2.60281301 15.8998899,4.13282066 L17.4201408,4.13282066 C17.029538,1.79040895 14.7504017,0 12.000062,0 C9.24972229,0 6.96934595,1.79040895 6.57998316,4.13282066 Z"
                    id="path-travel-16"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/luggage"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-16"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-16"></use>
            </g>
        </svg>
    );
};

export default Luggage;
