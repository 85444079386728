import { FIELDS } from 'modules/bookingSteps/constants';
import { FORM_INITIAL_VALUES_QPK } from './QPK';
import { FORM_INITIAL_VALUES_GEN_EXP } from './GCR_EXP';
import { FORM_INITIAL_VALUES_LFG, FORM_INITIAL_VALUES_LXL } from './Lifeguard';
import { GCR, EXP, QPK, QPI, LFG, LXL } from './productCodes';

export const generateInitialValues = (product, defaultCommodity = null) => {
    let initialValues;
    product = product || GCR;

    switch (product) {
        case GCR:
        case EXP:
            initialValues = FORM_INITIAL_VALUES_GEN_EXP;
            break;
        case QPK:
        case QPI:
            initialValues = FORM_INITIAL_VALUES_QPK;
            break;
        case LFG:
            initialValues = {
                ...FORM_INITIAL_VALUES_LFG,
                [FIELDS.COMMODITY_CODE]: defaultCommodity,
            };
            break;
        case LXL:
            initialValues = {
                ...FORM_INITIAL_VALUES_LXL,
                [FIELDS.COMMODITY_CODE]: defaultCommodity,
            };
            break;
        default:
            initialValues = FORM_INITIAL_VALUES_GEN_EXP;
            break;
    }

    return initialValues;
};
