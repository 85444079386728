import React from 'react';

const SocialDistance = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/CleanPlus/social-distance"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Mask"
                    transform="translate(0.000017, 3.000133)"
                    fill={fill || '#002244'}>
                    <path
                        d="M5.88833333,3.49536667 L5.88833333,3.4962 L1.07716667,3.4962 C0.484333333,3.4962 3.37641026e-12,3.9797 3.37641026e-12,4.5752 L3.37641026e-12,10.2193667 C0.0496666667,10.7583667 0.3175,10.9268667 0.709166667,10.9268667 C1.08816667,10.9268667 1.31133333,10.6068667 1.31133333,10.2193667 L1.298,5.85536667 L1.74016667,5.85536667 L1.74016667,17.1017 C1.741,17.4883667 2.0535,17.8018667 2.44,17.8042 C2.83,17.8042 3.15016667,17.4857 3.15016667,17.1017 L3.15016667,10.8318667 L3.845,10.8318667 L3.845,17.1017 C3.845,17.4895333 4.15933333,17.8038667 4.547,17.8038667 C4.93466667,17.8038667 5.24916667,17.4895333 5.24916667,17.1017 L5.24916667,5.85536667 L5.6955,5.85536667 L5.6955,10.2370333 C5.6955,10.6203667 5.89683333,10.9445333 6.28516667,10.9445333 C6.67166667,10.9445333 6.9885,10.7593667 6.9885,10.2370333 L6.9885,4.61803333 C6.9885,3.86686667 6.43083333,3.49536667 5.88833333,3.49536667"
                        id="Fill-1"></path>
                    <path
                        d="M3.46848333,3.05233333 C4.31131667,3.05233333 4.99481667,2.369 4.99481667,1.52616667 C4.99481667,0.683166667 4.31131667,7.83817455e-13 3.46848333,7.83817455e-13 C2.62548333,7.83817455e-13 1.94231667,0.683166667 1.94231667,1.52616667 C1.94231667,2.369 2.62548333,3.05233333 3.46848333,3.05233333"
                        id="Fill-3"></path>
                    <path
                        d="M20.48005,3.05233333 C21.32305,3.05233333 22.0062167,2.369 22.0062167,1.52616667 C22.0062167,0.683166667 21.32305,7.83817455e-13 20.48005,7.83817455e-13 C19.6372167,7.83817455e-13 18.9538833,0.683166667 18.9538833,1.52616667 C18.9538833,2.369 19.6372167,3.05233333 20.48005,3.05233333"
                        id="Fill-5"></path>
                    <path
                        d="M22.8999667,3.49536667 L22.8999667,3.4962 L18.0888,3.4962 C17.4958,3.4962 17.0114667,3.9797 17.0114667,4.5752 L17.0114667,10.2193667 C17.0613,10.7583667 17.3291333,10.9268667 17.7206333,10.9268667 C18.0998,10.9268667 18.3229667,10.6068667 18.3229667,10.2193667 L18.3094667,5.85536667 L18.7516333,5.85536667 L18.7516333,17.1017 C18.7526333,17.4883667 19.0651333,17.8018667 19.4516333,17.8042 C19.8416333,17.8042 20.1616333,17.4857 20.1616333,17.1017 L20.1616333,10.8318667 L20.8566333,10.8318667 L20.8566333,17.1017 C20.8566333,17.4895333 21.1708,17.8038667 21.5586333,17.8038667 C21.9463,17.8038667 22.2606333,17.4895333 22.2606333,17.1017 L22.2606333,5.85536667 L22.7069667,5.85536667 L22.7069667,10.2370333 C22.7069667,10.6203667 22.9083,10.9445333 23.2966333,10.9445333 C23.6833,10.9445333 23.9999667,10.7593667 23.9999667,10.2370333 L23.9999667,4.61803333 C23.9999667,3.86686667 23.4423,3.49536667 22.8999667,3.49536667"
                        id="Fill-7"></path>
                    <path
                        d="M15.2189,8.61223333 L14.1210667,7.51423333 C13.9624,7.35556667 13.7052333,7.35556667 13.5465667,7.51423333 C13.3879,7.6729 13.3879,7.93006667 13.5465667,8.08873333 L13.9524,8.4949 L9.89973333,8.4949 C9.89656667,8.4949 9.89406667,8.49656667 9.8909,8.49673333 L9.73656667,8.49673333 L10.1445667,8.08873333 C10.3032333,7.93006667 10.3032333,7.6729 10.1445667,7.51423333 C9.9859,7.35556667 9.72873333,7.35556667 9.57006667,7.51423333 L8.46956667,8.61473333 C8.44106667,8.64306667 8.42373333,8.67756667 8.40556667,8.71106667 C8.3989,8.72323333 8.38806667,8.73273333 8.38273333,8.74556667 C8.36173333,8.79573333 8.35056667,8.84856667 8.35056667,8.9019 C8.35056667,8.95506667 8.3614,9.00773333 8.38206667,9.05756667 C8.40306667,9.1084 8.43373333,9.15373333 8.47223333,9.1919 L9.57006667,10.2897333 C9.72873333,10.4485667 9.9859,10.4485667 10.1445667,10.2897333 C10.3032333,10.1310667 10.3032333,9.87406667 10.1445667,9.71523333 L9.73856667,9.30923333 L13.7914,9.30923333 C13.7944,9.30923333 13.7970667,9.30756667 13.8000667,9.3074 L13.9545667,9.3074 L13.5465667,9.71523333 C13.3879,9.87406667 13.3879,10.1310667 13.5465667,10.2897333 C13.7052333,10.4485667 13.9624,10.4485667 14.1210667,10.2897333 L15.2215667,9.1894 C15.2500667,9.16106667 15.2674,9.12656667 15.2855667,9.09306667 C15.2922333,9.08106667 15.3029,9.0714 15.3084,9.05873333 C15.3292333,9.0084 15.3404,8.95556667 15.3404,8.90223333 C15.3404,8.84923333 15.3295667,8.7964 15.3090667,8.74673333 C15.2880667,8.6959 15.2574,8.6504 15.2189,8.61223333"
                        id="Fill-9"></path>
                </g>
            </g>
        </svg>
    );
};

export default SocialDistance;
