import { generateSchema } from 'modules/bookingSteps/schema';
import { buildErrors } from 'basic_components/jsTools';

export const validateStep1 = async (route, product, values, setErrors) => {
    const validationResult = await checkStep1Schema(route, product, values);
    if (validationResult === true) {
        return [];
    } else if (validationResult?.errors) {
        const errors = buildErrors(validationResult.inner);
        setErrors(errors);
        return errors;
    }
};

const checkStep1Schema = (route, product, values) => {
    const step1Schema = generateSchema(route, product);
    return step1Schema
        .validate(values, {
            abortEarly: false,
        })
        .then(valid => {
            return true;
        })
        .catch(errors => {
            return errors;
        });
};
