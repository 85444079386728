import { generateShipmentSchema } from './components/shipmentDetails/schema';
import { generateContactSchemas } from './components/contactDetails/schema';
import { generateNotificationSchema } from './components/notificationPreferences/schema';
import { GCR } from './components/common/initialValuesByProductType/productCodes';

export const generateSchema = (section, productObject) => {
    let schema;
    const product = productObject?.code || GCR;

    switch (section) {
        case '/bookingsteps/details':
            schema = generateShipmentSchema(product, productObject);
            break;
        case '/bookingsteps/flights':
            schema = null;
            break;
        case '/bookingsteps/contact':
            schema = generateContactSchemas(product);
            break;
        case '/bookingsteps/notification':
            schema = generateNotificationSchema(product);
            break;
        default:
            schema = null;
            break;
    }

    return schema;
};
