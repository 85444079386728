import React from 'react';

const UpgradeMixed = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Upgrade/upgrade-mixed"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L20,0 Z M8.80289594,17.7927779 L7.58834457,17.7927779 L7.58834457,20 L8.80289594,20 L8.80289594,17.7927779 Z M16.4051778,17.7927779 L15.1906265,17.7927779 L15.1906265,20 L16.4051778,20 L16.4051778,17.7927779 Z M15.9533647,14.6058661 L8.04663529,14.6058661 C6.80536378,14.6058661 5.72036456,15.5610756 5.71064815,16.7874879 L5.71064815,16.8130881 L18.2893519,16.8130881 L18.2893519,16.7874879 C18.2796354,15.5610756 17.1938265,14.6058661 15.9533647,14.6058661 Z M14.184654,4 L9.8090303,4 C8.56613939,4 7.54834534,4.99920999 7.54834534,6.23362234 L7.54834534,10.8632686 L5.77671974,10.8632686 L5.77671974,12.0624806 L7.54834534,12.0624806 L7.54834534,13.5608956 L16.445339,13.5608956 L16.445339,12.0664807 L18.2169646,12.0664807 L18.2169646,10.8632686 L16.445339,10.8632686 L16.445339,6.23362234 C16.445339,4.99920999 15.4267352,4 14.184654,4 Z"
                    id="Combined-Shape"
                    fill={fill || '#796E65'}></path>
            </g>
        </svg>
    );
};

export default UpgradeMixed;
