import * as c from './actionTypes';

export const search = filters => ({ type: c.SEARCH, filters });
export const selectTemplate = data => ({ type: c.SELECT_TEMPLATE, data });
export const viewTemplate = id => ({ type: c.VIEW_TEMPLATE, id });
export const utilizeTemplate = (id, reset) => ({
    type: c.USE_TEMPLATE,
    id,
    reset,
});
export const saveTemplate = template => ({
    type: c.SAVE_TEMPLATE,
    template,
});
export const updateTemplate = template => ({
    type: c.UPDATE_TEMPLATE,
    template,
});
export const deleteTemplate = (id, table) => ({ type: c.DELETE, id, table });
