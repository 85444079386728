import React from 'react';

const Bundle = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M5.04246848,4.932 L10.2262774,4.932 C9.77239549,5.52 9.46184472,6.216 9.35434638,6.984 L7.08493696,6.984 L5.04246848,4.932 Z M13.3198407,3.288 C12.734572,3.324 12.185136,3.48 11.6954214,3.732 L7.3118779,3.732 C7.58659589,2.292 8.86463172,1.2 10.3815528,1.2 C11.7312541,1.2 12.8898474,2.064 13.3198407,3.288 Z M16.6881221,6.984 L10.5487724,6.984 C10.8354346,5.544 12.1015262,4.452 13.6184472,4.452 C15.1473125,4.452 16.4134041,5.544 16.6881221,6.984 Z M14.4545455,15.288 L14.2992701,18.54 L13.8214997,18.816 L13.0809555,16.08 L11.0145985,17.28 L10.5726609,18.324 L10.0948905,18.6 L10.2023889,16.92 L8.70935634,16.188 L9.18712674,15.912 L10.3696085,16.044 L12.3881885,14.88 L10.4054413,12.864 L10.8832117,12.588 L13.7617784,14.088 L16.8911745,12.264 C17.2136695,12.072 17.6436629,12.192 17.8347711,12.528 C18.0258792,12.864 17.9064366,13.284 17.5839416,13.476 L14.4545455,15.288 Z M18.9575315,6.984 L17.8825481,6.984 C17.7989383,6.372 17.5958859,5.796 17.2733908,5.292 L18.9575315,6.984 Z M20.8208361,7.164 L17.5839416,3.912 C17.464499,3.78 17.2972794,3.732 17.142004,3.732 L15.5534174,3.732 C15.2428666,3.576 14.9203716,3.444 14.5739881,3.372 C14.1320504,1.428 12.412077,0 10.3815528,0 C8.19575315,0 6.41605839,1.632 6.11745189,3.732 L3.59721301,3.732 C3.20305242,3.732 3,4.08 3,4.332 L3,20.148 C3,20.328 3.07166556,20.472 3.20305242,20.592 L6.41605839,23.82 C6.51564026,23.9377594 6.66394043,24 6.8341075,24 L20.402787,24 C20.7372263,24 21,23.736 21,23.4 L21,7.584 C21,7.43865967 20.9435425,7.26475525 20.8208361,7.164 Z"
                    id="path-digital-1"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/bundle"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-1"></use>
                </mask>
                <use
                    id="Mask-Copy-2"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-1"></use>
            </g>
        </svg>
    );
};

export default Bundle;
