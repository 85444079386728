// React
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { formatPackageInOneObject } from 'basic_components/sagaFormatters';
import isObject from 'lodash/isObject';

// Constants
import { FIELDS } from 'modules/bookingSteps/constants';
import {
    DETAILS_ROUTE,
    RATES_ROUTE,
    CONTACTS_ROUTE,
    NOTIFICATION_ROUTE,
    REVIEW_ROUTE,
    topProductErrors,
} from 'modules/bookingSteps/constants';

import { FORMS } from '../contactDetails/constants';

// Actions
import {
    search,
    setForm,
    userReceived,
    saveBooking,
    updateBooking,
    createContact,
    modifyContact,
    updatePartial,
    checkExtraEmbargoes,
    isApiResponseLoading as apiResponseLoadingAction,
} from '../../actions';

import { openModal } from 'basic_components/Modal';
import { isDomesticIataRestriction } from 'basic_components/Validations';
import { isRestrictedProductDG } from '../../utils/isRestrictedProductDG';

// Components
import { AlertAtmosNotification } from 'basic_components/AlertAtmosNotification/AlertAtmosNotification';
import ActionButtons from './formActions/ActionButtons';
import removeNotifications from './formActions/removeNotifications';
import handleNotifications from './formActions/handleNotifications';
import { validateStep1 } from './formActions/validateStep1';
import { isOverwrittenRoute } from '../../../../basic_components/jsTools';
import jwt_token from 'jwt-decode';
import { Box } from '@freightos/uikit';
import { volumeToCbmValue, weightToKg } from 'basic_components/conversions';

function FormActions() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.bookingSteps.status);
    const notificationsPreferencesStructure = useSelector(
        state => state.bookingSteps.notificationsPreferencesStructure
    );
    const agent = useSelector(state => state?.bookingSteps?.form?.agent);
    const bookingProduct = useSelector(
        state => state.bookingSteps.bookingProduct
    );
    const {
        values,
        validateForm,
        setFieldValue,
        setErrors,
    } = useFormikContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleNext = async () => {
        removeNotifications();
        switch (pathname) {
            case DETAILS_ROUTE:
                dispatch(apiResponseLoadingAction(true));

                const detailsErrors = await validateStep1(
                    DETAILS_ROUTE,
                    bookingProduct,
                    values,
                    setErrors
                );

                if (!_.isEmpty(detailsErrors)) {
                    let alert = 'div[role="alert"]';
                    const includeTopErrors = Object.keys(
                        detailsErrors
                    ).some(errorId => topProductErrors.includes(errorId));
                    if (
                        !includeTopErrors &&
                        (detailsErrors['package_list'] ||
                            detailsErrors['container_list'] ||
                            detailsErrors['package_total_shipment_pieces'] ||
                            detailsErrors['package_total_shipment_volume'] ||
                            detailsErrors['package_total_shipment_weight'])
                    ) {
                        alert = 'div[role="alert-packages"]';
                    }
                    if (document.querySelectorAll(alert)[0]) {
                        document.querySelectorAll(alert)[0].scrollIntoView();
                        setTimeout(() => {
                            window.scrollBy(0, -250);
                        }, 0);
                    }
                    dispatch(apiResponseLoadingAction(false));
                } else if (_.isEmpty(detailsErrors)) {
                    if (
                        isDomesticIataRestriction(
                            values.origin,
                            values.destination,
                            values.account_number
                        )
                    ) {
                        dispatch(apiResponseLoadingAction(false));
                        return dispatch(
                            openModal('isDomesticIATA', '', '', 800, true)
                        );
                    }
                    if (values[FIELDS.ALLOTMENT]) {
                        const formatPackage = formatPackageInOneObject(values);
                        let allotmentWeight =
                            values[FIELDS.ALLOTMENT]?.segments[0]
                                .remainig_capacity?.weight?.amount;
                        const packageWeight =
                            formatPackage?.returnedObject?.totalWeight;
                        const weightUnit =
                            formatPackage?.returnedObject?.weightUnit;
                        const dimensionsUnit =
                            formatPackage?.returnedObject?.volumeUnit;
                        const dimensionsValue =
                            formatPackage?.returnedObject?.volume;

                        const dimensionInCm = volumeToCbmValue({
                            value: dimensionsValue,
                            unit: dimensionsUnit,
                        });
                        const packageWeightInKg = weightToKg({
                            value: packageWeight,
                            unit: weightUnit,
                        });

                        if (packageWeightInKg > allotmentWeight) {
                            dispatch(apiResponseLoadingAction(false));
                            AlertAtmosNotification(
                                <span>
                                    {t(
                                        'Packages exceeds max weight of allotment'
                                    )}
                                </span>,
                                'warning'
                            );
                            break;
                        }

                        let allotmentVolume =
                            values[FIELDS.ALLOTMENT]?.segments[0]
                                .remainig_capacity?.volume?.amount;
                        if (dimensionInCm > allotmentVolume) {
                            dispatch(apiResponseLoadingAction(false));
                            AlertAtmosNotification(
                                <span>
                                    {t(
                                        'Packages exceeds max volume of allotment'
                                    )}
                                </span>,
                                'warning'
                            );
                            break;
                        }
                    }
                    dispatch(search({ ...values, agent }, true, false));
                }
                break;
            case RATES_ROUTE:
                if (values[FIELDS.FLIGHT]) {
                    const routeToCheck = isObject(values[FIELDS.FLIGHT])
                        ? values[FIELDS.FLIGHT]
                        : jwt_token(values[FIELDS.FLIGHT])?.data?.data;

                    if (isOverwrittenRoute(routeToCheck)) {
                        dispatch(
                            openModal(
                                'confirmWithOverwritten',
                                'normal',
                                <Box
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1em',
                                        lineHeight: '1.4em',
                                    }}>
                                    {t(
                                        'Are you sure you want to proceed with this operation and override the following restrictions?'
                                    )}
                                </Box>,
                                600,
                                false
                            )
                        );
                    } else {
                        dispatch(push(CONTACTS_ROUTE));
                        dispatch(setForm(values));
                    }
                } else {
                    AlertAtmosNotification(
                        <span>{t('Please select a flight')}</span>
                    );
                }
                break;
            case CONTACTS_ROUTE:
                if (agent?.isCompleteUser) {
                    // validate if all agent info is complete
                    await setFieldValue('contactValidation', false);
                } else {
                    await setFieldValue('contactValidation', true);
                }

                const contactsErrors = await validateForm();
                if (!_.isEmpty(contactsErrors)) {
                    if (document.querySelectorAll('div[role="alert"]')[0]) {
                        document
                            .querySelectorAll('div[role="alert"]')[0]
                            .scrollIntoView();
                        setTimeout(() => {
                            window.scrollBy(0, -200);
                        }, 0);
                    }
                } else if (_.isEmpty(contactsErrors)) {
                    FORMS.map(form => {
                        dispatch(userReceived(values[form], form));
                        if (values[form]?.save) {
                            const contact_type = {
                                value: form === 'shipper' ? 'SHI' : 'CON',
                            };
                            if (values[form]?.id) {
                                dispatch(
                                    modifyContact(
                                        {
                                            ...values[form],
                                            type_of_contact: contact_type,
                                            account_number: values[form]
                                                .account_number_id
                                                ? values[form]
                                                : values[FIELDS.ACCOUNT_NUMBER],
                                        },
                                        form
                                    )
                                );
                            } else {
                                dispatch(
                                    createContact(
                                        {
                                            ...values[form],
                                            type_of_contact: contact_type,
                                            account_number: values[form]
                                                .account_number_id
                                                ? values[form]
                                                : values[FIELDS.ACCOUNT_NUMBER],
                                        },
                                        form
                                    )
                                );
                            }
                        }
                    });
                    dispatch(setForm(values));
                    await dispatch(checkExtraEmbargoes(values));
                }

                break;
            case NOTIFICATION_ROUTE:
                const validation = await handleNotifications(
                    dispatch,
                    setFieldValue,
                    validateForm,
                    notificationsPreferencesStructure
                );
                if (validation) {
                    dispatch(push(REVIEW_ROUTE));
                }
                break;
            case REVIEW_ROUTE:
                if (
                    status === 'update_booking' &&
                    typeof values.flight !== 'string'
                ) {
                    dispatch(updatePartial(values));
                } else {
                    !values[FIELDS.BOOKING_DATA]?.booking_status
                        ? dispatch(saveBooking(values))
                        : dispatch(updateBooking(values));
                }
                break;
            default:
                break;
        }
    };
    return <ActionButtons handleNext={handleNext} />;
}

export default FormActions;
