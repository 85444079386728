import React from 'react';

const Rating = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M22.9014766,8.41941392 L15.4978321,8.27498692 L13.061949,1.24620617 C12.9059565,0.788854003 12.4859767,0.5 11.9940003,0.5 C11.5140233,0.5 11.1060429,0.788854003 10.938051,1.24620617 L8.50216793,8.27498692 L1.09852337,8.41941392 C0.618546415,8.4314495 0.210566002,8.73233909 0.0545734912,9.18969126 C-0.0894195958,9.65907902 0.0545734912,10.1525379 0.45055448,10.4413919 L6.35427105,14.9306646 L4.20637417,22.0436944 C4.09837935,22.3927263 4.03414917,22.8295593 4.55216823,23.2265483 C5.07018729,23.6235372 5.730301,23.4277865 5.92229179,23.2953951 L11.9940003,19.0468341 L18.0777082,23.2953951 C18.269699,23.4277865 19.0172026,23.603121 19.4594116,23.2265483 C19.9016206,22.8499756 19.9016206,22.3927263 19.7936258,22.0436944 L17.645729,14.9306646 L23.5494455,10.4413919 C23.9454265,10.1525379 24.0894196,9.65907902 23.9454265,9.18969126 C23.789434,8.73233909 23.3814536,8.4314495 22.9014766,8.41941392 Z"
                    id="path-digital-20"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/rating"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-20"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-20"></use>
            </g>
        </svg>
    );
};

export default Rating;
