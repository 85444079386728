import React from 'react';

const Change = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/change"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M20.1308058,2.19649362 L23.7671627,5.90236701 C24.0776124,6.21875168 24.0776124,6.78124832 23.7671627,7.09763299 L20.1308058,10.8035064 C19.8474177,11.092312 19.4166713,11.0588658 19.1687067,10.7288023 C18.9207422,10.3987387 18.9494586,9.89704604 19.2328467,9.6082404 L21.2792666,7.52212067 C17.3319095,7.71791725 14.1132918,9.20343709 11.5685997,11.9983598 C14.1122752,14.7957565 17.3308171,16.2815922 21.2781278,16.4776415 L19.2328467,14.3917596 C18.9494586,14.102954 18.9207422,13.6012613 19.1687067,13.2711977 C19.4166713,12.9411342 19.8474177,12.907688 20.1308058,13.1964936 L23.7671627,16.902367 C24.0776124,17.2187517 24.0776124,17.7812483 23.7671627,18.097633 L20.1308058,21.8035064 C19.8474177,22.092312 19.4166713,22.0588658 19.1687067,21.7288023 C18.9207422,21.3987387 18.9494586,20.897046 19.2328467,20.6082404 L21.3196175,18.4816427 C16.8729221,18.2923642 13.1677494,16.6397937 10.2575318,13.5431146 C7.33265275,16.9043426 4.31687298,18.897647 1.19621097,19.4824201 C0.648820596,19.5849943 0.121197854,19.2282214 0.017732596,18.6855456 C-0.0857326621,18.1428698 0.274139752,17.6197914 0.82153013,17.5172173 C3.50870898,17.0136735 6.21732357,15.1897125 8.93247125,12.0016863 C6.2178881,8.81048638 3.50898901,6.98619766 0.82153013,6.48260141 C0.274139752,6.38002728 -0.0857326621,5.85694889 0.017732596,5.31427312 C0.121197854,4.77159735 0.648820596,4.41482447 1.19621097,4.51739859 C4.31687298,5.1021717 7.33265275,7.0954761 10.2574757,10.4565261 C13.1676682,7.36009704 16.8727237,5.70754222 21.3192608,5.51819121 L19.2328467,3.3917596 C18.9494586,3.10295396 18.9207422,2.60126133 19.1687067,2.27119774 C19.4166713,1.94113416 19.8474177,1.90768798 20.1308058,2.19649362 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Change;
