import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
    FIELDS as SHIPMENT_FIELDS,
    LABELS as SHIPMENT_LABELS,
    ERROR_LABELS as SHIPMENT_ERRORS,
} from './components/shipmentDetails/constants';

import {
    FIELDS as FLIGHTS_FIELDS,
    LABELS as FLIGHTS_LABELS,
    ERROR_LABELS as FLIGHTS_ERRORS,
} from './components/flightsAndRates/constants';

import {
    FIELDS as CONTACT_FIELDS,
    LABELS as CONTACT_LABELS,
    ERROR_LABELS as CONTACT_ERRORS,
} from './components/contactDetails/constants';

import {
    FIELDS as NOTIFICATION_FIELDS,
    LABELS as NOTIFICATION_LABELS,
    ERROR_LABELS as NOTIFICATION_ERRORS,
} from './components/notificationPreferences/constants';

import {
    FIELDS as REVIEW_FIELDS,
    LABELS as REVIEW_LABELS,
    ERROR_LABELS as REVIEW_ERRORS,
} from './components/reviewAndBook/constants';

export const MODULE_ROUTE = '/bookingsteps';

export const MODULE_NAME = 'BOOKINGSTEPS';

export const ROUTES_CONSTANTS = {
    SHIPMENT_DETAILS: 'details',
    FLIGHTS_RATES: 'flights',
    CONTACT_DETAILS: 'contact',
    NOTIFICATION_PREFERENCES: 'notification',
    REVIEW_BOOK: 'review',
};

export const DETAILS_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.SHIPMENT_DETAILS}`;
export const RATES_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.FLIGHTS_RATES}`;
export const CONTACTS_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.CONTACT_DETAILS}`;
export const NOTIFICATION_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.NOTIFICATION_PREFERENCES}`;
export const REVIEW_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.REVIEW_BOOK}`;

export const FIELDS = {
    ...SHIPMENT_FIELDS,
    ...FLIGHTS_FIELDS,
    ...CONTACT_FIELDS,
    ...NOTIFICATION_FIELDS,
    ...REVIEW_FIELDS,
};
export const LABELS = {
    ...SHIPMENT_LABELS,
    ...FLIGHTS_LABELS,
    ...CONTACT_LABELS,
    ...NOTIFICATION_LABELS,
    ...REVIEW_LABELS,
};

export const ERROR_LABELS = {
    ...SHIPMENT_ERRORS,
    ...FLIGHTS_ERRORS,
    ...CONTACT_ERRORS,
    ...NOTIFICATION_ERRORS,
    ...REVIEW_ERRORS,
};

export const EMPTY_NOTIFICATION = {
    email: { value: null, used: false },
    phone: { value: null, used: false },
    notifications: [],
    error: null,
};

export const StopsOptions = () => {
    const { t } = useTranslation();

    return [
        { value: 0, label: t('Direct') },
        { value: 1, label: t('1 stop') },
        { value: 2, label: t('2+ stops') },
    ];
};

export const SortOptions = () => {
    const { t } = useTranslation();

    return [
        {
            value: 'Price',
            field: 'Price',
            label: t('Price (lowest to greatest)'),
            order: 'Asc',
        },
        {
            value: 'Journey',
            field: 'Journey',
            label: t('Fastest'),
            order: 'Asc',
        },
        {
            value: 'Departure',
            field: 'Departure',
            label: t('Earliest pick-up time'),
            order: 'Asc',
        },
        {
            value: 'Departure_Latest',
            field: 'Departure_Latest',
            label: t('Latest pick-up time'),
            order: 'Desc',
        },
    ];
};

export const AircraftOptions = () => {
    const { t } = useTranslation();

    return [
        { value: 'passenger', label: t('Passenger') },
        { value: 'freighter', label: t('Freighter') },
        { value: 'truck', label: t('Truck') },
        { value: 'widebody', label: t('WideBody') },
        { value: 'uax', label: t('UAX') },
    ];
};

export const ProductOptions = async () => {
    const {
        response: { data },
    } = await axios.get('/cargo/products/select2/n');
    return data;
};

export const prePaidOption = { value: 'PP', label: 'Pre-paid' };

// export const PaymentOptions = ({ isBLS, onlyPrepaid }) => {
//     const { t } = useTranslation();
//     const prepaid = {
//         value: prePaidOption.value,
//         label: t(prePaidOption.label),
//     };
//     const changesCollect = {
//         value: 'CC',
//         label: t('Collect charges at tender'),
//     };

//     return isBLS || onlyPrepaid ? [prepaid] : [prepaid, changesCollect];
// };

export const PaymentOptions = () => {
    const { t } = useTranslation();
    const prepaid = {
        value: prePaidOption.value,
        label: t(prePaidOption.label),
    };
    return [prepaid];
};
export const AWBTypeOptions = productConfig => {
    const { t } = useTranslation();
    if (productConfig) {
        switch (productConfig) {
            case 1:
                return [
                    {
                        value: 'assignAWB',
                        label: t(
                            'Assign an air waybill number to this shipment'
                        ),
                    },
                    {
                        value: 'haveAWBNumber',
                        label: t('I have an air waybill number'),
                    },
                    {
                        value: 'laterAWB',
                        label: t('I will provide an air waybill number later'),
                    },
                ];
            case 2:
                return [
                    {
                        value: 'haveAWBNumber',
                        label: t('I have an air waybill number'),
                    },
                    {
                        value: 'laterAWB',
                        label: t('I will provide an air waybill number later'),
                    },
                ];

            case 3:
                return [
                    {
                        value: 'assignAWB',
                        label: t(
                            'Assign an air waybill number to this shipment'
                        ),
                    },
                ];
        }
    } else {
        return [
            {
                value: 'assignAWB',
                label: t('Assign an air waybill number to this shipment'),
            },
            {
                value: 'haveAWBNumber',
                label: t('I have an air waybill number'),
            },
            {
                value: 'laterAWB',
                label: t('I will provide an air waybill number later'),
            },
        ];
    }
};

export const weightUnitOptions = () => {
    return [
        { value: 'kg', label: 'kg' },
        { value: 'lb', label: 'lb' },
    ];
};

export const dimensionsUnitOptions = () => {
    return [
        { value: 'cm', label: 'cm' },
        { value: 'in', label: 'in' },
    ];
};

export const weightUnitOptionsDinamically = (products, OnEdit) => {
    if (
        !products ||
        (Array.isArray(products) && products.length === 0) ||
        (Array.isArray(products) && products.length === 1 && OnEdit)
    ) {
        return [
            { value: 'kg', label: 'kg' },
            { value: 'lb', label: 'lb' },
        ];
    }
    return [{ value: products[0].weightUnit, label: products[0].weightUnit }];
};

export const volumeUnitOptions = () => {
    return [
        { value: 'cbm', label: 'cbm' },
        { value: 'cu in', label: 'cu in' },
    ];
};

export const PIECES_EMPTY_LOAD = (removeExtraPackageDetails, dryIce) => {
    const extraDetails = {
        stackable: !removeExtraPackageDetails || undefined,
    };
    const dryIceObj = dryIce
        ? { dry_ice_weight: '', dry_ice_weightUnit: 'kg' }
        : {};
    return {
        pieces: null,
        width: '',
        height: '',
        length: '',
        dimsUnit: 'cm',
        weight: '',
        weightUnit: '',
        ...extraDetails,
        ...dryIceObj,
    };
};

export const movementStatusOptions = [
    'Preflight',
    'In transit',
    //'Transfered',
    'Arrived',
    'Delivered',
];

export const validStatusSaveForLaterShow = [
    'booking',
    'update_draft',
    'use_template',
];
export const validStatusSaveAsTemplateShow = [
    'booking',
    'use_template',
    'update_draft',
];
export const updateStatus = ['update_booking'];
export const validPartialStepsEdit = ['show', 'update_booking'];

export const UNITED_TERMS_AND_CONDITIONS =
    'https://www.unitedcargo.com/en/us/terms-and-conditions.html';
export const topProductErrors = [
    FIELDS.COMMODITY_CODE,
    FIELDS.COMMODITY_DESCRIPTION,
    FIELDS.ACCOUNT_NUMBER,
    FIELDS.PRODUCT,
    FIELDS.ORIGIN,
    FIELDS.DESTINATION,
    FIELDS.DEPARTURE_DATE,
];

export const handlingInformationObject = {
    [FIELDS.HANDLING_TYPE]: null,
    [FIELDS.HANDLING_REMARKS]: '',
};
export const initialValuesAWB = originCurrency => {
    return {
        [FIELDS.DOCUMENTATION]: '',
        [FIELDS.COUNTRY]: null,
        [FIELDS.CUSTOM_IDENTIFIER]: null,
        [FIELDS.INFO_IDENTIFIER]: null,
        [FIELDS.SUPPLEMENTAL_CUSTOM]: '',
        [FIELDS.VALUE_CARRIAGE]: { amount: 0, currency: originCurrency },
        [FIELDS.VALUE_CUSTOM]: { amount: 0, currency: originCurrency },
        [FIELDS.NO_CARRIAGE]: false,
        [FIELDS.NO_CUSTOM]: false,
        [FIELDS.ACCOUNTING_CODE]: null,
        [FIELDS.ACCOUNTING_DESCRIPTION]: '',
        [FIELDS.HANDLING_INFO]: [handlingInformationObject],
        [FIELDS.SCI]: null,
    };
};

export const optionsDocumentationAwb = [
    { value: FIELDS.EAP, label: LABELS.EAP },
    { value: FIELDS.EAW, label: LABELS.EAW },
];

export const ORIGIN_COUNTRY_ERROR = agent => {
    return `Origin station must be in the same country (${agent?.country?.label}) as the selected agent account`;
};

export const LINEAR_ERROR =
    'Exceeds max linear volume of 228.6 cm / 90 inches.';
