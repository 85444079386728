// React
import React from 'react';

// Constants

// Actions

// Components

// Styles
// import './fonts.css';
import './AntdMin.min.css';
import './HeaderAndFooter.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function Footer() {
    const { t } = useTranslation();
    const {
        contact_us: contactLink,
        billing: billingLink,
        claims: claimsLink,
        faqs: faqsLink,
        rules: rulesLink,
        terms: termsLink,
        privacy: privacyLink,
        register: registerLink,
        flight_status: flightStatusLink,
        sign_up: signUpLink,
        sitemap: sitemapLink,
        united: unitedLink,
        careers: careersLink,
    } = JSON.parse(process.env.REACT_APP_FOOTER_LINKS);

    return (
        <footer
            role="navigation"
            aria-label="Footer Navigation"
            className="footer-container">
            <div className="container">
                <div
                    className="ant-collapse ant-collapse-borderless ant-collapse-icon-position-right collapse"
                    role="tablist">
                    <div
                        className="ant-collapse-item ant-collapse-no-arrow visible panel"
                        style={{
                            borderBottom: 'none',
                        }}>
                        <div
                            className="ant-collapse-header"
                            role="tab"
                            tabIndex="0"
                            aria-expanded="true"
                            style={{ position: 'static' }}>
                            {t('Support')}
                        </div>
                        <div
                            className="ant-collapse-content ant-collapse-content-inactive"
                            role="tabpanel">
                            <div className="ant-collapse-content-box">
                                <div>
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={contactLink}>
                                                {t('Contact us')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={billingLink}>
                                                {t('Billing')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={claimsLink}>
                                                {t('Claims')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={faqsLink}>
                                                {t('FAQs')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="ant-collapse-item ant-collapse-no-arrow visible panel"
                        style={{
                            borderBottom: 'none',
                        }}>
                        <div
                            className="ant-collapse-header"
                            role="tab"
                            tabIndex="0"
                            style={{ position: 'static' }}
                            aria-expanded="true">
                            {t('Legal')}
                        </div>
                        <div
                            className="ant-collapse-content ant-collapse-content-inactive"
                            role="tabpanel">
                            <div className="ant-collapse-content-box">
                                <div>
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={rulesLink}>
                                                {t(
                                                    'Rates, rules & regulations'
                                                )}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={termsLink}>
                                                {t('Terms & conditions')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={privacyLink}>
                                                {t('Privacy policy')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="ant-collapse-item ant-collapse-no-arrow visible panel"
                        style={{
                            borderBottom: 'none',
                        }}>
                        <div
                            className="ant-collapse-header"
                            role="tab"
                            tabIndex="0"
                            style={{ position: 'static' }}
                            aria-expanded="true">
                            {t('Tools')}
                            <div className="ant-collapse-extra"></div>
                        </div>
                        <div
                            className="ant-collapse-content ant-collapse-content-inactive"
                            role="tabpanel">
                            <div className="ant-collapse-content-box">
                                <div>
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={registerLink}>
                                                {t('Register')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                href={flightStatusLink}>
                                                {t('Flight Status')}
                                                <span className="IconContainer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 10 10">
                                                        <path
                                                            fill="#024"
                                                            fillRule="evenodd"
                                                            d="M7.798 6.756a.417.417 0 0 1 .833 0v2.839c0 .23-.186.417-.416.417H.417A.417.417 0 0 1 0 9.595V1.833c0-.23.187-.416.417-.416h2.862a.417.417 0 1 1 0 .833H.833v6.928h6.965V6.756zM6.042.833a.417.417 0 1 1 0-.833h3.547c.23 0 .417.187.417.417V3.97a.417.417 0 1 1-.834 0V1.42l-4.65 4.667a.417.417 0 0 1-.591-.588L8.58.833H6.042z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={signUpLink}>
                                                {t(
                                                    'Sign up for our newsletter'
                                                )}
                                                <span className="IconContainer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 10 10">
                                                        <path
                                                            fill="#024"
                                                            fillRule="evenodd"
                                                            d="M7.798 6.756a.417.417 0 0 1 .833 0v2.839c0 .23-.186.417-.416.417H.417A.417.417 0 0 1 0 9.595V1.833c0-.23.187-.416.417-.416h2.862a.417.417 0 1 1 0 .833H.833v6.928h6.965V6.756zM6.042.833a.417.417 0 1 1 0-.833h3.547c.23 0 .417.187.417.417V3.97a.417.417 0 1 1-.834 0V1.42l-4.65 4.667a.417.417 0 0 1-.591-.588L8.58.833H6.042z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={sitemapLink}>
                                                {t('Sitemap')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="ant-collapse-item ant-collapse-no-arrow visible panel"
                        style={{
                            borderBottom: 'none',
                        }}>
                        <div
                            className="ant-collapse-header"
                            role="tab"
                            tabIndex="0"
                            style={{ position: 'static' }}
                            aria-expanded="true">
                            {t('Company')}
                            <div className="ant-collapse-extra"></div>
                        </div>
                        <div
                            className="ant-collapse-content ant-collapse-content-inactive"
                            role="tabpanel">
                            <div className="ant-collapse-content-box">
                                <div>
                                    <ul>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={unitedLink}>
                                                United.com
                                                <span className="IconContainer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 10 10">
                                                        <path
                                                            fill="#024"
                                                            fillRule="evenodd"
                                                            d="M7.798 6.756a.417.417 0 0 1 .833 0v2.839c0 .23-.186.417-.416.417H.417A.417.417 0 0 1 0 9.595V1.833c0-.23.187-.416.417-.416h2.862a.417.417 0 1 1 0 .833H.833v6.928h6.965V6.756zM6.042.833a.417.417 0 1 1 0-.833h3.547c.23 0 .417.187.417.417V3.97a.417.417 0 1 1-.834 0V1.42l-4.65 4.667a.417.417 0 0 1-.591-.588L8.58.833H6.042z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={careersLink}>
                                                {t('Careers')}
                                                <span className="IconContainer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="10"
                                                        height="10"
                                                        viewBox="0 0 10 10">
                                                        <path
                                                            fill="#024"
                                                            fillRule="evenodd"
                                                            d="M7.798 6.756a.417.417 0 0 1 .833 0v2.839c0 .23-.186.417-.416.417H.417A.417.417 0 0 1 0 9.595V1.833c0-.23.187-.416.417-.416h2.862a.417.417 0 1 1 0 .833H.833v6.928h6.965V6.756zM6.042.833a.417.417 0 1 1 0-.833h3.547c.23 0 .417.187.417.417V3.97a.417.417 0 1 1-.834 0V1.42l-4.65 4.667a.417.417 0 0 1-.591-.588L8.58.833H6.042z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="CopySection">
                <section className="container margin SectionBottom">
                    <p className="Copyright">
                        {t('Copyright')} © {moment().format('YYYY')}{' '}
                        {t('United Airlines, Inc. All rights reserved.')}
                    </p>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/company/united-cargo"
                                title="linkedin">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    alt="linkedin">
                                    <path
                                        fill="#0C2340"
                                        fillRule="evenodd"
                                        d="M20.45 20.451h-3.556v-5.57c0-1.328-.025-3.037-1.85-3.037-1.852 0-2.137 1.447-2.137 2.941v5.666H9.351V8.998h3.414v1.565h.048c.475-.9 1.636-1.85 3.368-1.85 3.604 0 4.27 2.372 4.27 5.456v6.282zM5.34 7.432a2.064 2.064 0 1 1 0-4.129 2.064 2.064 0 0 1 0 4.129zm-1.78 13.02h3.56V8.997H3.56V20.45zM22.223 0H1.77C.794 0 0 .775 0 1.73v20.539C0 23.224.794 24 1.771 24h20.453c.979 0 1.776-.776 1.776-1.731V1.729C24 .776 23.203 0 22.224 0z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
        </footer>
    );
}

export default Footer;
