import React from 'react';

const CircleStatus = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M11.6122449,14 C11.2693878,14 11,13.7051282 11,13.3589744 L11,6.64102564 C11,6.28205128 11.2693878,6 11.6122449,6 C11.9428571,6 12.2244898,6.28205128 12.2244898,6.64102564 L12.2244898,12.7179487 L16.3877551,12.7179487 C16.7183673,12.7179487 17,13 17,13.3589744 C17,13.7051282 16.7183673,14 16.3877551,14 L11.6122449,14 Z M0,12 C0,18.636 5.376,24 12,24 C18.624,24 24,18.636 24,12 C24,5.376 18.624,0 12,0 C5.376,0 0,5.376 0,12 Z"
                    id="path-alert-5"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/circle-status"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-5"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-alert-5"></use>
            </g>
        </svg>
    );
};

export default CircleStatus;
