import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from './actionTypes';
import { MODULE_ROUTE } from './constants';
import { doRequest } from '../../api/requestSaga';
import { push } from 'connected-react-router';
import { oneReceived } from './actions';
import { closeModal, openModal } from '../../basic_components/Modal';
import { formatContact } from 'basic_components/sagaFormatters';

function* getOne(payload) {
    const data = {
        id: payload.id,
    };
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo/cargo_contacts/show',
            data
        );
        const contact = { ...formatContact(res.response.data, false) };
        yield put(oneReceived(contact));
    } catch (e) {}
}

function* createOne(payload) {
    const data = {
        ...formatContact(payload.data),
    };
    try {
        yield call(doRequest, 'POST', '/cargo/cargo_contacts/save', data);
        yield put(closeModal('contact'));
        yield put(push(MODULE_ROUTE));
    } catch (e) {}
}

function* modifyOne(payload) {
    const data = {
        ...formatContact(payload.data),
        id: payload.data?.id,
    };
    try {
        yield call(doRequest, 'PUT', '/cargo/cargo_contacts/update', data);
        yield put(closeModal('contact'));
        yield put(push(MODULE_ROUTE));
    } catch (e) {}
}

function* deleteOne(payload) {
    const data = {
        id: payload.id,
    };
    try {
        yield call(doRequest, 'DELETE', 'cargo/cargo_contacts/delete', data);
        put(closeModal('DeleteConfirmationModal'));
        put(closeModal('contact'));
        yield put(push(MODULE_ROUTE));
    } catch (e) {}
}

function* editForm(payload) {
    try {
        yield getOne(payload);
        yield put(openModal('contact', 'normal', 'Edit a contact', 800, true));
    } catch (e) {}
}

export default function*() {
    yield all([
        takeLatest(types.ONE, getOne),
        takeLatest(types.EDIT_FORM, editForm),
        takeLatest(types.MODIFY, modifyOne),
        takeLatest(types.CREATE, createOne),
        takeLatest(types.DELETE, deleteOne),
    ]);
}
