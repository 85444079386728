import React from 'react';

const Share = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M17.508,17.6232949 C17.492,17.6072053 14.048,15.9821616 7.176,12.7481637 C7.224,12.5068206 7.26,12.2654774 7.26,12 C7.26,11.6983211 7.212,11.4087093 7.14,11.1311647 C14.004,7.91325638 17.436,6.3043022 17.436,6.3043022 C18.096,7.20933893 19.164,7.80062959 20.364,7.80062959 C22.368,7.80062959 24,6.17156348 24,4.15634837 C24,2.14113326 22.368,0.5 20.364,0.5 C18.36,0.5 16.728,2.14113326 16.728,4.15634837 C16.728,4.32528856 16.764,4.48216159 16.776,4.63903463 C16.752,4.6470794 13.256,6.28016789 6.288,9.5383001 C5.616,8.81427072 4.68,8.35571878 3.624,8.35571878 C1.62,8.35571878 0,9.99685205 0,12 C0,14.0152151 1.62,15.6563484 3.624,15.6563484 C4.728,15.6563484 5.7,15.1495278 6.372,14.3651626 C13.292,17.5911158 16.768,19.2081147 16.8,19.2161595 C16.764,19.4333683 16.728,19.63851 16.728,19.8557188 C16.728,21.8709339 18.36,23.5 20.364,23.5 C22.368,23.5 24,21.8709339 24,19.8557188 C24,17.8405037 22.368,16.1993704 20.364,16.1993704 C19.2,16.1993704 18.18,16.7665268 17.508,17.6232949 Z"
                    id="path-digital-23"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/share"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-23"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-23"></use>
            </g>
        </svg>
    );
};

export default Share;
