import {
    FIELDS,
    PIECES_EMPTY_LOAD,
    prePaidOption,
} from 'modules/bookingSteps/constants';
import { CLEAR_FORM } from '../../contactDetails/constants';

export const AIRCRAFT_FILTERS = {
    [FIELDS.FREIGHTER]: false,
    [FIELDS.PASSENGER]: true,
    [FIELDS.TRUCK]: false,
    [FIELDS.WIDEBODY]: true,
    [FIELDS.NARROWBODY]: true,
    [FIELDS.UAX]: false,
};

const IS_MEDEVAC = {
    [FIELDS.IS_MEDEVAC]: null,
};

const IS_LFG = {
    [FIELDS.LIFEGUARD_XL]: false,
};

const IS_LXL = {
    [FIELDS.LIFEGUARD_XL]: true,
};

const totalShipmentExtraDetails = {
    PACKAGE_TOTAL_SHIPMENT_CRATED: false,
    PACKAGE_TOTAL_SHIPMENT_FORKLIFTABLE: false,
    PACKAGE_TOTAL_SHIPMENT_STACKABLE: false,
    PACKAGE_TOTAL_SHIPMENT_TURNABLE: false,
};

const FORM_INITIAL_VALUES_LIFEGUARD = {
    ...AIRCRAFT_FILTERS,
    [FIELDS.ACCEPT_TERMS]: false,
    [FIELDS.ACCOUNT_NUMBER]: null,
    [FIELDS.AIRCRAFT_MODE]: [],
    [FIELDS.AIRCRAFT_TYPE]: [],
    [FIELDS.ALLOTMENT]: null,
    [FIELDS.ALLOTMENT_ID]: null,
    [FIELDS.AWB_CAPTURE]: null,
    [FIELDS.AWB_TYPE]: 'assignAWB',
    [FIELDS.BOOKING_DATA]: { is_editable: true },
    [FIELDS.CALLER_NAME]: '',
    [FIELDS.COMMODITY_CODE]: undefined,
    [FIELDS.COMMODITY_DESCRIPTION]: '',
    [FIELDS.CONSIGNEE]: CLEAR_FORM,
    [FIELDS.CONTAINER_LIST]: [],
    [FIELDS.DELIVER_DATE]: null,
    [FIELDS.DEPARTURE_DATE]: null,
    [FIELDS.DESTINATION]: null,
    [FIELDS.DRY_ICE_MORE_THAN]: null,
    [FIELDS.FLIGHT]: null,
    [FIELDS.HAS_OVERFLOW_WEIGHT]: false,
    [FIELDS.IS_ALLOTMENT_BOOKING]: false,
    [FIELDS.IS_DANGEROUS_GOODS]: null,
    [FIELDS.IS_RDS]: null,
    [FIELDS.IS_DRY_ICE]: null,
    [FIELDS.IS_UPLOAD_CSV]: false,
    [FIELDS.MEASURE_BY]: 'pieces',
    [FIELDS.NOTIFICATION_PREFERENCES]: [],
    [FIELDS.ORIGIN]: null,
    [FIELDS.OVERFLOW_WEIGHT]: 0,
    [FIELDS.PACKAGE_LIST]: [],
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_PIECES]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT]: 'cbm',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT]: '',
    [FIELDS.PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT]: 'kg',
    [FIELDS.PAYMENT_INFO]: prePaidOption?.value,
    [FIELDS.PRODUCT]: undefined,
    [FIELDS.PRODUCT_TYPE]: 'bulk',
    [FIELDS.REMEMBER_SETTINGS]: false,
    [FIELDS.SHC]: [],
    [FIELDS.SHIPPER]: CLEAR_FORM,
    [FIELDS.SORT]: {
        value: 'Journey',
        field: 'Journey',
        label: 'Fastest',
        order: 'Asc',
    },
    [FIELDS.STOPS]: [0, 1],
    [FIELDS.TEMPLATE_DESCRIPTION]: null,
    [FIELDS.TEMPLATE_ID]: null,
    [FIELDS.TEMPLATE_NAME]: null,
    [FIELDS.TENDER_DATE]: null,
    [FIELDS.VIA]: null,
};

export const FORM_INITIAL_VALUES_LFG = {
    ...FORM_INITIAL_VALUES_LIFEGUARD,
    ...IS_MEDEVAC,
    ...IS_LFG,
    [FIELDS.BY_PIECE_PRODUCT_EDIT]: PIECES_EMPTY_LOAD(true, true),
};

export const FORM_INITIAL_VALUES_LXL = {
    ...FORM_INITIAL_VALUES_LIFEGUARD,
    ...IS_LXL,
    [FIELDS.BY_PIECE_PRODUCT_EDIT]: PIECES_EMPTY_LOAD(false, true),
    // Uncomment next line to add Turnable, Stackable, Crated and Forkliftable
    // ...totalShipmentExtraDetails,
};
