import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { MODULE_ROUTE, ROUTES_CONSTANTS } from './constants';
import BookingHeading from './components/common/BookingHeading';
import DisabledScreen from './components/common/disabledScreen/DisabledScreen';
import Form from './Form';
import FormActions from './components/common/FormActions';

const ShipmentDetails = React.lazy(() =>
    import('./components/shipmentDetails')
);
const FlightsAndRates = React.lazy(() =>
    import('./components/flightsAndRates')
);
const ContactDetails = React.lazy(() => import('./components/contactDetails'));
const NotificationPreferences = React.lazy(() =>
    import('./components/notificationPreferences')
);
const ReviewAndBook = React.lazy(() => import('./components/reviewAndBook'));

export default () => (
    <Form>
        <DisabledScreen />
        <BookingHeading />
        <Switch>
            {/** STEP 1 */}
            <Route
                path={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.SHIPMENT_DETAILS}`}
                component={ShipmentDetails}
            />
            {/** STEP 2 */}
            <Route
                path={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.FLIGHTS_RATES}`}
                component={FlightsAndRates}
            />
            {/** STEP 3 */}
            <Route
                path={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.CONTACT_DETAILS}`}
                component={ContactDetails}
            />

            {/** STEP 4 */}
            <Route
                path={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.NOTIFICATION_PREFERENCES}`}
                component={NotificationPreferences}
            />
            {/** STEP 5 */}
            <Route
                path={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.REVIEW_BOOK}`}
                component={ReviewAndBook}
            />

            <Redirect
                path="*"
                to={`${MODULE_ROUTE}/${ROUTES_CONSTANTS.SHIPMENT_DETAILS}`}
            />
        </Switch>
        <FormActions />
    </Form>
);
