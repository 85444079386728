export const AWBFormat = awb => {
    let error = false;
    if (
        awb &&
        (parseInt(awb.substring(0, 7), 10) % 7 !==
            parseInt(awb.substring(7, 8)) ||
            awb.length !== 8)
    ) {
        error = true;
    }
    return error;
};

export const flightFormat = str => {
    let error = false;
    if (str && str.length !== 4) {
        error = true;
    }
    return error;
};

export const validateEmail = email => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    return emailRegex.test(email);
};

export const getCountryCode = label => {
    const regex = /\(([^)]+)\)/;
    const code = regex.exec(label);
    return code?.[1];
};

export const isDomesticIataRestriction = (
    origin,
    destination,
    account_number
) => {
    return (
        origin?.isDomestic &&
        destination?.isDomestic &&
        account_number?.account_type === 'IATA'
    );
};

export const compareAgentAndOriginCountries = (origin, agent) => {
    const agentCountryCode =
        agent?.country?.code || getCountryCode(agent?.country?.label);
    return !!agent?.country && !!origin && origin?.country !== agentCountryCode;
};
