import React from 'react';

const MobileMore = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/mobile-more"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M4.8,12.40192 C4.8,13.72768 3.72576,14.80192 2.4,14.80192 C1.07424,14.80192 0,13.72768 0,12.40192 C0,11.07712 1.07424,10.00192 2.4,10.00192 C3.72576,10.00192 4.8,11.07712 4.8,12.40192 M14.4,12.4 C14.4,13.72576 13.32576,14.8 12,14.8 C10.67424,14.8 9.6,13.72576 9.6,12.4 C9.6,11.07424 10.67424,10 12,10 C13.32576,10 14.4,11.07424 14.4,12.4 M24,12.4 C24,13.72576 22.92576,14.8 21.6,14.8 C20.27424,14.8 19.2,13.72576 19.2,12.4 C19.2,11.07424 20.27424,10 21.6,10 C22.92576,10 24,11.07424 24,12.4"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default MobileMore;
