import React from 'react';
import PrivateRoute from 'basic_components/PrivateRoute';
import { Route, Switch } from 'react-router';
import { MODULE_ROUTE, TEMPLATE_SHOW } from './constants';
import Form from '../bookingSteps/Form';
import BookingHeading from '../bookingSteps/components/common/BookingHeading';
import FormActions from "../bookingSteps/components/common/FormActions";

const List = React.lazy(() => import('./components/List'));
const ReviewAndBook = React.lazy(() => import('../bookingSteps/components/reviewAndBook'));

export default () => (
    <Switch>
        <PrivateRoute exact path={`${MODULE_ROUTE}/`} component={List} />
        <Form>
            <Route path={`${MODULE_ROUTE}${TEMPLATE_SHOW}/:id`} render={ () => (
                <>
                    <BookingHeading/>
                    <ReviewAndBook/>
                    <FormActions/>
                </>
            )}/>
        </Form>
    </Switch>
);
