import { Flex, Notification } from '@freightos/uikit';
import { default as GlobalIcon } from 'icons/X Global Icons/GlobalIcons';
import theme from 'configs/theme';
import React from 'react';
import { isEmptyValues } from 'basic_components/jsTools';
import styled from 'styled-components';

const EmbargoRow = styled.span`
    color: #000;
    padding: 5px 0;
    margin-right: 3px;
    ${({ showBorder }) => {
        return showBorder && 'border-bottom: 1px solid rgb(204,204,204);';
    }}
`;

const remarkString = embargo_remark =>
    `${embargo_remark.external_remark} (ref ${embargo_remark.type} ${
        embargo_remark.code ? '#' + embargo_remark.code : '-'
    })`;

export const embargoesAlert = extra_params => {
    const embargoes = [];
    Object.keys(extra_params).forEach(key => {
        embargoes.push(extra_params[key].embargoes[0]);
    });
    const filteredEmbargoes = embargoes.filter(
        (embargo, index, selfArray) =>
            index ===
            selfArray.findIndex(selfEmbargo => selfEmbargo.id === embargo.id)
    );

    if (!isEmptyValues(filteredEmbargoes)) {
        Notification.show({
            message: (
                <>
                    <GlobalIcon
                        className="warning"
                        iconName="warning"
                        size="18px"
                        mr="2"
                        fill={theme.chipWarningBg}
                        pointer
                        style={{
                            hoverColor: theme.chipWarningBg,
                        }}
                    />
                    <Flex flexDirection="column">
                        {filteredEmbargoes.map((embargo_remark, index) => {
                            return (
                                <EmbargoRow
                                    showBorder={
                                        filteredEmbargoes.length - 1 > index
                                    }>
                                    {remarkString(embargo_remark)}
                                </EmbargoRow>
                            );
                        })}
                    </Flex>
                </>
            ),
            level: 'warning',
            type: 'toast',
            className: 'embargoes-notification',
            autoClose: false,
        });
    }
};
