import * as c from './actionTypes';
const initialState = {
    templateSelected: null,
    filters: null,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case c.SELECT_TEMPLATE:
            return Object.assign({}, state, { templateSelected : action.data });

        case c.SEARCH:
            return Object.assign({}, state, { filters : action.filters});

        default:
            return state;
    }
};