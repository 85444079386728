import React from 'react';

const WeatherSleet = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-sleet"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M7.6938,18.4092129 C9.2373,18.4092129 10.4838,19.661285 10.4838,21.2061077 C10.4838,22.7479279 9.2373,24 7.6938,24 C6.1473,24 4.8963,22.7479279 4.8963,21.2061077 C4.8963,19.661285 6.1473,18.4092129 7.6938,18.4092129 Z M16.69665,16.2216898 C18.27615,16.2216898 19.55565,17.5052889 19.55565,19.0861425 C19.55565,20.6699987 18.27615,21.9535978 16.69665,21.9535978 C15.11265,21.9535978 13.83465,20.6699987 13.83465,19.0861425 C13.83465,17.5052889 15.11265,16.2216898 16.69665,16.2216898 Z M8.553,-5.5067062e-14 C10.8405,-5.5067062e-14 12.9885,0.890262287 14.6025,2.50414417 C15.06375,2.9595341 15.4625,3.4618392 15.8013542,4.00063388 L15.9975,4.32820603 L16.161,4.61795412 L16.4925,4.55490013 C16.881,4.485841 17.277,4.44530629 17.667,4.44530629 C21.156,4.44530629 24,7.28423713 24,10.7762271 C24,13.0838102 22.7530753,15.0982404 20.9034603,16.2003073 L20.6955,16.3189731 L19.9575,15.1539756 L19.9785,15.1014306 C21.528,14.272721 22.5795,12.6438263 22.5795,10.7762271 C22.5795,8.06790815 20.376,5.86552235 17.667,5.86552235 C16.8555,5.86552235 16.0755,6.06219075 15.3675,6.43601083 C14.4555,3.4934913 11.685,1.42021606 8.553,1.42021606 C4.6185,1.42021606 1.419,4.61945541 1.419,8.55132207 C1.419,10.8630615 2.5304496,12.9174542 4.24241376,14.2203943 L4.4595,14.3793123 L3.8685,15.7049474 C3.384,15.3866748 2.925,15.0173586 2.505,14.5969987 C0.891,12.9816155 0,10.8362786 0,8.55132207 C0,6.26786686 0.891,4.11952735 2.505,2.50414417 C4.1205,0.890262287 6.27,-5.5067062e-14 8.553,-5.5067062e-14 Z M10.01985,9.34865479 C11.77185,9.34865479 13.18785,10.7703721 13.18785,12.5223723 C13.18785,14.2743724 11.77185,15.6960898 10.01985,15.6960898 C8.26785,15.6960898 6.85035,14.2743724 6.85035,12.5223723 C6.85035,10.7703721 8.26785,9.34865479 10.01985,9.34865479 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherSleet;
