import { Button, Flex, Row } from '@freightos/uikit';
import { updateDesktopScreenStatus } from '../modules/login/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Modal, { closeModal, openModal } from 'basic_components/Modal';
import useWindowDimensions from './customHooks/useWindowDimensions';
const MobileScreenModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { desktopScreen } = useSelector(state => state.user);
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        dispatch(openModal('mobileScreenModal', 'normal', '', 650));
    });
    return (
        <Flex flexDirection="column" style={{ height: '100vh' }}>
            <Modal id="mobileScreenModal">
                <Flex flexDirection="column" className="mobileScreenModal">
                    <Flex
                        justifyContent="center"
                        alignItems="flex-start"
                        flexDirection="column">
                        <Row>
                            <p style={{
                                    fontSize: '3em',
                                    fontWeight: 'bold',
                                    paddingTop: '10px',
                                }}>
                                {t('Heads up')}
                            </p>
                        </Row>
                        <Row>
                            <p style={{ fontSize: '2em' }}>
                                {t(
                                    'This section of our portal is not yet optimized for mobile/tablet view. We recommend visiting this section on a desktop.'
                                )}
                            </p>
                        </Row>
                    </Flex>
                    <Flex flexDirection="column" justifyContent="flex-center" paddingBottom="20px">
                        <Button
                            data-cc="btn_bp_product_selection_confirm"
                            variation="primary"
                            onClick={() => {
                                dispatch(closeModal('mobileScreenModal'));
                                dispatch(
                                    updateDesktopScreenStatus(!desktopScreen)
                                );
                            }}
                            mt={3}
                            padding={5}>
                            <p style={{ fontSize: '2em' }}>
                                {t('Continue to site')}
                            </p>
                        </Button>
                        <Button
                            data-cc="btn_bp_product_selection_abort"
                            onClick={() => {
                                window.location.href =
                                    'https://unitedcargo.com';
                            }}
                            padding={5}
                            mt={3}>
                            <p style={{ fontSize: '2em' }}>{t('Back')}</p>
                        </Button>
                    </Flex>
                </Flex>
            </Modal>
        </Flex>
    );
};

export default MobileScreenModal;
