import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import reducer from '../reducers/index';
import rootSaga from '../sagas/index';
import { loadState, saveState } from './localstorage';

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
});

const sagaMiddleware = createSagaMiddleware();

let store;
if (
    process.env.REACT_APP_ENVIRONMENT !== 'stage' &&
    process.env.REACT_APP_ENVIRONMENT !== 'prod'
) {
    const composeEnhancer =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
        compose;

    store = createStore(
        reducer(history),
        loadState(),
        composeEnhancer(
            applyMiddleware(routerMiddleware(history), sagaMiddleware)
        )
    );
    store.subscribe(() => {
        saveState(store.getState());
    });
} else {
    store = createStore(
        reducer(history),
        loadState(),
        compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    );
    store.subscribe(() => {
        saveState(store.getState());
    });
}

sagaMiddleware.run(rootSaga);

export default store;
