export const FIELDS = {
    SORT: 'sort',
    FLIGHT: 'flight',
};

export const LABELS = {
    SORT: 'Sort flights by',
};

export const ERROR_LABELS = {};
