import { FIELDS } from 'modules/bookingSteps/constants';
import { isEmptyPackages } from 'basic_components/sagaFormatters';
import { isRestrictedProductDG } from '../../../utils/isRestrictedProductDG';

const partialSaveCheck = values => {
    return (
        values[FIELDS.ACCOUNT_NUMBER] &&
        values[FIELDS.PRODUCT] &&
        values[FIELDS.ORIGIN] &&
        values[FIELDS.DESTINATION] &&
        !isRestrictedProductDG(values) &&
        !isEmptyPackages(values)
    );
};

export default partialSaveCheck;
