import React from 'react';

const CircleCheck = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M17.7192748,7.65300236 C18.0354839,7.89998381 18.0932204,8.35862624 17.8482328,8.6774089 L12.0539902,16.2169901 C11.9395142,16.3659485 11.7791964,16.4580117 11.6086116,16.4886282 C11.3992714,16.5300685 11.1736358,16.4845597 10.9932185,16.3435759 L6.28491905,12.6643606 C5.95842191,12.4092253 5.90364936,11.9380302 6.16258113,11.6119168 C6.4215129,11.2858034 6.8960968,11.2282643 7.22259393,11.4833996 L11.368,14.723 L16.7031381,7.78300993 C16.9481257,7.46422728 17.4030656,7.4060209 17.7192748,7.65300236 Z M0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.364 18.624,0 12,0 C5.376,0 0,5.364 0,12 Z"
                    id="path-alert-1"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/circle-check"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-1"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-alert-1"></use>
            </g>
        </svg>
    );
};

export default CircleCheck;
