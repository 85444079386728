import * as c from './actionTypes';

export default (state = {}, action) => {
    const newState = { ...state };
    let newTable;
    switch (action.type) {
        case c.TABLE_CHANGE:
            newTable = state[action.table_id];
            newTable = Object.assign({}, newTable, {
                pagination: action.pagination,
            });
            newTable = Object.assign({}, newTable, { filters: action.filters });
            newTable = Object.assign({}, newTable, { sorter: action.sorter });

            return Object.assign({}, state, { [action.table_id]: newTable });

        case c.ROWS_RECEIVED:
            newTable = state[action.table_id];
            newTable = Object.assign({}, newTable, { rows: action.rows });
            newTable = Object.assign({}, newTable, {
                pagination: action.pagination,
            });
            return Object.assign({}, state, { [action.table_id]: newTable });

        case c.LAST_TABLE_OPENED:
            return {
                ...state,
                lastTableOpened: action.table_id,
                lastRowSelected: action.row_id,
            };
        case 'DELETE':
            newTable = state[`${action.table_id}_TABLE`];
            newTable.rows = newTable.rows.filter(r => r.id !== action.id);
            newState[`${action.table_id}_TABLE`] = newTable;
            return newState;
        default:
            return state;
    }
};
