import React from 'react';

const Train = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Destination/train"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M18,0.5 C19.1045695,0.5 20,1.3954305 20,2.5 L20,15.5 C20,16.4535852 19.3326329,17.2512984 18.4394773,17.4515612 L24,23.4550012 L21,23.4550012 L18.79,21 L5.21,21 L3,23.4550012 L0,23.4550012 L5.56153772,17.4517885 C4.66787275,17.2519027 4,16.4539464 4,15.5 L4,2.5 C4,1.3954305 4.8954305,0.5 6,0.5 L18,0.5 Z M15.64,17.5 L8.36,17.5 L7.01,19 L16.99,19 L15.64,17.5 Z M7.5,12.5 C6.67157288,12.5 6,13.1715729 6,14 C6,14.8284271 6.67157288,15.5 7.5,15.5 C8.32842712,15.5 9,14.8284271 9,14 C9,13.1715729 8.32842712,12.5 7.5,12.5 Z M16.5,12.5 C15.6715729,12.5 15,13.1715729 15,14 C15,14.8284271 15.6715729,15.5 16.5,15.5 C17.3284271,15.5 18,14.8284271 18,14 C18,13.1715729 17.3284271,12.5 16.5,12.5 Z M10,4.5 L7,4.5 C6.44771525,4.5 6,4.94771525 6,5.5 L6,5.5 L6,8.5 C6,9.05228475 6.44771525,9.5 7,9.5 L7,9.5 L10,9.5 C10.5522847,9.5 11,9.05228475 11,8.5 L11,8.5 L11,5.5 C11,4.94771525 10.5522847,4.5 10,4.5 L10,4.5 Z M17,4.5 L14,4.5 C13.4477153,4.5 13,4.94771525 13,5.5 L13,5.5 L13,8.5 C13,9.05228475 13.4477153,9.5 14,9.5 L14,9.5 L17,9.5 C17.5522847,9.5 18,9.05228475 18,8.5 L18,8.5 L18,5.5 C18,4.94771525 17.5522847,4.5 17,4.5 L17,4.5 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Train;
