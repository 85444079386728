import React from 'react';
import PrivateRoute from 'basic_components/PrivateRoute';
import { Route, Switch } from 'react-router';
import { MODULE_ROUTE, BOOKING_SHOW } from './constants';
import Form from 'modules/bookingSteps/Form';
import BookingHeading from 'modules/bookingSteps/components/common/BookingHeading';
import FormActions from 'modules/bookingSteps/components/common/FormActions';

const List = React.lazy(() => import('./components/List'));
const ReviewAndBook = React.lazy(() =>
    import('modules/bookingSteps/components/reviewAndBook')
);

export default () => (
    <Switch>
        <PrivateRoute exact path={`${MODULE_ROUTE}/`} component={List} />
        <Form>
            <Route
                path={`${MODULE_ROUTE}${BOOKING_SHOW}/:id`}
                render={() => (
                    <>
                        <BookingHeading />
                        <ReviewAndBook />
                        <FormActions />
                    </>
                )}
            />
        </Form>
    </Switch>
);
