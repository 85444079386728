import React from 'react';

const EcoLeaf = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-travel-8"
                    points="1.37712832e-05 0 19.6877273 0 19.6877273 24 1.37712832e-05 24"></polygon>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/eco-leaf"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-3" transform="translate(2.000000, 0.000000)">
                    <mask id="mask-eco-leaf" fill="white">
                        <use xlinkHref="#path-travel-8"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path
                        d="M0.918590909,11.9878667 C-0.8255,15.3892 0.285863636,19.1820889 1.20540909,21.3092 C2.83313636,18.4247556 6.12722727,15.4576444 7.67813636,14.1700889 C9.30586364,12.8172 10.3899545,11.6776444 11.0831364,10.8465333 C10.9445,11.2674222 10.7885909,11.6807556 10.5967727,12.0834222 C10.3176818,12.5603111 10.0322273,13.0434222 9.74586364,13.5212 C9.30359091,14.1963111 8.82768182,14.8572 8.33404545,15.5007556 C7.98904545,15.8896444 7.61904545,16.2949778 7.21722727,16.7109778 C5.37540909,18.6260889 3.19177273,21.3492 2.00768182,23.6536444 C2.36677273,23.5407556 3.3645,23.5229778 4.71268182,23.7060889 C14.6431364,25.6332 18.4981364,17.5803111 19.2726818,14.3594222 C20.9890455,7.23986667 16.8276818,-0.000133333333 16.8276818,-0.000133333333 C14.5567727,6.96475556 4.11904545,5.74475556 0.918590909,11.9878667"
                        id="Fill-1"
                        fill={fill || '#002244'}
                        mask="url(#mask-eco-leaf)"></path>
                </g>
            </g>
        </svg>
    );
};

export default EcoLeaf;
