import * as c from './actionTypes';

export const search = filters => ({ type: c.SEARCH, filters });
export const getOne = id => ({ type: c.ONE, id });
export const getBookingStatusOptions = () => ({
    type: c.GET_BOOKINGS_STATUS_OPTIONS,
});
export const getBookingMovementStatusOptions = () => ({
    type: c.GET_BOOKINGS_MOVEMENT_STATUS_OPTIONS,
});
export const storeBookingStatusOptions = data => ({
    type: c.STORE_BOOKINGS_STATUS_OPTIONS,
    data,
});
export const storeBookingMovementStatusOptions = data => ({
    type: c.STORE_BOOKINGS_MOVEMENT_STATUS_OPTIONS,
    data,
});
