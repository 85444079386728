import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@freightos/uikit';
import PropTypes from 'prop-types';
import 'basic_components/Modal.css';

// Actions
export const openModal = (
    id,
    modal_type,
    title,
    size,
    closable = false,
    footer
) => ({
    type: 'MODAL_OPEN',
    id,
    modal_type,
    title,
    size,
    closable,
    footer,
});
export const closeModal = id => ({ type: 'MODAL_CLOSE', id });

// Reducer
export const modalReducer = (state = {}, action) => {
    switch (action.type) {
        case 'MODAL_OPEN':
            const newModal = Object.assign({}, state[action.id], {
                isOpen: true,
                modal_type: action.modal_type,
                title: action.title,
                size: action.size,
                closable: action.closable,
                footer: action.footer,
            });
            return Object.assign({}, state, { [action.id]: newModal });
        case 'MODAL_CLOSE':
            const newModalC = Object.assign({}, state[action.id], {
                isOpen: false,
            });
            return Object.assign({}, state, { [action.id]: newModalC });
        default:
            return state;
    }
};

// Component
const ModalCmp = props => {
    const data = useSelector(state => state.modal[props.id]);
    const dispatch = useDispatch();
    return (
        <Modal
            title={data ? data.title : ''}
            info={data && data.modal_type === 'info' ? true : false}
            closable={data?.closable || props?.closable || false}
            isOpen={data ? data.isOpen : false}
            width={data ? data.size : 1000}
            shouldCloseOnOverlayClick={data?.closable || false}
            secondaryButton={null}
            primaryButton={null}
            footer={data?.footer || null}
            onHide={() => dispatch(closeModal(props.id))}>
            {props.children}
        </Modal>
    );
};

ModalCmp.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ModalCmp;
