import React from 'react';

const SpecialNeeds = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M9.03185005,4.15719804 C10.194793,4.15719804 11.1284765,3.21970975 11.1284765,2.07897401 C11.1284765,0.926238422 10.194793,-1.04360964e-13 9.03185005,-1.04360964e-13 C7.8749602,-1.04360964e-13 6.93522364,0.926238422 6.93522364,2.07897401 C6.93522364,3.21970975 7.8749602,4.15719804 9.03185005,4.15719804 M6.22414118,9.58518019 L6.33612267,11.517156 C5.00596416,12.6503919 4.15097035,14.318371 4.15097035,16.1895976 C4.15097035,19.6043049 6.94067139,22.3635205 10.3810757,22.3635205 C13.3750673,22.3635205 15.8560626,20.2537968 16.4492618,17.4630817 L16.4492618,17.4630817 L17.5569706,19.3658079 C16.3244176,22.0875239 13.5861675,24 10.3810757,24 C6.02590367,24 2.5,20.4990438 2.5,16.1895976 C2.5,13.3921326 3.99661748,10.963663 6.22414118,9.58518019 L6.22414118,9.58518019 Z M9.06847102,4.90066374 C10.1829895,4.83916451 11.1287791,5.69715379 11.202929,6.78839015 L11.202929,6.78839015 L11.3413926,8.57261784 L15.262258,8.57261784 C15.7941701,8.5921176 16.2080476,8.99636255 16.2080476,9.51610605 C16.2080476,10.0440994 15.782064,10.4370945 15.262258,10.4693441 L15.262258,10.4693441 L11.4511042,10.4693441 L11.4995286,12.0375745 L16.747526,12.0375745 C17.1477842,12.0503244 17.5382062,12.2595718 17.7614125,12.6270672 L17.7614125,12.6270672 L21.3357406,18.7462407 C21.6626055,19.3469832 21.4908501,20.0647242 20.9165667,20.4067199 C20.3339603,20.7509656 19.5667357,20.5297184 19.2323045,19.9904751 L19.2323045,19.9904751 L16.4547096,15.2205347 L9.65713048,15.2205347 C8.55169158,15.2257847 7.61498155,14.4352946 7.52115922,13.3313084 L7.52115922,13.3313084 L7.15646274,7.02313721 C7.10198526,5.91690104 7.94865612,4.96216297 9.06847102,4.90066374 Z"
                    id="path-travel-27"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/special-needs"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-27"></use>
                </mask>
                <use
                    id="Combined-Shape"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-27"></use>
            </g>
        </svg>
    );
};

export default SpecialNeeds;
