import * as c from './actionTypes';

export const search = filters => ({ type: c.SEARCH, filters });
export const getOne = id => ({ type: c.ONE, id });
export const oneReceived = data => ({ type: c.ONE_RECEIVED, data });

export const addForm = () => ({ type: c.ADD_FORM });
export const editForm = id => ({ type: c.EDIT_FORM, id });

export const createOne = data => ({ type: c.CREATE, data });
export const modifyOne = data => ({ type: c.MODIFY, data });
export const deleteOne = id => ({ type: c.DELETE, id });
export const contactIdToDelete = id => ({ type: c.CONTACT_ID_TO_DELETE, id });
