import { MODULE_NAME } from './constants';

export const SEARCH = `${MODULE_NAME}/SEARCH`;
export const ONE = `${MODULE_NAME}/ONE`;
export const ONE_RECEIVED = `${MODULE_NAME}/ONE_RECEIVED`;

export const ADD_FORM = `${MODULE_NAME}/ADD_FORM`;
export const EDIT_FORM = `${MODULE_NAME}/EDIT_FORM`;
export const DUPLICATE_FORM = `${MODULE_NAME}/DUPLICATE_FORM`;

export const CREATE = `${MODULE_NAME}/CREATE`;
export const MODIFY = `${MODULE_NAME}/MODIFY`;
export const DELETE = `${MODULE_NAME}/DELETE`;
export const CONTACT_ID_TO_DELETE = `${MODULE_NAME}/CONTACT_ID_TO_DELETE`;
