import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga4';

// import axios from 'axios';
import store from './store/index';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './configs/theme';
import 'configs/axios';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    Sentry.init({
        dsn: `https://41279e9478b64d419086c754584158ea@o568011.ingest.sentry.io/6066305`,
        environment: process.env.REACT_APP_ENVIRONMENT,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send(window.location.pathname + window.location.search);
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
