import React from 'react';

const Sort = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/sort"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M1.25,10.75 L14.75,10.75 C15.4403559,10.75 16,11.3096441 16,12 C16,12.6903559 15.4403559,13.25 14.75,13.25 L1.25,13.25 C0.559644063,13.25 8.45442189e-17,12.6903559 0,12 C-8.45442189e-17,11.3096441 0.559644063,10.75 1.25,10.75 Z M1.25,19.5 L6.75,19.5 C7.44035594,19.5 8,20.0596441 8,20.75 C8,21.4403559 7.44035594,22 6.75,22 L1.25,22 C0.559644063,22 8.45442189e-17,21.4403559 0,20.75 C-8.45442189e-17,20.0596441 0.559644063,19.5 1.25,19.5 Z M1.25,2 L22.75,2 C23.4403559,2 24,2.55964406 24,3.25 C24,3.94035594 23.4403559,4.5 22.75,4.5 L1.25,4.5 C0.559644063,4.5 8.45442189e-17,3.94035594 0,3.25 C-8.45442189e-17,2.55964406 0.559644063,2 1.25,2 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Sort;
