import React from 'react';

const PaypalCredit = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/paypal_credit"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Group-16"
                    transform="translate(0.000000, 5.000000)"
                    fillRule="nonzero">
                    <rect
                        id="Rectangle-path"
                        stroke="#999999"
                        stroke-width="0.5"
                        fill="#FFFFFF"
                        x="0.25"
                        y="0.25"
                        width="23.5"
                        height="14.5"
                        rx="1.5"></rect>
                    <g id="Group-15" transform="translate(2.625000, 3.375000)">
                        <path
                            d="M10.2136552,4.04164093 C10.2136552,3.97766577 10.2800252,3.91369062 10.3463952,3.91369062 L11.6737956,3.91369062 C12.735716,3.91369062 13.5321562,4.74536765 13.3330462,5.83294531 C13.1339361,6.92052297 12.1383858,7.75220001 11.0100954,7.75220001 L9.68269502,7.75220001 C9.616325,7.75220001 9.616325,7.68822485 9.616325,7.68822485 L10.2136552,4.04164093 Z M10.7446154,6.85654782 L10.9437254,6.85654782 C11.5410556,6.85654782 12.1383858,6.53667203 12.2711258,5.76897016 C12.4038659,5.06524343 12.0056458,4.6813925 11.3419455,4.6813925 L11.1428355,4.6813925 C11.0764655,4.6813925 11.0764655,4.6813925 11.0764655,4.74536765 L10.7446154,6.85654782 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M8.48803464,4.87331797 L8.42166462,5.38511922 L9.48358495,5.38511922 C9.54995498,5.38511922 9.54995498,5.44909437 9.54995498,5.44909437 L9.41721493,6.08884594 C9.41721493,6.15282109 9.35084491,6.21679625 9.28447489,6.21679625 L8.3552946,6.21679625 C8.28892457,6.21679625 8.22255455,6.28077141 8.22255455,6.34474656 L8.15618453,6.85654782 L9.28447489,6.85654782 C9.35084491,6.85654782 9.35084491,6.92052297 9.35084491,6.92052297 L9.21810487,7.56027454 C9.21810487,7.62424969 9.15173485,7.68822485 9.08536483,7.68822485 L7.0942642,7.68822485 C7.02789417,7.68822485 7.02789417,7.62424969 7.02789417,7.62424969 L7.62522436,4.04164093 C7.62522436,3.97766577 7.69159439,3.91369062 7.75796441,3.91369062 L9.74906504,3.91369062 C9.81543506,3.91369062 9.81543506,3.97766577 9.81543506,3.97766577 L9.68269502,4.61741734 C9.68269502,4.6813925 9.616325,4.74536765 9.54995498,4.74536765 L8.55440466,4.74536765 C8.55440466,4.74536765 8.48803464,4.80934281 8.48803464,4.87331797 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M14.2622265,7.68822485 L13.4657862,7.68822485 C13.3994162,7.68822485 13.3994162,7.62424969 13.3994162,7.62424969 L13.9967464,4.04164093 C13.9967464,3.97766577 14.0631164,3.91369062 14.1294864,3.91369062 L14.9259267,3.91369062 C14.9922967,3.91369062 14.9922967,3.97766577 14.9922967,3.97766577 L14.3949665,7.56027454 C14.3949665,7.62424969 14.3285965,7.68822485 14.2622265,7.68822485 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M6.76241409,7.68822485 L5.70049375,7.68822485 C5.63412373,7.68822485 5.63412373,7.68822485 5.56775371,7.62424969 L4.9040535,6.21679625 L4.9040535,6.21679625 L4.70494344,7.56027454 C4.70494344,7.62424969 4.63857342,7.62424969 4.57220339,7.62424969 L3.77576314,7.62424969 C3.70939312,7.62424969 3.70939312,7.56027454 3.70939312,7.56027454 L4.30672331,3.97766577 C4.30672331,3.91369062 4.37309333,3.91369062 4.43946335,3.91369062 L5.89960382,3.91369062 C6.69604407,3.91369062 7.22700424,4.29754156 7.0942642,5.06524343 C7.02789417,5.57704469 6.62967405,6.02487078 6.03234386,6.15282109 L6.96152415,7.56027454 C6.82878411,7.62424969 6.82878411,7.68822485 6.76241409,7.68822485 Z M4.97042352,5.64101984 L5.03679354,5.64101984 C5.36864365,5.64101984 5.76686377,5.57704469 5.8332338,5.19319375 C5.89960382,4.80934281 5.70049375,4.74536765 5.30227363,4.74536765 L5.16953358,4.74536765 C5.10316356,4.74536765 5.10316356,4.74536765 5.10316356,4.80934281 L4.97042352,5.64101984 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M16.4524372,7.68822485 L15.6559969,7.68822485 C15.5896269,7.68822485 15.5896269,7.62424969 15.5896269,7.62424969 L16.054217,4.74536765 L15.3241468,4.74536765 C15.2577768,4.74536765 15.2577768,4.6813925 15.2577768,4.6813925 L15.3905168,4.04164093 C15.3905168,3.97766577 15.4568869,3.91369062 15.5232569,3.91369062 L17.9789477,3.91369062 C18.0453177,3.91369062 18.0453177,3.97766577 18.0453177,3.97766577 L17.9125776,4.61741734 C17.9125776,4.6813925 17.8462076,4.74536765 17.7798376,4.74536765 L17.0497674,4.74536765 L16.5851772,7.56027454 C16.5851772,7.62424969 16.5188072,7.68822485 16.4524372,7.68822485 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M3.70939312,5.00126828 C3.70939312,5.06524343 3.57665308,5.06524343 3.57665308,5.06524343 C3.37754302,4.87331797 3.11206293,4.80934281 2.84658285,4.80934281 C2.24925266,4.80934281 1.71829249,5.2571689 1.65192247,5.83294531 C1.58555245,6.40872172 1.91740255,6.85654782 2.51473274,6.85654782 C2.78021283,6.85654782 3.04569291,6.7285975 3.31117299,6.60064719 C3.37754302,6.53667203 3.44391304,6.60064719 3.44391304,6.66462235 L3.31117299,7.56027454 C3.31117299,7.62424969 3.24480297,7.62424969 3.24480297,7.68822485 C2.91295287,7.75220001 2.7138428,7.81617516 2.3819927,7.81617516 C0.656372151,7.81617516 0.390892067,6.40872172 0.523632109,5.83294531 C0.789112194,4.16959124 2.18288264,3.78574031 3.04569291,3.84971546 C3.31117299,3.84971546 3.57665308,3.91369062 3.84213316,3.97766577 C3.90850318,3.97766577 3.97487321,4.10561609 3.97487321,4.16959124 L3.70939312,5.00126828 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M8.75351472,0.139156387 L7.89070445,0.139156387 C7.82433443,0.139156387 7.75796441,0.203131543 7.75796441,0.2671067 L7.4261143,2.44226202 C7.4261143,2.50623718 7.4261143,2.50623718 7.49248432,2.50623718 L7.95707447,2.50623718 C8.02344449,2.50623718 8.02344449,2.50623718 8.02344449,2.44226202 L8.15618453,1.80251045 C8.15618453,1.7385353 8.22255455,1.67456014 8.28892457,1.67456014 L8.55440466,1.67456014 C9.15173485,1.67456014 9.48358495,1.41865952 9.54995498,0.842883108 C9.616325,0.586982482 9.54995498,0.395057013 9.41721493,0.2671067 C9.28447489,0.203131543 9.08536483,0.139156387 8.75351472,0.139156387 Z M8.88625476,0.906858264 C8.81988474,1.22673405 8.62077468,1.22673405 8.3552946,1.22673405 L8.22255455,1.22673405 L8.28892457,0.650957638 C8.28892457,0.586982482 8.3552946,0.586982482 8.3552946,0.586982482 L8.42166462,0.586982482 C8.55440466,0.586982482 8.75351472,0.586982482 8.81988474,0.650957638 C8.88625476,0.714932795 8.88625476,0.842883108 8.88625476,0.906858264 Z"
                            id="Shape"
                            fill="#69B3E7"></path>
                        <path
                            d="M2.44836272,0.139156387 L1.58555245,0.139156387 C1.51918243,0.139156387 1.4528124,0.203131543 1.4528124,0.2671067 L1.1209623,2.44226202 C1.1209623,2.50623718 1.1209623,2.50623718 1.18733232,2.50623718 L1.58555245,2.50623718 C1.65192247,2.50623718 1.71829249,2.44226202 1.71829249,2.37828686 L1.78466251,1.80251045 C1.78466251,1.7385353 1.85103253,1.67456014 1.91740255,1.67456014 L2.18288264,1.67456014 C2.78021283,1.67456014 3.11206293,1.41865952 3.17843295,0.842883108 C3.24480297,0.586982482 3.17843295,0.395057013 3.04569291,0.2671067 C2.97932289,0.203131543 2.78021283,0.139156387 2.44836272,0.139156387 Z M2.58110276,0.906858264 C2.51473274,1.22673405 2.31562268,1.22673405 2.05014259,1.22673405 L1.91740255,1.22673405 L1.98377257,0.650957638 C1.98377257,0.586982482 2.05014259,0.586982482 2.05014259,0.586982482 L2.11651262,0.586982482 C2.24925266,0.586982482 2.44836272,0.586982482 2.51473274,0.650957638 C2.58110276,0.714932795 2.58110276,0.842883108 2.58110276,0.906858264 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M5.10316356,0.906858264 L4.70494344,0.906858264 C4.63857342,0.906858264 4.63857342,0.906858264 4.63857342,0.970833421 L4.63857342,1.09878373 L4.63857342,1.03480858 C4.57220339,0.906858264 4.37309333,0.842883108 4.10761325,0.842883108 C3.6430231,0.842883108 3.24480297,1.16275889 3.17843295,1.67456014 C3.11206293,1.93046077 3.17843295,2.12238624 3.31117299,2.31431171 C3.44391304,2.44226202 3.6430231,2.50623718 3.84213316,2.50623718 C4.24035329,2.50623718 4.43946335,2.25033655 4.43946335,2.25033655 L4.43946335,2.37828686 C4.43946335,2.44226202 4.43946335,2.44226202 4.50583337,2.44226202 L4.9040535,2.44226202 C4.97042352,2.44226202 5.03679354,2.37828686 5.03679354,2.31431171 L5.23590361,0.906858264 C5.16953358,0.970833421 5.16953358,0.906858264 5.10316356,0.906858264 Z M4.50583337,1.7385353 C4.43946335,1.99443592 4.24035329,2.12238624 4.04124323,2.12238624 C3.90850318,2.12238624 3.84213316,2.05841108 3.77576314,1.99443592 C3.70939312,1.93046077 3.70939312,1.80251045 3.70939312,1.7385353 C3.77576314,1.48263467 3.97487321,1.35468436 4.17398327,1.35468436 C4.30672331,1.35468436 4.37309333,1.41865952 4.43946335,1.48263467 C4.50583337,1.48263467 4.57220339,1.61058499 4.50583337,1.7385353 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M11.4083156,0.906858264 L11.0100954,0.906858264 C10.9437254,0.906858264 10.9437254,0.906858264 10.9437254,0.970833421 L10.9437254,1.09878373 L10.9437254,1.03480858 C10.8773554,0.906858264 10.6782453,0.842883108 10.4127652,0.842883108 C9.9481751,0.842883108 9.54995498,1.16275889 9.48358495,1.67456014 C9.41721493,1.93046077 9.48358495,2.12238624 9.616325,2.31431171 C9.74906504,2.44226202 9.9481751,2.50623718 10.1472852,2.50623718 C10.5455053,2.50623718 10.7446154,2.25033655 10.7446154,2.25033655 L10.7446154,2.37828686 C10.7446154,2.44226202 10.7446154,2.44226202 10.8109854,2.44226202 L11.2092055,2.44226202 C11.2755755,2.44226202 11.3419455,2.37828686 11.3419455,2.31431171 L11.5410556,0.906858264 C11.5410556,0.970833421 11.4746856,0.906858264 11.4083156,0.906858264 Z M10.8109854,1.7385353 C10.7446154,1.99443592 10.5455053,2.12238624 10.3463952,2.12238624 C10.2136552,2.12238624 10.1472852,2.05841108 10.0809151,1.99443592 C10.0145451,1.93046077 10.0145451,1.80251045 10.0145451,1.7385353 C10.0809151,1.48263467 10.2800252,1.35468436 10.4791353,1.35468436 C10.6118753,1.35468436 10.6782453,1.41865952 10.7446154,1.48263467 C10.8109854,1.48263467 10.8773554,1.61058499 10.8109854,1.7385353 Z"
                            id="Shape"
                            fill="#69B3E7"></path>
                        <path
                            d="M7.35974428,0.906858264 L6.96152415,0.906858264 C6.89515413,0.906858264 6.89515413,0.906858264 6.82878411,0.970833421 L6.23145392,1.80251045 L5.96597384,0.970833421 C5.96597384,0.906858264 5.89960382,0.906858264 5.8332338,0.906858264 L5.43501367,0.906858264 C5.36864365,0.906858264 5.36864365,0.970833421 5.36864365,0.970833421 L5.8332338,2.31431171 L5.36864365,2.89008811 C5.30227363,2.95406327 5.36864365,3.01803843 5.43501367,3.01803843 L5.8332338,3.01803843 C5.89960382,3.01803843 5.89960382,3.01803843 5.96597384,2.95406327 L7.35974428,0.970833421 C7.49248432,0.970833421 7.4261143,0.906858264 7.35974428,0.906858264 Z"
                            id="Shape"
                            fill="#003087"></path>
                        <path
                            d="M11.9392757,0.203131543 L11.6074256,2.44226202 C11.6074256,2.50623718 11.6074256,2.50623718 11.6737956,2.50623718 L12.0720158,2.50623718 C12.1383858,2.50623718 12.2047558,2.44226202 12.2047558,2.37828686 L12.5366059,0.203131543 C12.5366059,0.139156387 12.5366059,0.139156387 12.4702359,0.139156387 L12.0720158,0.139156387 C11.9392757,0.139156387 11.9392757,0.139156387 11.9392757,0.203131543 Z"
                            id="Shape"
                            fill="#69B3E7"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PaypalCredit;
