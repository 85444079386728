import React from 'react';

const Cruise = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12,9.27825278 L24,13.8130981 L20.3503913,24 L3.64960867,24 L0,13.8130981 L12,9.27825278 Z M17.8423841,4.57684577 C19.2198675,4.57684577 20.3481036,5.71325713 20.3481036,7.08607086 L20.3481036,7.08607086 L20.3481036,11.0929109 L11.9940999,7.90927909 L3.65213727,11.0809108 L3.65213727,7.08607086 C3.65213727,5.71325713 4.76712824,4.57684577 6.15665262,4.57684577 L6.15665262,4.57684577 Z M7.82167369,3.32883329 L16.172065,3.32883329 L16.172065,0 L7.82167369,0 L7.82167369,3.32883329 Z"
                    id="path-3"></path>
            </defs>
            <g
                id="X-Global-Icons/Destination/cruise"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-3"></use>
            </g>
        </svg>
    );
};

export default Cruise;
