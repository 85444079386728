import React from 'react';

const Closet = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/closet"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12,12 L3,18.3 L21,18.3 L12,12 Z M12,2.5 C13.9329966,2.5 15.5,4.06700338 15.5,6 C15.5,7.58556977 14.4456654,8.9248865 12.9998374,9.35510906 L12.9999327,10.3840332 L12.9940824,10.486 L23.5881717,18.1912639 C24.3714165,18.7608965 23.9684801,20 23,20 L1,20 C0.0315199235,20 -0.371416482,18.7608965 0.411828302,18.1912639 L11.0051461,10.4867742 C11.0016988,10.4529964 10.9999327,10.418721 10.9999327,10.3840332 L10.9999327,8.5 C10.9999327,7.94771525 11.4476479,7.5 11.9999327,7.5 C12.8284271,7.5 13.5,6.82842712 13.5,6 C13.5,5.17157288 12.8284271,4.5 12,4.5 C11.1715729,4.5 10.5,5.17157288 10.5,6 C10.5,6.55228475 10.0522847,7 9.5,7 C8.94771525,7 8.5,6.55228475 8.5,6 C8.5,4.06700338 10.0670034,2.5 12,2.5 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Closet;
