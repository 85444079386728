import React from 'react';
import styled from 'styled-components';
import WarningIconFilled from '../huge/assets/exclamation-triangle-solid.svg';
import theme from 'configs/theme';

const CustomDiv = styled.div`
    color: ${theme.error};
    display: flex;
    font-size: 1em;
    align-items: bottom;
    div:first-child#icon-error-label {
        display: block;
    }
`;

const ErrorLabel = props => {
    return (
        <CustomDiv>
            <WarningIconFilled
                style={{
                    width: '12px',
                    height: '12px',
                    minWidth: '12px',
                    marginRight: '5px',
                }}
            />
            <span style={{ fontSize: '11.2px', paddingTop: '2px' }}>
                {props.error}
            </span>
        </CustomDiv>
    );
};
export default ErrorLabel;
