export const MODULE_NAME = 'notification_preferences';

export const FIELDS = {
    ACCEPT_TERMS: 'accept_terms',
    CODE: 'code',
    EMAIL: 'email',
    NOTIFICATION_PREFERENCES: 'notification_preferences',
    NOTIFICATIONS: 'notifications',
    PHONE: 'phone',
    REMEMBER_SETTINGS: 'remember_notification_settings',
};

export const LABELS = {
    ACCEPT: 'I accept the United Cargo',
    ADD_CONTACT: 'Add extra contact',
    ADD_EMAIL: 'Add my email',
    ADD_PHONE: 'Add my phone number',
    ALL: 'All',
    APPLY_PREFERENCES: "Apply above contact's preferences",
    APPLY_CONTACT_INFO: 'Apply my contact information to these notifications',
    CONTACT_DETAILS: 'Contact details',
    DELETE_CONTACT: 'Delete extra contact',
    EMAIL: 'Email address',
    MY_CONTACT_DETAILS: 'Agent contact details',
    MY_PHONE: 'My phone number',
    NOTIFICATIONS: 'Notification Type',
    PHONE: 'Phone',
    REMEMBER_SETTINGS: 'Remember preferences for future bookings',
    TERMS: 'terms and conditions',
};

export const ERROR_LABELS = {};
