import * as c from './actionTypes';

export const onChange = (pagination, filters, sorter, endpoint, table_id) => ({
    type: c.TABLE_CHANGE,
    pagination,
    filters,
    sorter,
    endpoint,
    table_id,
});
export const rowsReceived = (rows, pagination, table_id) => ({
    type: c.ROWS_RECEIVED,
    rows,
    pagination,
    table_id,
});
export const deleteSelected = (idToRemove, section) => ({
    type: c.DELETE_SELECTED,
    idToRemove,
    section,
});
export const enableSelected = (idToEnable, section) => ({
    type: c.ENABLE_SELECTED,
    idToEnable,
    section,
});
export const disableSelected = (idToDisable, section) => ({
    type: c.DISABLE_SELECTED,
    idToDisable,
    section,
});
export const onDelete = (table_id, id) => ({
    type: 'DELETE',
    table_id,
    id,
});
export const changeLastTableOpened = (table_id, row_id) => ({
    type: c.LAST_TABLE_OPENED,
    table_id,
    row_id,
});
