import React from 'react';

const Kiosk = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M6,23.4275971 C6,23.7431987 6.26598955,24 6.59441797,24 L17.3226632,24 C17.643599,24 17.9170812,23.7479987 17.9170812,23.4275971 L17.9170812,21.8807894 C17.9170812,21.5675878 17.6523404,21.3131866 17.3276583,21.3131866 L6.58942286,21.3131866 C6.263492,21.3131866 6,21.5675878 6,21.8807894 L6,23.4275971 Z M10.2574562,11.8506593 C9.92403272,11.8506593 9.66303828,11.5878579 9.66303828,11.2794564 C9.66303828,10.9590548 9.92403272,10.7082535 10.2574562,10.7082535 L13.6591254,10.7082535 C13.9800612,10.7082535 14.2535434,10.9590548 14.2535434,11.2794564 C14.2535434,11.5878579 13.9800612,11.8506593 13.6591254,11.8506593 L10.2574562,11.8506593 Z M6.97442088,20.1715009 L16.9284243,20.1715009 L16.9284243,9.56584783 L6.97442088,9.56584783 L6.97442088,20.1715009 Z M15.2289633,6.47643238 C15.2289633,6.75123376 14.995442,6.98643493 14.6969842,6.98643493 L9.38593461,6.98643493 C9.09871584,6.98643493 8.85520428,6.75123376 8.85520428,6.47643238 L8.85520428,2.63761319 C8.85520428,2.35201176 9.09871584,2.12761064 9.38593461,2.12761064 L14.6969842,2.12761064 C14.995442,2.12761064 15.2289633,2.35201176 15.2289633,2.63761319 L15.2289633,6.47643238 Z M17.3231627,8.42284211 C17.6428497,8.42284211 17.9063417,8.17324087 17.9200783,7.86723934 C17.9737757,6.70683353 17.9525465,3.06241531 17.8688784,1.40520703 C17.8576394,1.15320577 17.6665765,0.94800474 17.416821,0.879604398 C15.0391492,0.284401422 13.290861,0 12.0658106,0 C10.8282723,0 9.06874519,0.284401422 6.66609778,0.879604398 C6.41634233,0.94800474 6.22652819,1.15320577 6.21404042,1.40520703 C6.13037234,2.98201491 6.11039191,6.69363347 6.16408933,7.86723934 C6.17782588,8.17324087 6.44131788,8.42284211 6.75975607,8.42284211 L17.3231627,8.42284211 Z"
                    id="path-travel-13"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/kiosk"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-13"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-13"></use>
            </g>
        </svg>
    );
};

export default Kiosk;
