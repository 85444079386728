import {
    AEV,
    EXP,
    GCR,
    HUM,
    LFG,
    QPK,
    TCO,
} from '../common/initialValuesByProductType/productCodes';

export const FIELDS = {
    ACCOUNT_NUMBER: 'account_number',
    ACCOUNT_STATUS: 'account_status',
    AIRCRAFT_MODE: 'aircraft_mode',
    AIRCRAFT_TYPE: 'aircraft_type',
    ALLOTMENT: 'allotment',
    ALLOTMENT_ID: 'allotment_id',
    AWB_NUMBER: 'awb_number',
    AWB_TYPE: 'awb_type',
    BY_PIECE_PRODUCT_EDIT: 'byPieceProductOnEdit',
    COMMODITY_CODE: 'commodity',
    COMMODITY_DESCRIPTION: 'commodity_description',
    CONTAINER_ACTUAL_WEIGHT: 'container_actual_weight',
    CONTAINER_ACTUAL_WEIGHT_UNIT: 'container_actual_weight_unit',
    CONTAINER_LIST: 'container_list',
    CONTAINER_TYPE: 'container_type',
    DELIVER_DATE: 'deliver_date',
    DEPARTURE_DATE: 'departure_date',
    DESTINATION: 'destination',
    DRY_ICE_MORE_THAN: 'dry_ice_more_than',
    FREIGHTER: 'freighter',
    HAS_OVERFLOW_WEIGHT: 'has_overflow_weight',
    IS_ALLOTMENT_BOOKING: 'isAllotmentBooking',
    IS_DANGEROUS_GOODS: 'is_dangerous_goods',
    IS_RDS: 'is_rds',
    IS_DRY_ICE: 'is_dry_ice',
    IS_MEDEVAC: 'is_medevac',
    IS_UPLOAD_CSV: 'isUploadCSV',
    LINEAR_VOLUME: 'linear_volume',
    LIFEGUARD_XL: 'lifeguard_xl',
    MEASURE_BY: 'measure_by',
    NARROWBODY: 'narrowbody',
    ORIGIN: 'origin',
    OVERFLOW_WEIGHT: 'uld_overflow_weight',
    OVERFLOW_WEIGHT_UNIT: 'uld_overflow_weight_unit',
    PACKAGE_LIST: 'package_list',
    PACKAGE_LIST_CSV: 'package_list_csv',
    PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT: 'overweight',
    PACKAGE_TOTAL_SHIPMENT_PIECES: 'package_total_shipment_pieces',
    PACKAGE_TOTAL_SHIPMENT_VOLUME: 'package_total_shipment_volume',
    PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT: 'package_total_shipment_volume_unit',
    PACKAGE_TOTAL_SHIPMENT_WEIGHT: 'package_total_shipment_weight',
    PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT: 'package_total_shipment_weight_unit',
    PACKAGE_TOTAL_SHIPMENT_CRATED: 'package_total_shipment_weight_unit',
    PACKAGE_TOTAL_SHIPMENT_FORKLIFTABLE: 'package_total_shipment_weight_unit',
    PACKAGE_TOTAL_SHIPMENT_STACKABLE: 'package_total_shipment_weight_unit',
    PACKAGE_TOTAL_SHIPMENT_TURNABLE: 'package_total_shipment_weight_unit',
    PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT: 'package_total_shipment_dry_ice_weight',
    PACKAGE_TOTAL_SHIPMENT_DRY_ICE_WEIGHT_UNIT: 'package_total_shipment_dry_ice_weight_unit',
    PASSENGER: 'passenger',
    PAYMENT_INFO: 'payment_info',
    PRODUCT: 'product_id',
    PRODUCT_TYPE: 'product_type',
    SHC: 'shc_code',
    STOPS: 'stops',
    TEMPLATE_DESCRIPTION: 'template_description',
    TEMPLATE_ID: 'template_id',
    TEMPLATE_NAME: 'template_name',
    TENDER_DATE: 'tender_date',
    TRUCK: 'truck',
    UAX: 'uax',
    UN_NUMBER: 'un_number',
    VIA: 'via',
    WIDEBODY: 'widebody',
};

export const LABELS = {
    ACCOUNT_NUMBER: 'Select the account number',
    AIRCRAFT_MODE: 'Aircraft mode',
    AIRCRAFT_TYPE: 'Aircraft type',
    ALLOTMENT_ID: 'Allotment ID',
    AWB_NUMBER: 'Your air waybill number',
    AWB_TYPE: 'AWB type',
    COMMODITY_CODE: 'Commodity code',
    COMMODITY_DESCRIPTION: 'Manifest description',
    CONTAINER_ACTUAL_WEIGHT: 'Container actual weight',
    CONTAINER_ACTUAL_WEIGHT_UNIT: 'Container actual weight unit',
    CONTAINER_TYPE: 'Container type',
    DANGEROUS_GOODS: 'Dangerous Goods',
    RDS: 'RDS',
    DELIVER_DATE: 'Deliver by date',
    DEPARTURE_DATE: 'Departure date',
    DESTINATION: 'Destination',
    DRY_ICE: 'Dry Ice',
    DRY_ICE_MORE_THAN:
        'Does each piece of the shipment contain dry ice of 2.5 kgs or under?',
    IS_ALLOTMENT_BOOKING: 'isAllotmentBooking',
    IS_DANGEROUS_GOODS: 'Does the shipment contain dangerous goods?',
    IS_RDS: 'Does the shipment contain Biological Substance (RDS)?',
    IS_DRY_ICE: 'Does the shipment contain dry ice?',
    IS_MEDEVAC: 'Is the shipment for a life-saving medical situation?',
    LIFEGUARD: 'Lifeguard',
    LIFEGUARD_XL: 'What are you booking today?',
    MEASURE_BY: 'Measure by',
    MEDEVAC: 'Medevac',
    ORIGIN: 'Origin',
    OVERFLOW_WEIGHT: 'Overflow weight',
    PACKAGE_LIST: 'Package list',
    PACKAGE_TOTAL_SHIPMENT_OVERWEIGHT: 'package_total_shipment_overweight',
    PACKAGE_TOTAL_SHIPMENT_PIECES: 'package_total_shipment_pieces',
    PACKAGE_TOTAL_SHIPMENT_VOLUME: 'package_total_shipment_volume',
    PACKAGE_TOTAL_SHIPMENT_VOLUME_UNIT: 'package_total_shipment_volume_unit',
    PACKAGE_TOTAL_SHIPMENT_WEIGHT: 'package_total_shipment_weight',
    PACKAGE_TOTAL_SHIPMENT_WEIGHT_UNIT: 'package_total_shipment_weight_unit',
    PAYMENT_INFO: 'Payment information',
    PRODUCT: 'Product',
    PRODUCT_TYPE: 'Product type',
    SHC: 'SHC code',
    STOPS: 'Stops',
    TEMPLATE: 'Booking templates',
    TEMPLATE_ID: 'Use a saved template',
    TENDER_DATE: 'Tender by date',
    UN_NUMBER: 'UN Number',
    VIA: 'Via airport',
};

export const ERROR_LABELS = {
    ACCOUNT_NUMBER: 'Account number',
    ALLOTMENT_BUP:
        'Shipper is not permitted to book shipper built ULDs. Contact Sales Representative if user wants to ship BUP',
    AWB_NUMBER: 'AWB Number',
    BLH:
        'Bookings for this account are currently disabled. Please contact Cargo Accounting for further information.',
    CONTAINER_LIST: 'Container list',
    CONTAINER_TYPE: 'Container type',
    CONTAINER_WEIGHT: 'Weight',
    FROZEN:
        'Bookings for this account are currently disabled. Please contact Cargo Accounting for further information.',
    INACTIVE:
        'Bookings for this account are currently disabled. Please contact Cargo Accounting for further information.',
    INVALID: 'Please see below account description:',
    DECIMALS_ERROR: 'Max 2 decimals.',
};

export const agentStatusRestricted = ['BLH', 'FROZEN', 'INACTIVE', 'INVALID'];

export const SPECIAL_SHC_TO_GET = [
    {
        product: 10,
        name: 'BUP',
    },
    {
        product: 15,
        name: 'H7E',
    },
    {
        product: 12,
        name: 'LHO',
    },
    {
        product: 9,
        name: 'LHO',
    },
    {
        product: 9,
        name: 'LHX',
    },
];

export const DEFAULT_LIST_FILTER = {
    GCR: 'GEN_EXP_DEFAULT_LIST',
    EXP: 'GEN_EXP_DEFAULT_LIST',
    QPK: 'QPK_QPI_DEFAULT_LIST',
    QPI: 'QPK_QPI_DEFAULT_LIST',
    LFG: 'LFG_DEFAULT_LIST',
    LXL: 'LXL_DEFAULT_LIST',
};
