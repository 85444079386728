import React from 'react';

const CircleQuestion = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.0060606,16 C12.5515152,16 13,16.4484848 13,16.9939394 C13,17.5393939 12.5515152,18 12.0060606,18 C11.4484848,18 11,17.5393939 11,16.9939394 C11,16.4484848 11.4484848,16 12.0060606,16 Z M12.0035427,6 C14.0923838,6 15.5,7.19126245 15.5,9.00881024 C15.5,10.3334967 14.3680725,11.0626221 13.8380096,11.4647791 C13.0229471,12.0831645 12.746741,12.495972 12.746741,13.264581 C12.746741,13.6707417 12.3927569,14 11.9560954,14 C11.519434,14 11.1654499,13.6707417 11.1654499,13.264581 C11.1654499,11.9696038 11.6983339,11.2232716 12.8274403,10.3334967 C13.2506409,10 13.918709,9.72674561 13.918709,9.00881024 C13.918709,8.03621503 13.2506498,7.47083804 12.0035427,7.47083804 C10.9119851,7.47083804 10.2101796,8.09218446 10.072391,8.93386708 C10.0066577,9.3353994 9.60342016,9.61134041 9.17173466,9.55019859 C8.74004917,9.48905677 8.44338629,9.11398528 8.50911957,8.71245296 C8.75973239,7.18158248 10.0943198,6 12.0035427,6 Z M0,12 C0,18.624 5.364,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 C5.364,0 0,5.376 0,12 Z"
                    id="path-alert-4"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/circle-question"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-4"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-alert-4"></use>
            </g>
        </svg>
    );
};

export default CircleQuestion;
