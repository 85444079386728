import React from 'react';

const DownloadFile = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M16.1617312,0 L16.1617312,5.904 C16.2215262,6.168 16.4487472,6.384 16.7477221,6.384 L16.7477221,6.384 L22.5,6.384 L22.5,23.4 C22.5,23.736 22.2369021,24 21.9020501,24 L21.9020501,24 L2.09794989,24 C1.76309795,24 1.5,23.736 1.5,23.4 L1.5,23.4 L1.5,0.6 C1.5,0.264 1.76309795,0 2.09794989,0 L2.09794989,0 L16.1617312,0 Z M12,8 C11.7383915,8 11.5263159,8.22135461 11.5263159,8.49440936 L11.5263159,8.49440936 L11.526,16.343 L8.29833177,13.1359007 C8.1289955,12.9676712 7.87331513,12.9565555 7.69266168,13.097392 L7.62874869,13.1573183 C7.44945147,13.3584052 7.45853579,13.6748424 7.64903909,13.8641006 L7.64903909,13.8641006 L11.6753537,17.8641 C11.8577457,18.0453 12.1422543,18.0453 12.3246463,17.8641 L12.3246463,17.8641 L16.3509609,13.8641006 C16.5414642,13.6748424 16.5505485,13.3584052 16.3712513,13.1573183 C16.1919541,12.9562315 15.8921715,12.9466425 15.7016682,13.1359007 L15.7016682,13.1359007 L12.473,16.342 L12.4736841,8.49440936 C12.4736841,8.25169403 12.3061182,8.04982819 12.0851453,8.0079656 Z M17.3455524,0.35952 L22.1650285,5.18352 L17.3455524,5.18352 L17.3455524,0.35952 Z"
                    id="path-digital-6"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/download-file"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-6"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-6"></use>
            </g>
        </svg>
    );
};

export default DownloadFile;
