import React from 'react';

const UpgradeConfirmed = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Upgrade/upgrade-confirmed"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L20,0 Z M19.6254385,6.31546462 C19.2035322,5.99248887 18.5965225,6.0686049 18.2696444,6.48547453 L18.2696444,6.48547453 L11.155,15.558 L5.65097247,11.3326053 C5.25232284,11.0265562 4.68328098,11.0711902 4.32473791,11.4182739 L4.23970796,11.511318 C3.89215852,11.9415714 3.9605142,12.5591377 4.39238463,12.8906908 L4.39238463,12.8906908 L10.6202351,17.6719011 C10.7742332,17.7901277 10.9536585,17.8560228 11.1365539,17.8723669 C11.4774631,17.9291475 11.8394885,17.8039789 12.0664562,17.5145255 L12.0664562,17.5145255 L19.7975026,7.65507317 C20.1243806,7.23820354 20.0473449,6.63844037 19.6254385,6.31546462 Z"
                    id="Combined-Shape"
                    fill={fill || '#1B7742'}></path>
            </g>
        </svg>
    );
};

export default UpgradeConfirmed;
