import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'antd';
import { Flex } from '@freightos/uikit';

const { Content } = Layout;

const ErrorBoundaryMessage = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <Content style={{ padding: '16px' }}>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column">
                    <h1>{t('Aaaah! Something went wrong')}</h1>
                    <p
                        style={{
                            textAlign: 'center',
                            marginTop: '10px',
                            color: '#333333',
                            fontSize: '18px',
                            lineHeight: '23px',
                        }}>
                        {t('Brace yourself until we get the error fixed.')}
                        <br />
                        {t('You may also refresh the page or try again later.')}
                    </p>
                </Flex>
            </Content>
        </Layout>
    );
};

export default ErrorBoundaryMessage;
