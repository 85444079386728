import React from 'react';

const UpgradeSkipped = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Upgrade/upgrade-skipped"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L20,0 Z M12,16.25 C11.0887302,16.25 10.35,16.9887302 10.35,17.9 C10.35,18.8112698 11.0887302,19.55 12,19.55 C12.9112698,19.55 13.65,18.8112698 13.65,17.9 C13.65,16.9887302 12.9112698,16.25 12,16.25 Z M12,4 C11.3786797,4 10.875,4.50367966 10.875,5.125 L10.875,5.125 L10.875,12.875 C10.875,13.4963203 11.3786797,14 12,14 C12.6213203,14 13.125,13.4963203 13.125,12.875 L13.125,12.875 L13.125,5.125 C13.125,4.50367966 12.6213203,4 12,4 Z"
                    id="Combined-Shape"
                    fill={fill || '#D50032'}></path>
            </g>
        </svg>
    );
};

export default UpgradeSkipped;
