import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Box, Button, Flex, InputField, FormikHOC } from '@freightos/uikit';
import { useTranslation } from 'react-i18next';
import { MODULE_NAME } from 'modules/bookingSteps/constants';
import { push } from 'connected-react-router';
import { doLogin } from './actions';

const Login = props => {
    const { t } = useTranslation(MODULE_NAME);
    const { loggedIn } = useSelector(state => state.user);
    const redirectTo = useSelector(state => state.router.location.state);

    const dispatch = useDispatch();

    const handleSubmit = (values, actions) => {
        const { email, password } = values;
        dispatch(doLogin(email, password));
        actions.setSubmitting(false);
    };

    useEffect(() => {
        if (loggedIn) {
            dispatch(push(redirectTo ? redirectTo.from : '/'));
        }
    }, [dispatch, loggedIn, redirectTo]);
    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{ email: '', password: '' }}
                render={({ isSubmitting }) => (
                    <Form>
                        <div
                            style={{
                                width: '40%',
                                margin: '120px 30% auto 30%',
                                padding: '6%',
                                border: '1px solid #AAA',
                                borderRadius: '8px',
                            }}>
                            <Field
                                label={t('MileagePlusⓇ number (required)')}
                                name="email"
                                component={FormikHOC}
                                uiComponent={InputField}
                            />
                            <Button variation="link" size="sm">
                                {t('Forgot your MileagePlus number?')}
                            </Button>
                            <br />
                            <br />
                            <Field
                                label={t('Password (required)')}
                                type="password"
                                name="password"
                                component={FormikHOC}
                                uiComponent={InputField}
                            />
                            <Button variation="link" size="sm">
                                {t('Forgot your password?')}
                            </Button>
                            <br />
                            <br />
                            <Button
                                variation="primary"
                                type="submit"
                                disabled={isSubmitting}>
                                {t('Sign in')}
                            </Button>
                            <br />
                            <br />
                            <Flex>
                                <Box width={2 / 4}>
                                    <a
                                        href="https://custregqa-ualcargo.cs200.force.com/UCCargoCustRegCommunity/services/oauth2/authorize?response_type=code&client_id=3MVG9jfQT7vUue.Eb.zRnkKZmaD6BZVzU0n8kLC8nzp7F6OfVGCMgE__sJNU6HOEYVVRRLS5Z_dpmI5NTYAMZ&redirect_uri=https://ua-alpha.webcargonet.com/salesforce_callback_cargo.php"
                                        disabled={isSubmitting}>
                                        {t('I have a Commercial Account')}
                                    </a>
                                </Box>
                                <Box width={2 / 4}>
                                    <Button
                                        variation="primary"
                                        type="submit"
                                        disabled={isSubmitting}>
                                        {t('Continue as guest')}
                                    </Button>
                                </Box>
                            </Flex>
                        </div>
                    </Form>
                )}
            />
        </div>
    );
};
export default Login;
