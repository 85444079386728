import React from 'react';

const WeatherLightning = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-lightning"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M17.97465,9.2358691 L13.23015,14.199245 L18.32565,14.199245 L5.43465,24 L11.10915,16.5046874 L6.31665,16.5061873 L12.01515,9.2358691 L17.97465,9.2358691 Z M8.5545,-2.04281037e-14 C10.8375,-2.04281037e-14 12.99,0.890977726 14.6025,2.50343741 C15.153,3.05092373 15.621,3.66440839 15.999,4.32439189 L15.999,4.32439189 L16.161,4.61538462 L16.494,4.55388615 C16.881,4.48038799 17.274,4.44588885 17.6685,4.44588885 C21.156,4.44588885 24,7.27931802 24,10.7682308 C24,12.8531787 22.9755,14.7056324 21.408,15.8531037 L21.408,15.8531037 L20.6685,14.6321342 C21.825,13.7351566 22.5765,12.3416915 22.5765,10.7682308 C22.5765,8.06229844 20.376,5.86335342 17.6685,5.86335342 C16.857,5.86335342 16.074,6.05834854 15.369,6.4318392 C14.4525,3.4919127 11.6865,1.42196445 8.5545,1.42196445 C4.62,1.42196445 1.4205,4.61688458 1.4205,8.54378641 C1.4205,10.7532312 2.4405,12.7166821 4.02,14.0231494 L4.02,14.0231494 L3.0795,15.1031224 C2.886,14.9396265 2.6895,14.7671308 2.505,14.5856354 C0.888,12.9716757 0,10.8267293 0,8.54378641 C0,6.26534337 0.888,4.11739707 2.505,2.50343741 C4.122,0.890977726 6.267,-2.04281037e-14 8.5545,-2.04281037e-14 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherLightning;
