// React
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

// Constants
import {
    MODULE_ROUTE as BOOKINGS_ROUTE,
    BOOKING_SHOW,
} from 'modules/bookings/constants';

// Constants
import { MODULE_ROUTE, ROUTES_CONSTANTS } from '../../constants';
import { DETAILS_ROUTE } from 'modules/bookingSteps/constants';
import { isTemplate } from '../../../../basic_components/sagaFormatters';

// Actions
import { resetForm, saveBookingProduct } from '../../actions';

// Components
import {Button, Box, Flex, Alert} from '@freightos/uikit';
import { default as GlobalIcon } from 'icons/X Global Icons/GlobalIcons';
import { PaddedChip } from './styled';
import BookgingSubHeading from './BookgingSubHeading';
import GoBackButton from './headingComponents/GoBackButton';

const REVIEW_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.REVIEW_BOOK}`;
const REJECTED_STATUS = ['Rejected', 'Rejected at tender'];

function BookingHeading() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const status = useSelector(state => state?.bookingSteps.status);
    const form = useSelector(state => state?.bookingSteps.form);
    const showAlertOfBookingStatus = useSelector(
        state => state?.bookingSteps.showAlertOfBookingStatus
    );

    const bookingProduct = useSelector(
        state => state?.bookingSteps.bookingProduct
    );
    const products = useSelector(
        state => state?.bookingSteps.products
    );
    const productItem = products?.find(item => item.code === "LFG");
    const lfgCargoDisabled= productItem?.cargo_enabled ?? 0;
    const isCargoDisabled = (bookingProduct?.code === 'LXL' || bookingProduct?.code === 'LFG') ? (lfgCargoDisabled === 0) : (bookingProduct?.cargo_enabled === 0);

    const isShowPage = status === 'show';
    const isBookingRejected = REJECTED_STATUS.includes(
        form?.booking_data?.booking_status
    );

    if (
        showAlertOfBookingStatus &&
        !pathname.includes(BOOKING_SHOW, REVIEW_ROUTE) &&
        isShowPage
    ) {
        //Prevent browser before button
        return <Redirect to={BOOKINGS_ROUTE} />;
    }

    return (
        <Box>
            {!isShowPage && (
                <h2
                    style={{
                        marginBottom: '31px',
                        fontSize: '32px',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    {t('Make a booking')}
                    <PaddedChip label="BETA" type="primary" ml={2} />
                </h2>
            )}
            <GoBackButton
                isShowPage={isShowPage}
                isTemplate={isTemplate(form)}
            />

            {isTemplate(form) && isShowPage ? (
                <>
                    <Box
                        pb={3}
                        style={{
                            borderBottom: '2px solid #ccc',
                        }}>
                        {/* <h2>{t('My Templates')}</h2> */}
                        <h5
                            style={{
                                whiteSpace: 'nowrap',
                                margin: '25px 0',
                                fontWeight: 'bold',
                                fontSize: '1.6em',
                            }}>
                            {form.template_name}
                        </h5>
                        {isCargoDisabled &&
                            <Alert
                                title={
                                    <Flex alignItems="top">
                                        <Flex style={{width:'45px', padding:'4px'}}>
                                            <GlobalIcon
                                                fill="#F5A213"
                                                width="15px"
                                                height="15px"
                                                iconName="YellowWarning"
                                            />
                                        </Flex>
                                        <Flex flexDirection="column">
                                            <p>{t('The template you selected is unavailable')}</p>
                                            <p style={{fontWeight:'normal'}}>{t(`We are currently not accepting online ${ bookingProduct.code } bookings at this time.
                                        Please contact us by phone at 1-800-UA-CARGO (1-800-822-2746), by email at cargo@united.com or your local sales office.`)}</p>
                                        </Flex>
                                    </Flex>
                                }
                                level="warning"
                                style={{padding:'5px'}}
                                theme={{
                                    alertTitleFontSize: '15px',
                                }}>
                            </Alert>
                        }

                    </Box>
                </>
            ) : (
                <>
                    <Flex alignItems="center" justifyContent="space-between">
                        {isShowPage && (
                            <>
                                <h2
                                    style={{
                                        marginBottom: '31px',
                                        fontSize: '32px',
                                    }}>
                                    {t('Booking Management')}
                                </h2>
                                <Button
                                    data-cc="bookings_new_booking"
                                    onClick={() => {
                                        dispatch(resetForm());
                                        dispatch(saveBookingProduct(null));
                                    }}
                                    mb={0}
                                    variation="primary">
                                    <NavLink
                                        style={{ color: 'white' }}
                                        to={DETAILS_ROUTE}>
                                        {t('New booking')}
                                    </NavLink>
                                </Button>
                            </>
                        )}
                    </Flex>
                    <BookgingSubHeading
                        form={form}
                        isBookingRejected={isBookingRejected}
                        isShowPage={isShowPage}
                        showAlertOfBookingStatus={showAlertOfBookingStatus}
                    />
                </>
            )}
        </Box>
    );
}

export default BookingHeading;
