import React, { useEffect } from 'react';

function SFLogin({ path }) {
    useEffect(() => {
        sessionStorage.setItem(process.env.REACT_APP_LOGIN_LAST_ROUTE, path);
        window.location.href = process.env.REACT_APP_SALESFORE_SSO_REDIRECT;
    }, []);
    return <></>;
}

export default SFLogin;
