import { all } from 'redux-saga/effects';
import requestSaga from '../api/requestSaga';
import LoginSaga from '../modules/login/sagas';
import TablesSaga from '../basic_components/AntdTable/sagas';
// Modules
import ContactsSaga from '../modules/contacts/sagas';
import BookingStepsSaga from '../modules/bookings/sagas';
import BookingSaga from '../modules/bookingSteps/sagas';
import TemplatesSaga from '../modules/templates/sagas';

export default function* rootSaga() {
    yield all([
        requestSaga(),
        BookingSaga(),
        BookingStepsSaga(),
        // PetsSaga(),
        ContactsSaga(),
        LoginSaga(),
        TablesSaga(),
        TemplatesSaga(),
    ]);
}
