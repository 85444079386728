import React from 'react';

const BoardingPass = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M23.4,4 C23.736,4 24,4.26347305 24,4.5988024 L24,4.5988024 L24,19.4011976 C24,19.7365269 23.736,20 23.4,20 L23.4,20 L0.6,20 C0.276,20 0,19.7365269 0,19.4011976 L0,19.4011976 L0,4.5988024 C0,4.26347305 0.276,4 0.6,4 L0.6,4 Z M21.936,17.005988 L17.808,17.005988 L17.808,17.9401198 L21.936,17.9401198 L21.936,17.005988 Z M21.936,16.4670659 L17.808,16.4670659 L17.808,16.7664671 L21.936,16.7664671 L21.936,16.4670659 Z M8.04,7.67664671 L7.344,7.67664671 L8.244,11.1257485 L5.292,11.1257485 L4.08,10.2155689 L3.372,10.2155689 L4.548,11.9760479 L3.372,13.7365269 L4.08,13.7365269 L5.22,12.8742515 L8.244,12.8742515 L7.344,16.3353293 L8.04,16.3353293 L10.26,12.8742515 L14.844,12.8742515 C15.324,12.8742515 15.72,12.491018 15.72,12 C15.72,11.5209581 15.324,11.1257485 14.844,11.1257485 L14.844,11.1257485 L10.26,11.1257485 L8.04,7.67664671 Z M21.936,14.9700599 L17.808,14.9700599 L17.808,15.7964072 L21.936,15.7964072 L21.936,14.9700599 Z M21.936,13.7005988 L17.808,13.7005988 L17.808,14.6347305 L21.936,14.6347305 L21.936,13.7005988 Z M21.936,12.5988024 L17.808,12.5988024 L17.808,12.8982036 L21.936,12.8982036 L21.936,12.5988024 Z M21.936,12.0598802 L17.808,12.0598802 L17.808,12.3473054 L21.936,12.3473054 L21.936,12.0598802 Z M21.936,11.5329341 L17.808,11.5329341 L17.808,11.8323353 L21.936,11.8323353 L21.936,11.5329341 Z M21.936,10.0718563 L17.808,10.0718563 L17.808,11.005988 L21.936,11.005988 L21.936,10.0718563 Z M21.936,8.68263473 L17.808,8.68263473 L17.808,9.28143713 L21.936,9.28143713 L21.936,8.68263473 Z M21.936,7.67664671 L17.808,7.67664671 L17.808,8.26347305 L21.936,8.26347305 L21.936,7.67664671 Z M21.936,6.05988024 L17.808,6.05988024 L17.808,7.30538922 L21.936,7.30538922 L21.936,6.05988024 Z"
                    id="path-travel-33"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/boarding-pass"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-33"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-33"></use>
            </g>
        </svg>
    );
};

export default BoardingPass;
