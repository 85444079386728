import { openModal } from 'basic_components/Modal';
import {
    formatNotificationOrderByOptions,
    isDraft,
    isTemplate,
} from 'basic_components/sagaFormatters';
import { push } from 'connected-react-router';
import { changeBookingStatus, setIsDraft } from 'modules/bookingSteps/actions';
import { DETAILS_ROUTE, FIELDS } from 'modules/bookingSteps/constants';

const handleEdit = (
    dispatch,
    setFieldValue,
    form,
    notificationsPreferencesStructure
) => {
    const tempNotification = formatNotificationOrderByOptions(
        notificationsPreferencesStructure
    );
    setFieldValue(FIELDS.NOTIFICATION_PREFERENCES, tempNotification);
    if (isTemplate(form)) {
        dispatch(push(DETAILS_ROUTE));
        dispatch(changeBookingStatus('use_template'));
    } else if (isDraft(form)) {
        dispatch(setIsDraft(false));
        dispatch(push(DETAILS_ROUTE));
        dispatch(changeBookingStatus());
    } else {
        dispatch(openModal('cancelModal', 'normal', 'Cancel', 800, true));
    }
};

export default handleEdit;
