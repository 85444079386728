import React from 'react';

const WeatherRainFreezing = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-rain-freezing"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M16.09335,14.3176725 L18.81735,19.1206106 C19.12035,19.6078 19.29285,20.1879302 19.29285,20.7965422 C19.29285,22.5654145 17.85735,24 16.09335,24 C14.32335,24 12.88785,22.5654145 12.88785,20.7965422 C12.88785,20.2640067 13.0210688,19.7532776 13.2543451,19.3075373 L13.36035,19.1206106 L16.09335,14.3176725 Z M7.03785,17.4349352 C8.26785,17.4349352 9.25485,18.4258036 9.25485,19.6490237 C9.25485,20.8692459 8.26785,21.8601142 7.03785,21.8601142 C5.81835,21.8601142 4.82835,20.8692459 4.82835,19.6490237 C4.82835,18.4258036 5.81835,17.4349352 7.03785,17.4349352 Z M8.553,4.61852778e-14 C10.8405,4.61852778e-14 12.9885,0.890432349 14.6025,2.50040599 C15.06375,2.95636532 15.4625,3.45812878 15.8013542,3.99528634 L15.9975,4.32174489 L16.161,4.61106045 L16.4925,4.54959963 C16.881,4.48064359 17.277,4.44166844 17.667,4.44166844 C21.156,4.44166844 24,7.27636132 24,10.7601404 C24,13.0642787 22.7530753,15.0770956 20.9034603,16.1776169 L20.6955,16.2961112 L19.9575,15.1343519 L19.9785,15.0788872 C21.528,14.2529138 22.5795,12.6279497 22.5795,10.7601404 C22.5795,8.05736343 20.376,5.85826536 17.667,5.85826536 C16.8555,5.85826536 16.0755,6.05464017 15.3675,6.42640317 C14.4555,3.48977527 11.685,1.42109405 8.553,1.42109405 C4.6185,1.42109405 1.419,4.61405854 1.419,8.54155476 C1.419,10.8484041 2.5304496,12.8996725 4.24241376,14.199339 L4.4595,14.3578469 L3.8685,15.6845011 C3.384,15.3652047 2.925,14.9964398 2.505,14.5752083 C0.891,12.9622366 0,10.8216012 0,8.54155476 C0,6.26000924 0.891,4.11487677 2.505,2.50040599 C4.1205,0.890432349 6.27,4.61852778e-14 8.553,4.61852778e-14 Z M10.27155,9.13952355 C11.95905,9.13952355 13.33005,10.5096501 13.33005,12.2005721 C13.33005,13.8899951 11.95905,15.2601217 10.27155,15.2601217 C8.57655,15.2601217 7.20705,13.8899951 7.20705,12.2005721 C7.20705,10.5096501 8.57655,9.13952355 10.27155,9.13952355 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherRainFreezing;
