import * as Yup from 'yup';
import { FIELDS } from './constants';
import { isEmptyValues } from '../../../../basic_components/jsTools';
import { checkAgentNotificationsSelection } from '../../../../basic_components/sagaFormatters';
import { GCR, EXP } from '../common/initialValuesByProductType/productCodes';

export const existNotificationError = data => {
    const errorIndexes = [];
    if (data === undefined) {
        return null;
    }
    data.forEach((notification, index) => {
        if (
            isEmptyValues(
                checkAgentNotificationsSelection(notification.email)
            ) &&
            isEmptyValues(
                checkAgentNotificationsSelection(notification.phone)
            ) &&
            !isEmptyValues(notification.notifications)
        ) {
            errorIndexes.push({ error: 'contact', index });
        }
        if (
            isEmptyValues(notification?.notifications) &&
            (!isEmptyValues(
                checkAgentNotificationsSelection(notification.email)
            ) ||
                !isEmptyValues(
                    checkAgentNotificationsSelection(notification.phone)
                ))
        ) {
            errorIndexes.push({ error: 'notifications', index });
        }
    });
    return errorIndexes.length > 0 ? errorIndexes : null;
};

export const generateNotificationSchema = product => {
    let schema;
    switch (product) {
        case GCR:
        case EXP:
            schema = Yup.object().shape({
                [FIELDS.ACCEPT_TERMS]: Yup.boolean().when(
                    [FIELDS.NOTIFICATION_PREFERENCES],
                    {
                        is: val => !isEmptyValues(val),
                        then: Yup.boolean()
                            .required(
                                'Please accept UNITED terms and conditions.'
                            )
                            .oneOf(
                                [true],
                                'Please accept UNITED terms and conditions.'
                            ),
                        otherwise: Yup.boolean(),
                    }
                ),
                [FIELDS.NOTIFICATION_PREFERENCES]: Yup.array()
                    .min(0)
                    .nullable(),
            });
            break;
        default:
            schema = Yup.object().shape({
                [FIELDS.ACCEPT_TERMS]: Yup.boolean().when(
                    [FIELDS.NOTIFICATION_PREFERENCES],
                    {
                        is: val => !isEmptyValues(val),
                        then: Yup.boolean()
                            .required(
                                'Please accept UNITED terms and conditions.'
                            )
                            .oneOf(
                                [true],
                                'Please accept UNITED terms and conditions.'
                            ),
                        otherwise: Yup.boolean(),
                    }
                ),
                [FIELDS.NOTIFICATION_PREFERENCES]: Yup.array()
                    .min(0)
                    .nullable(),
            });
            break;
    }

    return schema;
};
