import React from 'react';

const JCB = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient
                    x1="99.6817073%"
                    y1="49.7744076%"
                    x2="0.381707317%"
                    y2="49.7744076%"
                    id="linearGradient-1">
                    <stop
                        stop-color="#239DD8"
                        stop-opacity="0"
                        offset="0%"></stop>
                    <stop stop-color="#000000" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="99.0207317%"
                    y1="49.7744076%"
                    x2="-0.462195122%"
                    y2="49.7744076%"
                    id="linearGradient-2">
                    <stop
                        stop-color="#E9292B"
                        stop-opacity="0"
                        offset="0%"></stop>
                    <stop stop-color="#000000" offset="100%"></stop>
                </linearGradient>
                <linearGradient
                    x1="100.585366%"
                    y1="49.7744076%"
                    x2="0.0731707317%"
                    y2="49.7744076%"
                    id="linearGradient-3">
                    <stop
                        stop-color="#0DA54E"
                        stop-opacity="0"
                        offset="0%"></stop>
                    <stop stop-color="#000000" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g
                id="X-Global-Icons/Digital/Payment/JCB"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group" transform="translate(0.000000, 3.000000)">
                    <path
                        d="M0.52148264,18.4615385 L4.60642998,18.4615385 C5.99705036,18.4615385 7.64841205,17.4115931 7.64841205,16.0116661 L7.64841205,0 L3.73729225,0 C1.91210301,0 0.52148264,0.962449872 0.52148264,2.53736785 L0.52148264,18.4615385 L0.52148264,18.4615385 Z"
                        id="Shape"
                        fill="#239DD8"></path>
                    <path
                        d="M8.60446355,18.4615385 L12.6894109,18.4615385 C14.0800313,18.4615385 15.731393,17.4115931 15.731393,16.0116661 L15.731393,0 L11.8202732,0 C9.99508393,0 8.60446355,0.962449872 8.60446355,2.53736785 L8.60446355,18.4615385 L8.60446355,18.4615385 Z"
                        id="Shape"
                        fill="#E9292B"></path>
                    <path
                        d="M16.6005307,18.4615385 L20.685478,18.4615385 C22.0760984,18.4615385 23.7274601,17.4115931 23.7274601,16.0116661 L23.7274601,0 L19.8163403,0 C17.9911511,0 16.6005307,0.962449872 16.6005307,2.53736785 L16.6005307,18.4615385 L16.6005307,18.4615385 Z"
                        id="Shape"
                        fill="#0DA54E"></path>
                    <path
                        d="M0.52148264,18.4615385 L4.60642998,18.4615385 C5.99705036,18.4615385 7.64841205,17.4115931 7.64841205,16.0116661 L7.64841205,0 L3.73729225,0 C1.91210301,0 0.52148264,0.962449872 0.52148264,2.53736785 L0.52148264,18.4615385 L0.52148264,18.4615385 Z"
                        id="Shape"
                        fill="url(#linearGradient-1)"
                        opacity="0.75"></path>
                    <path
                        d="M8.60446355,18.4615385 L12.6894109,18.4615385 C14.0800313,18.4615385 15.731393,17.4115931 15.731393,16.0116661 L15.731393,0 L11.8202732,0 C9.99508393,0 8.60446355,0.962449872 8.60446355,2.53736785 L8.60446355,18.4615385 L8.60446355,18.4615385 Z"
                        id="Shape"
                        fill="url(#linearGradient-2)"
                        opacity="0.75"></path>
                    <path
                        d="M16.6005307,18.4615385 L20.685478,18.4615385 C22.0760984,18.4615385 23.7274601,17.4115931 23.7274601,16.0116661 L23.7274601,0 L19.8163403,0 C17.9911511,0 16.6005307,0.962449872 16.6005307,2.53736785 L16.6005307,18.4615385 L16.6005307,18.4615385 Z"
                        id="Shape"
                        fill="url(#linearGradient-3)"
                        opacity="0.75"></path>
                    <path
                        d="M1.12987905,9.23076923 L0,9.23076923 C0.347655093,10.1538462 1.82518924,10.9090909 3.47655093,10.9090909 C4.95408508,10.9090909 7.04001563,10.2377622 7.04001563,9.06293706 L7.04001563,6.7972028 L4.51951621,6.7972028 L4.51951621,9.06293706 C4.51951621,9.73426573 3.82420602,10.2377622 2.86815452,10.2377622 C2.08593056,10.2377622 1.3037066,9.81818182 1.12987905,9.23076923 L1.12987905,9.23076923 Z"
                        id="Shape"
                        fill="#FFFFFF"></path>
                    <path
                        d="M23.2928912,9.65034965 C23.2928912,10.1538462 22.6844948,10.5734266 21.9891846,10.5734266 L16.3397894,10.5734266 L16.3397894,6.7972028 L21.9891846,6.7972028 C22.6844948,6.7972028 23.2059775,7.21678322 23.2059775,7.72027972 C23.2059775,8.22377622 22.597581,8.64335664 21.9891846,8.64335664 C22.6844948,8.72727273 23.2928912,9.14685315 23.2928912,9.65034965 L23.2928912,9.65034965 Z M18.4257199,8.55944056 L20.1639954,8.55944056 C20.5985643,8.55944056 21.0331331,8.22377622 21.0331331,7.88811189 C21.0331331,7.55244755 20.5985643,7.21678322 20.1639954,7.21678322 L18.4257199,7.21678322 L18.4257199,8.55944056 L18.4257199,8.55944056 Z M20.2509092,10.2377622 C20.685478,10.2377622 21.1200469,9.9020979 21.1200469,9.56643357 C21.1200469,9.23076923 20.685478,8.8951049 20.2509092,8.8951049 L18.4257199,8.8951049 L18.4257199,10.2377622 L20.2509092,10.2377622 L20.2509092,10.2377622 Z"
                        id="Shape"
                        fill="#FFFFFF"></path>
                    <path
                        d="M15.0360828,9.98601399 L15.0360828,10.6573427 L10.7773079,10.6573427 C9.47360129,10.6573427 8.34372223,9.81818182 8.34372223,8.81118881 C8.34372223,7.8041958 9.47360129,6.96503497 10.7773079,6.96503497 L15.0360828,6.96503497 L15.0360828,7.63636364 C13.9931175,7.46853147 13.037066,7.38461538 12.5155834,7.38461538 C11.4726181,7.38461538 10.6034803,8.05594406 10.6034803,8.8951049 C10.6034803,9.73426573 11.4726181,10.4055944 12.5155834,10.4055944 C13.037066,10.2377622 13.9931175,10.1538462 15.0360828,9.98601399 L15.0360828,9.98601399 Z"
                        id="Shape"
                        fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    );
};

export default JCB;
