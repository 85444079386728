import React from 'react';

const WeatherHurricane = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-hurricane"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M17.7850135,0.000710156727 C18.7082293,0.00875086505 20.6189985,0.0922941176 21.7209129,0.609 C22.2678816,0.8655 22.5843097,1.4535 22.4803405,2.046 C22.377878,2.637 21.8957018,3.0855 21.2990088,3.1425 C21.1754511,3.156 19.6897457,3.3645 18.3697883,4.5225 C19.2331851,5.2515 19.966997,6.1275 20.5365676,7.1055 C21.3939372,8.5875 21.848991,10.2795 21.848991,12 C21.848991,16.2435 19.8554938,19.5945 16.082465,21.6975 C12.3501198,23.775 8.05272439,24 6.37414848,24 C5.53486053,24 3.44944845,23.94 2.27866436,23.391 C1.72868212,23.1345 1.41376079,22.5465 1.52074364,21.954 C1.62169928,21.363 2.10387548,20.916 2.70056853,20.8575 C2.82412619,20.8425 4.30681801,20.6355 5.62677537,19.4775 C4.7663922,18.7485 4.0325803,17.8725 3.46451646,16.8945 C2.60564009,15.411 2.1505863,13.719 2.1505863,12 C2.1505863,7.7565 4.14408354,4.404 7.91861913,2.3025 C11.6479507,0.2235 15.9483597,0 17.623922,0 Z M21.1513423,1.815 C18.6153968,0.627 3.48862527,0.8355 3.48862527,12 C3.48862527,15.081 5.14007876,17.775 7.6112318,19.2585 C5.76540103,21.876 2.848235,22.185 2.848235,22.185 C5.38418046,23.373 20.5079384,23.163 20.5079384,12 C20.5079384,8.919 18.8594985,6.2235 16.3883455,4.7415 L16.3883455,4.7415 L16.5402764,4.5342896 C18.3897301,2.10752301 21.1513423,1.815 21.1513423,1.815 Z M12.0003914,8.09355 C14.1671707,8.09355 15.9241002,9.84255 15.9241002,11.99955 C15.9241002,14.15805 14.1671707,15.90705 12.0003914,15.90705 C9.83210526,15.90705 8.07668252,14.15805 8.07668252,11.99955 C8.07668252,9.84255 9.83210526,8.09355 12.0003914,8.09355 Z M12.0003914,9.42555 C10.574958,9.42555 9.41170788,10.58055 9.41170788,11.99955 C9.41170788,13.41855 10.574958,14.57355 12.0003914,14.57355 C13.424318,14.57355 14.5875681,13.41855 14.5875681,11.99955 C14.5875681,10.58055 13.424318,9.42555 12.0003914,9.42555 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherHurricane;
