import React from 'react';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from 'axios';
import { Notification, Row } from '@freightos/uikit';
import { logOut } from 'modules/login/actions';
import { REQUEST } from './actionTypes';
import { onDelete } from 'basic_components/AntdTable/actions';
import * as Sentry from '@sentry/react';
import { AlertAtmosNotification } from 'basic_components/AlertAtmosNotification/AlertAtmosNotification';

const token = process.env.REACT_APP_TOKEN_NAME;

function notify() {
    Notification.show({
        message: 'Deleted',
        level: 'info',
        type: 'toast',
    });
}

function* doGet(url, data) {
    const query = Object.keys(data)
        .map(k => encodeURIComponent(data[k]))
        .join('/');
    const apiUrl = `${url}/${query}`;

    return yield call(axios.get, apiUrl, {
        headers: {
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            authorization_type: 0,
            Authorization: sessionStorage.getItem(token),
        },
    });
}

function* doPost(url, data) {
    const formDataPost = new FormData();
    Object.keys(data).map(k => formDataPost.append(k, data[k]));
    const apiUrl = `${url}`;

    return yield call(axios.post, apiUrl, formDataPost, {
        headers: {
            accept: 'application/json',
            'content-type': 'multipart/form-data',
            authorization_type: 0,
            Authorization: sessionStorage.getItem(token),
        },
    });
}

function* doGeneric(method, url, data) {
    const apiUrl = `${url}`;
    return yield call(axios.request, {
        headers: {
            Accept: 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            authorization_type: 0,
            Authorization: sessionStorage.getItem(token),
        },
        method,
        url: apiUrl,
        data,
    });
}

export function* doRequest(
    method,
    req_url,
    data = {},
    headers = {},
    actions = {}
) {
    let result;
    try {
        switch (method) {
            case 'GET':
                result = yield doGet(req_url, data);
                break;
            case 'POST':
                result = yield doPost(req_url, data);
                break;
            default:
                result = yield doGeneric(method, req_url, data);
                break;
        }
        if (method === 'DELETE' && !actions.isLogout) {
            yield put(onDelete(req_url.split('/')[1].toUpperCase(), data.id));
            notify();
        }
        return result.data;
    } catch (e) {
        if (actions.silent) return;
        if (e.response.status === 401) {
            // put this flag ON true when need not be logout when we receive a 401. for example when we call 3rd party api.
            if (!actions.ignoreLogout) {
                yield put(logOut());
                yield put(push('/'));
            }
        } else {
            const {
                response: {
                    data: {
                        response: { Errors },
                    },
                },
            } = e;
            if (Errors && Errors.show) {
                if (!actions.customMessage) {
                    const message = (
                        <div>
                            {Errors.Message.split('|').map(msg => (
                                <Row>{msg}</Row>
                            ))}
                        </div>
                    );
                    let level;
                    switch (Errors.level) {
                        case 'Error':
                            level = 'danger';
                            break;
                        default:
                            level = Errors.level.toLowerCase();
                            break;
                    }
                    if (level === 'warning') {
                        AlertAtmosNotification(<span>{message}</span>, false);
                    } else {
                        Notification.show({
                            message: message,
                            level: level,
                            type: 'toast',
                            autoClose: false,
                        });
                    }
                }
                if (Errors.level === 'Error') throw e;
            } else if (actions.hideNotification) {
                throw e;
            } else {
                Sentry.captureException(e, {
                    tags: {
                        section: 'something_wrong',
                    },
                });
                // Notification.show({
                //     message: 'Something went wrong.',
                //     level: 'danger',
                //     type: 'toast',
                //     autoClose: false,
                // });
                throw e;
            }
        }
    }
}

export default function*() {
    yield all([takeLatest(REQUEST, doRequest)]);
}
