import React from 'react';

const Subtitles = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/IFE/subtitles"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M21.134966,3.49809786 L21.378375,3.54045373 C22.5030741,3.74491677 23.3930012,4.59485592 23.6568499,5.69498647 L23.6949606,5.88059037 C23.8983202,7.06730657 24,8.60387993 24,10.4903104 C24,11.9429117 23.9397099,13.1916035 23.8191297,14.2363857 L23.776855,14.5771145 C23.570848,16.1177591 22.368827,17.3353692 20.8309647,17.5612103 C20.1244838,17.6648079 19.2971322,17.750055 18.348911,17.8169581 L18.3486903,22 L13.4096067,17.9928415 C13.0964638,17.9960231 12.7763289,17.9981441 12.4492022,17.9992046 L11.9532683,18 C8.06517291,18 5.13886498,17.8563934 3.17434448,17.5691801 L3.17439318,17.5688485 C1.64168175,17.3437381 0.441920909,16.1332673 0.230440382,14.5986158 C0.0768049592,13.4834771 -1.78346227e-12,12.1140408 -1.78346227e-12,10.4903104 C-1.78346227e-12,9.03435052 0.0619163475,7.78503888 0.185749042,6.7423755 L0.229518196,6.40250215 C0.441739934,4.86983204 1.63973634,3.66096712 3.17042735,3.43491235 C5.04766318,3.15758883 7.80785323,3.01288117 11.4510023,3.0008222 L11.9532683,3 C16.0826198,3 19.1431857,3.16603262 21.134966,3.49809786 Z M13.2845528,11.1902543 L10.6829268,11.1902543 L10.6829268,12.8025797 L13.2845528,12.8025797 L13.2845528,11.1902543 Z M8.73170732,11.1902543 L2.87804878,11.1902543 L2.87804878,12.8025797 L8.73170732,12.8025797 L8.73170732,11.1902543 Z M21.0894309,11.1902543 L15.2357724,11.1902543 L15.2357724,12.8025797 L21.0894309,12.8025797 L21.0894309,11.1902543 Z M5.4796748,7.64313863 L2.87804878,7.64313863 L2.87804878,9.25546396 L5.4796748,9.25546396 L5.4796748,7.64313863 Z M21.0894309,7.64313863 L18.4878049,7.64313863 L18.4878049,9.25546396 L21.0894309,9.25546396 L21.0894309,7.64313863 Z M16.5365854,7.64313863 L7.43089431,7.64313863 L7.43089431,9.25546396 L16.5365854,9.25546396 L16.5365854,7.64313863 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default Subtitles;
