const convertMeasures = value => {
    const isOnlyNumbers = /^\d+$/.test(value);
    return isOnlyNumbers ? `${value * 6}px` : value;
};

const defineMargin = values => {
    const margins = {};
    if (values.mt) {
        margins.marginTop = convertMeasures(values.mt);
    }
    if (values.mr) {
        margins.marginRight = convertMeasures(values.mr);
    }
    if (values.mb) {
        margins.marginBottom = convertMeasures(values.mb);
    }
    if (values.ml) {
        margins.marginLeft = convertMeasures(values.ml);
    }
    return margins;
};

const definePadding = values => {
    const paddings = {};
    if (values.pt) {
        paddings.paddingTop = convertMeasures(values.pt);
    }
    if (values.pr) {
        paddings.paddingRight = convertMeasures(values.pr);
    }
    if (values.pb) {
        paddings.paddingBottom = convertMeasures(values.pb);
    }
    if (values.pl) {
        paddings.paddingLeft = convertMeasures(values.pl);
    }
    return paddings;
};

const setMarginOrPadding = values => {
    const hasMarginsDefined =
        !!values?.mt || !!values?.mr || !!values?.mb || !!values?.ml;
    const hasPaddingsDefined =
        !!values?.pt || !!values?.pr || !!values?.pb || !!values?.pl;
    const margins = hasMarginsDefined ? defineMargin(values) : {};
    const paddings = hasPaddingsDefined ? definePadding(values) : {};

    return { margins, paddings };
};

export default setMarginOrPadding;
