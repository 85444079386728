import React from 'react';
import theme from 'configs/theme';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { MODULE_ROUTE, ROUTES_CONSTANTS } from '../../constants';

import { Box, Flex } from '@freightos/uikit';

const Steps = styled(Flex)`
    border: 1px solid ${theme.primary};
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 10px;
`;

const StepNumber = styled.div`
    display: inline-block;
    width: 26px;
    height: 26px;
    background: ${props => (props.active ? theme.primary : '#8F8F8F')};
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
`;
const StepLabel = styled.span`
    color: ${props => (props.active ? theme.primary : '#8F8F8F')};
    font-size: 1.2em;
`;

const FLIGHTS_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.FLIGHTS_RATES}`;
const CONTACT_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.CONTACT_DETAILS}`;
const NOTIFICATION_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.NOTIFICATION_PREFERENCES}`;
const REVIEW_ROUTE = `${MODULE_ROUTE}/${ROUTES_CONSTANTS.REVIEW_BOOK}`;

const BookingStepsBullets = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    let progress = 1;
    switch (pathname) {
        case FLIGHTS_ROUTE:
            progress = 2;
            break;

        case CONTACT_ROUTE:
            progress = 3;
            break;

        case NOTIFICATION_ROUTE:
            progress = 4;
            break;

        case REVIEW_ROUTE:
            progress = 5;
            break;
        default:
            progress = 1;
            break;
    }
    return (
        <Steps>
            <Box mr={2}>
                <StepNumber active={progress >= 1}>1</StepNumber>
                <StepLabel active={progress >= 1}>
                    {t('Shipment details')}
                </StepLabel>
            </Box>
            <Box mr={2}>
                <StepNumber active={progress >= 2}>2</StepNumber>
                <StepLabel active={progress >= 2}>
                    {t('Flights and rates')}
                </StepLabel>
            </Box>
            <Box mr={2}>
                <StepNumber active={progress >= 3}>3</StepNumber>
                <StepLabel active={progress >= 3}>
                    {t('Contact details')}
                </StepLabel>
            </Box>
            <Box mr={2}>
                <StepNumber active={progress >= 4}>4</StepNumber>
                <StepLabel active={progress >= 4}>
                    {t('Notification preferences')}
                </StepLabel>
            </Box>
            <Box mr={2}>
                <StepNumber active={progress >= 5}>5</StepNumber>
                <StepLabel active={progress >= 5}>
                    {t('Review and Book')}
                </StepLabel>
            </Box>
        </Steps>
    );
};

export default BookingStepsBullets;
