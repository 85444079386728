import * as c from './actionTypes';
import { FIELDS, EMPTY_NOTIFICATION } from './constants';
import { FORM_INITIAL_VALUES_GEN_EXP } from './components/common/initialValuesByProductType/GCR_EXP';
import {
    FORM_INITIAL_VALUES_LFG,
    FORM_INITIAL_VALUES_LXL,
} from './components/common/initialValuesByProductType/Lifeguard';
import { FORM_INITIAL_VALUES_QPK } from './components/common/initialValuesByProductType/QPK';
import {
    LFG,
    LXL,
    GCR,
    EXP,
    QPI,
    QPK,
} from './components/common/initialValuesByProductType/productCodes';
import { isCompleteUser } from 'basic_components/userTools';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { formatNotificationOrderByContacts } from 'basic_components/sagaFormatters';

const initialState = {
    form: cloneDeep(FORM_INITIAL_VALUES_GEN_EXP),
    status: 'booking',
    isApiResponseLoading: false,
    specialSHCs: null,
    notificationsPreferencesStructure: [],
    isEditingAWB: false,
    uld_list: [],
    allotmentsList: {},
    isDraft: false,
    restrictionQuestion: false,
    isDomesticFlight: null,
    showAlertOfBookingStatus: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case c.RESET_FORM:
            let formToUse = action.product ? '' : cloneDeep(initialState.form);
            let newBookingProduct = state.bookingProduct;
            let commodity = undefined;
            switch (action.product) {
                case GCR:
                case EXP:
                    formToUse = cloneDeep(FORM_INITIAL_VALUES_GEN_EXP);
                    break;
                case QPK:
                case QPI:
                    formToUse = cloneDeep(FORM_INITIAL_VALUES_QPK);
                    newBookingProduct = state.products.filter(bProduct => {
                        return bProduct.code === QPK;
                    })[0];
                    break;
                case LFG:
                    formToUse = cloneDeep(FORM_INITIAL_VALUES_LFG);
                    commodity = newBookingProduct.default_commodity;
                    break;
                case LXL:
                    formToUse = cloneDeep(FORM_INITIAL_VALUES_LXL);
                    commodity = newBookingProduct.default_commodity;
                    break;
                default:
                    formToUse = cloneDeep(initialState.form);
                    break;
            }
            const specialSHCsToUse = isEmpty(state?.specialSHCs)
                ? null
                : state.specialSHCs;
            return {
                ...state,
                ...cloneDeep(initialState),
                specialSHCs: { ...specialSHCsToUse },
                bookingProduct: newBookingProduct,
                form: {
                    ...formToUse,
                    commodity,
                    product_id: state.bookingProduct,
                },
            };
        case c.CHANGE_INITIAL_VALUES:
            return { ...state, form: action.initialValues };
        case c.ONE_RECEIVED:
            return {
                ...state,
                form: action.data,
                isApiResponseLoading: false,
                status: action.status,
                notificationsPreferencesStructure: formatNotificationOrderByContacts(
                    action.data[FIELDS.NOTIFICATION_PREFERENCES],
                    action.data.agent
                ),
                showAlertOfBookingStatus: null,
            };
        case c.SEARCH_SCHEDULES:
            return { ...state, filters: action.data };
        case c.SET_FORM:
            return { ...state, form: action.data };
        case c.SORT_BY:
            return { ...state, filters: { sortBy: action.sortBy } };
        case c.PRODUCTS_RECEIVED:
            return { ...state, products: action.products };
        case c.SAVE_BOOKING_PRODUCT:
            return {
                ...state,
                bookingProduct: action.product,
                restrictionQuestion: false,
            };
        case c.SAVE_BOOKING_PRODUCT_INIT:
            return {
                ...state,
                form:action?.data?.form,
                isDomesticFlight: action?.data.product?.code !== QPI,
                bookingProduct: action?.data.product,
                restrictionQuestion: false,
            };
        case c.AIRCRAFT_MODES_RECEIVED:
            return { ...state, aircraftModes: action.aircrafts };
        case c.UAX_RECEIVED:
            //pending to know how display uax, temporary inside aircraftTypes
            return {
                ...state,
                UAX: [...action.data],
            };
        case c.AIRCRAFT_TYPES_RECEIVED:
            return { ...state, aircraftTypes: action.aircrafts };
        case c.ROUTINGS_RECEIVED:
            return {
                ...state,
                routings: action.routings,
                gettingRoutings: false,
            };
        case c.CHANGE_BOOKING_STATUS:
            return action.status
                ? {
                      ...state,
                      status: action.status,
                  }
                : state.status !== 'show'
                ? {
                      ...state,
                  }
                : state.form?.booking_data?.booking_number
                ? {
                      ...state,
                      status: 'update_booking',
                  }
                : {
                      ...state,
                      status: 'update_draft',
                  };
        case c.IS_API_RESPONSE_LOADING:
            return {
                ...state,
                isApiResponseLoading: action.state,
            };

        case c.SHOW_ALERT_OF_BOOKING_STATUS:
            return {
                ...state,
                showAlertOfBookingStatus: action.show,
            };

        case c.STORE_ALLOTMENTS:
            return {
                ...state,
                allotmentsList: { ...action.data },
            };
        case c.UPDATE_ACCOUNT_NUMBER:
            return {
                ...state,
                form: { ...state.form, account_number: { ...action.data } },
            };
        case c.USER_RECEIVED:
            let temp = {
                ...action.data,
            };
            if (action.user_type === 'agent') {
                temp.isCompleteUser = isCompleteUser(action.data);
                temp.phone = action.data?.phone || '';
            }

            return {
                ...state,
                form: {
                    ...cloneDeep(state.form),
                    [action.user_type]: temp,
                },
            };

        case c.NOTIFICATION_OPTIONS_RECEIVED:
            return { ...state, notificationOptions: action.data };

        case c.SET_NOTIFICATIONS_PREFERENCES_STRUCTURE:
            return { ...state, notificationsPreferencesStructure: action.data };

        case c.ADD_NOTIFICATIONS_PREFERENCES_STRUCTURE:
            return {
                ...state,
                notificationsPreferencesStructure: [
                    ...state.notificationsPreferencesStructure,
                    cloneDeep(EMPTY_NOTIFICATION),
                ],
            };
        case c.DELETE_NOTIFICATIONS_PREFERENCES_STRUCTURE:
            const auxArray = [...state.notificationsPreferencesStructure];
            auxArray.splice(action.position, 1);

            return {
                ...state,
                notificationsPreferencesStructure: auxArray,
            };
        case c.DELETE_ALL_NOTIFICATIONS_PREFERENCES_STRUCTURE:
            return {
                ...state,
                notificationsPreferencesStructure: [],
            };
        case c.COPY_NOTIFICATIONS_PREFERENCES_STRUCTURE:
            let modelNotification = [];
            let copiedArray = [...state.notificationsPreferencesStructure];

            if (action.typeData === 'notifications') {
                modelNotification = [
                    ...state.notificationsPreferencesStructure,
                ][action.position - 1]?.notifications;
                copiedArray[action.position].notifications = [
                    ...modelNotification,
                ];
            } else {
                const userEmail = {
                    value:
                        state.notificationsPreferencesStructure[0]?.email
                            ?.value,
                    used: true,
                };
                const userPhone = {
                    value:
                        state.notificationsPreferencesStructure[0]?.phone
                            ?.value,
                    used: true,
                };
                copiedArray[action.position].email = { ...userEmail };
                copiedArray[action.position].phone = { ...userPhone };
            }

            return {
                ...state,
                notificationsPreferencesStructure: [...copiedArray],
            };

        case c.SET_NOTIFICATIONS_PREFERENCES_STRUCTURE_FIELD:
            let tempNotification = [...state.notificationsPreferencesStructure];
            if (tempNotification.length === 0) {
                let emptyNotification = cloneDeep(EMPTY_NOTIFICATION);
                emptyNotification[action.field] = action.data;
                return {
                    ...state,
                    notificationsPreferencesStructure: [emptyNotification],
                };
            } else {
                tempNotification[action.position][action.field] = action.data;

                return {
                    ...state,
                    notificationsPreferencesStructure: [...tempNotification],
                };
            }
        case c.SET_NOTIFICATIONS_AGENT:
            let agentNotification = [
                ...state.notificationsPreferencesStructure,
            ];

            if (agentNotification[0]) {
                agentNotification[0][action.field] = {
                    value: action.data,
                    used: agentNotification[0][action.field]?.used,
                };
            }

            return {
                ...state,
                notificationsPreferencesStructure: [...agentNotification],
            };
        case c.NOTIFICATIONS_ERRORS:
            const structure = cloneDeep(
                state.notificationsPreferencesStructure
            );
            structure.forEach((structure, structureIndex) => {
                structure.error = null;
                if (action.arrayOfIndexWithTypeError.length > 0) {
                    action.arrayOfIndexWithTypeError.forEach(ind => {
                        if (ind.index === structureIndex) {
                            return (structure.error = ind.error);
                        }
                    });
                }
            });
            return {
                ...state,
                notificationsPreferencesStructure: [...structure],
            };

        case c.SEARCH_CONTACTS:
            return {
                ...state,
                contacts: { ...state.contacts, filters: action.filters },
            };

        case c.STORE_ULD_LIST:
            return {
                ...state,
                uld_list: action.uld_list,
            };

        case c.CONTACT_RECEIVED:
            return {
                ...state,
                form: { ...state.form, [action.contact_type]: action.data },
            };

        case c.SAVE_SPECIAL_SHC:
            return {
                ...state,
                specialSHCs: {
                    ...state.specialSHCs,
                    [action.shcName]: action.shc,
                },
            };

        case c.SAVE_MULTIPLE_SHC:
            const specialSHCs = { ...state.specialSHCs };
            action.newSHCList.map(shc => {
                specialSHCs[shc.name] = shc;
            });
            return {
                ...state,
                specialSHCs,
            };

        case c.IS_EDITING_AWB:
            return {
                ...state,
                isEditingAWB: action.data,
            };

        case c.SAVE_ALLOTMENT_ROUTE:
            return {
                ...state,

                form: { ...state.form, flight: action.data },
            };

        case c.SET_IS_DRAFT:
            return {
                ...state,
                isDraft: action.isDraft,
            };

        case c.SET_RESTRICTION_QUESTION:
            return {
                ...state,
                restrictionQuestion: action.restrictionQuestion,
            };

        case c.SET_IS_DOMESTIC_FLIGHT:
            return {
                ...state,
                isDomesticFlight: action.isDomesticFlight,
            };

        case c.GET_PRODUCT_MENUS:
           return { ...state, productMenus: action.products };

        default:
            return state;
    }
};
