import React from 'react';

const SprayBottle = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/CleanPlus/spray-bottle"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Group-2"
                    transform="translate(7.000000, 0.000000)"
                    fill={fill || '#002244'}>
                    <path
                        d="M9.95977778,12.8691846 C9.95777778,12.8105179 9.90777778,11.4138512 9.25955556,10.1489624 C8.71733333,9.09118458 7.82488889,6.72629569 7.69866667,6.38962903 C7.618,5.99785125 7.32755556,5.32829569 6.90555556,5.34651791 L3.18422222,5.34651791 C2.83533333,5.3354068 2.75133333,5.5754068 2.73466667,5.94407347 C2.72711111,6.10607347 2.74,6.36451791 2.75733333,6.72207347 C2.812,7.84096236 2.92644444,10.1689624 2.356,10.985629 C2.08044444,11.3802957 1.73155556,11.7274068 1.394,12.0631846 C0.726666667,12.7274068 0.0366666667,13.4142957 0.0502222222,14.4054068 C0.0664444444,15.5814068 0.000666666667,21.1567401 0,21.2231846 C0.006,21.9700735 0.49,23.3929624 2.27844444,23.4322957 C2.67644444,23.4409624 3.168,23.4478512 3.68977778,23.4531846 C5.32355556,23.4694068 7.25444444,23.4714068 7.54622222,23.4714068 C8.16133333,23.5469624 9.33133333,23.1705179 9.68755556,21.1971846 C10.0635556,19.1118512 9.96422222,13.1300735 9.95977778,12.8691846"
                        id="Fill-19"></path>
                    <path
                        d="M0.665511111,1.81903674 L0.831955556,1.82081451 C0.917066667,1.8217034 0.996622222,1.79992562 1.06795556,1.76437007 L1.06617778,1.94681451 C1.06462222,2.08325896 1.1224,2.21392562 1.22462222,2.30481451 C1.25662222,2.33303674 1.29262222,2.35481451 1.33017778,2.37325896 C1.32751111,3.01859229 1.23062222,4.10859229 0.689955556,5.03325896 L0.507066667,5.34614785 L1.27817778,5.28392562 L1.32862222,5.22725896 C1.3724,5.17859229 2.34617778,4.07659229 2.45395556,3.03081451 L2.85884444,2.98037007 C3.04284444,3.33837007 3.17128889,3.7157034 3.10306667,4.82192562 C3.09728889,4.91481451 3.09395556,5.09059229 3.26151111,5.0857034 L6.67795556,5.0857034 C6.8804,5.0857034 6.95306667,4.84303674 6.93062222,4.68948118 C6.88662222,4.38548118 6.90084444,4.23059229 6.95684444,4.08637007 C7.53262222,4.06814785 8.30173333,4.10281451 8.30995556,4.10325896 C8.36906667,4.10637007 8.42284444,4.09837007 8.47662222,4.08103674 C8.63995556,4.02837007 8.83995556,3.88037007 8.91195556,3.62281451 C9.0344,3.18125896 8.68728889,2.78237007 8.57284444,2.65103674 C8.52995556,2.6017034 8.47928889,2.54148118 8.42151111,2.47281451 C7.77684444,1.70681451 6.44928889,0.128814513 4.85595556,0.112592291 C4.82417778,0.112370068 4.79262222,0.112592291 4.76106667,0.113481179 C3.2464,0.157703402 1.59062222,0.0823700683 1.57462222,0.0814811795 C1.43862222,0.0745922906 1.32151111,0.121925624 1.2284,0.209925624 C1.1384,0.294814513 1.08817778,0.411481179 1.08328889,0.534592291 C1.01195556,0.496592291 0.931955556,0.472592291 0.845511111,0.471925624 L0.679066667,0.470147846 C0.391733333,0.467258957 0.156177778,0.697925624 0.153066667,0.985481179 L0.150146732,1.29303674 C0.147066667,1.58059229 0.378177778,1.81614785 0.665511111,1.81903674"
                        id="Fill-17"></path>
                </g>
            </g>
        </svg>
    );
};

export default SprayBottle;
