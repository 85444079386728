import React from 'react';

const Activity = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M19.2702285,6.084 L19.2702285,1.2 L20.0917233,1.2 C20.4203212,1.2 20.6785053,0.924 20.6785053,0.6 C20.6785053,0.264 20.4203212,0 20.0917233,0 L17.2869055,0 C16.9583076,0 16.7001235,0.264 16.7001235,0.6 C16.7001235,0.924 16.9583076,1.2 17.2869055,1.2 L18.0966646,1.2 L18.0966646,6.084 L4.10778258,6.084 C3.215874,6.084 2.5,6.816 2.5,7.728 L2.5,10.476 C2.5,12.9 4.42464484,14.88 6.79524398,14.88 C9.20217442,15.0301497 10.8608114,14.0661497 11.771155,11.988 L12.228845,11.988 C13.0151328,13.728 14.7285361,14.844 16.6414453,14.88 C17.4238213,14.856 17.9088944,14.824 18.0966646,14.784 L18.0966646,20.352 C18.0966646,21.696 17.0287214,22.8 15.7143298,22.8 C14.3999382,22.8 13.2896118,21.468 13.2896118,20.124 L14.1417542,20.124 C14.4703521,20.124 14.7285361,19.848 14.7285361,19.524 C14.7285361,19.188 14.4703521,18.924 14.1417542,18.924 L11.3369364,18.924 C11.0083385,18.924 10.7501544,19.188 10.7501544,19.524 C10.7501544,19.848 11.0083385,20.124 11.3369364,20.124 L12.1470337,20.124 C12.228845,22.0224609 13.7544781,24 15.7143298,24 C17.6741816,24 19.2702285,22.368 19.2702285,20.352 L19.2702285,14.316 C20.5963558,13.56 21.5,12.132 21.5,10.476 L21.5,7.728 C21.5,6.816 20.784126,6.084 19.8922174,6.084 L19.2702285,6.084 Z"
                    id="path-1"></path>
            </defs>
            <g
                id="X-Global-Icons/Destination/activity"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-1"></use>
            </g>
        </svg>
    );
};

export default Activity;
