import React from 'react';

const Food = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M1.47776096,15.6219544 C2.08850459,15.6219544 6.46558895,15.6219544 14.609014,15.6219544 C15.5812225,15.6219544 15.1753991,14.7852627 15.1753991,14.7724443 C14.1902251,12.023481 11.618693,10.1356808 8.65237141,9.89679249 L8.65237141,9.37007293 C8.65237141,9.03562932 8.38117857,8.77343484 8.03678767,8.77343484 C7.70439645,8.77343484 7.4224039,9.03562932 7.4224039,9.37007293 L7.4224039,9.89679249 C4.46808195,10.1356808 1.89534993,12.023481 0.911375922,14.7724443 C0.911375922,14.7724443 0.561645508,15.6219544 1.47776096,15.6219544 Z M0.615583736,16.8043932 C0.271192835,16.8043932 0,17.067753 0,17.4021966 C0,17.7249871 0.271192835,18 0.615583736,18 L15.3475945,18 C15.6919854,18 15.9631783,17.7249871 15.9631783,17.4021966 C15.9631783,17.067753 15.6919854,16.8043932 15.3475945,16.8043932 L0.615583736,16.8043932 Z M23.3968619,6 L23.9752466,10.3990406 C24.0484446,10.9840256 23.9632469,11.593482 23.6668547,12.1073832 C23.2382783,12.8306593 22.480922,13.3730881 21.5265723,13.5377262 L21.5263513,16.3042427 C22.8391166,16.5178826 23.4954993,16.7843499 23.4954993,17.1036445 C23.4954993,17.5930742 22.3375298,17.9719543 20.9107675,17.9719543 C19.4828053,17.9719543 18.3260358,17.5930742 18.3260358,17.1036445 C18.3260358,16.7843499 18.9824185,16.5178826 20.2951838,16.3042427 L20.2951838,16.3042427 L20.2953496,13.5378186 C19.2269141,13.3542829 18.3801708,12.6994949 18.0066043,11.8323703 C17.8206092,11.4140245 17.7966098,10.947901 17.8458085,10.4945959 L17.8458085,10.4945959 L18.4361929,6 L23.3968619,6 Z"
                    id="path-travel-9"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/food"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-9"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-9"></use>
            </g>
        </svg>
    );
};

export default Food;
