import React from 'react';

const DirectTV = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/directv"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="directv"
                    transform="translate(0.000000, 9.725000)"
                    fill={fill || '#002244'}
                    fillRule="nonzero">
                    <path
                        d="M15.5984969,3.13853862 C15.554405,3.33795407 15.4662213,3.49728601 15.3409603,3.60551148 C15.2146973,3.71473904 15.051357,3.77286013 14.8549478,3.77286013 C14.7266806,3.77286013 14.6064301,3.74881002 14.4982046,3.6936952 C14.3899791,3.63858038 14.2937787,3.55240084 14.2156159,3.43014614 C14.1414614,3.31290188 14.1073904,3.12150313 14.0923591,2.93812109 C14.0773278,2.75574113 14.0813361,2.58137787 14.0813361,2.50020877 L14.0813361,2.50020877 L14.0813361,2.50020877 C14.0813361,2.50020877 14.080334,2.27273486 14.0833403,2.03223382 C14.0863466,1.79073069 14.0923591,1.53620042 14.1073904,1.48008351 C13.9330271,1.47306889 13.7636743,1.46705637 13.6123591,1.46204593 C13.4600418,1.45703549 13.3247599,1.45302714 13.2175365,1.45002088 C13.2015031,1.51816284 13.1964927,1.80977035 13.1944885,2.08434238 C13.1924843,2.35891441 13.1944885,2.61745303 13.1944885,2.61745303 L13.1944885,2.61745303 L13.1944885,2.61745303 C13.1954906,2.86797495 13.2055115,3.10546973 13.2506054,3.32592902 C13.2956994,3.54638831 13.3748643,3.74981211 13.5171608,3.93018789 C13.668476,4.12359081 13.8658873,4.27991649 14.0943633,4.38713987 C14.3228392,4.49436326 14.5813779,4.55348643 14.8559499,4.55348643 C15.3249269,4.55348643 15.7097286,4.39515658 15.9903132,4.13862213 C16.2708977,3.88208768 16.4482672,3.52835073 16.503382,3.13853862 L16.0524426,3.13853862 L15.5984969,3.13853862 L15.5984969,3.13853862 L15.5984969,3.13853862 Z"
                        id="Path"></path>
                    <path
                        d="M14.2136117,1.13837161 C14.228643,1.11231733 14.2777453,1.02313152 14.3779541,0.939958246 C14.4791649,0.856784969 14.6324843,0.780626305 14.856952,0.780626305 C15.0243006,0.780626305 15.1665971,0.823716075 15.2818372,0.90388309 C15.3970772,0.984050104 15.485261,1.10029228 15.543382,1.24659708 C15.6997077,1.26463466 15.8590397,1.28467641 16.0183716,1.30772443 C16.1777035,1.33077244 16.3370355,1.35582463 16.4953653,1.38488518 C16.4372443,1.00308977 16.2648852,0.65736952 15.988309,0.405845511 C15.7117328,0.155323591 15.3309395,-1.78006531e-15 14.8579541,-1.78006531e-15 C14.6835908,-1.78006531e-15 14.514238,0.0230480167 14.3619207,0.063131524 C14.2096033,0.103215031 14.0713152,0.161336117 13.9600835,0.232484342 C13.7696868,0.352734864 13.623382,0.484008351 13.5131524,0.629311065 C13.4029228,0.774613779 13.3277662,0.932943633 13.2786639,1.10830898 C13.3878914,1.10931106 13.5241754,1.11231733 13.6815031,1.11632568 C13.8408351,1.12133612 14.0192067,1.12835073 14.2136117,1.13837161 L14.2136117,1.13837161 L14.2136117,1.13837161 Z"
                        id="Path"></path>
                    <path
                        d="M2.79983299,0.463966597 C2.65553236,0.312651357 2.47816284,0.205427975 2.2697286,0.136283925 C2.06129436,0.0671398747 1.82379958,0.0360751566 1.55824635,0.0360751566 L0.779624217,0.0360751566 L-4.45016327e-16,0.0360751566 L-4.45016327e-16,1.7165762 L-4.45016327e-16,3.39707724 C0.141294363,3.34196242 0.284592902,3.2868476 0.429895616,3.23273486 C0.576200418,3.17862213 0.724509395,3.12551148 0.874822547,3.07340292 L0.874822547,1.9440501 L0.874822547,0.814697286 L1.20951983,0.814697286 L1.54421712,0.814697286 C1.68851775,0.814697286 1.81478079,0.839749478 1.92300626,0.886847599 C2.03123173,0.93394572 2.12041754,1.00308977 2.18956159,1.09127349 C2.27373695,1.19749478 2.31983299,1.29670146 2.34488518,1.50713987 C2.36993737,1.71757829 2.37394572,2.04125261 2.37394572,2.59841336 C2.51724426,2.55732777 2.73670146,2.49820459 2.92008351,2.45010438 C3.10346555,2.40100209 3.25177453,2.36292276 3.25177453,2.36292276 C3.25177453,1.82279749 3.25177453,1.46705637 3.19565762,1.18647182 C3.13954071,0.905887265 3.0263048,0.70045929 2.79983299,0.463966597 L2.79983299,0.463966597 L2.79983299,0.463966597 Z"
                        id="Path"></path>
                    <path
                        d="M8.20609603,2.58438413 C8.38045929,2.52125261 8.54580376,2.42104384 8.68208768,2.28275574 C8.81837161,2.14446764 8.9245929,1.96810021 8.98070981,1.75365344 C8.58588727,1.81377871 8.08484342,1.8959499 7.53770355,2.0011691 C6.99056367,2.10638831 6.39632568,2.23465553 5.81812109,2.38597077 L5.81812109,3.45018789 L5.81812109,4.51440501 L6.25503132,4.51440501 L6.69194154,4.51440501 L6.69194154,3.62154489 L6.69194154,2.72868476 L7.00659708,2.72868476 L7.32025052,2.72868476 L7.75716075,3.62154489 L8.19407098,4.51440501 L8.70012526,4.51440501 L9.20617954,4.51440501 L9.20617954,4.51440501 L9.20617954,4.51440501 L9.20617954,4.51440501 L9.20617954,4.51440501 L8.70613779,3.54939457 L8.20609603,2.58438413 L8.20609603,2.58438413 L8.20609603,2.58438413 Z"
                        id="Path"></path>
                    <path
                        d="M12.7114823,3.72676409 L11.6773278,3.72676409 L10.6431733,3.72676409 L10.6431733,3.18764092 L10.6431733,2.64751566 L11.5270146,2.64751566 L12.4108559,2.64751566 L12.4108559,2.64450939 L12.4108559,2.64150313 L12.4108559,2.64150313 L12.4108559,2.64150313 L12.4108559,2.25269311 L12.4108559,1.86388309 L11.5270146,1.86388309 L10.6431733,1.86388309 L10.6431733,1.71056367 L10.6431733,1.55724426 C10.4988727,1.5702714 10.354572,1.58430063 10.2092693,1.59933194 C10.0639666,1.61436326 9.91665971,1.63139875 9.76935282,1.64843424 L9.76935282,3.08242171 L9.76935282,4.51640919 L11.2434238,4.51640919 L12.7174948,4.51640919 L12.7174948,4.12659708 L12.7174948,3.73678497 L12.7144885,3.73678497 L12.7114823,3.73678497 L12.7114823,3.73277662 L12.7114823,3.72676409 L12.7114823,3.72676409 L12.7114823,3.72676409 Z"
                        id="Path"></path>
                    <path
                        d="M1.61736952,4.5154071 C1.84985386,4.5154071 2.07131524,4.4823382 2.27173278,4.41219207 C2.47215031,4.34204593 2.65152401,4.23482255 2.79983299,4.08651357 C2.93110647,3.95524008 3.02430063,3.81394572 3.0914405,3.66463466 C3.15757829,3.51532359 3.1976618,3.35799582 3.22070981,3.19565762 C2.68759916,3.39407098 2.18154489,3.60551148 1.70655532,3.82597077 C1.23056367,4.04643006 0.784634656,4.27691023 0.370772443,4.5154071 L0.994070981,4.5154071 L1.61736952,4.5154071 L1.61736952,4.5154071 L1.61736952,4.5154071 Z"
                        id="Path"></path>
                    <path
                        d="M4.06146138,4.5154071 L4.49837161,4.5154071 L4.93528184,4.5154071 L4.93528184,3.5714405 L4.93528184,2.6274739 C4.78597077,2.67056367 4.63866388,2.71565762 4.49336117,2.76075157 C4.34805846,2.80584551 4.20375783,2.85194154 4.06146138,2.89903967 L4.06146138,3.70672234 L4.06146138,4.5154071 L4.06146138,4.5154071 L4.06146138,4.5154071 Z"
                        id="Path"></path>
                    <path
                        d="M4.06146138,2.16951983 C4.20375783,2.13745303 4.34805846,2.10538622 4.49436326,2.0743215 C4.63966597,2.04325678 4.78697286,2.01319415 4.93528184,1.98313152 L4.93528184,1.01110647 L4.93528184,0.0390814196 L4.49837161,0.0390814196 L4.06146138,0.0390814196 L4.06146138,1.10430063 L4.06146138,2.16951983 L4.06146138,2.16951983 L4.06146138,2.16951983 Z"
                        id="Path"></path>
                    <path
                        d="M12.7114823,0.0380793319 L11.2404175,0.0380793319 L9.76935282,0.0380793319 L9.76935282,0.656367432 L9.76935282,1.27465553 C9.91565762,1.26162839 10.0609603,1.24860125 10.2072651,1.2365762 C10.3525678,1.22455115 10.4978706,1.21352818 10.6431733,1.20350731 L10.6431733,1.01110647 L10.6431733,0.818705637 L11.680334,0.818705637 L12.7174948,0.818705637 L12.7174948,0.428893528 L12.7174948,0.0380793319 L12.7144885,0.0380793319 L12.7114823,0.0380793319 L12.7114823,0.0380793319 L12.7114823,0.0380793319 L12.7114823,0.0380793319 L12.7114823,0.0380793319 Z"
                        id="Path"></path>
                    <polygon
                        id="Path"
                        points="22.5169102 4.5154071 22.1892276 4.5154071 21.862547 4.5154071 21.1240084 2.27674322 20.3844676 0.0380793319 20.8374113 0.0380793319 21.2893528 0.0380793319 21.7392902 1.497119 22.1892276 2.95515658 22.6391649 1.497119 23.0881002 0.0380793319 23.5440501 0.0380793319 24 0.0380793319 23.2584551 2.27674322 22.5169102 4.5154071"></polygon>
                    <polygon
                        id="Path"
                        points="18.7851357 0.817703549 18.7851357 2.66655532 18.7851357 4.5154071 18.3482255 4.5154071 17.9103132 4.5154071 17.9103132 2.66655532 17.9103132 0.817703549 17.326096 0.817703549 16.7408768 0.817703549 16.7408768 0.427891441 16.7408768 0.0380793319 18.3472234 0.0380793319 19.954572 0.0380793319 19.954572 0.427891441 19.954572 0.817703549 19.3693528 0.817703549 18.7851357 0.817703549"></polygon>
                    <path
                        d="M6.69093946,0.817703549 L7.10279749,0.817703549 L7.51465553,0.817703549 C7.70104384,0.817703549 7.86037578,0.871816284 7.9726096,0.979039666 C8.08484342,1.08626305 8.14997912,1.24559499 8.14997912,1.45503132 L8.58588727,1.40292276 L9.02179541,1.3508142 C9.00776618,0.99006263 8.8674739,0.661377871 8.61895616,0.42388309 C8.3714405,0.185386221 8.01569937,0.0380793319 7.57177453,0.0380793319 L6.69394572,0.0380793319 L5.817119,0.0380793319 L5.817119,0.926931106 L5.817119,1.81578288 C5.96141962,1.7897286 6.10672234,1.76467641 6.25202505,1.73962422 C6.39732777,1.71457203 6.54363257,1.69052192 6.69093946,1.66647182 L6.69093946,1.24158664 L6.69093946,0.817703549 L6.69093946,0.817703549 L6.69093946,0.817703549 Z"
                        id="Path"></path>
                </g>
            </g>
        </svg>
    );
};

export default DirectTV;
