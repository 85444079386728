export default {
    alertBorderColor: 'rgb(204, 204, 204)',
    alertBorderWidth: '1px',
    alertErrorBackground: '#fff',
    alertInfoBackground: '#fff',
    alertSuccessBackground: '#fff',
    alertWarningBackground: '#fff',
    blue: 'steelblue',
    buttonBorderColor: '#6244bb',
    buttonDefaultBackground: 'white',
    buttonDefaultColor: '#6244bb',
    buttonLinkColor: 'steelblue',
    buttonPrimaryBackground: '#6244bb',
    calendarColor: '#000',
    cardBodyColor: '#000',
    cardHeaderColor: '#000',
    checkboxCheckedBg: '#0C2340',
    chipDangerBg: '#D50032',
    chipDangerColor: '#FFF',
    chipDefaultColor: '#000',
    chipLightColor: '#4F3D66',
    chipPrimaryBg: '#024',
    chipPrimaryColor: '#000',
    chipSuccessBg: '#1B7742',
    chipSuccessColor: '#FFF',
    chipWarningBg: '#FFC658',
    chipWarningColor: '#000',
    error: '#d50032',
    focusOutline: 'inset 0px 0px 0px 2px #6244bb',
    fontFamily: '"Open Sans","HelveticaNeue","Helvetica","Arial",sans-serif',
    green: '#1B7741',
    iconDefaultColor: '#0C2340',
    iconDefaultSize: 20,
    inputBackgroundError: '#fff',
    inputBorderColor: '#6F7676',
    inputColor: '#000000',
    inputFocusBorderColor: '#6244bb',
    inputLabelColor: '#000000',
    inputLabelColorDisabled: '#767676',
    inputPlaceholderColor: '#4C7494',
    inputRequiredIndicator: ' (required)',
    inputRequiredIndicatorColor: '#666666',
    notificationWarningColor: '#ffc300',
    primary: '#0C2340',
    radioDisabledBorderColor: '#767676',
    secondary: '#4D148C',
    tabItemColor: '#000',
    tabItemColorSelected: '#000',
    textColor: '#000000',
};
