import axios from 'axios';

const tokenName = process.env.REACT_APP_TOKEN_NAME;
const EXCLUDED_URLS_FOR_REFRESH_TOKEN = [
    'login/login/dev_salesforce_sso',
    'login/login/salesforce_sso',
    '/login/login/check_token',
    'login/login/logout',
];
const URL_REFRESH_TOKEN = 'login/authorizations/refresh_token';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const generateBackEndEndPoint = () => {
    let backendUrl = process.env.REACT_APP_ENDPOINT;
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        const backUrl = window.location.host.split('.');
        if (backUrl[1] === 'webcargonet') {
            backendUrl = 'https://ua-mycargoapi.webcargonet.com/src/api';
        } else {
            backendUrl = 'https://ua-mycargoapi.webcargonet.com/src/api';
        }
    }
    return backendUrl;
};

axios.defaults.baseURL = `${generateBackEndEndPoint()}`;

axios.defaults.headers.common.Authorization =
    sessionStorage.getItem(tokenName) || '';
//axios.defaults.headers.common.authorization_type = 1;
axios.defaults.headers.common.accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.withCredentials = true;

const logout = () => {
    sessionStorage.clear();
    //window.location.href = process.env.REACT_APP_SALESFORCE_LOGOUT_URL;
    window.location.href = process.env.REACT_APP_SALESFORE_REDIRECT_LOGOUT;
};

const validateStatusFunction = status => {
    return status >= 200 && status < 300;
};

const refreshToken = () => {
    return new Promise((resolve, reject) => {
        delete axios.defaults.headers.common['Authorization'];

        axios
            .post(URL_REFRESH_TOKEN, null)
            .then(newToken => {
                resolve(newToken.data.response.data);
                processQueue(null, newToken.data.response.data);
                isRefreshing = false;
            })
            .catch(error => {
                logout();
                reject(error);
            });
    });
};

/**
 *  Axios interceptors for refresh token
 */

axios.interceptors.response.use(
    res => res,
    async error => {
        const originalRequest = error.config;
        const { url } = error?.response?.config;

        const newRes = { ...error.response };
        newRes.data = newRes.data.response.Errors;

        if (url === URL_REFRESH_TOKEN) {
            logout();
            Promise.reject(error);
        } else if (
            newRes.data.Code === 401 &&
            !EXCLUDED_URLS_FOR_REFRESH_TOKEN.includes(url) &&
            !originalRequest._retry
        ) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(() => {
                        originalRequest.headers[
                            'Authorization'
                        ] = sessionStorage.getItem(tokenName);
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const returnedData = await refreshToken().then(async newToken => {
                sessionStorage.setItem(tokenName, newToken?.authToken);

                axios.defaults.headers.common.Authorization =
                    newToken?.authToken;

                error.config.headers.Authorization = newToken?.authToken;

                const axiosInstance = axios.create({
                    validateStatus: validateStatusFunction,
                });

                return await axiosInstance.request(originalRequest);
            });
            return returnedData;
        } else {
            return Promise.reject(error);
        }
    }
);

export default axios;
