import * as Yup from 'yup';
import { countDecimals } from 'basic_components/jsTools';

const yupMethods = () => {
    Yup.addMethod(Yup.number, 'maxTwoDecimals', function(
        label,
        errorMessage = 'Max 2 decimals.'
    ) {
        return this.moreThan(0)
            .required()
            .nullable()
            .label(label)
            .test('maxDecimals', errorMessage, val => {
                return countDecimals(val) <= 2;
            });
    });
    return null;
};

export default yupMethods;
