import React from 'react';

const ArrowLeft = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/arrow-left"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M9.6853647,2.77180288 C10.0875384,3.15031935 10.1067165,3.78319382 9.72819999,4.18536757 L3.313,11 L23.0000029,11 C23.5522876,11 24.0000029,11.4477153 24.0000029,12 C24.0000029,12.5522848 23.5522876,13 23.0000029,13 L3.315,13 L9.72819999,19.8146382 C10.1067165,20.2168119 10.0875384,20.8496864 9.6853647,21.2282029 C9.28319095,21.6067193 8.65031648,21.5875413 8.27180001,21.1853676 L0.271800007,12.6853676 C-0.0906000024,12.3003176 -0.0906000024,11.6996882 0.271800007,11.3146382 L8.27180001,2.81463817 C8.65031648,2.41246442 9.28319095,2.39328641 9.6853647,2.77180288 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default ArrowLeft;
