import React from 'react';

const CircleMoney = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.1355737,6 C12.5608396,6 12.9055858,6.31330312 12.9055858,6.6997821 L12.9055619,7.53399153 C14.0977175,7.77171396 14.9412258,8.50571412 15.3540678,9.68036149 C15.4834599,10.0485169 15.2599516,10.442292 14.8548482,10.5598827 C14.4497448,10.6774733 14.0164506,10.4743505 13.8870585,10.106195 C13.5801486,9.23295298 13.0037402,8.84890131 12.0028221,8.84890131 C10.9151237,8.84890131 10.456683,9.40286305 10.456683,9.89327825 L10.4564882,9.93043353 L10.4564882,9.93043353 C10.4543292,10.2139505 10.5104486,10.383034 10.7810457,10.6180892 C11.0869401,10.8838056 11.6046131,11.1267126 12.3847046,11.3202888 C13.4172002,11.5764979 14.1739968,11.9299194 14.6890491,12.3756235 C15.3250406,12.9259833 15.5007686,13.4531901 15.5,14.1028131 L15.5,14.1166295 C15.5,15.1497671 14.488169,16.2856255 12.7934331,16.559802 L12.7920115,17.3002179 C12.7920115,17.6866969 12.4472654,18 12.0219995,18 C11.5967336,18 11.2519875,17.6866969 11.2519875,17.3002179 L11.2505068,16.5699093 C9.86938089,16.3698096 8.92446945,15.5943966 8.52852251,14.3046894 C8.41423629,13.932428 8.65365281,13.5464532 9.06327427,13.4425906 C9.47289574,13.338728 9.89760684,13.5563082 10.0118931,13.9285696 C10.2867269,14.8237785 10.8898156,15.222226 12.0028221,15.222226 C13.286814,15.222226 13.959962,14.5546466 13.959962,14.1166295 L13.9599743,14.1013033 C13.9603334,13.7987458 13.9035777,13.6284711 13.627067,13.3891906 C13.3137288,13.1180412 12.7817694,12.869618 11.9790522,12.6704273 C10.9679397,12.4195243 10.2244298,12.0706481 9.71694744,11.6298216 C9.08748482,11.0830365 8.91168361,10.5533605 8.91650099,9.92074804 L8.91665897,9.89327825 L8.91665897,9.89327825 C8.91665897,8.83315773 9.73875826,7.72349151 11.3651608,7.4928286 L11.3655617,6.6997821 C11.3655617,6.31330312 11.7103078,6 12.1355737,6 Z M0,12 C0,18.624 5.376,24 12,24 C18.636,24 24,18.624 24,12 C24,5.364 18.636,0 12,0 C5.376,0 0,5.364 0,12 Z"
                    id="path-alert-3"></path>
            </defs>
            <g
                id="X-Global-Icons/Alert/circle-money"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-alert-3"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-alert-3"></use>
            </g>
        </svg>
    );
};

export default CircleMoney;
