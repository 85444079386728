import React from 'react';

const Discover = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/discover"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="Discover_Acceptance_Mark_(Flat)"
                    transform="translate(0.000000, 5.000000)">
                    <path
                        d="M5.25,15 C12.8688727,15 18.3688727,15 21.75,15 C22.75,15 23.5,14.5 24,13.5 L24,8.25 C19.4379032,11.4242655 13.1879032,13.6742655 5.25,15 Z"
                        id="path16"
                        fill="#F46F1B"
                        fillRule="nonzero"></path>
                    <rect
                        id="Rectangle-5"
                        stroke="#F46F1B"
                        stroke-width="0.5"
                        x="0.25"
                        y="0.25"
                        width="23.5"
                        height="14.5"
                        rx="1.5"></rect>
                    <path
                        d="M2.88545307,7.15339298 C2.67294358,7.33919912 2.39690002,7.42029211 1.95984344,7.42029211 L1.77836251,7.42029211 L1.77836251,5.19944912 L1.95984344,5.19944912 C2.39690002,5.19944912 2.66205265,5.27522368 2.88545307,5.4710193 C3.11936459,5.67278509 3.26008057,5.98535789 3.26008057,6.30714474 C3.26008057,6.62945088 3.11936459,6.95162719 2.88545307,7.15339298 L2.88545307,7.15339298 Z M2.0953229,4.63035175 L1.10253374,4.63035175 L1.10253374,7.98887105 L2.09009769,7.98887105 C2.6152195,7.98887105 2.99445709,7.86885 3.32731716,7.60117018 C3.72288344,7.28405439 3.95679876,6.80617632 3.95679876,6.31194825 C3.95679876,5.32076842 3.19220223,4.63035175 2.0953229,4.63035175 L2.0953229,4.63035175 Z"
                        id="path20-7"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <polyline
                        id="path22"
                        fill="#000000"
                        fillRule="nonzero"
                        points="4.26799472 7.98887105 4.94434759 7.98887105 4.94434759 4.63035175 4.26799472 4.63035175 4.26799472 7.98887105"></polyline>
                    <path
                        d="M6.59816712,5.91918684 C6.19231363,5.77373246 6.07309848,5.67771754 6.07309848,5.49632105 C6.07309848,5.28482719 6.285456,5.12406053 6.57697386,5.12406053 C6.7795949,5.12406053 6.94616359,5.20476754 7.12235475,5.39602456 L7.47625621,4.94720965 C7.18541045,4.70080789 6.83753171,4.57482105 6.45735994,4.57482105 C5.84384631,4.57482105 5.37585998,4.9875614 5.37585998,5.53719474 C5.37585998,5.99989035 5.59376169,6.2366886 6.2289926,6.45817982 C6.4937693,6.54861491 6.62852341,6.60894912 6.69654983,6.64943333 C6.83166466,6.73494035 6.89933035,6.85587018 6.89933035,6.9969114 C6.89933035,7.269 6.67593383,7.47063684 6.37420854,7.47063684 C6.05159765,7.47063684 5.79177274,7.31441667 5.63598865,7.0227307 L5.19909537,7.43028509 C5.51072042,7.87325965 5.88491882,8.06957456 6.3995789,8.06957456 C7.10241845,8.06957456 7.59552453,7.61699649 7.59552453,6.96680614 C7.59552453,6.43326491 7.36751792,6.19166579 6.59816712,5.91918684"
                        id="path24"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <path
                        d="M7.8088618,6.31194825 C7.8088618,7.29910526 8.60911961,8.06451316 9.63902853,8.06451316 C9.93026536,8.06451316 10.179571,8.0091114 10.4871523,7.86885 L10.4871523,7.09772982 C10.2166795,7.35982895 9.97715502,7.46557632 9.670512,7.46557632 C8.98930651,7.46557632 8.50583448,6.98717895 8.50583448,6.30714474 C8.50583448,5.66227719 9.00471251,5.15364649 9.63902853,5.15364649 C9.96174974,5.15364649 10.2058306,5.26510175 10.4871523,5.53148596 L10.4871523,4.76075614 C10.1901546,4.6149114 9.94607366,4.55444825 9.65510672,4.55444825 C8.6304192,4.55444825 7.8088618,5.33542895 7.8088618,6.31194825"
                        id="path26"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <polyline
                        id="path28"
                        fill="#000000"
                        fillRule="nonzero"
                        points="15.8498427 6.88623158 14.925091 4.63035175 14.1862832 4.63035175 15.6577404 8.07502544 16.0217162 8.07502544 17.5196948 4.63035175 16.7865138 4.63035175 15.8498427 6.88623158"></polyline>
                    <polyline
                        id="path30"
                        fill="#000000"
                        fillRule="nonzero"
                        points="17.8255363 7.98887105 19.7436235 7.98887105 19.7436235 7.42029211 18.5013808 7.42029211 18.5013808 6.51371053 19.697808 6.51371053 19.697808 5.94487807 18.5013808 5.94487807 18.5013808 5.19944912 19.7436235 5.19944912 19.7436235 4.63035175 17.8255363 4.63035175 17.8255363 7.98887105"></polyline>
                    <path
                        d="M21.0647692,6.17648684 L20.8671758,6.17648684 L20.8671758,5.15935439 L21.0754895,5.15935439 C21.4966696,5.15935439 21.7256133,5.33036842 21.7256133,5.65721579 C21.7256133,5.99483246 21.4966696,6.17648684 21.0647692,6.17648684 Z M22.4215535,5.62192193 C22.4215535,4.99314123 21.9745177,4.63035175 21.1944462,4.63035175 L20.1915976,4.63035175 L20.1915976,7.98887105 L20.8671758,7.98887105 L20.8671758,6.63970088 L20.9553211,6.63970088 L21.8914605,7.98887105 L22.7232371,7.98887105 L21.6317045,6.57404825 C22.1411656,6.47362018 22.4215535,6.13613158 22.4215535,5.62192193 L22.4215535,5.62192193 Z"
                        id="path32"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <path
                        d="M22.8148026,4.81805526 L22.7960563,4.81805526 L22.7960563,4.72014337 L22.8160131,4.72014337 C22.8705176,4.72014337 22.8990333,4.73598302 22.8990333,4.76827412 C22.8990333,4.80140211 22.8701379,4.81805526 22.8147957,4.81805526 L22.8148026,4.81805526 Z M23.009521,4.76695694 C23.009521,4.69212253 22.9458331,4.65124298 22.8343317,4.65124298 L22.6855934,4.65124298 L22.6855934,5.02624298 L22.7960797,5.02624298 L22.7960797,4.88085893 L22.925692,5.02624298 L23.0605934,5.02624298 L22.9083955,4.87163083 C22.973507,4.85747276 23.009521,4.81904594 23.009521,4.76695694 L23.009521,4.76695694 Z"
                        id="path34"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <path
                        d="M22.7970503,5.00961842 C22.6806376,5.00961842 22.5853884,4.91581053 22.5853884,4.79864298 C22.5853884,4.68108596 22.6794303,4.58727456 22.7970503,4.58727456 C22.9129313,4.58727456 23.0073727,4.68329298 23.0073727,4.79864298 C23.0073727,4.9147693 22.9129313,5.00961842 22.7970503,5.00961842 Z M22.7981914,4.54095351 C22.6501621,4.54095351 22.5328075,4.65539474 22.5328075,4.79825351 C22.5328075,4.9411114 22.6514989,5.05568246 22.7981914,5.05568246 C22.9423327,5.05568246 23.0604888,4.93994211 23.0604888,4.79825351 C23.0604888,4.65734123 22.9423327,4.54095351 22.7981914,4.54095351 L22.7981914,4.54095351 Z"
                        id="path36"
                        fill="#000000"
                        fillRule="nonzero"></path>
                    <path
                        d="M10.707389,6.32193772 C10.707389,6.32193772 10.707389,6.32193772 10.707389,6.32083246 C10.707389,5.33406579 11.5332715,4.53530877 12.5520652,4.53530877 C13.5708552,4.53530877 14.3967414,5.33406579 14.3967414,6.32083246 C14.3967414,6.32193772 14.3967414,6.32193772 14.3967414,6.32193772 C14.3967414,7.30870439 13.5708552,8.1086307 12.5520652,8.1086307 C11.5332715,8.1086307 10.707389,7.30870439 10.707389,6.32193772"
                        id="path42"
                        fill="#F46F1B"
                        fillRule="nonzero"></path>
                </g>
            </g>
        </svg>
    );
};

export default Discover;
