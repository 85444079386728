// React
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Logo from '../Page/logo.svg';
import UserMenu from './UserMenu';

// Styles
import '../AntdMin.min.css';
import '../HeaderAndFooter.css';
import MyCargoMenu from './MyCargoMenu';
import styled from 'styled-components';

const FlexLi = styled.li`
    display: flex;
    align-items: center;
`;

function Header() {
    const { userInfo } = useSelector(state => state.user);
    const { t } = useTranslation();
    const {
        logo: logoLink,
        support: supportLink,
        register: registerLink,
    } = JSON.parse(process.env.REACT_APP_HEADER_LINKS);

    return (
        <header
            className="header"
            style={{
                position: 'sticky',
                zIndex: '10000',
                padding: 0,
                width: '100%',
                height: '100%',
            }}>
            <div className="navigation">
                <nav
                    style={{ position: 'relative' }}
                    role="navigation"
                    aria-label="Main Navigation"
                    className="topNav">
                    <ul role="menu" className="menu-list">
                        <FlexLi>
                            <a
                                role="menuitem"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={supportLink}>
                                {t('Support')}
                            </a>
                        </FlexLi>
                        <FlexLi>
                            <a
                                role="menuitem"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={registerLink}>
                                {t('Register')}
                            </a>
                        </FlexLi>
                        <FlexLi>
                            <MyCargoMenu />
                        </FlexLi>
                        <FlexLi>
                            {userInfo ? (
                                <UserMenu {...userInfo} />
                            ) : (
                                <a>{t('SIGN IN')}</a>
                            )}
                        </FlexLi>
                    </ul>
                </nav>
                <nav
                    role="navigation"
                    aria-label="Secondary Navigation"
                    className="bottomNav">
                    <a
                        href={logoLink}
                        rel="noopener noreferrer"
                        aria-label="United Cargo logo"
                        className="mainLogo logoStyles">
                        <figure className="ImageContainer">
                            <Logo />
                        </figure>
                    </a>
                    <ul
                        role="menubar"
                        aria-label="Main Navigation"
                        className="main-nav-links"></ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
