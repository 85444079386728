import React from 'react';
import * as AlertIcons from './Alert/AlertIcons';
import * as CleanPlusIcons from './CleanPlus/CleanPlusIcons';
import * as DestinationIcons from './Destination/DestinationIcons';
import * as DigitalIcons from './Digital/DigitalIcons';
import * as InteractiveIcons from './Interactive/InteractiveIcons';
import * as TravelAirportIcons from './Travel & Airport/TravelAirportIcons';
import * as WeatherIcons from './Weather/WeatherIcons';
import { capitalizeFirstLetter } from 'basic_components/jsTools';
import setMarginOrPadding from 'basic_components/setMarginOrPadding';

const Icon = ({ iconName, ...rest }) => {
    const globalIcons = {
        ...AlertIcons,
        ...CleanPlusIcons,
        ...DestinationIcons,
        ...DigitalIcons,
        ...InteractiveIcons,
        ...TravelAirportIcons,
        ...WeatherIcons,
    };
    const IconToUse = globalIcons[capitalizeFirstLetter(iconName)];

    const width = rest?.size || rest?.width || '18px';
    const height = rest?.size || rest?.height || '18px';

    const { margins, paddings } = setMarginOrPadding(rest);

    const pointer = rest?.pointer ? { cursor: 'pointer' } : {};

    const style = { ...rest.style, ...paddings, ...margins, ...pointer };

    return (
        <IconToUse
            width={width}
            height={height}
            {...rest}
            style={{ ...style }}
        />
    );
};

export default Icon;
