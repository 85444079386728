import React from 'react';

const Pet = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.0095929,10.3794922 C13.4977408,10.3794922 14.817872,11.4879283 15.309921,13.1385342 C15.3219222,13.1626307 15.3339233,13.1987753 15.3339233,13.2228718 C15.3579257,13.3072093 15.3819281,13.3915468 15.4179317,13.4758844 C15.8979794,14.9337188 16.8580749,16.1264924 17.8661751,16.5240836 C19.1383015,17.0301088 19.9543827,18.1505931 19.9543827,19.3795114 C19.9543827,21.0449768 18.4740755,22.4064657 16.6192058,22.4953811 L16.4260319,22.5 L7.56915146,22.5 C5.63695938,22.5 4.0528019,21.1024067 4.0528019,19.3795114 C4.0528019,18.1024003 4.9168878,16.9698677 6.24902023,16.487939 C7.15263947,16.0910565 8.02436626,15.0431533 8.51400908,13.7359719 L8.60125406,13.4879326 L8.64925883,13.343354 C9.08130178,11.5963623 10.461439,10.3794922 12.0095929,10.3794922 Z M21.8857922,7.30307595 C23.1743071,8.14508143 23.374557,10.482831 22.3330627,12.5245858 C21.2915685,14.5663406 19.4027228,15.5389295 18.1142078,14.6969241 C16.8256929,13.8549186 16.625443,11.517169 17.6669373,9.47541422 C18.7084315,7.43365944 20.5972772,6.46107047 21.8857922,7.30307595 Z M6.33306274,9.47541422 C7.37455699,11.517169 7.17430713,13.8549186 5.88579216,14.6969241 C4.59727719,15.5389295 2.70843151,14.5663406 1.66693726,12.5245858 C0.625443012,10.482831 0.825692871,8.14508143 2.11420784,7.30307595 C3.40272281,6.46107047 5.29156849,7.43365944 6.33306274,9.47541422 Z M7.44659783,2.07203087 C8.99747275,1.6568326 10.7263295,3.07885958 11.3081074,5.24821703 C11.8898854,7.41757448 11.1042771,9.51277086 9.55340217,9.92796913 C8.00252725,10.3431674 6.27367051,8.92114042 5.69189256,6.75178297 C5.11011461,4.58242552 5.89572292,2.48722914 7.44659783,2.07203087 Z M12.6918926,5.24821703 C13.2736705,3.07885958 15.0025273,1.6568326 16.5534022,2.07203087 C18.1042771,2.48722914 18.8898854,4.58242552 18.3081074,6.75178297 C17.7263295,8.92114042 15.9974727,10.3431674 14.4465978,9.92796913 C12.8957229,9.51277086 12.1101146,7.41757448 12.6918926,5.24821703 Z"
                    id="path-travel-22"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/pet"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-22"></use>
                </mask>
                <use
                    id="Combined-Shape"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-22"></use>
            </g>
        </svg>
    );
};

export default Pet;
