import React from 'react';

const Golf = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M12.3942869,16.2213127 L12.4927463,16.2347908 C14.113628,16.582845 15.3932714,17.1949404 16.1976187,17.96306 C16.8191598,18.5511515 17.160398,19.2232562 17.160398,19.9433683 C17.160398,22.2237234 13.7236414,24 9.32410547,24 C4.93675661,24 1.5,22.2237234 1.5,19.9433683 C1.5,18.2871104 3.29150079,16.858888 6.17983879,16.2347908 C6.50888995,16.1627796 6.82575404,16.3668114 6.89887652,16.6908619 C6.971999,17.0149123 6.76481863,17.3269609 6.43576747,17.3989721 C4.21771888,17.8910487 2.71870802,18.9112076 2.71870802,19.9433683 C2.71870802,21.2995795 5.4364269,22.7998131 9.32410547,22.7998131 C13.2239711,22.7998131 15.94169,21.2995795 15.94169,19.9433683 C15.94169,19.2112543 15.2104652,18.4911422 13.9917572,17.96306 C13.4798998,17.7350245 12.89492,17.5429946 12.2246306,17.3989721 C11.8955794,17.3269609 11.688399,17.0149123 11.7615215,16.6908619 C11.834644,16.3668114 12.1636952,16.1627796 12.4927463,16.2347908 Z M8.9829891,0.104159076 C9.15360822,-0.00385774356 9.36078858,-0.0278614813 9.54359479,0.032147863 L9.54359479,0.032147863 L9.56794507,0.0415259633 C9.95804878,0.185615638 14.93159,1.89886711 21.8769199,1.74841511 C22.1450357,1.74841511 22.4253385,1.92844314 22.4862739,2.216488 C22.5472093,2.49253098 22.4009644,2.78057583 22.1328486,2.88859265 C20.7435215,3.4886861 16.9777137,5.3369739 16.1368052,6.78920003 C16.0880568,6.89721685 14.5037364,9.86167846 9.94576843,10.6417999 L9.94576843,10.6417999 L9.94576843,19.9432483 C9.94576843,20.2793006 9.66546559,20.5433417 9.33641442,20.5433417 C8.99517618,20.5433417 8.72706041,20.2793006 8.72706041,19.9432483 L8.72706041,19.9432483 L8.72706041,0.5962357 C8.72706041,0.404205798 8.82455705,0.212175896 8.9829891,0.104159076 Z M19.4151297,12.8259 C20.7313344,12.8259 21.7916104,13.8700626 21.7916104,15.1662645 C21.7916104,16.4504644 20.7313344,17.5066289 19.4151297,17.5066289 C18.1111121,17.5066289 17.0386491,16.4504644 17.0386491,15.1662645 C17.0386491,13.8700626 18.1111121,12.8259 19.4151297,12.8259 Z"
                    id="path-4"></path>
            </defs>
            <g
                id="X-Global-Icons/Destination/golf"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-4"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-4"></use>
            </g>
        </svg>
    );
};

export default Golf;
