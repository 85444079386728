import React from 'react';

const VisaCheckout = ({ width, height, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <polygon
                    id="path-digital-1"
                    points="0 0 24 0 24 15 0 15"></polygon>
            </defs>
            <g
                id="X-Global-Icons/Digital/Payment/visa_checkout"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g id="Group-17" transform="translate(0.000000, 5.000000)">
                    <mask id="mask-2" fill="white">
                        <use xmlnsXlink="#path-digital-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path
                        d="M24,13.7488636 C24,14.4395455 23.4326633,15 22.7335001,15 L1.26649987,15 C0.566646536,15 0,14.4395455 0,13.7488636 L0,1.25113636 C0,0.560454545 0.566646536,0 1.26649987,0 L22.7335001,0 C23.4326633,0 24,0.560454545 24,1.25113636 L24,13.7488636 Z"
                        id="Fill-1"
                        fill="#1A1F71"
                        mask="url(#mask-2)"></path>
                    <g id="Group" mask="url(#mask-2)" fill="#FFFFFF">
                        <g transform="translate(2.625000, 2.250000)">
                            <path
                                d="M9.15052703,0.66375 L8.1057973,5.681875 L6.84214865,5.681875 L7.88748649,0.66375 L9.15052703,0.66375 Z M14.4647838,3.904375 L15.1294459,2.019375 L15.5119459,3.904375 L14.4647838,3.904375 Z M15.8737703,5.681875 L17.0425541,5.681875 L16.0227568,0.66375 L14.943973,0.66375 C14.7019459,0.66375 14.4976216,0.80875 14.4070135,1.031875 L12.5109324,5.681875 L13.8378243,5.681875 L14.1011351,4.931875 L15.7217432,4.931875 L15.8737703,5.681875 Z M12.5772162,4.04375 C12.5826892,2.719375 10.7948514,2.646875 10.8070135,2.05375 C10.8112703,1.874375 10.9778919,1.683125 11.3421486,1.63375 C11.5233649,1.609375 12.0232297,1.590625 12.5881622,1.85875 L12.8107297,0.795 C12.5066757,0.680625 12.1150541,0.571875 11.6291757,0.571875 C10.3795135,0.571875 9.5007973,1.254375 9.49410811,2.230625 C9.4862027,2.954375 10.1216757,3.35625 10.6002568,3.595625 C11.0922162,3.8425 11.2570135,3.999375 11.2551892,4.220625 C11.2521486,4.556875 10.8629595,4.705 10.4993108,4.711875 C9.86444595,4.72125 9.49532432,4.534375 9.20160811,4.394375 L8.97356757,5.494375 C9.26789189,5.633125 9.81336486,5.75375 10.3782973,5.76 C11.7045811,5.76 12.5729595,5.0875 12.5772162,4.04375 L12.5772162,4.04375 Z M7.34566216,0.66375 L5.29877027,5.681875 L3.96397297,5.681875 L2.95633784,1.676875 C2.89613514,1.430625 2.84262162,1.34 2.65654054,1.236875 C2.35248649,1.066875 1.85140541,0.9075 1.41052703,0.80875 L1.44032432,0.66375 L3.58937838,0.66375 C3.86363514,0.66375 4.10991892,0.850625 4.17194595,1.175625 L4.70404054,4.07875 L6.01877027,0.66375 L7.34566216,0.66375 L7.34566216,0.66375 Z"
                                id="Fill-4"></path>
                            <path
                                d="M2.76064865,9.74125 C2.59159459,9.8025 2.42922973,9.849375 2.27355405,9.880625 C2.11848649,9.9125 1.97010811,9.928125 1.83145946,9.928125 C1.46294595,9.928125 1.16862162,9.824375 0.948486486,9.616875 C0.728351351,9.409375 0.618283784,9.134375 0.618283784,8.7925 C0.618283784,8.5675 0.652337838,8.355 0.72227027,8.155 C0.792202703,7.95625 0.895581081,7.774375 1.0317973,7.609375 C1.19112162,7.415625 1.38389189,7.266875 1.6095,7.1625 C1.83571622,7.0575 2.07895946,7.005625 2.34044595,7.005625 C2.48213514,7.005625 2.62382432,7.0225 2.76733784,7.0575 C2.91024324,7.0925 3.05558108,7.145 3.20456757,7.215 L3.09085135,7.7975 C2.97348649,7.70625 2.85490541,7.639375 2.73632432,7.59625 C2.61774324,7.55375 2.49004054,7.531875 2.35504054,7.531875 C2.07227027,7.531875 1.83571622,7.644375 1.64477027,7.868125 C1.45321622,8.091875 1.35713514,8.371875 1.35713514,8.706875 C1.35713514,8.92375 1.41672973,9.09375 1.53348649,9.216875 C1.65145946,9.34 1.81321622,9.401875 2.01997297,9.401875 C2.14402703,9.401875 2.27537838,9.37875 2.41463514,9.333125 C2.55267568,9.286875 2.70895946,9.215 2.88166216,9.116875 L2.76064865,9.74125 Z"
                                id="Fill-6"></path>
                            <path
                                d="M5.42112162,8.58675 L5.17666216,9.873625 L4.51747297,9.873625 L4.72483784,8.77175 C4.74064865,8.7005 4.75098649,8.63925 4.75889189,8.58925 C4.7667973,8.539875 4.77105405,8.499875 4.77105405,8.471125 C4.77105405,8.391125 4.74977027,8.32925 4.70598649,8.28425 C4.6622027,8.2405 4.60078378,8.21925 4.52112162,8.21925 C4.38551351,8.21925 4.26875676,8.273625 4.17206757,8.382375 C4.07416216,8.491125 4.00666216,8.641125 3.96895946,8.83425 L3.77071622,9.873625 L3.11152703,9.873625 L3.66612162,6.938 L4.32713514,6.938 L4.11186486,8.069875 C4.23348649,7.948 4.3545,7.857375 4.47672973,7.797375 C4.59956757,7.73925 4.72727027,7.70925 4.86105405,7.70925 C5.05139189,7.70925 5.19672973,7.7605 5.2995,7.862375 C5.40227027,7.963625 5.45395946,8.107375 5.45395946,8.292375 C5.45395946,8.332375 5.45091892,8.377375 5.44605405,8.42675 C5.43997297,8.4755 5.43206757,8.528625 5.42112162,8.58675"
                                id="Fill-8"></path>
                            <path
                                d="M7.22464865,8.58675 C7.22708108,8.5705 7.23072973,8.553 7.23316216,8.536125 C7.23377027,8.519875 7.23559459,8.503 7.23559459,8.486125 C7.23559459,8.386125 7.2057973,8.306125 7.14681081,8.24675 C7.08721622,8.188 7.00877027,8.158 6.90782432,8.158 C6.78012162,8.158 6.67005405,8.194875 6.58005405,8.26675 C6.49127027,8.33925 6.42194595,8.4455 6.37451351,8.58675 L7.22464865,8.58675 Z M7.84491892,8.896125 L7.82181081,9.003625 L6.28390541,9.003625 C6.28390541,9.011125 6.2832973,9.022375 6.28208108,9.03675 C6.28086486,9.051125 6.28025676,9.062375 6.28025676,9.06925 C6.28025676,9.207375 6.321,9.308625 6.40127027,9.377375 C6.48093243,9.444875 6.60194595,9.478625 6.76127027,9.478625 C6.89991892,9.478625 7.04525676,9.457375 7.19910811,9.4155 C7.35235135,9.37175 7.51471622,9.308625 7.68255405,9.22425 L7.58221622,9.748625 C7.42106757,9.80925 7.26235135,9.85425 7.10485135,9.883625 C6.94491892,9.913625 6.78377027,9.928 6.62018919,9.928 C6.29971622,9.928 6.05160811,9.85175 5.87768919,9.699875 C5.70377027,9.547375 5.61559459,9.332375 5.61559459,9.05425 C5.61559459,8.893625 5.64356757,8.737375 5.69890541,8.586125 C5.75545946,8.433625 5.83633784,8.2955 5.94336486,8.169875 C6.06924324,8.01925 6.21944595,7.90425 6.3957973,7.82675 C6.57093243,7.748625 6.76552703,7.70925 6.98018919,7.70925 C7.25748649,7.70925 7.47822973,7.7855 7.64181081,7.938 C7.80478378,8.08925 7.88687838,8.294875 7.88687838,8.552375 C7.88687838,8.603 7.88383784,8.656125 7.87654054,8.712375 C7.86985135,8.768 7.85951351,8.82925 7.84491892,8.896125 L7.84491892,8.896125 Z"
                                id="Fill-10"></path>
                            <path
                                d="M10.0478514,7.8265625 L9.94325676,8.3771875 C9.85508108,8.3171875 9.76872973,8.2709375 9.68237838,8.2390625 C9.59663514,8.2078125 9.51332432,8.1921875 9.43122973,8.1921875 C9.21656757,8.1921875 9.04204054,8.2665625 8.90643243,8.4140625 C8.77021622,8.5615625 8.70210811,8.7509375 8.70210811,8.9809375 C8.70210811,9.1284375 8.74406757,9.2421875 8.8292027,9.3234375 C8.91372973,9.4040625 9.03291892,9.4446875 9.18677027,9.4446875 C9.28954054,9.4446875 9.3917027,9.4290625 9.49021622,9.3978125 C9.58933784,9.3665625 9.68298649,9.3196875 9.77298649,9.2584375 L9.67204054,9.8115625 C9.56258108,9.8503125 9.45251351,9.8790625 9.34366216,9.8984375 C9.23298649,9.9184375 9.1217027,9.9284375 9.01102703,9.9284375 C8.68872973,9.9284375 8.4387973,9.8534375 8.26487838,9.7046875 C8.09095946,9.5553125 8.004,9.3440625 8.004,9.0690625 C8.004,8.8971875 8.03501351,8.7296875 8.09947297,8.5678125 C8.16393243,8.4053125 8.25575676,8.2596875 8.37616216,8.1303125 C8.50872973,7.9878125 8.66258108,7.8815625 8.83467568,7.8134375 C9.00737838,7.7446875 9.20683784,7.7096875 9.43122973,7.7096875 C9.53521622,7.7096875 9.63859459,7.7196875 9.74197297,7.7390625 C9.84474324,7.7578125 9.9462973,7.7871875 10.0478514,7.8265625"
                                id="Fill-12"></path>
                            <polygon
                                id="Fill-14"
                                points="10.5787905 6.9375625 11.2398041 6.9375625 10.9387905 8.5281875 11.8260203 7.7600625 12.5995338 7.7600625 11.4052095 8.7375625 12.2729797 9.8738125 11.5231824 9.8738125 10.8560878 8.9619375 10.682777 9.8738125 10.0254122 9.8738125"></polygon>
                            <path
                                d="M13.5789527,8.192125 C13.4092905,8.192125 13.2688176,8.2715 13.15875,8.429625 C13.0480743,8.589 12.9939527,8.79025 12.9939527,9.03525 C12.9939527,9.169 13.02375,9.270875 13.0833446,9.34025 C13.141723,9.41025 13.2286824,9.444625 13.3417905,9.444625 C13.5126689,9.444625 13.6525338,9.36525 13.7626014,9.207125 C13.8726689,9.047125 13.9280068,8.844625 13.9280068,8.59775 C13.9280068,8.465875 13.8982095,8.36525 13.8386149,8.2965 C13.7796284,8.227125 13.6926689,8.192125 13.5789527,8.192125 M13.6318581,7.709625 C13.9377365,7.709625 14.1785473,7.789 14.3530743,7.949 C14.5282095,8.109 14.615777,8.3265 14.615777,8.6015 C14.615777,8.7615 14.5890203,8.9165 14.5336824,9.0665 C14.4783446,9.2165 14.3986824,9.354625 14.2928716,9.479 C14.1682095,9.627125 14.0210473,9.739625 13.8501689,9.814625 C13.6798986,9.89025 13.4901689,9.92775 13.2821959,9.92775 C12.9799662,9.92775 12.7415878,9.84775 12.5670608,9.689 C12.3925338,9.529 12.3049662,9.3115 12.3049662,9.03525 C12.3049662,8.877125 12.3329392,8.72275 12.3894932,8.57025 C12.4460473,8.419 12.5269257,8.28025 12.6315203,8.154625 C12.7549662,8.009 12.9003041,7.89775 13.0699662,7.822125 C13.2390203,7.74775 13.4263176,7.709625 13.6318581,7.709625"
                                id="Fill-16"></path>
                            <path
                                d="M14.8194932,9.0509375 L15.0603041,7.7603125 L15.7188851,7.7603125 L15.5115203,8.8596875 C15.4957095,8.9315625 15.4853716,8.9928125 15.4786824,9.0428125 C15.470777,9.0934375 15.4665203,9.1328125 15.4665203,9.1621875 C15.4665203,9.2415625 15.4890203,9.3028125 15.5315878,9.3471875 C15.5741554,9.3915625 15.6349662,9.4128125 15.7152365,9.4128125 C15.8508446,9.4128125 15.9676014,9.3590625 16.0661149,9.2503125 C16.1634122,9.1421875 16.2303041,8.9921875 16.2673986,8.8003125 L16.4656419,7.7603125 L17.1248311,7.7603125 L16.7271284,9.8734375 L16.0636824,9.8734375 L16.1244932,9.5640625 C16.0119932,9.6853125 15.8940203,9.7771875 15.7699662,9.8371875 C15.6465203,9.8984375 15.5163851,9.9278125 15.3789527,9.9278125 C15.1922635,9.9278125 15.0463176,9.8771875 14.9441554,9.7753125 C14.8413851,9.6734375 14.7890878,9.5296875 14.7890878,9.3428125 C14.7890878,9.2990625 14.7921284,9.2528125 14.7969932,9.2040625 C14.8018581,9.1546875 14.8085473,9.1034375 14.8194932,9.0509375"
                                id="Fill-18"></path>
                            <path
                                d="M18.1836689,7.160375 L18.0723851,7.760375 L18.7437365,7.760375 L18.6500878,8.242875 L17.9787365,8.242875 L17.8090743,9.137875 C17.8048176,9.157875 17.8011689,9.17475 17.7993446,9.1885 C17.7975203,9.20225 17.7963041,9.216 17.7963041,9.2285 C17.7963041,9.286625 17.8163716,9.32725 17.85225,9.352875 C17.8905608,9.377875 17.9519797,9.391 18.0407635,9.391 L18.3806959,9.391 L18.2870473,9.8735 L17.734277,9.8735 C17.5360338,9.8735 17.3846149,9.830375 17.2806284,9.74475 C17.17725,9.65975 17.1249527,9.536 17.1249527,9.375375 C17.1249527,9.340375 17.1273851,9.30225 17.1310338,9.26225 C17.1334662,9.221625 17.1395473,9.180375 17.1462365,9.13975 L17.5226554,7.160375 L18.1836689,7.160375 Z"
                                id="Fill-20"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default VisaCheckout;
