import React from 'react';

const Email = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M24,3.87708172 L24,20.4152009 C24,20.7309924 23.724,21 23.4,21 L23.4,21 L0.6,21 C0.264,21 0,20.7309924 0,20.4152009 L0,20.4152009 L0,3.87708172 L11.628,12.5438047 C12,12.8111877 12,12.8111877 12.387558,12.5438047 L12.387558,12.5438047 L24,3.87708172 Z M23.196,3 L12,11.3392355 L0.792,3 L23.196,3 Z"
                    id="path-digital-8"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/email"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-8"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-8"></use>
            </g>
        </svg>
    );
};

export default Email;
