import React from 'react';

const PayInPerson = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/pay-in-person"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M21,4 C22.6568542,4 24,5.34314575 24,7 L24,18 C24,19.6568542 22.6568542,21 21,21 L3,21 C1.34314575,21 2.02906125e-16,19.6568542 0,18 L0,7 C-2.02906125e-16,5.34314575 1.34314575,4 3,4 L21,4 Z M12.807377,12.1454465 L11.192623,12.1454465 C9.56711066,12.1454465 8.25,13.4364594 8.25,15.0224752 L8.25,17.2539975 C8.25,17.3919989 8.36116803,17.5 8.50204918,17.5 L15.4974385,17.5 C15.638832,17.5 15.75,17.3919989 15.75,17.2539975 L15.75,15.0224752 C15.75,13.4364594 14.4328893,12.1454465 12.807377,12.1454465 Z M12.0000512,7.5 C10.8289447,7.5 9.87505123,8.43150932 9.87505123,9.57402074 C9.87505123,10.7220322 10.8289447,11.6530415 12.0000512,11.6530415 C13.1706455,11.6530415 14.1250512,10.7220322 14.1250512,9.57402074 C14.1250512,8.43150932 13.1706455,7.5 12.0000512,7.5 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default PayInPerson;
