import React from 'react';

const WeatherClear = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Weather/weather-clear"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M12.3657279,1.4119713 C6.97158504,1.9624713 2.90289415,6.5029713 2.90289415,11.9719713 C2.90289415,17.8234713 7.63200494,22.6891493 13.4409823,22.5874713 C16.4470836,22.5348537 19.0825985,21.3383537 21.3475269,18.9979713 C20.6009053,19.0629713 20.1124241,19.0954713 19.8820833,19.0954713 C14.2422106,19.0954713 9.65674232,14.4739713 9.65674232,8.7934713 C9.65674232,6.1309713 10.6634901,3.6049713 12.4952946,1.6774713 L12.7857026,1.3699713 L12.3657279,1.4119713 Z M1.5,11.9719713 C1.5,5.7754713 6.11078587,0.630471298 12.2227578,0.00647129804 C12.9299927,-0.0562044832 13.5199137,0.343971298 13.7820255,0.877971298 C14.0664765,1.4659713 13.9547811,2.1814713 13.5079995,2.6524713 C11.927882,4.3174713 11.0596365,6.4969713 11.0596365,8.7934713 C11.0596365,13.6939713 15.0151427,17.6824713 19.8820833,17.6824713 C20.181427,17.6824713 20.8903204,17.6269713 20.9439342,17.6269713 C21.5336859,17.6269713 22.0832272,17.9719713 22.3423606,18.5089713 C22.6283008,19.0954713 22.5180946,19.8079713 22.0698238,20.2834713 C19.7897485,22.6789713 16.7307839,23.9989713 13.4409823,23.9989713 C6.85840038,23.9989713 1.5,18.6019713 1.5,11.9719713 Z"
                    id="Fill-35-Copy-2"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default WeatherClear;
