import React from 'react';
import { Route } from 'react-router';
import { MODULE_ROUTE } from './constants';

const TokenAuth = React.lazy(() => import('./components/TokenAuth'));

export default (
    <Route
        path={MODULE_ROUTE}
        render={({ match: { url } }) => (
            <>
                <Route
                    exact
                    path={`${url}/sf/:sf_token`}
                    component={TokenAuth}
                />
            </>
        )}
    />
);
