import React, { useState, useEffect, useRef } from 'react';
import { logOut } from '../../modules/login/actions';
import { useDispatch } from 'react-redux';
import { Modal } from '@freightos/uikit';
import { useTranslation } from 'react-i18next';

function IdleMonitor() {
    //Modal
    const [idleModal, setIdleModal] = useState(false);
    const { t } = useTranslation();
    let idleTimeout = 1000 * 60 * process.env.REACT_APP_TIMEOUT;
    let idleLogout = 1000 * 60 * process.env.REACT_APP_LOGOUT - idleTimeout;
    const idleEvent = useRef();
    const idleLogoutEvent = useRef();

    const dispatch = useDispatch();

    /**
     * Add any other events listeners here
     */
    const events = ['mousemove', 'click', 'keypress'];

    /**
     * @method sessionTimeout
     * This function is called with each event listener to set a timeout or clear a timeout.
     */
    const sessionTimeout = () => {
        if (!!idleEvent.current) clearTimeout(idleEvent.current);
        idleEvent.current = setTimeout(() => {
            setIdleModal(true);
            sessionLogout();
        }, idleTimeout); //show session warning modal.
    };

    const sessionLogout = () => {
        idleLogoutEvent.current = setTimeout(() => {
            logUserOut();
        }, idleLogout); //Call logged out on session expire.
    };

    /**
     * @method extendSession
     * This function will extend current user session.
     */
    const extendSession = () => {
        clearTimeout(idleEvent.current);
        clearTimeout(idleLogoutEvent.current);
        setIdleModal(false);
    };

    /**
     * @method logUserOut
     * This function will destroy current user session.
     */
    const logUserOut = () => {
        dispatch(logOut());
    };

    useEffect(() => {
        for (let e in events) {
            window.addEventListener(events[e], sessionTimeout);
        }

        return () => {
            for (let e in events) {
                window.removeEventListener(events[e], sessionTimeout);
            }
        };
    }, []);

    return (
        <Modal
            id="inactivityModal"
            isOpen={idleModal}
            level={'warning'}
            primaryButton={{
                label: t('Log Out'),
                onClick: () => {
                    logUserOut();
                },
            }}
            secondaryButton={{
                label: t('Extend session'),
                onClick: () => {
                    extendSession();
                },
            }}>
            {t(
                'You are being timed-out due to inactivity. You will be logged off automatically in'
            )}{' '}
            {idleLogout / 60 / 1000} {t('minutes')}
        </Modal>
    );
}

export default IdleMonitor;
