import React from 'react';

const Home = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M11.5788635,4.78880244 C12,4.51426697 12,4.51426697 12.4284629,4.79845267 C12.7141049,4.98790981 15.7401029,6.95816589 21.506457,10.7092209 C21.8568568,10.9582672 21.8568568,10.9582672 21.8568568,11.3425175 L21.8568568,11.3425175 L21.8568568,22.2364252 C21.8568568,22.658623 21.517257,23 21.0972573,23 L21.0972573,23 L15.327661,23 C14.9076613,23 14.5584615,22.658623 14.5584615,22.2364252 L14.5584615,22.2364252 L14.5584615,14.8793282 L9.43926487,14.8793282 L9.43926487,22.2364252 C9.43926487,22.658623 9.09966509,23 8.66886538,23 L8.66886538,23 L2.91006913,23 C2.48046941,23 2.14086963,22.658623 2.14086963,22.2364252 L2.14086963,22.2364252 L2.14121883,11.2438499 C2.14436163,10.9767254 2.17578961,10.9524068 2.4900694,10.7092209 L2.4900694,10.7092209 Z M11.5789835,1.12062792 C11.9989832,0.863525391 12,0.863525391 12.4285829,1.13027816 C12.7143049,1.30811333 16.4535024,3.74037436 23.6461756,8.42706123 C24.0061754,8.65866684 24.1266997,9.14305167 23.8765755,9.49220579 C23.5960999,9.88372803 23.1952515,9.88372803 22.8169762,9.7238114 L22.8169762,9.7238114 L11.9989832,2.68879092 L1.18099026,9.7238114 C0.809204102,9.84443933 0.350590797,9.84443933 0.121390946,9.49220579 C-0.107124753,9.14102366 0.000191025397,8.65866684 0.350590797,8.42706123 L0.350590797,8.42706123 L3.75018858,6.2159514 L3.75018858,1.77322499 C3.75018858,1.34137702 4.08978836,1 4.52058808,1 L4.52058808,1 L8.34978558,1 C8.76978531,1 9.10938509,1.34137702 9.10938509,1.77322499 L9.10938509,1.77322499 L9.10938509,2.72859814 Z"
                    id="path-digital-11"></path>
            </defs>
            <g
                id="X-Global-Icons/Digital/home"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-digital-11"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-digital-11"></use>
            </g>
        </svg>
    );
};

export default Home;
