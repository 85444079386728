import {
    FIELDS as AWB_FIELDS,
    LABELS as AWB_LABELS,
    ERROR_LABELS as AWB_ERRORS,
} from './AWBSection/constants';

export const FIELDS = {
    BOOKING_DATA: 'booking_data',
    ...AWB_FIELDS,
};

export const LABELS = {
    ...AWB_LABELS,
};

export const ERROR_LABELS = {
    ...AWB_ERRORS,
};
