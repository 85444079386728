import { MODULE_NAME } from './constants';

export const ONE_RECEIVED = `${MODULE_NAME}/ONE_RECEIVED`;

export const SEARCH_SCHEDULES = `${MODULE_NAME}/SEARCH_SCHEDULES`;
export const GET_ALLOTMENTS = `${MODULE_NAME}/GET_ALLOTMENTS`;
export const STORE_ALLOTMENTS = `${MODULE_NAME}/STORE_ALLOTMENTS`;
export const SAVE = `${MODULE_NAME}/SAVE`;
export const UPDATE = `${MODULE_NAME}/UPDATE`;
export const DRAFT = `${MODULE_NAME}/DRAFT`;
export const UPDATE_DRAFT = `${MODULE_NAME}/UPDATE_DRAFT`;
export const CANCEL = `${MODULE_NAME}/CANCEL`;
export const SORT_BY = `${MODULE_NAME}/SORT_BY`;
export const FETCH_PRODUCTS = `${MODULE_NAME}/FETCH_PRODUCTS`;
export const PRODUCTS_RECEIVED = `${MODULE_NAME}/PRODUCTS_RECEIVED`;
export const SAVE_BOOKING_PRODUCT = `${MODULE_NAME}/SAVE_BOOKING_PRODUCT`;
export const SAVE_BOOKING_PRODUCT_INIT = `${MODULE_NAME}/SAVE_BOOKING_PRODUCT_INIT`;
export const FETCH_AIRCRAFT_MODES = `${MODULE_NAME}/FETCH_AIRCRAFT_MODES`;
export const FETCH_UAX = `${MODULE_NAME}/FETCH_UAX`;
export const AIRCRAFT_MODES_RECEIVED = `${MODULE_NAME}/AIRCRAFT_MODES_RECEIVED`;
export const UAX_RECEIVED = `${MODULE_NAME}/UAX_RECEIVED`;
export const FETCH_AIRCRAFT_TYPES = `${MODULE_NAME}/FETCH_AIRCRAFT_TYPES`;
export const AIRCRAFT_TYPES_RECEIVED = `${MODULE_NAME}/AIRCRAFT_TYPES_RECEIVED`;
export const CHECK_IS_DOMESTIC = `${MODULE_NAME}/CHECK_IS_DOMESTIC`;
export const SET_IS_DOMESTIC_FLIGHT = `${MODULE_NAME}/SET_IS_DOMESTIC_FLIGHT`;

export const SET_FORM = `${MODULE_NAME}/SET_FORM`;
export const RESET_FORM = `${MODULE_NAME}/RESET_FORM`;
export const CHANGE_INITIAL_VALUES = `${MODULE_NAME}/CHANGE_INITIAL_VALUES`;
export const ROUTINGS_RECEIVED = `${MODULE_NAME}/ROUTINGS_RECEIVED`;
export const GO_BACK = `${MODULE_NAME}/GO_BACK`;
export const CHANGE_BOOKING_STATUS = `${MODULE_NAME}/CHANGE_BOOKING_STATUS`;
export const IS_API_RESPONSE_LOADING = `${MODULE_NAME}/IS_API_RESPONSE_LOADING`;
export const UPDATE_PARTIAL = `${MODULE_NAME}/UPDATE_PARTIAL`;

export const GET_USER_DATA = `${MODULE_NAME}/GET_USER_DATA`;
export const UPDATE_ACCOUNT_NUMBER = `${MODULE_NAME}/UPDATE_ACCOUNT_NUMBER`;
export const USER_RECEIVED = `${MODULE_NAME}/USER_RECEIVED`;

export const GET_NOTIFICATION_OPTIONS = `${MODULE_NAME}/GET_NOTIFICATION_OPTIONS`;
export const NOTIFICATION_OPTIONS_RECEIVED = `${MODULE_NAME}/NOTIFICATION_OPTIONS_RECEIVED`;

export const SET_NOTIFICATIONS_PREFERENCES_STRUCTURE = `${MODULE_NAME}/SET_NOTIFICATIONS_PREFERENCES_STRUCTURE`;
export const ADD_NOTIFICATIONS_PREFERENCES_STRUCTURE = `${MODULE_NAME}/ADD_NOTIFICATIONS_PREFERENCES_STRUCTURE`;
export const DELETE_NOTIFICATIONS_PREFERENCES_STRUCTURE = `${MODULE_NAME}/DELETE_NOTIFICATIONS_PREFERENCES_STRUCTURE`;
export const DELETE_ALL_NOTIFICATIONS_PREFERENCES_STRUCTURE = `${MODULE_NAME}/DELETE_ALL_NOTIFICATIONS_PREFERENCES_STRUCTURE`;
export const COPY_NOTIFICATIONS_PREFERENCES_STRUCTURE = `${MODULE_NAME}/COPY_NOTIFICATIONS_PREFERENCES_STRUCTURE`;
export const SET_NOTIFICATIONS_PREFERENCES_STRUCTURE_FIELD = `${MODULE_NAME}/SET_NOTIFICATIONS_PREFERENCES_STRUCTURE_FIELD`;
export const SET_NOTIFICATIONS_AGENT = `${MODULE_NAME}/SET_NOTIFICATIONS_AGENT`;

export const SET_IS_DRAFT = `${MODULE_NAME}/SET_IS_DRAFT`;

export const NOTIFICATIONS_ERRORS = `${MODULE_NAME}/NOTIFICATIONS_ERRORS`;

export const SEARCH_CONTACTS = `${MODULE_NAME}/SEARCH_CONTACTS`;
export const GET_CONTACT = `${MODULE_NAME}/GET_CONTACT`;
export const CONTACT_RECEIVED = `${MODULE_NAME}/CONTACT_RECEIVED`;
export const CREATE_CONTACT = `${MODULE_NAME}/CREATE_CONTACT`;
export const MODIFY_CONTACT = `${MODULE_NAME}/MODIFY_CONTACT`;

export const GET_ULD_LIST = `${MODULE_NAME}/GET_ULD_LIST`;
export const STORE_ULD_LIST = `${MODULE_NAME}/STORE_ULD_LIST`;

export const GET_SHC = `${MODULE_NAME}/GET_SHC`;
export const GET_MULTIPLE_SHC = `${MODULE_NAME}/GET_MULTIPLE_SHC`;
export const SAVE_SPECIAL_SHC = `${MODULE_NAME}/SAVE_SPECIAL_SHC`;
export const SAVE_MULTIPLE_SHC = `${MODULE_NAME}/SAVE_MULTIPLE_SHC`;
export const SHOW_ALERT_OF_BOOKING_STATUS = `${MODULE_NAME}/SHOW_ALERT_OF_BOOKING_STATUS`;

export const SAVE_AWB = `${MODULE_NAME}/SAVE_AWB`;
export const IS_EDITING_AWB = `${MODULE_NAME}/IS_EDITING_AWB`;

export const CHECK_EXTRA_EMBARGOES = `${MODULE_NAME}/CHECK_EXTRA_EMBARGOES`;
export const SAVE_ALLOTMENT_ROUTE = `${MODULE_NAME}/SAVE_ALLOTMENT_ROUTE`;

export const SET_RESTRICTION_QUESTION = `${MODULE_NAME}/SET_RESTRICTION_QUESTION`;
export const GET_PRODUCT_MENUS = `${MODULE_NAME}/GET_PRODUCT_MENUS`;