import React from 'react';
import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
    take,
    delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { doRequest } from '../../api/requestSaga';
import * as c from './actionTypes';
import { openModal } from 'basic_components/Modal';
import '../../components/Page/Page.css';
import {
    DETAILS_ROUTE,
    RATES_ROUTE,
    CONTACTS_ROUTE,
    NOTIFICATION_ROUTE,
    REVIEW_ROUTE,
    FIELDS,
} from './constants';
import {
    MODULE_ROUTE as BOOKING_ROUTE,
    BOOKING_SHOW,
} from 'modules/bookings/constants';

import {
    productsReceived,
    isApiResponseLoading,
    notificationOptionsReceived,
    contactReceived,
    routingsReceived,
    userReceived,
    aircraftModesReceived,
    aircraftUAX,
    aircraftTypesReceived,
    storeAllotments,
    saveSpecialSHCs,
    showAlertOfBookingStatus,
    storeULDList,
    saveBookingProduct,
    saveAllotmentRoute,
    saveMultipleSHC,
    setIsDomesticFlight,
    setForm,
    search, getProductMenus,
} from './actions';
import { updateNotificationPreferences } from '../login/actions';
import { closeModal } from '../../basic_components/Modal';
import { getOne } from 'modules/bookings/actions';
import * as format from 'basic_components/sagaFormatters';
import { formatExtraEmbargoesCheck } from 'basic_components/sagaFormatters';

import { Notification, Flex } from '@freightos/uikit';
import {
    formatCustomerCommunications,
    formatNotificationOrderByOptions,
} from 'basic_components/sagaFormatters';
import { embargoesAlert } from './components/common/EmbargoesAlert';
import { AlertAtmosNotification } from 'basic_components/AlertAtmosNotification/AlertAtmosNotification';
import setMinMaxTimeToBook from './utils/setMinMaxTimeToBook';
import { CustomerEmbargoesData } from '../templates/components/Modals';
import cloneDeep from 'lodash/cloneDeep';
import {
    EXP,
    LXL,
    QPI,
    QPK,
} from './components/common/initialValuesByProductType/productCodes';

export function* callNotificationFormat(data) {
    const notifications = yield select();
    const dataToSend = {
        ...data,
        notification_preferences: formatNotificationOrderByOptions(
            notifications.bookingSteps.notificationsPreferencesStructure || null
        ),
    };
    return dataToSend;
}

function* getSchedule(action) {
    const apiResponseLoading = yield select(
        state => state.bookingSteps.isApiResponseLoading
    );
    if (!apiResponseLoading) {
        yield put(isApiResponseLoading(true));
    }
    const { data, showCard, avoidRedirect } = action;
    const sendData = format.formatShipment(data);
    sendData.packages = JSON.stringify(sendData.packages);
    console.timeEnd('Next');
    try {
        const res = yield call(
            doRequest,
            'POST',
            'cargo/schedules/list',
            sendData,
            {},
            { hideNotification: true }
        );

        const routings = res.response.data ? res.response.data.routings : [];

        yield put(routingsReceived(routings));
        yield put(setForm(data));
        if (data.isAllotmentBooking) {
            yield put(saveAllotmentRoute(routings[0]));
            if (!avoidRedirect) yield put(push(`${CONTACTS_ROUTE}`));
        } else {
            if (!avoidRedirect) yield put(push(`${RATES_ROUTE}`));
        }
    } catch (e) {
        const {
            response: {
                data: {
                    response: { Errors },
                },
            },
        } = e;

        if (showCard && !Errors.show) {
            AlertAtmosNotification(<span>{Errors.Message}</span>, false);

            if (Errors.extra_params) {
                embargoesAlert(Errors.extra_params);
            }
        }
        yield put(routingsReceived([]));
    }
    yield put(isApiResponseLoading(false));
}

function* saveBooking(action) {
    const { data } = action;
    yield put(isApiResponseLoading(true));
    const sendData = format.formatSendBooking(data);

    try {
        const res = yield call(
            doRequest,
            'POST',
            'cargo/bookings/save',
            sendData
        );
        if (res) {
            const last_insert_id_booking = res.response.last_insert_id.find(
                row => row.table === 'bookings'
            );

            const bookingId = last_insert_id_booking?.id
                ? last_insert_id_booking.id
                : sendData.id;
            if (bookingId) {
                if (data.remember_notification_settings) {
                    yield put(
                        updateNotificationPreferences(
                            data.notification_preferences
                        )
                    );
                }

                yield put(getOne(bookingId));
                yield take(c.ONE_RECEIVED);
                yield put(showAlertOfBookingStatus('bookingSaved'));
                yield put(push(`${BOOKING_ROUTE}${BOOKING_SHOW}/${bookingId}`));
            }
        }
    } catch (e) {
        const {
            response: {
                data: {
                    response: { Errors },
                },
            },
        } = e;
        if (!Errors.show) {
            AlertAtmosNotification(<span>{Errors.Message}</span>, false);
        }
    }
    yield put(isApiResponseLoading(false));
}

function* updateBooking(action) {
    const { data } = action;
    yield put(isApiResponseLoading(true));
    const sendData = format.formatSendBooking(data);

    try {
        const res = yield call(
            doRequest,
            'PUT',
            'cargo/bookings/update',
            sendData
        );
        if (res) {
            if (sendData.id) {
                if (data.remember_notification_settings) {
                    yield put(
                        updateNotificationPreferences(
                            data.notification_preferences
                        )
                    );
                }
                yield put(getOne(sendData.id));
                yield take(c.ONE_RECEIVED);
                yield put(showAlertOfBookingStatus('bookingUpdated'));
                yield put(
                    push(`${BOOKING_ROUTE}${BOOKING_SHOW}/${sendData.id}`)
                );
            }
        }
    } catch (e) {
        const {
            response: {
                data: {
                    response: { Errors },
                },
            },
        } = e;
        if (!Errors.show) {
            AlertAtmosNotification(<span>{Errors.Message}</span>, false);
        }
    }
    yield put(isApiResponseLoading(false));
}

function* saveDraft(action) {
    const { data } = action;
    const dataToSend = yield callNotificationFormat(data);
    const sendData = format.formatSendBooking(dataToSend);
    try {
        const res = yield call(
            doRequest,
            'POST',
            'cargo/bookings/save_draft',
            sendData
        );
        if (res) {
            const last_insert_id_booking = res.response.last_insert_id.find(
                row => row.table === 'bookings'
            );
            if (last_insert_id_booking || sendData.id) {
                yield put(
                    getOne(
                        last_insert_id_booking?.id
                            ? last_insert_id_booking.id
                            : sendData.id
                    )
                );
                yield put(
                    openModal(
                        'draftConfirmation',
                        'normal',
                        'Draft completed',
                        800,
                        true
                    )
                );
            }
        }
    } catch (e) {}
}

function* updateDraft(action) {
    const { data } = action;
    const dataToSend = yield callNotificationFormat(data);
    const sendData = format.formatSendBooking(dataToSend);
    try {
        const res = yield call(
            doRequest,
            'PUT',
            'cargo/bookings/update_draft',
            sendData
        );
        if (res) {
            if (sendData.id) {
                yield put(getOne(sendData.id));
                yield put(
                    openModal(
                        'draftConfirmation',
                        'normal',
                        'Draft updated',
                        800,
                        true
                    )
                );
            }
        }
    } catch (e) {}
}

function* cancelBooking(action) {
    const data = {
        id: action.id,
    };

    try {
        const res = yield call(doRequest, 'PUT', 'cargo/bookings/cancel', data);
        if (res) {
            yield put(getOne(action.id));
            yield put(
                openModal(
                    'bookingCanceled',
                    'normal',
                    'Booking canceled',
                    800,
                    true
                )
            );
        }
    } catch (e) {}
}

function* updatePartial(action) {
    const { data } = action;
    const notificationsPreferences = formatCustomerCommunications(data);
    const contacts = format.formatContacts(data);
    const awbData = action.isAWB
        ? {
              awb_later: data?.awb_type === 'laterAWB' ? 1 : 0,
              awb_number:
                  data?.awb_type === 'laterAWB'
                      ? null
                      : data?.awb_number || null,
          }
        : { awb_later: null, awb_number: null };
    const sendData = {
        id: data.id,
        dropping_info: contacts?.dropping_info,
        picking_info: contacts?.picking_info,
        agent_info: contacts?.agent_info,
        customer_notifications: JSON.stringify({
            booking_id: data.id,
            ...notificationsPreferences,
        }),
        ...awbData,
    };
    try {
        yield put(isApiResponseLoading(true));
        const res = yield call(
            doRequest,
            'PUT',
            'cargo/bookings/update_partial',
            sendData
        );
        if (res) {
            if (data.id) {
                if (action.isAWB) {
                    yield put(closeModal('AWBEditNumber'));
                }
                yield put(getOne(data.id));
                yield take(c.ONE_RECEIVED);
                yield put(showAlertOfBookingStatus('bookingUpdated'));
                yield put(push(`${BOOKING_ROUTE}${BOOKING_SHOW}/${data.id}`));
            }
        }
    } catch (e) {}
    yield put(isApiResponseLoading(false));
}

function* goback({ pathname, hasAllotment }) {
    const form = yield select(state => state.bookingSteps?.form);
    switch (pathname) {
        case DETAILS_ROUTE:
            yield put(push(`${DETAILS_ROUTE}`));
            break;
        case RATES_ROUTE:
            yield put(push(`${DETAILS_ROUTE}`));
            break;
        case CONTACTS_ROUTE:
            if (!hasAllotment) {
                yield put(search(form));
            }
            yield put(
                push(hasAllotment ? `${DETAILS_ROUTE}` : `${RATES_ROUTE}`)
            );
            break;
        case NOTIFICATION_ROUTE:
            yield put(push(`${CONTACTS_ROUTE}`));
            break;
        case REVIEW_ROUTE:
            yield put(push(`${NOTIFICATION_ROUTE}`));
            break;
        default:
            break;
    }
}

function* getProducts() {
    const dateFormat = yield select(
        state => state?.user?.userInfo?.date_format || 'YYYY-MM-DD'
    );
    try {

        const res = yield call(doRequest,'GET','/cargo/products/select2/','');
        const responseData = res.response.data;
        const productToChange = cloneDeep(responseData).find(
            prod => prod.code === EXP
        );

        if (productToChange) {
            productToChange.code = LXL;
            productToChange.label = `${LXL} - Lifeguard XL`;
            productToChange.name = 'Lifeguard XL';
            responseData.push(productToChange);
        }

        const productsMinMaxTime = responseData
            ? responseData?.map(product =>
                  typeof product.maxTimeToBook === 'number'
                      ? setMinMaxTimeToBook(product, dateFormat)
                      : product
              )
            : [];


        yield put(productsReceived(productsMinMaxTime));


    } catch (e) {}
}

function* getMenuProducts() {
    const dateFormat = yield select(
        state => state?.user?.userInfo?.date_format || 'YYYY-MM-DD'
    );
    try {

        const productMenuRes = yield call(doRequest, 'GET', 'cargo/products/productMenuAccess/','');

        const menuResponseData = productMenuRes.response.data;
        const productMenuToChange = cloneDeep(menuResponseData).find(
            prod => prod.code === EXP
        );
        if (productMenuToChange) {
            productMenuToChange.code = LXL;
            productMenuToChange.label = `${LXL} - Lifeguard XL`;
            productMenuToChange.name = 'Lifeguard XL';
            menuResponseData.push(productMenuToChange);
        }

        const menuproductsMinMaxTime = menuResponseData
            ? menuResponseData?.map(product =>
                typeof product.maxTimeToBook === 'number'
                    ? setMinMaxTimeToBook(product, dateFormat)
                    : product
            )
            : [];
        yield put(getProductMenus(menuproductsMinMaxTime));

    } catch (e) {}
}

function* checkIsDomestic(data) {
    const formatData = { origin: data.origin, destination: data.destination };
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo/schedules/check_is_domestic',
            formatData
        );
        const isDomesticFlight = res.response.data;
        yield put(setIsDomesticFlight(isDomesticFlight));
    } catch (e) {}
}

function* getAircraftModes() {
    try {
        const res = yield call(doRequest, 'GET', '/cargo/aircraft/modes', '');

        const {
            response: { data },
        } = res;

        const formattedArray = format.formatAircraftOptions(data);

        yield put(aircraftModesReceived(formattedArray));
    } catch (e) {}
}

function* fetchUAX() {
    try {
        const res = yield call(doRequest, 'GET', '/cargo/carrier/types');
        const formattedArray = format.formatAircraftOptions(res.response.data);
        yield put(aircraftUAX(formattedArray));
    } catch (e) {}
}

function* getAircraftTypes() {
    try {
        const res = yield call(doRequest, 'GET', '/cargo/aircraft/types', '');
        const {
            response: { data },
        } = res;

        const formattedArray = format.formatAircraftOptions(data);

        yield put(aircraftTypesReceived(formattedArray));
    } catch (e) {}
}

function* getNotificationOptions() {
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo/customer_communications/list'
        );
        const returnData = res.response.data.map(option => ({
            value: option.code,
            label: option.description,
        }));
        yield put(notificationOptionsReceived(returnData));
    } catch (e) {}
}

function* getContact(payload) {
    const data = {
        id: payload.id,
    };
    try {
        const res = yield call(
            doRequest,
            'GET',
            'cargo/cargo_contacts/show',
            data
        );
        const contact = { ...format.formatContact(res.response.data, false) };
        if (payload.without_client) contact.account_number = null;
        yield put(closeModal('AddressBook'));
        yield put(contactReceived(contact, payload.contact_type));
    } catch (e) {}
}

function* getUserData(payload) {
    const data = {
        id: payload.user?.account_number,
    };
    try {
        const res = yield call(
            doRequest,
            'GET',
            '/cargo/clients_accounts/by_account_number',
            data
        );
        const returnedData = {
            account_description: res.response?.data?.[0]?.account_description,
            account_number: res.response?.data?.[0]?.account_number,
            account_status: res.response?.data?.[0]?.account_status,
            address: res.response?.data?.[0]?.address,
            address2: res.response?.data?.[0]?.address2,
            address3: res.response?.data?.[0]?.address3,
            allowed_bup: res.response?.data?.[0]?.allowed_bup,
            calling_code: res.response?.data?.[0]?.calling_code,
            city: res.response?.data?.[0]?.main_city,
            city_text: res.response?.data?.[0]?.city,
            company_name: res.response?.data?.[0]?.client?.name,
            contact_name: '',
            country: res.response?.data?.[0]?.country,
            email: res.response?.data?.[0]?.email,
            phone: res.response?.data?.[0]?.phone,
            po_box: res.response?.data?.[0]?.po_box,
            state: res.response?.data?.[0]?.state,
            state_code: res.response?.data?.[0]?.state_code,
            zip_code: res.response?.data?.[0]?.zip_code,
            account_number_id: res.response?.data?.[0]?.id,

        };
        yield put(userReceived(returnedData, payload.user_type));
    } catch (e) {}
}

function* getAllotments(payload) {
    yield put(isApiResponseLoading(true));
    let data = {
        origin: payload?.data?.origin?.value,
        destination: payload?.data?.destination?.value,
        start_date: payload?.data?.departure_date,
        account_number: payload?.data?.account_number?.account_number,
    };
    if (payload.data.allotment_id) {
        data = { ...data, allotment_id: payload?.data?.allotment_id };
    }
    try {
        const res = yield call(
            doRequest,
            'GET',
            '/cargo/allotments/list',
            data
        );
        yield put(
            storeAllotments(format.formatAllotmentData(res.response.data))
        );
    } catch (e) {
        yield put(storeAllotments({}));
    }
    yield put(isApiResponseLoading(false));
}

function* getULDList(data) {
    try {
        const res = yield call(
            doRequest,
            'GET',
            `/cargo/ulds/show_per_product/${data.productId}`,
            ''
        );

        yield put(
            storeULDList(
                res.response.data ? format.formatULDList(res.response.data) : []
            )
        );
    } catch (e) {}
}

function* createContact(payload) {
    const data = {
        ...format.formatContact(payload.data),
    };

    const actions = {
        customMessage:
            'The contact could not be saved due to missing information',
    };
    try {
        const res = yield call(
            doRequest,
            'POST',
            'cargo/cargo_contacts/save',
            data,
            {},
            actions
        );
        const lastInsertIdContact = res.response?.last_insert_id.find(
            row => row.table === 'cargo_contacts'
        );
        yield put(
            contactReceived(
                {
                    ...payload.data,
                    id: lastInsertIdContact?.id,
                },
                payload.contact_type
            )
        );
    } catch (e) {
        Notification.show({
            message:
                'The contact could not be saved due to missing information',
            level: 'warning',
            type: 'toast',
            autoClose: false,
        });
    }
}

function* modifyContact(payload) {
    const data = {
        ...format.formatContact(payload.data),
        id: payload?.data?.id,
    };
    try {
        yield call(doRequest, 'PUT', '/cargo/cargo_contacts/update', data);
    } catch (e) {}
}

function* getSHC(payload) {
    const shcName = payload.shc;
    try {
        const res = yield call(
            doRequest,
            'GET',
            `/cargo/shcs/select2/${payload.product}/default/${shcName}`
        );
        const shcToAdd = res.response.data.find(obj =>
            obj.label.includes(shcName.toUpperCase())
        );
        if (shcToAdd) {
            yield put(saveSpecialSHCs(shcToAdd, shcName));
        }
    } catch (e) {
        console.error(e);
    }
}

function* getMultipleSHC(payload) {
    const shcListToGet = payload.shcListToGet;

    const newSHCList = [];
    yield all(
        shcListToGet.map(shc => {
            return call(function*() {
                try {
                    const res = yield call(
                        doRequest,
                        'GET',
                        `/cargo/shcs/select2/${shc.product}/default/${shc.name}`
                    );
                    const shcToAdd = res.response.data.find(obj =>
                        obj.label.includes(shc.name.toUpperCase())
                    );
                    if(shcToAdd) {
                        newSHCList.push({...shcToAdd, name: shc.name});
                    }


                } catch (e) {
                    console.error(e);
                }
            });
        })
    );

    if (newSHCList.length > 0) {
        yield put(saveMultipleSHC(newSHCList));
    }
}

function* saveAWB(payload) {
    const reduxData = yield select();
    const sendData = {
        booking_id: payload.booking_id,
        awb_capture: JSON.stringify(
            format.formatAWBCaptureToBack(payload.data)
        ),
    };
    try {
        yield call(doRequest, 'PUT', 'cargo/bookings/awb_capture', sendData);
        yield put(getOne(payload.booking_id));
        yield delay(2000);
        if (reduxData?.bookingSteps?.form?.awb_capture !== null) {
            yield put(showAlertOfBookingStatus('awbUpdated'));
        } else {
            yield put(showAlertOfBookingStatus('awbCompleted'));
        }
        yield put(
            push(`${BOOKING_ROUTE}${BOOKING_SHOW}/${payload.booking_id}`)
        );
    } catch (e) {}
}

function* checkExtraEmbargoes({ data }) {
    yield put(isApiResponseLoading(true));
    const formatData = formatExtraEmbargoesCheck(data);

    try {
        const response = yield call(
            doRequest,
            'POST',
            'cargo/schedules/check_extra_embargoes',
            formatData,
            {},
            { hideNotification: true }
        );
        const embargoesResponse = response?.response?.data;
        if (embargoesResponse) {
            const softEmbargoesToShow = embargoesResponse.filter(
                embargo => embargo.type === 'Soft Bookable'
            );
            if (softEmbargoesToShow.length > 0) {
                yield put(isApiResponseLoading(false));

                return yield put(
                    openModal(
                        'customerEmbargoesOverride',
                        'normal',
                        <Flex
                            style={{
                                textAlign: 'center',
                                fontSize: '1em',
                                lineHeight: '1.4em',
                            }}>
                            {
                                'Are you sure you want to proceed with this operation and override the following restrictions?'
                            }
                        </Flex>,
                        600,
                        false,
                        <CustomerEmbargoesData
                            embargoes={softEmbargoesToShow}
                        />
                    )
                );
            }
        }

        yield put(push(NOTIFICATION_ROUTE));
        yield put(isApiResponseLoading(false));
    } catch (e) {
        const {
            response: {
                data: {
                    response: { Errors },
                },
            },
        } = e;

        if (Errors.extra_params) {
            embargoesAlert(Errors.extra_params);
        }
        yield put(isApiResponseLoading(false));
    }
}

export default function*() {
    yield all([
        takeLatest(c.SEARCH_SCHEDULES, getSchedule),
        takeLatest(c.SAVE, saveBooking),
        takeLatest(c.UPDATE, updateBooking),
        takeLatest(c.DRAFT, saveDraft),
        takeLatest(c.UPDATE_DRAFT, updateDraft),
        takeLatest(c.CANCEL, cancelBooking),
        takeLatest(c.UPDATE_PARTIAL, updatePartial),
        takeLatest(c.FETCH_PRODUCTS, getProducts),
        takeLatest(c.CHECK_IS_DOMESTIC, checkIsDomestic),
        takeLatest(c.FETCH_AIRCRAFT_MODES, getAircraftModes),
        takeLatest(c.FETCH_UAX, fetchUAX),
        takeLatest(c.FETCH_AIRCRAFT_TYPES, getAircraftTypes),
        takeLatest(c.GO_BACK, goback),
        takeLatest(c.GET_NOTIFICATION_OPTIONS, getNotificationOptions),
        takeLatest(c.GET_CONTACT, getContact),
        takeLatest(c.GET_ALLOTMENTS, getAllotments),
        takeLatest(c.GET_USER_DATA, getUserData),
        takeLatest(c.GET_ULD_LIST, getULDList),
        takeEvery(c.CREATE_CONTACT, createContact),
        takeLatest(c.MODIFY_CONTACT, modifyContact),
        takeLatest(c.GET_SHC, getSHC),
        takeLatest(c.GET_MULTIPLE_SHC, getMultipleSHC),
        takeLatest(c.SAVE_AWB, saveAWB),
        takeLatest(c.CHECK_EXTRA_EMBARGOES, checkExtraEmbargoes),
        takeLatest(c.FETCH_PRODUCTS, getMenuProducts),
    ]);
}
