import React from 'react';

const Checkmark = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/checkmark"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M23.4381578,3.30600471 C24.0710174,3.79996762 24.1865709,4.71725248 23.6962539,5.35481779 L12.0996843,20.4339801 C11.846148,20.7636564 11.4802601,20.9539879 11.0985039,20.9926387 C10.7108338,21.0425655 10.3050919,20.9444795 9.97503959,20.6867233 L0.554720752,13.3298717 C-0.0985314798,12.8197106 -0.207904875,11.8772475 0.310428436,11.2248214 C0.828761747,10.5723953 1.77851949,10.4570666 2.43177172,10.9672277 L10.7282569,17.447 L21.4044667,3.56601987 C21.8947837,2.92845456 22.8052983,2.81204181 23.4381578,3.30600471 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Checkmark;
