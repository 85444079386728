import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
//FIELDS
import { FIELDS } from 'modules/bookingSteps/constants';

//ACTIONS
import {
    DETAILS_ROUTE,
    REVIEW_ROUTE,
    validStatusSaveForLaterShow,
    validStatusSaveAsTemplateShow,
    updateStatus, UNITED_TERMS_AND_CONDITIONS,
} from 'modules/bookingSteps/constants';

//COMPONENTS
import { Flex, Button, Box } from '@freightos/uikit';
import { ButtonLinkFormat, LinkContainer } from './../styled';
import EditableContent from 'modules/bookingSteps/components/reviewAndBook/EditableContent';
import {
    ConfirmWithOverwritten,
    CustomerEmbargoesOverwrite,
    DeleteModal,
    SaveTemplateModal,
} from 'modules/templates/components/Modals';
import IataRestrictionNotification from './iataRestrictionNotification';
import handleDraft from './handleDraft';
import { useLocation } from 'react-router';
import handleTemplate from './handleTemplate';
import { openModal } from 'basic_components/Modal';
import { isDraft, isTemplate } from 'basic_components/sagaFormatters';
import handleBack from './handleBack';
import { agentStatusRestricted } from '../../shipmentDetails/constants';
import handleEdit from './handleEdit';
import { isRestrictedProductDG } from '../../../utils/isRestrictedProductDG';

const ActionButtons = ({ handleNext }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const { values, validateForm, setFieldValue } = useFormikContext();

    const form = useSelector(state => state.bookingSteps.form);
    const status = useSelector(state => state.bookingSteps.status);
    const agent = useSelector(state => state?.bookingSteps?.form?.agent);
    const isEditingAWB = useSelector(state => state.bookingSteps.isEditingAWB);
    const notificationsPreferencesStructure = useSelector(
        state => state.bookingSteps.notificationsPreferencesStructure
    );
    const isApiResponseLoading = useSelector(
        state => state.bookingSteps.isApiResponseLoading
    );

    const bookingProduct = useSelector(
        state => state?.bookingSteps.bookingProduct
    );
    const products = useSelector(
        state => state?.bookingSteps.products
    );
    const productItem = products?.find(item => item.code === "LFG");
    const lfgCargoDisabled= productItem?.cargo_enabled ?? 0;
    const isCargoDisabled = (bookingProduct?.code === 'LXL' || bookingProduct?.code === 'LFG') ? (lfgCargoDisabled === 0) : (bookingProduct?.cargo_enabled === 0);

    const isFirstStep = pathname === DETAILS_ROUTE;
    const isShowRoute = pathname.includes('/management');
    const isLastStep = pathname === REVIEW_ROUTE;
    const visibleBackButton = !isFirstStep && !(isShowRoute && isEditingAWB);

    const StickyFooter=
            isLastStep && status !== 'view_template' ?
            styled(Flex)`
          position:sticky;         
          margin-top:100px;
          bottom:0;
          background:#fff;      
          padding-right:10px;
          padding-top:12px;              
          justify-content: center;
          margin-left: calc(((-99vw) + (1103px))/2) !important;
          display: flex;
          width: 99VW;          
        border-top: 1px solid #ccc;`: styled(Flex)`padding-top:12px; justify-content: flex-end;`;

    return (
        !isRestrictedProductDG(values) && (
            <StickyFooter>
                <flex style={{
                    width:'1103px',
                    justifyContent:'center',
                    display:'flex',
                    marginLeft:'12px',
                }}>
                    <Flex style={{
                        flexGrow: 2,
                    }}>
                        <p style={{lineHeight:'25px'}}>
                            {isLastStep && status !== 'view_template' && !updateStatus.includes(status) &&
                                <>
                                    {t('By selecting "Book now", you agree to the ')}
                            <a style={{ color: 'steelblue' }} href={UNITED_TERMS_AND_CONDITIONS} target="parent">
                                {t(' terms and conditions')}
                            </a>
                            <br/>
                                {t(' that is associated with this booking.')}
                                </>
                            }

                            {isLastStep && status !== 'view_template' && updateStatus.includes(status) &&
                                <>
                                    {t('By selecting "Update", you agree to the ')}
                                    <a style={{ color: 'steelblue' }} href={UNITED_TERMS_AND_CONDITIONS} target="parent">
                                        {t(' terms and conditions')}
                                    </a>
                                    <br/>
                                    {t(' that is associated with this booking.')}
                                </>
                            }
                        </p>
                    </Flex>

                <LinkContainer>
                    {validStatusSaveForLaterShow.includes(status) && (
                        <Flex>
                            <ButtonLinkFormat
                                data-cc="btn_bp_form_actions_save_draft"
                                onClick={() =>
                                    handleDraft(
                                        dispatch,
                                        values,
                                        setFieldValue,
                                        validateForm,
                                        isFirstStep,
                                        agent,
                                        notificationsPreferencesStructure,
                                        pathname,
                                        t
                                    )
                                }>
                                {t('Save for later')}
                            </ButtonLinkFormat>
                        </Flex>
                    )}
                    {validStatusSaveAsTemplateShow.includes(status) && (
                        <Flex>
                            <ButtonLinkFormat
                                p={5}
                                mr={2}
                                data-cc="btn_bp_form_actions_save_template"
                                onClick={() => {
                                    handleTemplate(
                                        dispatch,
                                        values,
                                        isFirstStep,
                                        t
                                    );
                                }}>
                                {t('Save as template')}
                            </ButtonLinkFormat>
                        </Flex>
                    )}

                    {isTemplate(form) && status === 'show' && (
                        <Flex>
                            <ButtonLinkFormat
                                p={5}
                                mr={2}
                                data-cc="btn_bp_form_actions_open_delete_template"
                                onClick={() => {
                                    dispatch(openModal('deleteModal'));
                                }}>
                                {t('Delete')}
                            </ButtonLinkFormat>
                        </Flex>
                    )}
                </LinkContainer>
                {visibleBackButton && (
                    <Box>
                        <Button
                            variation={isCargoDisabled ? "primary" : 'default'}
                            data-cc="btn_bp_form_actions_back"
                            onClick={() => {
                                handleBack(
                                    dispatch,
                                    values,
                                    status,
                                    form,
                                    pathname
                                );
                            }}
                            mr={2}>
                            {t('Back')}
                        </Button>
                    </Box>
                )}
                {status !== 'show' ? (
                    <Button
                        onMouseEnter={() => document.activeElement.blur()}
                        variation="primary"
                        data-cc={`btn_bp_form_actions_${
                            !isLastStep
                                ? 'next'
                                : updateStatus.includes(status)
                                ? 'update_booking'
                                : 'book'
                        }`}
                        onClick={handleNext}
                        isLoading={isApiResponseLoading}
                        disabled={
                            isApiResponseLoading ||
                            agentStatusRestricted.includes(
                                form?.agent?.account_status
                            )
                        }>
                        {!isLastStep
                            ? t('Next')
                            : updateStatus.includes(status)
                            ? t('Update')
                            : t('Book now')}
                    </Button>
                ) : (
                    !isEditingAWB && !isCargoDisabled && (
                        <EditableContent>
                            <Button
                                data-cc={`btn_bp_form_actions_${
                                    isTemplate(form)
                                        ? 'book'
                                        : isDraft(form)
                                        ? 'use_draft'
                                        : 'cancel_booking'
                                }`}
                                variation="primary"
                                onClick={() =>
                                    handleEdit(
                                        dispatch,
                                        setFieldValue,
                                        form,
                                        notificationsPreferencesStructure
                                    )
                                }>
                                {isTemplate(form)
                                    ? t('Make a booking')
                                    : isDraft(form)
                                    ? t('Use Draft')
                                    : t('Cancel booking')}
                            </Button>
                        </EditableContent>
                    )
                )}
                <DeleteModal template={form?.template_booking_id} />
                <SaveTemplateModal />
                <IataRestrictionNotification translate={t} />
                <ConfirmWithOverwritten flight={values[FIELDS.FLIGHT]} />
                <CustomerEmbargoesOverwrite/>
                </flex>
            </StickyFooter>
        )
    );
};

export default ActionButtons;
