import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as request from '../../api/requestSaga';
import { rowsReceived, changeLastTableOpened } from './actions';

function* getRows(payload) {
    const lastTableOpened = yield select(
        state => state.tables?.lastTableOpened
    );
    const sorting = payload.sorter.columnKey
        ? [
              {
                  id: payload.sorter.columnKey,
                  desc: payload.sorter.order !== 'ascend',
              },
          ]
        : [];
    const data = {
        pageSize: payload.pagination.pageSize,
        page: payload.pagination.current - 1,
        sorted: JSON.stringify(sorting),
        filtered: JSON.stringify([payload.filters]),
    };

    try {
        const res = yield call(
            request.doRequest,
            'GET',
            payload.endpoint,
            data
        );
        const newPagination = {
            pageSize: payload.pagination.pageSize,
            current: payload.pagination.current,
            total: res.response.num_rows || 0,
        };

        const response = res.response.data;
        yield put(rowsReceived(response, newPagination, payload.table_id));
        if (payload.table_id !== lastTableOpened) {
            yield put(changeLastTableOpened(payload.table_id, null));
        }
    } catch (e) {}
}

function* enableSelected(payload) {
    const data = {
        id: payload.idToEnable,
        enabled: 1,
    };

    const endpoint = 'admin/' + payload.section + '/update';
    try {
        yield call(request.doRequest, 'PUT', endpoint, data);
    } catch (e) {}
}

function* disableSelected(payload) {
    const data = {
        id: payload.idToDisable,
        enabled: 0,
    };
    const endpoint = 'admin/' + payload.section + '/update';
    try {
        yield call(request.doRequest, 'PUT', endpoint, data);
    } catch (e) {}
}

function* deleteSelected(payload) {
    const data = {
        id: payload.idToRemove,
    };
    const endpoint = 'admin/' + payload.section + '/delete';
    try {
        yield call(request.doRequest, 'DELETE', endpoint, data);
    } catch (e) {}
}

function* refreshTable(payload) {
    const [tableId] = payload.type.split('/');
    const tableProps = yield select(state => state.tables[`${tableId}_TABLE`]);
    if (tableProps) {
        tableProps.endpoint = `cargo/${tableId.toLowerCase()}/list`;
        tableProps.table_id = `${tableId}_TABLE`;
        yield delay(2000);
        yield getRows(tableProps);
    }
}

export default function*() {
    yield all([
        takeLatest(types.TABLE_CHANGE, getRows),
        takeLatest(types.ENABLE_SELECTED, enableSelected),
        takeLatest(types.DISABLE_SELECTED, disableSelected),
        takeLatest(types.DELETE_SELECTED, deleteSelected),
        takeLatest(action => /CREATE$|MODIFY$/.test(action.type), refreshTable),
    ]);
}
