import React from 'react';
import { Switch, Redirect } from 'react-router';
// import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Page from 'components/Page/Page';
import PrivateRoute from 'basic_components/PrivateRoute';
import { history } from 'store/index';
// Modules
import login from './login/routes';
// import petsRoutes from './pets/routes';
import { DETAILS_ROUTE } from 'modules/bookingSteps/constants';

import ContactsRoutes from './contacts/routes';
import BookingsRoutes from './bookings/routes';
import BookingSteps from './bookingSteps/routes';
import TemplateRoutes from './templates/routes';
import { MODULE_ROUTE as bookingStepsModule } from './bookingSteps/constants';
import { MODULE_ROUTE as bookingsModule } from './bookings/constants';
import { MODULE_ROUTE as contactsModule } from './contacts/constants';
import { MODULE_ROUTE as templateModule } from './templates/constants';

const Root = () => {
    return (
        <ConnectedRouter history={history}>
            <Page>
                <Switch>
                    {login}
                    <PrivateRoute
                        path={`${bookingStepsModule}`}
                        component={BookingSteps}
                    />
                    <PrivateRoute
                        path={`${bookingsModule}`}
                        component={BookingsRoutes}
                    />
                    <PrivateRoute
                        path={`${contactsModule}`}
                        component={ContactsRoutes}
                    />
                    <PrivateRoute
                        path={`${templateModule}`}
                        component={TemplateRoutes}
                    />
                    <Redirect path="*" to={`${DETAILS_ROUTE}`} />
                </Switch>
            </Page>
        </ConnectedRouter>
    );
};

// export default connect(mapStateToProps, mapDispatchToProps)(Root);
export default Root;
