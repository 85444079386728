import styled from 'styled-components';
import { Flex } from '@freightos/uikit';

export const StyledMenu = styled.div`
    color: white;
    cursor: pointer;
    position: inherit;

    button {
        line-height: 2;
        min-width: 0;
        display: flex;
        align-items: center;
        padding: 0;
    }
`;
export const CustomMenuRow = styled(Flex)`
    background-color: rgba(12, 35, 64, 1);
    border-left: 1px solid rgb(124, 135, 142);
    border-right: 1px solid rgb(124, 135, 142);
    ${props => `border-${props.direction}`}: 1px solid rgb(124, 135, 142);
    padding-bottom: ${props => (props.paddingBottom ? '12px' : '0px')};
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    min-height: 32px;
    a {
        color: rgba(255, 255, 255, 1);
        font-size: 11.952px;
        font-family: 'Open Sans', sans-serif;
        font-feature-settings: 'tnum';
        letter-spacing: normal;
        &:hover {
            color: rgba(124, 135, 142, 1);
        }
    }
`;

export const CustomDropMenu = styled(Flex)`
    min-width: 150px !important;
    position: absolute;
    z-index: 1111;
`;

export const ButtonDropDown = styled.button`
    background: none;
    color: inherit;
    border: none;
    line-height: 2;
    padding: 0;
    height: 24px;
`;
