import React from 'react';

const Plane = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path
                    d="M5.91127389,24 L7.38249228,24 C7.65969575,24 7.93689921,23.8748545 8.1037013,23.6257784 L14.959387,14.5010884 L22.481081,13.71984 C22.6290829,13.6987799 23.0174877,13.4067737 23.6462956,12.8438212 C24.1179015,12.3760441 24.1179015,11.5632056 23.6462956,11.0942135 C23.0174877,10.5312611 22.6290829,10.2396598 22.481081,10.2194097 L14.959387,9.43816129 L8.13130164,0.375436643 C7.93689921,0.125145548 7.68729609,0 7.41009263,0 L5.93887424,0 L9.01931274,9.90593834 L3.16443956,10.3129651 L0.970812135,8.06277527 C0.915611445,8.000405 0.5920074,7.94815977 0,7.90603959 L1.24921562,12.0309826 L0,16.0939604 C0.55520694,16.0518402 0.86961087,15.999595 0.94321179,15.9372247 L3.1356392,13.6882499 L8.9917124,14.0940617 L5.91127389,24 Z"
                    id="path-travel-23"></path>
            </defs>
            <g
                id="X-Global-Icons/Travel-&amp;-Airport/plane"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-travel-23"></use>
                </mask>
                <use
                    id="Mask"
                    fill={fill || '#002244'}
                    xlinkHref="#path-travel-23"></use>
            </g>
        </svg>
    );
};

export default Plane;
