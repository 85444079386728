export const FIELDS = {
    ADDRESS_1: 'address',
    ADDRESS_2: 'address2',
    ADDRESS_3: 'address3',
    CALLER_NAME: 'caller_name',
    CITY_CODE: 'city',
    CITY_TEXT: 'city_text',
    CONSIGNEE: 'consignee',
    ACCOUNT_NUMBER: 'account_number',
    COMPANY_NAME: 'company_name',
    CONTACT_NAME: 'contact_name',
    COUNTRY: 'country',
    COUNTRY_CALLING_CODE: 'calling_code',
    EMAIL: 'email',
    PO_BOX: 'po_box',
    PRIMARY_PHONE: 'phone',
    SHIPPER: 'shipper',
    STATE: 'state',
    STATE_CODE: 'state_code',
    TYPE_OF_CONTACT: 'type_of_contact',
    USE_CONTACT: 'use_contact',
    ZIP_CODE: 'zip_code',
};

export const LABELS = {
    ADDRESS_1: 'Address',
    ADDRESS_2: 'Address line 2',
    ADDRESS_3: 'Address line 3',
    CITY_CODE: 'City code',
    CITY_TEXT: 'City / Town',
    ACCOUNT_NUMBER: 'Select an account number',
    COMPANY_NAME: 'Company name',
    CONTACT_NAME: 'Contact name',
    COUNTRY: 'Country',
    COUNTRY_CALLING_CODE: 'Country calling code',
    EMAIL: 'Email address',
    PO_BOX: 'PO Box',
    PRIMARY_PHONE: 'Phone number',
    STATE: 'State / Province',
    USE_AGENT_INFO: 'Same as agent details',
    USE_SHIPPER_INFO: 'Same as shipper details',
    ZIP_CODE: 'ZIP/Postal code',
};

export const ERROR_LABELS = {};

export const FORMS = ['shipper', 'consignee'];

export const CLEAR_FORM = {
    [FIELDS.COMPANY_NAME]: '',
    [FIELDS.EMAIL]: '',
    [FIELDS.COUNTRY_CALLING_CODE]: null,
    [FIELDS.PRIMARY_PHONE]: '',
    [FIELDS.ADDRESS_1]: '',
    [FIELDS.ADDRESS_2]: '',
    [FIELDS.ADDRESS_3]: '',
    [FIELDS.COUNTRY]: null,
    [FIELDS.STATE]: '',
    [FIELDS.ZIP_CODE]: '',
    [FIELDS.CITY_TEXT]: '',
    [FIELDS.PO_BOX]: '',
    [FIELDS.CITY_CODE]: null,
    [FIELDS.ACCOUNT_NUMBER]: null,
    [FIELDS.CONTACT_NAME]: '',
    [FIELDS.USE_CONTACT]: false,
    [FIELDS.CALLER_NAME]: '',
};
