import moment from 'moment';

const setMinMaxTimeToBook = (productToUse, dateFormat = 'YYYY-MM-DD') => {
    productToUse.minTimeToBook = productToUse?.minTimeToBook
        ? moment()
              .add(productToUse?.minTimeToBook, 'days')
              .format(dateFormat)
        : null;
    productToUse.maxTimeToBook = productToUse?.maxTimeToBook
        ? moment()
              .add(productToUse?.maxTimeToBook, 'days')
              .format(dateFormat)
        : null;
    return productToUse;
};

export default setMinMaxTimeToBook;
