import React from 'react';

import { openModal } from 'basic_components/Modal';
import { isDomesticIataRestriction } from 'basic_components/Validations';
import { selectTemplate } from 'modules/templates/actions';
import partialSaveCheck from './partialSaveCheck';
import removeNotifications from './removeNotifications';
import { cloneDeep } from 'lodash';
import { AlertAtmosNotification } from 'basic_components/AlertAtmosNotification/AlertAtmosNotification';

const handleTemplate = async (dispatch, values, isFirstStep, t) => {
    if (
        isFirstStep &&
        isDomesticIataRestriction(
            values.origin,
            values.destination,
            values.account_number
        )
    ) {
        return dispatch(openModal('isDomesticIATA', '', '', 800, true));
    }
    removeNotifications();
    if (partialSaveCheck(values)) {
        dispatch(selectTemplate(cloneDeep(values)));
        dispatch(openModal('saveTemplateModal'));
    } else {
        AlertAtmosNotification(<span>{t('Incomplete details')}</span>);
    }
};
export default handleTemplate;
