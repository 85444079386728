import React from 'react';

const TravelBank = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/Payment/travel-bank"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M21,4 C22.6568542,4 24,5.34314575 24,7 L24,18 C24,19.6568542 22.6568542,21 21,21 L3,21 C1.34314575,21 2.02906125e-16,19.6568542 0,18 L0,7 C-2.02906125e-16,5.34314575 1.34314575,4 3,4 L21,4 Z M8.17224,7.5 L6.31224,7.5 C6.13824,7.5 6.00024,7.64109347 6.00024,7.81158142 C6.00024,7.98206937 6.13824,8.11728395 6.31224,8.11728395 L7.93224,8.11728395 L10.04424,15.5717225 C9.72024,15.7480894 9.50424,16.0831864 9.50424,16.4711934 C9.50424,17.0355673 9.97224,17.5 10.55424,17.5 C11.13024,17.5 11.60424,17.0355673 11.60424,16.4711934 C11.60424,16.324221 11.57424,16.1831276 11.51424,16.0596708 L14.80824,16.0596708 C14.75424,16.1831276 14.71824,16.324221 14.71824,16.4711934 C14.71824,17.0355673 15.19224,17.5 15.77424,17.5 C16.35024,17.5 16.82424,17.0355673 16.82424,16.4711934 C16.82424,15.9009406 16.35024,15.4365079 15.77424,15.4365079 L10.66224,15.4365079 L10.38624,14.4664903 L16.63224,14.4664903 C16.77624,14.4664903 16.90224,14.3665491 16.93824,14.2313345 L17.98824,10.016167 C18.01224,9.92210464 17.99424,9.82804233 17.92824,9.7516167 C17.87424,9.67519106 17.77824,9.6340388 17.68224,9.6340388 L9.01824,9.6340388 L8.47824,7.7292769 C8.43624,7.59406232 8.31624,7.5 8.17224,7.5 Z M10.55424,16.0596708 C10.78224,16.0596708 10.97424,16.2419165 10.97424,16.4711934 C10.97424,16.6945914 10.78224,16.882716 10.55424,16.882716 C10.32024,16.882716 10.13424,16.6945914 10.13424,16.4711934 C10.13424,16.2419165 10.32024,16.0596708 10.55424,16.0596708 Z M15.77424,16.0596708 C16.00224,16.0596708 16.19424,16.2419165 16.19424,16.4711934 C16.19424,16.6945914 16.00224,16.882716 15.77424,16.882716 C15.54024,16.882716 15.35424,16.6945914 15.35424,16.4711934 C15.35424,16.2419165 15.54024,16.0596708 15.77424,16.0596708 Z"
                    id="Combined-Shape"
                    fill={fill || '#002244'}></path>
            </g>
        </svg>
    );
};

export default TravelBank;
