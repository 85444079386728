export default (dimsUnit, linearSum) => {
    const linearSumToPrecision = Math.floor(linearSum * 1000) / 1000;
    if (dimsUnit === 'in') {
        return linearSumToPrecision <= 90;
    }
    if (dimsUnit === 'cm') {
        return linearSumToPrecision <= 228.6;
    }
    return false;
};
