import React from 'react';

const Bluetooth = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Digital/bluetooth"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="bluetooth"
                    transform="translate(5.000000, 0.000000)"
                    fill={fill || '#002244'}>
                    <path
                        d="M8.62318841,11.851432 L13.6956522,7.43377086 C13.8985507,7.33336947 14,7.03216531 14,6.83136253 C14,6.63055975 13.8985507,6.42975698 13.7971014,6.2289542 L7.91304348,0.305272265 C7.60869565,0.0040680991 7.30434783,-0.0963332896 6.89855072,0.104469488 C6.5942029,0.204870877 6.28985507,0.506075043 6.28985507,0.907680597 L6.28985507,9.7430028 L1.62318841,5.62654587 C1.2173913,5.3253417 0.710144928,5.3253417 0.304347826,5.62654587 C0.101449275,5.72694726 1.80210114e-15,5.92775003 1.80210114e-15,6.2289542 C1.80210114e-15,6.42975698 0.101449275,6.73096114 0.304347826,6.83136253 L5.98550725,11.851432 L0.304347826,16.7711 C0.101449275,16.9719028 1.80210114e-15,17.1727056 1.80210114e-15,17.3735083 C1.80210114e-15,17.5743111 0.101449275,17.8755153 0.304347826,17.9759167 C0.710144928,18.2771208 1.2173913,18.2771208 1.62318841,17.9759167 L6.28985507,13.8594597 L6.28985507,23.1967889 C6.28985507,23.5983944 6.5942029,23.8995986 6.89855072,24 C7,24 7.10144928,24 7.20289855,24 C7.50724638,24 7.71014493,23.8995986 7.91304348,23.6987958 L13.6956522,17.4739097 C13.7971014,17.273107 13.8985507,17.0723042 13.8985507,16.8715014 C13.8985507,16.6706986 13.7971014,16.4698958 13.5942029,16.2690931 L8.62318841,11.851432 Z M8.11594203,9.94380558 L8.11594203,3.11651115 L11.7681159,6.83136253 L8.11594203,9.94380558 Z M11.7681159,17.0723042 L8.11594203,20.9879583 L8.11594203,13.8594597 L11.7681159,17.0723042 Z"
                        id="Shape"></path>
                </g>
            </g>
        </svg>
    );
};

export default Bluetooth;
