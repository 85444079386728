import React from 'react';

const Filter = ({ width, height, fill, ...rest }) => {
    return (
        <svg
            {...rest}
            width={width || '24px'}
            height={height || '24px'}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g
                id="X-Global-Icons/Interactive/filter"
                stroke="none"
                stroke-width="1"
                fill="none"
                fillRule="evenodd">
                <path
                    d="M6,2 C7.48660106,2 8.72065259,3.08129303 8.9585702,4.50029469 L23,4.5 C23.5522847,4.5 24,4.94771525 24,5.5 C24,6.05228475 23.5522847,6.5 23,6.5 L8.59823591,6.50072151 C8.07941917,7.39702566 7.11012568,8 6,8 C4.88987432,8 3.92058083,7.39702566 3.40176409,6.50072151 L1,6.5 C0.44771525,6.5 0,6.05228475 0,5.5 C0,4.94771525 0.44771525,4.5 1,4.5 L3.0414298,4.50029469 C3.27934741,3.08129303 4.51339894,2 6,2 Z M9,16 C10.4866011,16 11.7206526,17.081293 11.9585702,18.5002947 L23,18.5 C23.5522847,18.5 24,18.9477153 24,19.5 C24,20.0522847 23.5522847,20.5 23,20.5 L11.5982359,20.5007215 C11.0794192,21.3970257 10.1101257,22 9,22 C7.88987432,22 6.92058083,21.3970257 6.40176409,20.5007215 L1,20.5 C0.44771525,20.5 0,20.0522847 0,19.5 C0,18.9477153 0.44771525,18.5 1,18.5 L6.0414298,18.5002947 C6.27934741,17.081293 7.51339894,16 9,16 Z M17,9 C18.4866011,9 19.7206526,10.081293 19.9585702,11.5002947 L23,11.5 C23.5522847,11.5 24,11.9477153 24,12.5 C24,13.0522847 23.5522847,13.5 23,13.5 L19.5982359,13.5007215 C19.0794192,14.3970257 18.1101257,15 17,15 C15.8898743,15 14.9205808,14.3970257 14.4017641,13.5007215 L1,13.5 C0.44771525,13.5 0,13.0522847 0,12.5 C0,11.9477153 0.44771525,11.5 1,11.5 L14.0414298,11.5002947 C14.2793474,10.081293 15.5133989,9 17,9 Z"
                    id="Combined-Shape"
                    fill="#002244"></path>
            </g>
        </svg>
    );
};

export default Filter;
