import { isTemplate } from 'basic_components/sagaFormatters';
import { push } from 'connected-react-router';
import { goBack } from '../../../actions';
import { FIELDS } from 'modules/bookingSteps/constants';
import { MODULE_ROUTE as BOOKING_ROUTE } from 'modules/bookings/constants';

import { setIsDraft } from 'modules/bookingSteps/actions';
import removeNotifications from './removeNotifications';

const handleBack = (dispatch, values, status, form, pathname) => {
    const hasAllotment = !!values[FIELDS.ALLOTMENT_ID];
    removeNotifications();
    if (isTemplate(form) && status === 'show') {
        dispatch(push('/templates/'));
    } else if (status === 'show') {
        dispatch(setIsDraft(false));
        return dispatch(push(BOOKING_ROUTE));
    } else {
        dispatch(goBack(pathname, hasAllotment));
    }
};

export default handleBack;
