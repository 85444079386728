import { isEmptyValues } from 'basic_components/jsTools';
import { openModal } from 'basic_components/Modal';
import { formatNotificationOrderByOptions } from 'basic_components/sagaFormatters';
import { saveNotificationsErrors } from 'modules/bookingSteps/actions';
import { FIELDS } from 'modules/bookingSteps/constants';
import { existNotificationError } from '../../notificationPreferences/schema';

const handleNotifications = async (
    dispatch,
    setFieldValue,
    validateForm,
    notificationsPreferencesStructure,
    showPopup = true
) => {
    const tempNotification = formatNotificationOrderByOptions(
        notificationsPreferencesStructure
    );

    if (existNotificationError(notificationsPreferencesStructure)) {
        dispatch(
            saveNotificationsErrors({
                arrayOfIndexWithTypeError: existNotificationError(
                    notificationsPreferencesStructure
                ),
            })
        );
        return false;
    } else {
        dispatch(
            saveNotificationsErrors({
                arrayOfIndexWithTypeError: [],
            })
        );
    }

    if (isEmptyValues(tempNotification) && showPopup) {
        dispatch(
            openModal('notificationModal', 'normal', 'Notification preferences')
        );
        await setFieldValue(FIELDS.NOTIFICATION_PREFERENCES, tempNotification);
        return false;
    }

    await setFieldValue(FIELDS.NOTIFICATION_PREFERENCES, tempNotification);

    const notificationsErrors = await validateForm();

    if (isEmptyValues(notificationsErrors)) {
        return true;
    }
    return false;
};

export default handleNotifications;
