import {
    CHECK_RECEIVED,
    LOGIN_SUCCESS,
    USER_DETAILS,
    UPDATE_NOTIFICATION_PREFERENCES,
    UPDATE_LOGIN_PROCESS_STATUS,
    UPDATE_MOBILE_ALERT_STATUS,
    UPDATE_DESKTOP_SCREEN_STATUS,
} from './actionTypes';

const initialState = {
    loggedIn: false,
    finishedCheck: false,
    desktopScreen: false,
    mobileAlert: false,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, loggedIn: true, token_cargo: action.token };
        case CHECK_RECEIVED:
            return { ...state, ...action.data };
        case USER_DETAILS:
            return { ...state, userInfo: action.details };
        case UPDATE_NOTIFICATION_PREFERENCES:
            let stateCopy = { ...state };
            stateCopy.userInfo.notification_preferences =
                action.notificationPreferences;
            return stateCopy;
        case UPDATE_LOGIN_PROCESS_STATUS:
            return { ...state, finishedCheck: action.status };
        case UPDATE_MOBILE_ALERT_STATUS:
            return {...state, mobileAlert: action.status};
        case UPDATE_DESKTOP_SCREEN_STATUS:
            return {...state, desktopScreen: action.status};
        default:
            return state;
    }
};
