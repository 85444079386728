import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import login from 'modules/login/reducers';
// Tools
import tableReducer from 'basic_components/AntdTable/reducers';
import { modalReducer } from 'basic_components/Modal';
// Modules
import contacts from 'modules/contacts/reducers';
import bookingSteps from 'modules/bookingSteps/reducers';
import bookings from 'modules/bookings/reducers';
import templates from 'modules/templates/reducers';

export default history =>
    combineReducers({
        router: connectRouter(history),
        user: login,
        contacts: contacts,
        modal: modalReducer,
        tables: tableReducer,
        bookings: bookings,
        bookingSteps: bookingSteps,
        templates: templates,
    });
